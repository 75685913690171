import { CSSProperties, ReactNode, forwardRef, useMemo } from 'react';
import { css, cx } from '../../../../../../core/emotion';
import { ConfigTheme } from './type';

type MenuPaneProps = {
  isOpen: boolean;
  children: ReactNode;
  style?: CSSProperties;
  className?: string;
  onMouseOut?: () => void;
  configTheme?: ConfigTheme;
};

const MenuPane = forwardRef<HTMLDivElement, MenuPaneProps>(
  (
    {
      isOpen,
      children,
      style,
      className,
      onMouseOut,
      configTheme,
      ...props
    }: MenuPaneProps,
    ref
  ) => {
    const menuStyle = useMemo(() => {
      return cx(
        css`
          width: 220px;
          box-shadow: 0px 3px 5px 0px #1b507140;

          ::-webkit-scrollbar {
            width: 5px;
          }

          ::-webkit-scrollbar-track {
            background-color: #f4f4f4;
            background-clip: padding-box;
            border-radius: 4px;
          }

          ::-webkit-scrollbar-thumb {
            background-color: #b9b9b9;
            border-radius: 4px;
          }
        `,
        className
      );
    }, [className]);

    return (
      <div
        ref={ref}
        {...props}
        className={cx(
          menuStyle,
          'rounded-medium z-10 overflow-y-auto bg-white',
          !isOpen ? 'pointer-events-none h-0 opacity-0' : 'max-h-57',
          css({ '&&': configTheme?.list?.root })
        )}
        style={style}
        onMouseOut={onMouseOut}
      >
        {isOpen ? children : null}
      </div>
    );
  }
);

export default MenuPane;
