import { useCallback, useEffect } from 'react';
import {
  MultipleSheetData,
  SheetData,
  SingleSpreadSheetMapper,
  MultipleFileSpreadSheetMapper,
  SpreadSheetMapper,
} from '../SpreadSheetMapper';
import SpreadSheetList from '../SpreadSheetList';
import { useContextConfirmModalManager } from 'baseUI/Confirm/context';
import { DataFile } from '../fileUtils';
import { useTranslation } from 'react-i18next';
import { usePage } from 'main/MainView';
import { useConfigure } from 'configure';
import { MAIN_PAGE_PATH } from 'core/constants/route';
import useLocationPathname from 'core/location';
import { copyNodeStyle } from 'core/style';

const useViewModel = ({
  onFileSelectedSuccess,
  onFileSelectedError,
}: {
  onFileSelectedSuccess: (spreadSheetList: SpreadSheetList) => void;
  onFileSelectedError?: () => void;
}) => {
  const { showConfirmModal } = useContextConfirmModalManager();
  const { pathname } = useLocationPathname();
  const { t } = useTranslation();
  const { closePage } = usePage();
  const { renderUploadButton } = useConfigure();

  /**
   * It takes an array of data files, filters out the ones that have no data, maps the remaining ones to
   * a SpreadSheetMapper, and then returns a SpreadSheetList
   * @param {DataFile[]} data - DataFile[] - This is the data that is returned from the file uploader.
   */
  const handleSuccess = (data: DataFile[]) => {
    const spreadSheets = data
      .filter((item) => item.data.length > 0)
      .map((dataItem) => {
        let spreadSheetMapper: SpreadSheetMapper;

        if (dataItem.type === 'text/csv') {
          spreadSheetMapper = new SingleSpreadSheetMapper({
            sheetData: dataItem.data as SheetData,
            filename: dataItem.filename,
            type: 'csv',
            sheetName: `${t('txt_default_sheet_prefix_name')} 1`,
            fileSize: dataItem.fileSize,
          });
        } else if (dataItem.type === 'application/json') {
          spreadSheetMapper = new SingleSpreadSheetMapper({
            sheetData: dataItem.data as SheetData,
            filename: dataItem.filename,
            type: 'json',
            sheetName: `${t('txt_default_sheet_prefix_name')} 1`,
            fileSize: dataItem.fileSize,
          });
        } else if (dataItem.type === 'text/xml') {
          spreadSheetMapper = new MultipleFileSpreadSheetMapper({
            multipleSheetData: dataItem.data as MultipleSheetData,
            filename: dataItem.filename,
            type: 'xml',
            fileSize: dataItem.fileSize,
          });
        } else {
          spreadSheetMapper = new MultipleFileSpreadSheetMapper({
            multipleSheetData: dataItem.data as MultipleSheetData,
            filename: dataItem.filename,
            type: 'xlsx',
            fileSize: dataItem.fileSize,
          });
        }

        return spreadSheetMapper.getSpreadSheet({
          autoDetectedHeader: true,
        });
      });
    onFileSelectedSuccess(new SpreadSheetList({ spreadSheets: spreadSheets }));
  };

  const handleError = useCallback(
    (errorMessage: string, title?: string) => {
      if (pathname === MAIN_PAGE_PATH) {
        closePage();
      }
      onFileSelectedError?.();
      showConfirmModal({
        isShowIcon: true,
        title: title ?? t('txt_import_error'),
        description: errorMessage,
        onClickNegativeButton: () => {},
        isShowPositiveButton: false,
        textNegativeButton: t('txt_submit_understand'),
      });
    },
    [showConfirmModal, t, closePage, onFileSelectedError, pathname]
  );

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (renderUploadButton) {
        const manualEntryButton = document.getElementById(
          'nuvo-manual-entry-button'
        );
        const wrapperRenderButton = document.getElementById(
          'nuvo-custom-upload-button'
        );
        const renderButton =
          wrapperRenderButton?.getElementsByTagName('button');

        if (renderButton?.[0] && manualEntryButton) {
          copyNodeStyle(renderButton?.[0], manualEntryButton);
        }
      }
    }, 0);

    return () => {
      clearTimeout(timeout);
    };
  }, [renderUploadButton]);

  return { handleSuccess, handleError, renderUploadButton };
};

export default useViewModel;
