import { useMemo } from 'react';
import { FieldMetaState } from 'react-final-form';
import { useTranslation } from 'react-i18next';

type ErrorMessageProps = {
  isFieldShowError: boolean;
  meta: FieldMetaState<string>;
};

const useErrorMessage = ({ isFieldShowError, meta }: ErrorMessageProps) => {
  const { t } = useTranslation();

  const isShowError = useMemo(() => {
    if (!isFieldShowError) {
      return false;
    }

    if (meta['error']) {
      return meta['submitFailed'] || meta['submitSucceeded'];
    } else if (meta['submitError']) {
      return meta['submitFailed'] && !meta['dirtySinceLastSubmit'];
    }
    return false;
  }, [meta, isFieldShowError]);

  const errorMessage = useMemo(
    () => t(meta.error) || t(meta.submitError),
    [t, meta]
  );

  return { isShowError, errorMessage };
};

export default useErrorMessage;
