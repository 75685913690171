import {
  CalculateSimilarityResult,
  ColumnConfig,
  ExecuteOptions,
  InputData,
  MatchingAble,
} from './types/ml';
import { Observable, zip, of, EMPTY } from 'rxjs';
import { calculateSimilarityResults } from './test/mock/calculateSimilarityResults';
import Tracking from '../tracking/Tracking';

export interface NuvoClientMachineLearningAble {
  execute: (
    columns: ColumnConfig[],
    inputJsons: InputData[],
    licenseKey: string,
    options: ExecuteOptions
  ) => Observable<CalculateSimilarityResult[]>;
  loadVector: () => Observable<unknown>;
  getVectors: () => Promise<Record<string, Float32Array>>;
}

class NuvoMachineLearning implements NuvoClientMachineLearningAble {
  private matching: MatchingAble;
  private locked = false;

  getVectors = () => {
    return this.matching.getVectors();
  };

  constructor(matching: MatchingAble) {
    this.matching = matching;
  }

  loadVector = () => {
    if (this.locked) {
      return EMPTY;
    }

    return new Observable((subscriber) => {
      this.locked = true;
      this.matching
        .loadVector()
        .then(() => {
          subscriber.next();
        })
        .catch((error) => {
          subscriber.error(error);
        })
        .finally(() => {
          this.locked = false;
          subscriber.complete();
        });
    });
  };

  private executeOne = (
    columns: ColumnConfig[],
    inputJson: InputData,
    licenseKey: string,
    options: ExecuteOptions
  ) => {
    return new Observable<CalculateSimilarityResult>((subscriber) => {
      setTimeout(() => {
        this.matching
          .execute(
            columns,
            inputJson,
            {
              licenseKey,
              framework: Tracking.getFrameWork(),
            },
            options
          )
          .then((result) => {
            subscriber.next(result);
          })
          .catch((error) => {
            subscriber.error(error);
          })
          .finally(() => {
            subscriber.complete();
          });
      }, 100);
    });
  };

  execute = (
    columns: ColumnConfig[],
    inputJsons: InputData[],
    licenseKey: string,
    options: ExecuteOptions
  ) => {
    return zip(
      inputJsons.map((inputJson) => {
        return this.executeOne(columns, inputJson, licenseKey, options);
      })
    );
  };

  cleanUp = () => {
    this.cleanUp();
  };
}

export class MockNuvoMachineLearning implements NuvoClientMachineLearningAble {
  getVectors = () => {
    return Promise.resolve({ a: new Float32Array(1).fill(0) });
  };

  execute = () => {
    return zip([of(calculateSimilarityResults[0])]);
  };

  loadVector = () => {
    return of({});
  };
}

export default NuvoMachineLearning;
