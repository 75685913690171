import { useFormState } from 'react-final-form';
import { useFieldArray } from 'react-final-form-arrays';
import DataModelSheetMatching from './../../../matching/DataModelSheetMatching';
import DataModelSheetMatcherModel from './../../../matching/DataModelSheetMatcher';
import DataModelSheetMatcher from '../DataModelSheetMatcher';
import { FormValues } from '../viewModel';
import Sheet from './../../../sheetImporter/Sheet';
import { useTheme } from 'theme';
import { Fragment, useMemo } from 'react';
import { cx } from 'core/emotion';
import { useTranslation } from 'react-i18next';
import { SHEET_COLUMN_TYPE } from 'core/constants/sheet';
import JoinColumnsModal from 'baseUI/Modal/JoinColumns';
import { MLExecuteOptions } from '../../../matching/MatchingRepository/MatchingRepository';

type DataModelSheetMatcherListProps = {
  sheets: Sheet[];
  dataModelSheetMatching: DataModelSheetMatching;
  dataModelSheetMatcherModel: DataModelSheetMatcherModel;
  setDataModelSheetMatcher: (
    dataModelSheetMatcher: DataModelSheetMatcherModel
  ) => void;
  mlExecuteOptions: MLExecuteOptions;
};

const DataModelSheetMatcherList = ({
  sheets,
  dataModelSheetMatching,
  dataModelSheetMatcherModel,
  setDataModelSheetMatcher,
  mlExecuteOptions,
}: DataModelSheetMatcherListProps) => {
  const { fields } = useFieldArray('matching', {
    subscription: {
      value: true,
    },
  });
  const { values } = useFormState<FormValues>({
    subscription: {
      values: true,
    },
  });
  const { t } = useTranslation();

  const theme = useTheme();

  const configTheme = useMemo(() => {
    return theme.getColumnMatchTheme();
  }, [theme]);

  const configThemePopper = useMemo(() => {
    return theme.getPopoverInfo();
  }, [theme]);

  const getSheetOnlyFirst = (index: number) => {
    const match = values?.matching[index];
    const prevMatch = values?.matching[index - 1];

    const isPrevHasSameSheet =
      match.sheetColumn.getSheet() !== prevMatch?.sheetColumn.getSheet();

    if (
      (isPrevHasSameSheet || index === 1) &&
      sheets.length > 1 &&
      match.sheetColumn.getType() !== SHEET_COLUMN_TYPE.JOIN
    ) {
      return match.sheetColumn.getSheet();
    }

    return null;
  };

  return (
    <>
      {fields.map((name, index) => {
        const match = values?.matching[index];

        if (!match) {
          return null;
        }

        const sheet = getSheetOnlyFirst(index);

        const matchedDataModel = dataModelSheetMatching
          .getDataModels()
          .find(
            (entry) =>
              entry.getKey() === match.matchedDataModel?.dataModel?.getKey()
          );

        return (
          <Fragment key={name}>
            {sheet ? (
              <div className={cx('mb-4 flex', index > 0 ? 'mt-10' : '')}>
                <p className="text-neutral-gray mr-2 font-semibold">
                  {sheet.getDisplayName({ t })}
                </p>
                <p className="text-gray-560 text-sm">
                  {sheet.getSpreadSheet().getFilename()}
                </p>
              </div>
            ) : null}
            <DataModelSheetMatcher
              prefixName={name}
              matchedDataModel={matchedDataModel}
              sheetColumn={match.sheetColumn}
              dataModelSheetMatcherModel={dataModelSheetMatcherModel}
              dataModelSheetMatching={dataModelSheetMatching}
              configTheme={configTheme}
              configThemePopper={configThemePopper}
              index={index}
              setDataModelSheetMatcher={setDataModelSheetMatcher}
              mlExecuteOptions={mlExecuteOptions}
            />
          </Fragment>
        );
      })}
      <JoinColumnsModal />
    </>
  );
};

export default DataModelSheetMatcherList;
