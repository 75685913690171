import Button from 'baseUI/Button';
import { cx } from 'core/emotion';
import { useTranslation } from 'react-i18next';
import useViewModel from './viewModel';
import { ModelTemplate } from 'core/constants/modelTemplate';
import { useUploadDataFormConfig } from '../uploadDataFormConfig';
import { Header } from 'navigation';
import { useSettings } from 'settings';
import { useLicenseKeyAuth } from 'license';

type DownloadTemplateProps = {
  className?: string;
  title?: string;
  titleClassName?: string;
};

const DownloadTemplateBanner = ({
  className,
  title,
  titleClassName,
}: DownloadTemplateProps) => {
  const { t } = useTranslation();
  const { downloadTemplateSheet } = useViewModel();
  const { disableTemplates, disableExcelTemplate } = useUploadDataFormConfig();
  const { multipleFileUpload } = useSettings();
  const { isLoading } = useLicenseKeyAuth();

  return (
    <div className={cx('relative', className)}>
      <Header
        isLoading={isLoading}
        title={title ?? t('txt_upload_data')}
        description={t(
          multipleFileUpload
            ? 'txt_page_multiple_upload_desc'
            : 'txt_page_upload_desc'
        )}
        scaleFontByWidth={true}
        className={cx('flex-shrink-0', titleClassName)}
        readerActions={
          <div>
            {!disableTemplates && !isLoading ? (
              <div className={cx('mt-4 flex w-auto flex-shrink-0 pl-8')}>
                {!disableExcelTemplate ? (
                  <Button
                    variant="secondary"
                    className={`${
                      isLoading ? 'w-52' : 'xsmd:w-auto min-w-52 w-full'
                    } min-h-40 mr-2 !px-8`}
                    onClick={() => {
                      downloadTemplateSheet(ModelTemplate.XLSX);
                    }}
                  >
                    {t('txt_download_excel')}
                  </Button>
                ) : null}
                <Button
                  variant="secondary"
                  className={`${
                    isLoading ? 'w-40' : 'xsmd:w-auto min-h-40 w-full'
                  } !px-8`}
                  onClick={() => {
                    downloadTemplateSheet(ModelTemplate.CSV);
                  }}
                >
                  {t('txt_download_csv')}
                </Button>
              </div>
            ) : null}
          </div>
        }
      />
    </div>
  );
};

export default DownloadTemplateBanner;
