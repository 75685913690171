import { useMainView } from '../../../../../settings/MainView';
import { useDropdownBoundary } from '../dropdownBoundary';
import MenuButton from './MenuButton';
import MenuItems from './MenuItems';
import SearchInput from './SearchInput';
import CreateNewColumnInput from './CreateNewColumnInput';
import { DropdownProps } from './types';
import Portal from 'baseUI/Portal';
import { useTranslation } from 'react-i18next';

const Dropdown = ({
  popper,
  configThemeDropdownButton,
  shownValue,
  variant,
  isOpenDropdown,
  isEmpty,
  onClear,
  getToggleButtonProps,
  onMenuButtonKeyDown,
  menuButtonRef,
  searchRef,
  getMenuProps,
  onMenuKeyDown,
  menuRef,
  configThemeDropdownMenuItems,
  getItemProps,
  highlightedIndex,
  options,
  searchOptions,
  selectedItem,
  onRemoveMatchColumn,
  setHighlightedIndex,
  setSearchValue,
  isSearching,
  onCreateColumnModel,
  toggleDropdown,
  allowCustomColumns,
}: DropdownProps) => {
  const { modal } = useMainView();
  const { pageWrapperElement } = useDropdownBoundary();
  const { t } = useTranslation();

  return (
    <div className="w-full">
      <div ref={popper.referenceElement}>
        <MenuButton
          configThemeDropdownButton={configThemeDropdownButton}
          shownValue={shownValue}
          variant={variant}
          open={isOpenDropdown}
          isEmpty={isEmpty}
          onClear={onClear}
          {...getToggleButtonProps({
            onKeyDown: onMenuButtonKeyDown,
            ref: menuButtonRef,
            onClick: () => {
              setTimeout(() => {
                searchRef.current?.focus();
              }, 0);
            },
          })}
        />
      </div>

      {isOpenDropdown ? (
        <Portal enable={modal} root={pageWrapperElement}>
          <div
            {...getMenuProps({
              onKeyDown: onMenuKeyDown,
              ref: menuRef,
            })}
          >
            <MenuItems
              configThemeDropdownMenuItems={configThemeDropdownMenuItems}
              getItemProps={getItemProps}
              highlightedIndex={highlightedIndex}
              options={options}
              searchOptions={searchOptions}
              popper={popper}
              selectedItem={selectedItem}
              onRemoveMatchColumn={onRemoveMatchColumn}
              setHighlightedIndex={setHighlightedIndex}
              renderSearch={() => (
                <SearchInput
                  theme={configThemeDropdownMenuItems?.menuItem?.search}
                  headerTheme={configThemeDropdownMenuItems?.header}
                  ref={searchRef}
                  onChange={(value) => setSearchValue(value)}
                />
              )}
              isSearching={isSearching}
              renderNewColumn={() => (
                <CreateNewColumnInput
                  ref={searchRef}
                  label={t('txt_create_new_column')}
                  onCreateColumn={() => {
                    onCreateColumnModel();
                    toggleDropdown();
                  }}
                  configTheme={
                    configThemeDropdownMenuItems?.menuItem?.createNewOption
                  }
                />
              )}
              allowCustomColumns={allowCustomColumns}
            />
          </div>
        </Portal>
      ) : (
        <div className="hidden" {...getMenuProps()} />
      )}
    </div>
  );
};

export default Dropdown;
