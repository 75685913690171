import { useTranslation } from 'react-i18next';
import { ReactComponent as CSVFileIcon } from '../../assets/icon/csv-file.svg';
import { ReactComponent as XLSXFileIcon } from '../../assets/icon/xlsx-file.svg';
import { ReactComponent as XMLFileIcon } from '../../assets/icon/xml-file.svg';
import { ReactComponent as JSONFileIcon } from '../../assets/icon/json-file-icon.svg';
import { ReactComponent as TSVFileIcon } from '../../assets/icon/tsv-file.svg';
import SpreadSheetList from '../SpreadSheetList';
import { css, cx } from 'core/emotion';
import { useTheme } from 'theme';
import useViewModel from './viewModel';
import MainUploader from '../MainUploader';
import UploadButton from './../UploadButton';
import { useSettings } from 'settings';
import Button from './../../baseUI/Button';
import { useLicenseKeyAuth, useWarnLicense } from 'license';
import {
  FileSupportIconSkeleton,
  TextSkeleton,
  useScreenSize,
} from '@nuvo-importer/common';
import { useWarnSmallScreen } from 'hooks';
import { breakpoints } from '@nuvo-importer/common/sdk';
import { useCallback, useMemo } from 'react';

type FileImporterProps = {
  onFileSelectedSuccess: (spreadSheetList: SpreadSheetList) => void;
  onFileSelectedError: () => void;
  onProcessing: () => void;
  className?: string;
  containerClassName?: string;
  onClickManualEntry: () => void;
};

const FileImportBanner = ({
  onFileSelectedSuccess,
  onProcessing,
  className,
  containerClassName,
  onClickManualEntry,
  onFileSelectedError,
}: FileImporterProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const {
    multipleFileUpload,
    modal,
    allowManualInput,
    embedUploadArea,
    disableTemplates,
    developerMode,
  } = useSettings();
  const { handleSuccess, handleError, renderUploadButton } = useViewModel({
    onFileSelectedSuccess,
    onFileSelectedError,
  });
  const { isLoading, isAuth } = useLicenseKeyAuth();
  const { warnLicenseKeyIsInvalid } = useWarnLicense();
  const { isNotSmallScreen } = useScreenSize();
  const { warnSmallScreen } = useWarnSmallScreen();

  const boxStyle = useMemo(() => {
    return css`
      .box {
        fill: ${theme.getGlobalTheme().getPrimaryColor()};
      }
      .box.box {
        ${theme.getDropzoneTheme().icon.box}
      }
    `;
  }, [theme]);

  const FileSupportIcon = useCallback(() => {
    return (
      <div
        className={cx(
          'mb-4 flex',
          css({
            color: theme.getGlobalTheme().getPrimaryColor(),
          })
        )}
      >
        <XLSXFileIcon
          className={cx(
            'max-w-114 h-auto w-full min-w-0 flex-shrink',
            boxStyle
          )}
        />
        <CSVFileIcon
          className={cx(
            'max-w-114 h-auto w-full min-w-0 flex-shrink',
            boxStyle
          )}
        />
        <TSVFileIcon
          className={cx(
            'max-w-114 h-auto w-full min-w-0 flex-shrink',
            boxStyle
          )}
        />
        <XMLFileIcon
          className={cx(
            'max-w-114 h-auto w-full min-w-0 flex-shrink',
            boxStyle
          )}
        />
        <JSONFileIcon
          className={cx(
            'max-w-114 h-auto w-full min-w-0 flex-shrink',
            boxStyle
          )}
        />
      </div>
    );
  }, [boxStyle, theme]);

  return (
    <MainUploader
      className={containerClassName}
      onFileSelectedSuccess={handleSuccess}
      onFileSelectedError={handleError}
      onProcessing={onProcessing}
    >
      <div
        className={cx(
          'rounded-2lg flex h-full flex-col items-center justify-center bg-gray-50 px-7',
          modal && !embedUploadArea ? 'py-0' : 'py-24',
          css({
            '&&': theme.getDropzoneTheme().root,
          }),
          css`
            background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='%23B3BCC0FF' stroke-width='1' stroke-dasharray='10%2c 10' stroke-dashoffset='86' stroke-linecap='round'/%3e%3c/svg%3e");
          `,
          className
        )}
      >
        {isLoading ? (
          <FileSupportIconSkeleton className="mb-4" />
        ) : (
          <FileSupportIcon />
        )}
        <div className="mb-4">
          {isLoading ? (
            <TextSkeleton className="w-90 h-5.5" />
          ) : (
            <p className="text-color-primary text-center text-lg">
              {t(
                multipleFileUpload
                  ? 'txt_allow_multiple_data_type'
                  : 'txt_allow_data_type'
              )}
            </p>
          )}
        </div>
        <div className="mb-4">
          {isLoading ? (
            <TextSkeleton className="h-5.5 w-90 mx-4" />
          ) : (
            <div className="w-90 flex items-center">
              <div className="h-px flex-1 bg-gray-300" />
              <p className="text-color-primary px-4 text-left text-lg">
                {t('txt_or')}
              </p>
              <div className="h-px flex-1 bg-gray-300" />
            </div>
          )}
        </div>
        <div
          className={cx(
            'flex w-full justify-center',
            css`
              max-width: 366px;
              @media (min-width: ${breakpoints.lg}) {
                max-width: none;
              }
            `
          )}
        >
          {isLoading ? (
            <div>
              <TextSkeleton className="h-5.5 w-50" />
              <div
                className={cx(
                  !modal
                    ? developerMode
                      ? 'h-79'
                      : 'h-38'
                    : embedUploadArea && disableTemplates
                    ? 'h-9'
                    : ''
                )}
              />
            </div>
          ) : (
            <div
              className={cx(
                'min-h-42 space-y-4 space-x-0 md:flex-wrap lg:flex lg:flex-nowrap lg:items-center lg:justify-center lg:space-x-4 lg:space-y-0',
                css`
                  @media only screen and (max-width: 914px) {
                    > button {
                      margin-top: 16px !important;
                    }
                  }
                `
              )}
            >
              <UploadButton
                onLaunch={() => {}}
                className={`${className} min-h-42`}
              />
              {allowManualInput && (
                <div className="flex h-auto items-center justify-center">
                  <Button
                    id="nuvo-manual-entry-button"
                    variant="tertiary"
                    disabled={isLoading}
                    loading={isLoading}
                    className={cx('lg:min-w-50 min-h-42 h-auto w-full')}
                    noSpan={!!renderUploadButton}
                    onClick={(e) => {
                      e.stopPropagation();
                      !isAuth
                        ? warnLicenseKeyIsInvalid()
                        : isNotSmallScreen
                        ? onClickManualEntry()
                        : warnSmallScreen();
                    }}
                  >
                    {t('txt_manual_entry')}
                  </Button>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </MainUploader>
  );
};

export default FileImportBanner;
