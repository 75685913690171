import { useMemo, useRef } from 'react';
import { usePopper as useBasePopper } from 'react-popper';

const usePopper = () => {
  const referenceElement = useRef<HTMLDivElement>(null);
  const popperElement = useRef<HTMLDivElement>(null);
  const modifiers = useMemo(
    () => [
      {
        name: 'offset',
        options: {
          offset: [0, 16],
        },
      },
      {
        name: 'preventOverflow',
        enabled: true,
        options: {
          tether: true,
          altBoundary: true,
        },
      },
      {
        name: 'flip',
        enabled: true,
      },
      {
        name: 'computeStyles',
        options: {
          gpuAcceleration: false,
        },
      },
    ],
    []
  );

  const { styles, attributes } = useBasePopper(
    referenceElement.current,
    popperElement.current,
    {
      placement: 'bottom',
      modifiers,
    }
  );

  const popper = useMemo(() => {
    return {
      styles,
      attributes,
      referenceElement,
      popperElement,
    };
  }, [styles, attributes]);

  return { popper };
};

export default usePopper;
