import { useContextCircleLoadingModalManager } from 'baseUI/Modal/CircleLoading/context';
import {
  createContext,
  useContext,
  useState,
  ReactNode,
  useCallback,
} from 'react';

type ContextType = {
  uploadWebWorker: Worker | null;
  setUploadWebWorker: (worker: Worker) => void;
  terminateUploadWebWorker: () => void;
};

const WebWorkerContext = createContext({} as ContextType);

const WebWorkerManager = ({ children }: { children: ReactNode }) => {
  const [uploadWebWorker, setUploadWebWorker] = useState<Worker | null>(null);
  const { setProcessing } = useContextCircleLoadingModalManager();

  const terminateUploadWebWorker = useCallback(() => {
    uploadWebWorker?.terminate();
    setProcessing(false);
  }, [uploadWebWorker, setProcessing]);

  return (
    <WebWorkerContext.Provider
      value={{
        uploadWebWorker,
        setUploadWebWorker,
        terminateUploadWebWorker,
      }}
    >
      {children}
    </WebWorkerContext.Provider>
  );
};

export const useContextWebWorkerManager = () => {
  return useContext(WebWorkerContext);
};

export default WebWorkerManager;
