import {
  DataModel,
  DEFAULT_LEVEL,
  RecordInfo,
  SheetData,
  SpreadSheet,
  SpreadSheetList,
  SpreadSheetNavigate,
  Value,
  Values,
  ValueParser,
} from '@nuvo-importer/common/sdk';
import { parse } from '@getnuvo/json-parser';
import { isNil, isArray, isObject, isString, isEmpty } from 'lodash';
import { ERROR_CODE_IMPORT_FILE } from '../../errors/errorCode';
import {
  SingleSpreadSheetMapper,
  MultipleFileSpreadSheetMapper,
  MultipleSheetData,
} from '../../sheetImporter/SpreadSheetMapper';
import { getIsDataInSheetDataFormat } from '../../sheetImporter/utils';
import {
  HookedRecordInfo,
  UploadData,
  SheetSelectionUploadData,
} from '../../types';

export class NuvoSessionHeaderAndMatchingHandler {
  handle = async ({
    data,
    headerIndex,
    hasDateType,
    advancedParsing,
    automaticHeaderDetection,
  }: {
    data: UploadData;
    headerIndex?: number;
    hasDateType: boolean;
    advancedParsing: boolean;
    automaticHeaderDetection?: boolean;
  }) => {
    let parsedData: SheetData;

    const isDataInSheetDataFormat = getIsDataInSheetDataFormat(data);

    if (isDataInSheetDataFormat) {
      parsedData = data as SheetData;
    } else {
      try {
        parsedData = (await parse(JSON.stringify(data), {
          hasDateType: hasDateType,
          advancedParsing: advancedParsing,
        })) as SheetData;
      } catch (error) {
        throw new Error(`${ERROR_CODE_IMPORT_FILE.INVALID_FORMAT}`);
      }
    }

    const spreadSheetMapper = new SingleSpreadSheetMapper({
      sheetData: parsedData as SheetData,
      filename: '',
      type: 'dynamic-import',
      sheetName: '',
      fileSize: 0,
    });

    let spreadSheet: SpreadSheet;

    if (!isDataInSheetDataFormat) {
      spreadSheet = spreadSheetMapper.getSpreadSheet({
        autoDetectedHeader: automaticHeaderDetection ?? false,
      });
    } else if (!isNil(headerIndex)) {
      spreadSheet = spreadSheetMapper.getSpreadSheet({
        autoDetectedHeader: false,
      });
      const data = spreadSheet.getSheets()[0].getData();
      if (headerIndex >= 0 && headerIndex < data.length) {
        spreadSheet.getSheets()[0].setHeaderRowIndex(headerIndex);
      } else {
        spreadSheet.getSheets()[0].setHeaderByAutoDetection();
      }
    } else {
      spreadSheet = spreadSheetMapper.getSpreadSheet({
        autoDetectedHeader: true,
      });
    }

    const spreadSheetList = new SpreadSheetList({
      spreadSheets: [spreadSheet],
    });

    spreadSheetList.selectAllSheets(true);

    const spreadSheetNavigate = new SpreadSheetNavigate({
      spreadSheetList: spreadSheetList,
    });

    return spreadSheetNavigate;
  };
}

type ReviewEntriesData = Record<
  string,
  { value?: Value; info?: HookedRecordInfo[] }
>[];

export class NuvoSessionReviewEntriesHandler {
  private dataModels: DataModel[];
  private generateMemorizeFindDataModel = () => {
    const keyDataModels: Record<string, DataModel> = {};
    const colIndexDataModels: Record<string, number> = {};

    for (let i = 0; i < this.dataModels.length; i++) {
      const element = this.dataModels[i];
      keyDataModels[element.getKey()] = element;
      colIndexDataModels[element.getKey()] = i;
    }

    return (key: string) => {
      return {
        dataModel: keyDataModels[key],
        colIndex: colIndexDataModels[key],
      };
    };
  };

  private checkInvalidFormat = (data: UploadData) => {
    return !isArray(data) || data.some((row) => !isObject(row) && !isNil(row));
  };

  constructor(dataModels: DataModel[]) {
    this.dataModels = dataModels;
  }

  handle = ({ data }: { data: UploadData }) => {
    const reviewEntriesData = data as ReviewEntriesData;

    if (this.checkInvalidFormat(data)) {
      throw new Error(`${ERROR_CODE_IMPORT_FILE.INVALID_FORMAT}`);
    }

    const memorizeFindDataModel = this.generateMemorizeFindDataModel();
    const parsedValues: Values = [];
    const dataInfos: Record<string, RecordInfo[]> = {};

    for (let i = 0; i < reviewEntriesData.length; ++i) {
      parsedValues[i] = {};
      if (isNil(reviewEntriesData[i])) {
        continue;
      }
      const keys = Object.keys(reviewEntriesData[i]);

      for (let j = 0; j < keys.length; ++j) {
        const key = keys[j];
        const dataModelInfo = memorizeFindDataModel(key);

        if (!dataModelInfo.dataModel) {
          throw new Error(`${ERROR_CODE_IMPORT_FILE.NOT_MATCHED_TDM}`);
        }

        if (
          isObject(reviewEntriesData[i][key]) &&
          !isArray(reviewEntriesData[i][key])
        ) {
          if (!isNil(reviewEntriesData[i][key].value)) {
            parsedValues[i][key] = ValueParser.parse(
              reviewEntriesData[i][key].value,
              {
                dataModel: dataModelInfo.dataModel,
              }
            );
          }
          if (isNil(reviewEntriesData[i][key].info)) continue;

          reviewEntriesData[i][key].info?.forEach((it) => {
            const obj = {
              rowIndex: i,
              colIndex: dataModelInfo.colIndex,
              popover: {
                message: it.message,
                level: it.level || DEFAULT_LEVEL,
              },
            };
            if ((dataInfos[i]?.length ?? 0) === 0) {
              dataInfos[i] = [obj];
            } else {
              dataInfos[i].push(obj);
            }
          });
        } else {
          if (!isNil(reviewEntriesData[i][key])) {
            parsedValues[i][key] = ValueParser.parse(
              reviewEntriesData[i][key],
              {
                dataModel: dataModelInfo.dataModel,
              }
            );
          }
        }
      }
    }

    return { dataInfos, parsedValues };
  };
}

export class NuvoSessionSheetSelectionHandler {
  private checkInvalidFormat(files: SheetSelectionUploadData) {
    if (!Array.isArray(files)) {
      return true;
    }

    for (const file of files) {
      if (
        !isString(file?.fileName) ||
        file.fileName.trim() === '' ||
        !Array.isArray(file?.sheets) ||
        file.sheets.length === 0
      ) {
        // NOTE: Each object should have "fileName" and "sheets" properties, with valid values
        return true;
      }

      const sheetNames: string[] = [];

      for (const sheet of file.sheets) {
        // check for similar sheet names within a file
        if (sheetNames.includes(sheet.sheetName)) {
          return true;
        } else {
          sheetNames.push(sheet.sheetName);
        }
        if (
          !isString(sheet?.sheetName) ||
          sheet?.sheetName.trim() === '' ||
          !Array.isArray(sheet?.data) ||
          sheet.data.length === 0 ||
          (Array.isArray(sheet?.data[0]) && sheet.data[0].length === 0) ||
          (!Array.isArray(sheet?.data[0]) && isEmpty(sheet.data[0]))
        ) {
          // NOTE: Each "sheets" object should have "sheetName" and "data" properties, with valid values
          return true;
        }
      }
    }

    return false;
  }

  handle = async ({
    data,
    hasDateType,
    advancedParsing,
  }: {
    data: UploadData;
    hasDateType: boolean;
    advancedParsing: boolean;
  }) => {
    if (this.checkInvalidFormat(data as SheetSelectionUploadData)) {
      throw new Error(`${ERROR_CODE_IMPORT_FILE.INVALID_FORMAT}`);
    }

    const spreadSheets: SpreadSheet[] = [];

    for (const file of data as SheetSelectionUploadData) {
      for (const sheet of file.sheets) {
        if (!getIsDataInSheetDataFormat(sheet.data)) {
          try {
            sheet.data = (await parse(JSON.stringify(sheet.data), {
              hasDateType: hasDateType,
              advancedParsing: advancedParsing,
            })) as SheetData;
          } catch (error) {
            throw new Error(`${ERROR_CODE_IMPORT_FILE.INVALID_FORMAT}`);
          }
        }
      }

      const spreadSheetMapper = new MultipleFileSpreadSheetMapper({
        multipleSheetData: file.sheets as MultipleSheetData,
        filename: file.fileName,
        type: 'dynamic-import',
        fileSize: 0,
      });

      spreadSheets.push(
        spreadSheetMapper.getSpreadSheet({ autoDetectedHeader: false })
      );
    }

    const spreadSheetList = new SpreadSheetList({
      spreadSheets: spreadSheets,
    });

    return spreadSheetList;
  };
}
