import { Form as FormValue } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import DataModelSheetMatcherList from './DataModelSheetMatcherList';
import useViewModel, { FormValues } from './viewModel';
import DataModelSheetMatcher from './../../matching/DataModelSheetMatcher';
import DataModelSheetMatching from './../../matching/DataModelSheetMatching';
import FormFooter from './FormFooter';
import { cx } from 'core/emotion';
import { useMainView } from 'settings';
import { CollapseContext, useCollapseProvider } from './FormFooter/collapse';
import { useTranslation } from 'react-i18next';
import Header from '../../navigation/Header';
import { MLExecuteOptions } from '../../matching/MatchingRepository/MatchingRepository';
import { useTheme } from 'theme';

const DataModelSheetMatcherForm = ({
  dataModelSheetMatching,
  dataModelSheetMatcher,
  isShowFieldRequired,
  setShowFieldRequired,
  setDataModelSheetMatcher,
  mlExecuteOptions,
}: {
  dataModelSheetMatching: DataModelSheetMatching;
  dataModelSheetMatcher: DataModelSheetMatcher;
  isShowFieldRequired: boolean;
  setShowFieldRequired: (isShowRequired: boolean) => void;
  setDataModelSheetMatcher: (
    dataModelSheetMatcher: DataModelSheetMatcher
  ) => void;
  mlExecuteOptions: MLExecuteOptions;
}) => {
  const { t } = useTranslation();
  const { initialValues, sheets, onBackClick, onSubmit, isModalClose } =
    useViewModel({
      setShowFieldRequired,
      dataModelSheetMatching,
      dataModelSheetMatcher,
    });
  const { isCollapse, isShow, setIsCollapse, setIsShow } =
    useCollapseProvider();

  const { modal } = useMainView();

  const theme = useTheme();
  const pageHeaderTheme = theme.getColumnMatchTheme().pageHeader;

  return (
    <FormValue<FormValues>
      initialValues={initialValues}
      onSubmit={onSubmit}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      mutators={arrayMutators as any}
      subscription={{
        values: true,
      }}
      validateOnBlur
      render={({ handleSubmit, values }) => {
        return (
          <form
            onSubmit={handleSubmit}
            className={cx(
              'flex flex-grow flex-col justify-start',
              modal ? 'px-0' : 'px-6'
            )}
          >
            {t('txt_match_column')?.length > 0 ||
            t('txt_match_column_description')?.length > 0 ? (
              <div className="-mt-3">
                <Header
                  title={t('txt_match_column')}
                  description={t('txt_match_column_description')}
                  wrapperClassName={!modal ? '!px-0' : ''}
                  headerTheme={pageHeaderTheme}
                />
              </div>
            ) : null}
            <CollapseContext.Provider
              value={{
                isCollapse,
                isShow,
              }}
            >
              <div className="large:pb-10 match-column-page w-full pb-24">
                <DataModelSheetMatcherList
                  sheets={sheets}
                  dataModelSheetMatching={dataModelSheetMatching}
                  dataModelSheetMatcherModel={dataModelSheetMatcher}
                  setDataModelSheetMatcher={setDataModelSheetMatcher}
                  mlExecuteOptions={mlExecuteOptions}
                />
              </div>
              <FormFooter
                values={values}
                isModalClose={isModalClose}
                onBackClick={onBackClick}
                isShowFieldRequired={isShowFieldRequired}
                onIsAvailableCollapseChange={setIsCollapse}
                onIsShowChange={setIsShow}
              />
            </CollapseContext.Provider>
          </form>
        );
      }}
    />
  );
};

export default DataModelSheetMatcherForm;
