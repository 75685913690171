import { UseSelectStateChange, useSelect } from 'downshift';
import { useMemo } from 'react';
import { usePopper } from './popper';

type ViewModelProps<T> = {
  value: T;
  onChange: (value: T) => void;
  options: Option<T>[];
};

export type Option<T> = {
  label: string;
  value: T;
  separator?: boolean;
};

const useViewModel = <T>({ value, onChange, options }: ViewModelProps<T>) => {
  const popper = usePopper();

  const handleChange = (changes: UseSelectStateChange<Option<T>>) => {
    if (changes.selectedItem?.value) {
      onChange(changes.selectedItem?.value);
    }
  };

  const {
    isOpen,
    selectedItem,
    getToggleButtonProps,
    getMenuProps,
    highlightedIndex,
    getItemProps,
  } = useSelect<Option<T>>({
    items: options,
    selectedItem:
      options.find((option) => option.value === value) ?? options[0],
    onSelectedItemChange: handleChange,
  });

  const shownValue = useMemo(() => {
    return selectedItem?.label;
  }, [selectedItem]);

  return {
    isOpen,
    selectedItem,
    getToggleButtonProps,
    getMenuProps,
    highlightedIndex,
    getItemProps,
    popper,
    shownValue,
  };
};

export default useViewModel;
