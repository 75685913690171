import { css, CSSInterpolation, cx } from '@nuvo-importer/common';
import { COLOR_JOIN_SHEET } from 'core/constants/colors';
import { Sheet } from 'sheetImporter';
import { ReactComponent as CheckColumn } from '../../../../assets/icon/check-column.svg';
import ToggleButton from '../ToggleButton';
import useViewModel from './viewModel';

type HeaderTableProps = {
  sheet: Sheet;
  isCollapse: boolean;
  toggleCollapse: () => void;
  configTheme?: {
    sheetName?: CSSInterpolation;
    fileName?: CSSInterpolation;
    icon?: { valid?: string; invalid?: string };
  };
  isSelected: boolean;
  matchPercentage: number;
  sheetIndex: number;
};

const HeaderTable = ({
  sheet,
  configTheme,
  toggleCollapse,
  isCollapse,
  isSelected,
  matchPercentage,
  sheetIndex,
}: HeaderTableProps) => {
  const { sheetName, fileName } = useViewModel({ sheet });

  return (
    <div
      className={cx(
        'flex items-center justify-between',
        isCollapse ? 'mb-4' : ''
      )}
    >
      <div className="flex items-center">
        <CheckColumn
          className={cx(css`
            rect {
              fill: ${isSelected && sheetIndex === 0
                ? configTheme?.icon?.valid || COLOR_JOIN_SHEET.SUCCESS_TEXT
                : matchPercentage > 0
                ? configTheme?.icon?.valid || COLOR_JOIN_SHEET.SUCCESS_TEXT
                : configTheme?.icon?.invalid || COLOR_JOIN_SHEET.GRAY};
            }
          `)}
        />
        <div
          className={cx(
            'text-blue-dark-900 ml-4 text-base font-semibold',
            css({ '&&': configTheme?.sheetName })
          )}
        >
          {sheetName}
        </div>
        <div
          className={cx(
            'text-gray-560 leading-5.5 ml-2 text-left text-sm font-normal',
            css({ '&&': configTheme?.fileName })
          )}
        >
          {fileName}
        </div>
      </div>
      <div className="flex flex-col items-center">
        <ToggleButton
          onClick={toggleCollapse}
          isCollapse={isCollapse}
          disabled={false}
        />
      </div>
    </div>
  );
};
export default HeaderTable;
