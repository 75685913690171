import { ReviewEntriesThemeAPI } from '../../../theme/themeAPI';
import { CSSInterpolation } from '../../emotion';
import { HookedRecordInfoLevel } from '../../hooks/hooksAPI';

export enum OptionKey {
  LABEL = 'label',
  VALUE = 'value',
}

export type FindDropdownOptionFn = (
  columnIndex: number,
  value: string,
  optionValueType: OptionKey
) => string;

export type ConfigTheme = {
  reviewEntriesTheme?: ReviewEntriesThemeAPI;
  popover?: PopoverThemeProps;
  popoverInfo?: CSSInterpolation;
};

export type PopoverThemeProps = {
  root?: CSSInterpolation;
  info?: {
    root?: CSSInterpolation;
    circle?: CSSInterpolation;
  };
  warning?: {
    root?: CSSInterpolation;
    circle?: CSSInterpolation;
  };
  error?: {
    root?: CSSInterpolation;
    circle?: CSSInterpolation;
  };
};

enum RECORD_INFO_SOURCE {
  CHANGE = 'CHANGE',
}

export enum CUSTOM_SOURCE_CHANGE {
  HOOK_ENTRY_CHANGE = 'hook:entryChange',
  INITIAL_ROW = 'INITIAL_ROW',
}

export type RecordInfo = {
  rowIndex: number;
  colIndex: number;
  popover: {
    message: string;
    level: HookedRecordInfoLevel;
    source?: RECORD_INFO_SOURCE;
  };
};

export enum CELL_TYPE_CLASS {
  DEFAULT = 'default-cell',
}

export type FieldMeta = {
  level: HookedRecordInfoLevel;
  message: string;
};
