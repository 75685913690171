import {
  DataModelSheetMatchingAutoMatching,
  LogAutoOptionMapping,
} from 'core/matching/autoMatching';
import { firstValueFrom } from 'rxjs';
import BaseAutoMatching from './BaseAutoMatching';
import { decode, encode } from 'core/base64Utils';
import Tracking from '../../tracking/Tracking';
import { API_URL, licenseKeyHeader } from 'core/constants/service';

class AutoMatchingAPI extends BaseAutoMatching {
  constructor() {
    super({
      baseURL: API_URL,
    });
  }

  private getHeaders = (licenseKey: string) => {
    return {
      Authorization: licenseKey,
      framework: Tracking.getFrameWork(),
      originRequest: Tracking.getOrigin(),
    };
  };

  save = (
    identifier: string,
    serializeValue: DataModelSheetMatchingAutoMatching,
    licenseKey: string
  ) => {
    return firstValueFrom<void>(
      this.post(
        '/auto-mapping',
        {
          data: encode(
            JSON.stringify({
              identifier,
              data: JSON.stringify(serializeValue),
            })
          ),
        },
        {
          headers: this.getHeaders(licenseKey),
        }
      )
    );
  };

  saveOption = (key: string, items: LogAutoOptionMapping[]) => {
    return firstValueFrom<void>(
      this.put(
        '/suggest-option-mapping',
        {
          data: encode(JSON.stringify(items)),
        },
        {
          headers: { ...this.getHeaders(key), [licenseKeyHeader]: key },
        }
      )
    );
  };

  getAutoMatch = (identifier: string, licenseKey: string) => {
    return firstValueFrom(
      this.post<string>(
        '/auto-mapping/v2/identifier',
        {
          identifier: identifier,
        },
        {
          headers: this.getHeaders(licenseKey),
        }
      )
    ).then((result: string) => {
      const data = decode(`${licenseKey}`, result);
      try {
        const item = JSON.parse(data) as {
          identifier: string;
          data: string;
        };
        return this.handleGetAutoMatch(identifier, {
          identifier: item.identifier,
          serializeValue: JSON.parse(
            item.data
          ) as DataModelSheetMatchingAutoMatching,
        });
      } catch (error) {
        return Promise.reject(error);
      }
    });
  };
}

export default AutoMatchingAPI;
