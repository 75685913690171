import { transformDataSpecial } from 'core/replaceRegex';

type Option = {
  baseKey?: string;
  baseLabel?: string;
  baseKeyCounter?: number;
  baseLabelCounter?: number;
};

enum MAX_COUNTER_TYPE {
  KEY = 'key',
  LABEL = 'label',
}

export type CustomOptionMeta = {
  key: {
    value: string;
    baseKey: string;
    baseKeyCounter: number;
  };
  label: {
    value: string;
    baseLabel: string;
    baseLabelCounter: number;
  };
};

export const useDuplicateOptions = () => {
  const getMaxCounterByKey = (
    inputBaseKey: string,
    options: Option[],
    type: MAX_COUNTER_TYPE
  ) => {
    let counter = 0;
    for (let i = 0; i < options.length; i++) {
      const entry = options[i];
      const counterKey =
        type === MAX_COUNTER_TYPE.KEY ? 'baseKeyCounter' : 'baseLabelCounter';
      const compareKey =
        type === MAX_COUNTER_TYPE.KEY ? 'baseKey' : 'baseLabel';

      if (
        entry?.[counterKey] &&
        inputBaseKey === entry?.[compareKey] &&
        counter < (entry?.[counterKey] ?? 0)
      ) {
        counter = entry?.[counterKey] || 0;
      }
    }

    return counter;
  };

  const isNewOptionByKey = (options: Option[], baseKey: string) => {
    return !options.some((entry) => entry.baseKey === baseKey);
  };

  const isNewOptionByLabel = (options: Option[], baseLabel: string) => {
    return !options.some((entry) => entry.baseLabel === baseLabel);
  };

  const getCustomColumn = (
    cleanedSearchValue: string,
    options: Option[],
    transformDataSpecialToKey = true
  ): CustomOptionMeta => {
    const baseKey = transformDataSpecialToKey
      ? transformDataSpecial(cleanedSearchValue)
      : cleanedSearchValue;
    let newCounterKey =
      getMaxCounterByKey(baseKey, options, MAX_COUNTER_TYPE.KEY) + 1;

    let keyValue = baseKey;

    if (isNewOptionByKey(options, baseKey)) {
      newCounterKey--;
    } else if (newCounterKey > 0) {
      keyValue = `${baseKey}_${newCounterKey}`;
    }

    let labelValue = cleanedSearchValue;

    let newCounterLabel =
      getMaxCounterByKey(cleanedSearchValue, options, MAX_COUNTER_TYPE.LABEL) +
      1;

    if (isNewOptionByLabel(options, cleanedSearchValue)) {
      newCounterLabel--;
    } else if (newCounterLabel > 0) {
      labelValue = `${cleanedSearchValue} (${newCounterLabel})`;
    }

    return {
      key: {
        value: keyValue,
        baseKey,
        baseKeyCounter: newCounterKey,
      },
      label: {
        value: labelValue,
        baseLabel: cleanedSearchValue,
        baseLabelCounter: newCounterLabel,
      },
    };
  };

  return { getCustomColumn };
};
