import {
  createContext,
  useContext,
  ReactNode,
  FC,
  useState,
  useRef,
  MutableRefObject,
  useCallback,
} from 'react';
import { PromiseCancelable } from '../../../core';
import { DataFile } from './../../../sheetImporter/fileUtils';

type CustomViewModalManagerProps = {
  children?: ReactNode;
};

type ContextType = {
  isOpen: boolean;
  setOpenModal: (isOpen: boolean) => void;
  convertFile2JsonRef: MutableRefObject<
    PromiseCancelable<DataFile[]> | undefined
  >;
  setConvertFile2Json: (ref: PromiseCancelable<DataFile[]> | null) => void;
  setWebWorker: (worker: Worker) => void;
  terminateUploadWebWorker: () => void;
};

const CustomViewModalManagerContext = createContext({} as ContextType);

const CustomViewModalManager: FC<CustomViewModalManagerProps> = ({
  children,
}) => {
  const [isOpen, setOpenModal] = useState(false);
  const [webWorker, setWebWorker] = useState<Worker>();
  const convertFile2JsonRef = useRef<PromiseCancelable<DataFile[]>>();

  const setConvertFile2Json = (ref: PromiseCancelable<DataFile[]> | null) => {
    if (ref) convertFile2JsonRef.current = ref;
  };

  const terminateUploadWebWorker = useCallback(() => {
    webWorker?.terminate();
  }, [webWorker]);

  return (
    <CustomViewModalManagerContext.Provider
      value={{
        isOpen,
        setOpenModal,
        convertFile2JsonRef,
        setConvertFile2Json,
        setWebWorker,
        terminateUploadWebWorker,
      }}
    >
      {children}
    </CustomViewModalManagerContext.Provider>
  );
};

export const useContextCustomViewModalManager = () => {
  return useContext(CustomViewModalManagerContext);
};

export default CustomViewModalManager;
