import { useState } from 'react';
import { createContext, ReactNode, useContext, useMemo } from 'react';
import ClientMatchingRepository from './MatchingRepository/ClientMatchingRepository';
import NuvoMachineLearning from './NuvoMachineLearning';
import MatchingWorker from './ml/client/worker/MatchingWorker';
import BackendMatchingRepository from './MatchingRepository/BackendMatchingRepository';
import UploadInputSheetRepository from './MatchingRepository/UploadInputSheetRepository';
import UploadInputSheetAPI from './MatchingRepository/UploadInputSheetAPI';
import BackendMl from './ml/backend/BackendMl';
import BackendAPI from './ml/backend/BackendMlAPI';
import BaseMatchingRepository from './MatchingRepository/MatchingRepository';
import { useSettings } from 'settings';
import InputSheetClearer from './MatchingRepository/InputSheetClearer';

const nuvoML = new NuvoMachineLearning(new MatchingWorker());
const mlBackend = new BackendMl(new BackendAPI());

const MatchingContext = createContext<{
  isLoadingModel: boolean;
  ml: BaseMatchingRepository;
  executing: boolean;
  setExecuting: (executing: boolean) => void;
}>({
  ml: new ClientMatchingRepository(nuvoML, mlBackend),
  isLoadingModel: true,
  executing: true,
  setExecuting: () => {},
});

type MatchingContextProviderProps = {
  children: ReactNode;
};

export const useMatching = () => {
  const context = useContext(MatchingContext);
  return context;
};

const MatchingContextProvider = ({
  children,
}: MatchingContextProviderProps) => {
  const [isLoadingModel] = useState(false);
  const [executing, setExecuting] = useState(true);
  const { processingEngine, enableReuseMapping } = useSettings();

  const ml = useMemo(() => {
    const backendMl = new BackendMl(new BackendAPI());
    const uploadInputSheetAPI = new UploadInputSheetAPI();
    const inputSheetClearer = new InputSheetClearer(uploadInputSheetAPI);
    if (processingEngine === 'node') {
      const uploadInputSheetRepository = new UploadInputSheetRepository(
        uploadInputSheetAPI,
        inputSheetClearer
      );

      const matchingRepository = new BackendMatchingRepository(
        uploadInputSheetRepository,
        backendMl
      );

      if (enableReuseMapping) {
        matchingRepository.setIgnoreReuseMappingColumn(false);
      }

      return matchingRepository;
    } else {
      const matchingRepository = new ClientMatchingRepository(
        nuvoML,
        backendMl
      );
      return matchingRepository;
    }
  }, [processingEngine, enableReuseMapping]);

  return (
    <MatchingContext.Provider
      value={{
        ml,
        isLoadingModel,
        executing,
        setExecuting,
      }}
    >
      {children}
    </MatchingContext.Provider>
  );
};

export default MatchingContextProvider;
