import { useMemo } from 'react';
import MenuPane from '../../../core/dataModelSheet/DataModelSheetForm/ContextMenu/components/Dropdown/MenuPane';
import { Popper } from '../../../core/dataModelSheet/DataModelSheetForm/ContextMenu/components/Dropdown/popper';
import MenuItem from '../MenuItem';
import { css, cx } from '../../../core/emotion';
import { Option } from '../viewModel';
import useViewModel from './viewModel';
import { useTranslation } from 'react-i18next';
import { ConfigTheme } from '../type';

type MenuProps<T> = {
  isOpen: boolean;
  popper: Popper;
  className?: string;
  options: Option<T>[];
  selected: T[];
  setSelected: (value: T[]) => void;
  configTheme?: ConfigTheme;
  menuItemClassName?: string;
};

const Menu = <T,>({
  isOpen,
  popper,
  options,
  selected,
  setSelected,
  configTheme,
  menuItemClassName,
}: MenuProps<T>) => {
  const style = useMemo(() => {
    return css`
      width: 314px;
    `;
  }, []);

  const {
    onSelectItem,
    isAllSelected,
    onSelectAll,
    highlightedIndex,
    onMouseOver,
    onMenuPaneMouseOut,
    allCheckboxIndeterminate,
  } = useViewModel({
    selected,
    setSelected,
    options,
  });
  const { t } = useTranslation();

  return (
    <MenuPane
      ref={popper.popperElement}
      isOpen={isOpen}
      style={popper.styles.popper}
      className={cx(
        'nuvo-checkbox-dropdown-menu-pane',
        style,
        css`
          max-height: 195px !important;
        `
      )}
      onMouseOut={onMenuPaneMouseOut}
      configTheme={configTheme}
    >
      <MenuItem
        isHighlighted={highlightedIndex === 0 || isAllSelected}
        isSelected={isAllSelected}
        indeterminate={allCheckboxIndeterminate}
        onClick={() => {
          onSelectAll();
        }}
        onMouseOver={() => {
          onMouseOver(0);
        }}
        configTheme={configTheme}
        className={menuItemClassName}
      >
        {t('txt_all')}
      </MenuItem>
      {options.map((menuItem, index) => {
        const isSelected = selected.includes(menuItem.value);
        const menuItemHighlightedIndex = index + 1;
        return (
          <MenuItem
            key={`${menuItem.value}`}
            isHighlighted={
              selected.includes(menuItem.value) ||
              highlightedIndex === menuItemHighlightedIndex
            }
            isSelected={isSelected}
            onClick={() => {
              onSelectItem(menuItem);
            }}
            onMouseOver={() => {
              onMouseOver(menuItemHighlightedIndex);
            }}
            configTheme={configTheme}
            className={menuItemClassName}
          >
            {menuItem.label}
          </MenuItem>
        );
      })}
    </MenuPane>
  );
};

export default Menu;
