import { SHEET_COLUMN_TYPE } from '../constants/sheet';
import { SheetColumnJSON } from './serializer/type';
import Sheet, { Value } from './Sheet';
import SheetColumn from './SheetColumn';

class JoinSheetColumn extends SheetColumn {
  sheetColumns: SheetColumn[];

  constructor({
    sheet,
    sheetColumns,
    rows,
    id: idConstruct,
  }: {
    sheet: Sheet;
    sheetColumns: SheetColumn[];
    rows: Value[];
    id?: string;
  }) {
    super({
      columnKey: sheetColumns[0].getColumnKey(),
      rows,
      sheet,
      id: idConstruct,
    });

    this.sheetColumns = sheetColumns;
  }

  getType = () => {
    return SHEET_COLUMN_TYPE.JOIN;
  };

  getJoinedSheetColumns = () => {
    return this.sheetColumns;
  };

  getSheetColumns = () => {
    return this.sheetColumns;
  };

  serialize = (): SheetColumnJSON => {
    return {
      columnKey: this.columnKey,
      id: this.id,
      sheet: this.sheet.serialize(),
      rows: this.rows,
      sheetColumns: this.sheetColumns.map((sheetColumn) => {
        return sheetColumn.serialize();
      }),
      type: this.getType(),
    };
  };
}

export default JoinSheetColumn;
