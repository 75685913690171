import { useLayoutEffect } from 'react';
import { useLicenseKeyAuth, useWarnLicense } from 'license';
import Router from './Router';
import Uploader from '../common/Uploader';
import DownloadTemplateCard from '../common/DownloadTemplate/DownloadTemplateCard';
import useViewModel from './viewModel';
import { useUploadDataFormConfig } from '../common/uploadDataFormConfig';
import { useContextCircleLoadingModalManager } from 'baseUI/Modal/CircleLoading/context';
import { useTheme } from 'theme';
import { css, cx } from 'core/emotion';
import UploadButton from './../../../sheetImporter/UploadButton';
import { UPLOAD_PAGE_PATH } from 'core/constants/route';
import { useScreenSize } from 'core/constants/screensSize';
import { useWarnSmallScreen } from 'hooks';
import useInAppNavigate, { useManualNavigate } from 'core/navigate';
import Button from 'baseUI/Button';
import { useTranslation } from 'react-i18next';

const ModalView = ({
  isOpenModal,
  cancel,
  openModal,
  closeModal,
  setIsOpenModal,
}: {
  isOpenModal: boolean;
  cancel: () => void;
  openModal: () => void;
  closeModal: () => void;
  setIsOpenModal: (isOpenModal: boolean) => void;
}) => {
  const { onUploadSuccess, renderUploadButton } = useViewModel({
    openModal,
  });
  const { disableTemplates, embedUploadArea, modal, buttonMode } =
    useUploadDataFormConfig();
  const { setProcessing } = useContextCircleLoadingModalManager();
  const navigate = useInAppNavigate();
  const theme = useTheme();
  const { isNotSmallScreen } = useScreenSize();
  const { warnSmallScreen, isOpen, setOpenModal, type } = useWarnSmallScreen();
  const { navigateToReviewEntries } = useManualNavigate();
  const { isLoading, isAuth } = useLicenseKeyAuth();
  const { t } = useTranslation();
  const { warnLicenseKeyIsInvalid } = useWarnLicense();

  const containerTheme = theme.getDropzoneTheme().container;

  useLayoutEffect(() => {
    if (!modal) {
      return () => {};
    }
    if (!isNotSmallScreen && isOpenModal && !isOpen) {
      setTimeout(() => {
        warnSmallScreen(() => setIsOpenModal(false));
      }, 0);
    }
    if (isNotSmallScreen && isOpen && type === 'confirm') {
      setOpenModal(false);
    }

    return () => {};
  }, [
    type,
    warnSmallScreen,
    isNotSmallScreen,
    modal,
    setIsOpenModal,
    isOpenModal,
    isOpen,
    setOpenModal,
  ]);

  const onClickEditData = () => {
    openModal();
    navigateToReviewEntries();
  };

  const editButtonView = () => {
    return (
      <Button
        id="nuvo-edit-data-button"
        variant="tertiary"
        disabled={isLoading}
        loading={isLoading}
        className={cx('min-w-50 min-h-42 h-auto')}
        noSpan={!!renderUploadButton}
        onClick={(e) => {
          e.stopPropagation();
          !isAuth ? warnLicenseKeyIsInvalid() : onClickEditData();
        }}
      >
        {t('txt_edit_data_button')}
      </Button>
    );
  };

  const uploadButtonView = () => {
    return (
      <UploadButton
        isModalView={true}
        className="min-h-42"
        onLaunch={() => {
          openModal();
          navigate({ pathname: UPLOAD_PAGE_PATH });
        }}
      />
    );
  };

  const renderButtonView = () => {
    if (buttonMode === 'both') {
      return (
        <div className="flex justify-center gap-4">
          {uploadButtonView()}
          {editButtonView()}
        </div>
      );
    } else if (buttonMode === 'edit') {
      return (
        <div className="mt-6 flex items-center justify-center md:mt-0">
          {editButtonView()}
        </div>
      );
    } else {
      return uploadButtonView();
    }
  };

  return (
    <div>
      {embedUploadArea ? (
        <div
          className={cx(
            'border-1 flex flex-row border-gray-100 p-8',
            css`
              background-color: ${theme.getGlobalTheme().getBackgroundColor()};
            `,
            css({ '&&': containerTheme })
          )}
        >
          <Uploader
            className="order-1 w-full flex-1"
            onUploadSuccess={onUploadSuccess}
            onUploadFailure={() => {
              setProcessing(false);
              closeModal();
            }}
            onProcessing={() => {
              setProcessing(true);
              openModal();
              navigate({ pathname: UPLOAD_PAGE_PATH });
            }}
            onClickManualEntry={() => {
              openModal();
              navigateToReviewEntries();
            }}
          />
          {!disableTemplates ? (
            <DownloadTemplateCard
              className={cx('large:min-w-100 order-2 ml-6')}
              isLoading={isLoading}
            />
          ) : null}
        </div>
      ) : (
        renderButtonView()
      )}
      <Router
        cancel={cancel}
        isOpenModal={isOpenModal}
        closeModal={closeModal}
      />
    </div>
  );
};

export default ModalView;
