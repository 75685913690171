import { useCallback } from 'react';
import { useSettings } from 'settings';
import { SpreadSheetList } from 'sheetImporter';

const useNavigatePolicy = () => {
  const { multipleFileUpload } = useSettings();

  const getHasSheetSelectionPage = useCallback(
    (spreadSheetList: SpreadSheetList) => {
      const allSheet = spreadSheetList.getAllSheets().length;
      if (multipleFileUpload) {
        if (allSheet <= 1) {
          return false;
        }
        return true;
      } else {
        if (allSheet <= 1) {
          return false;
        } else {
          return true;
        }
      }
    },
    [multipleFileUpload]
  );

  const getHasJoinColumnPage = (spreadSheetList: SpreadSheetList) => {
    return spreadSheetList.getSelectedSheets().length > 1;
  };

  return {
    getHasSheetSelectionPage,
    getHasJoinColumnPage,
  };
};

export default useNavigatePolicy;
