import { useFeatureWhiteList } from '../configure/ConfigureProvider';
import { useLocalStorage } from 'react-use';

export const useIgnoreReuseMappingColumns = () => {
  const [configure] = useLocalStorage<{ ignoreReuseMappingColumns: boolean }>(
    'configure',
    { ignoreReuseMappingColumns: false }
  );

  const { featureWhiteList } = useFeatureWhiteList();

  const isIgnoreReuseMappingForSaleDomain =
    configure?.ignoreReuseMappingColumns === true &&
    process.env.NX_APP_ENVIRONMENT === 'development' &&
    window.location.hostname === 'sales-demo.getnuvo.com';

  const ignoreReuseMappingColumns =
    isIgnoreReuseMappingForSaleDomain ||
    !(
      featureWhiteList.getEnableReuseMapping() === true &&
      featureWhiteList.getDisableColumnHeaderProcessing() === false
    );

  return ignoreReuseMappingColumns;
};
