import { useTranslation } from 'react-i18next';
import { Sheet } from 'sheetImporter';

const useViewModel = ({ sheet }: { sheet: Sheet }) => {
  const { t } = useTranslation();
  const sheetName = sheet.getDisplayName({ t });
  const fileName = sheet.getSpreadSheet().getFilename();

  return {
    sheetName,
    fileName,
  };
};

export default useViewModel;
