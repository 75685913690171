import { flatten } from 'lodash';
import SpreadSheet from './SpreadSheet';
import Sheet from './Sheet';

class SpreadSheetList {
  private spreadSheets: SpreadSheet[];
  constructor({ spreadSheets }: { spreadSheets: SpreadSheet[] }) {
    this.spreadSheets = spreadSheets;
  }

  getSpreadSheets = () => {
    return this.spreadSheets;
  };

  getSelectedSpreadSheets = () => {
    return this.spreadSheets.filter((spreadSheet) => spreadSheet.isSelected());
  };

  getIsAllSheetsManualSelectHeader = () => {
    const selectedSpreadSheets = this.getSelectedSpreadSheets();
    for (let i = 0; i < selectedSpreadSheets.length; i++) {
      const selectedSheets = selectedSpreadSheets[i].getSelectedSheets();
      for (let j = 0; j < selectedSheets.length; j++) {
        if (!selectedSheets[j].getIsManualSelectHeader()) {
          return false;
        }
      }
    }

    return true;
  };

  length = () => {
    return this.spreadSheets.length;
  };

  getAllSheets = () => {
    const allSheets = flatten(
      this.spreadSheets.map((spreadSheet) => {
        const allSheets = spreadSheet.getSheets();
        return allSheets;
      })
    );
    return allSheets;
  };

  setSheet = (dataSheet: Sheet) => {
    for (let i = 0; i < this.spreadSheets.length; i++) {
      const element = this.spreadSheets[i];
      for (let j = 0; j < element.getSheets().length; j++) {
        const sheet = element.getSheets()[j];
        if (sheet.getId() === dataSheet.getId()) {
          this.spreadSheets?.[i].getSheets()?.[j]?.setData(dataSheet.getData());
        }
      }
    }
  };

  getSelectedSheets = () => {
    return this.getAllSheets().filter((sheet) => sheet.isSelected());
  };

  selectAllSheets = (selected: boolean) => {
    this.spreadSheets.forEach((spreadSheet) => {
      spreadSheet.selectAllSheet(selected);
    });
  };

  addList = (spreadSheetList: SpreadSheetList) => {
    const spreadSheets = spreadSheetList.getSpreadSheets();
    for (let i = 0; i < spreadSheets.length; i++) {
      this.spreadSheets.push(spreadSheets[i]);
    }
  };
}

export default SpreadSheetList;
