import { useMemo, useState } from 'react';
import { NumberToAlphabet } from '@nuvo-importer/common/sdk';
import { Sheet, SheetColumn } from 'sheetImporter';
import { cx, css, CSSInterpolation } from '@nuvo-importer/common';
import { useMediaQuery } from 'react-responsive';
import { SheetData } from '../../../../sheetImporter/Sheet';
import { useTheme } from 'theme';

const numberToAlphabet = new NumberToAlphabet();

const useViewModel = ({
  sheet,
  configTheme,
}: {
  sheet: Sheet;
  configTheme?: {
    root: CSSInterpolation;
    content: CSSInterpolation;
    selectColumnColor: string;
    hoverColumnColor: string;
    scrollbarNavigatorColor: string;
    scrollbarBackgroundColor: string;
    th: CSSInterpolation;
    td: CSSInterpolation;
  };
}) => {
  const theme = useTheme();
  const selectColThemeColor = useMemo(() => {
    return theme.getGlobalTheme().getDark50Color();
  }, [theme]);

  const [currentHoverCol, setCurrentHoverCol] = useState<number | null>(null);
  const mediaSize = useMediaQuery({
    query: '(max-width: 1440px)',
  });

  const columnHeaders = useMemo(() => {
    const headerAlphabet = [];
    for (let i = 0; i < sheet.getColumnsWithRemoved().length; i++) {
      headerAlphabet.push(numberToAlphabet.numberToString(i + 1).toUpperCase());
    }
    return headerAlphabet;
  }, [sheet]);

  const hoverColStyled = useMemo(() => {
    return configTheme?.hoverColumnColor
      ? css({
          '&&': css`
            background-color: ${configTheme.hoverColumnColor};
          `,
        })
      : 'hover-col bg-gray-100';
  }, [configTheme?.hoverColumnColor]);

  const selectedColStyled = useMemo(() => {
    return cx(
      css({
        '&&&': css`
          background: ${configTheme?.selectColumnColor
            ? configTheme?.selectColumnColor
            : selectColThemeColor};
        `,
      })
    );
  }, [configTheme?.selectColumnColor, selectColThemeColor]);

  const customHoverTableStyle = (
    currentHoverCol: number | null,
    index: number
  ) => {
    if (currentHoverCol === index) {
      return hoverColStyled;
    }
    return 'default-col bg-gray-50';
  };

  const customSelectedTableStyle = (
    selectedSheetColumns: SheetColumn[],
    sheetColumn: SheetColumn
  ) => {
    if (selectedSheetColumns.includes(sheetColumn)) {
      return selectedColStyled;
    }
    return '';
  };

  const rows = useMemo(() => {
    const headers = sheet.getHeaderRow();
    const dataRows = sheet.getValues().slice(0, 3);

    const data: SheetData = [[]];

    for (let i = 0; i < headers.length; i++) {
      data[0].push(headers[i]);
    }

    for (let i = 0; i < dataRows.length; i++) {
      const index = i + 1;
      data[index] = [];
      for (let j = 0; j < headers.length; j++) {
        data[index].push(dataRows[i][j] ?? '');
      }
    }

    return data;
  }, [sheet]);

  return {
    columnHeaders,
    currentHoverCol,
    customHoverTableStyle,
    customSelectedTableStyle,
    mediaSize,
    setCurrentHoverCol,
    rows,
  };
};
export default useViewModel;
