import {
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import DataModelColumn from './columns/DataModelColumn';
import {
  ConfigTheme,
  CUSTOM_SOURCE_CHANGE,
  FieldMeta,
  OptionKey,
  RecordInfo,
} from './type';
import HotTable from '@handsontable/react';
import { ColumnSettings, GridSettings } from 'handsontable/settings';
import { CellCoords } from 'handsontable';
import { forEach, isArray, isEmpty, isNil } from 'lodash';
import { elementId, HIGHT_SCROLL_BAR } from './constant';
import { popperRootClassName } from '../../reviewEntries/utils/popperRootClassName';
import { Error, Validator } from '../../reviewEntries/validator';
import {
  CellChange,
  CellValue,
  ChangeSource,
  RowObject,
} from 'handsontable/common';
import isPromise from 'is-promise';
import { useTranslation } from 'react-i18next';
import { useDropdown } from './dropdown';
import CategoryDataModel, {
  Option,
} from '../../dataModel/model/CategoryDataModel';
import { useMediaQuery } from 'react-responsive';
import { css } from '@emotion/css';
import CellRange from 'handsontable/3rdparty/walkontable/src/cell/range';
import SimpleTextInputView from '../../dataModelSheet/CellComponents/Viewer/TextInput/simpleTextInput';
import DropdownInputBooleanView from '../../dataModelSheet/CellComponents/Viewer/Dropdown/boolean';
import DropdownInputView from '../../dataModelSheet/CellComponents/Viewer/Dropdown';
import { PercentageInputEditor } from '../../dataModelSheet/CellComponents/Editor/PercentageInput';
import ValueParser from '../valueParser/ValueParser';
import { StackedMessagePopper } from '../Popover/StackedMessage/stackedMessagePopper';
import { InfoMessagePopper } from '../Popover/InfoMessage';
import { TextInputEditor } from '../CellComponents/Editor/TextInput';
import {
  customColumnHeader,
  customGetColHeader,
} from '../CellComponents/Viewer/ColumnHeader';
import { NumberInputEditor } from '../CellComponents/Editor/NumberInput';
import { DateInputEditor } from '../CellComponents/Editor/DateInput';
import { TimeInputEditor } from '../CellComponents/Editor/TimeInput';
import { CurrencyInputEditor } from '../CellComponents/Editor/CurrencyInput';
import { DataModel } from '../../dataModel/model/DataModel';
import { booleanDropdownOptions } from '../../constants/boolean';
import { breakpointsNumber } from '../../constants/breakpoints';
import { severity } from '../../constants/severity';
import { ColumnAPI, NumberFormat } from '../../dataModel/columnsAPI';
import { DataModelContainer } from '../../dataModel/model/DataModelContainer';
import { findDataModel } from '../../dataModel/utils';
import { DATATYPE } from '../../dataType';
import {
  formatDateStringByDateType,
  formatDateStringISO,
  formatTime,
  MOMENT_FORMAT_TIME,
} from '../../date';
import exportToXlsx from '../../exportData/xlsx';
import {
  HookedRecordInfoLevel,
  HookedRecordResult,
  OnEntryChange,
  OnEntryInit,
  Row,
} from '../../hooks/hooksAPI';
import {
  checkIsMultipleValues,
  separateMultipleValues,
} from '../../multipleSelection';
import DataModelSheet from '../model/DataModelSheet';
import ValueBeforeHookParser from '../valueResultParser/ValueBeforeHookParser';
import { useScreenSize } from '../../constants/screensSize';
import { parseNumberStringToNumber, parseTextPercent } from '../../number';
import { LEVEL } from '../../level';
import { OptionValidator } from '../../reviewEntries/validator/optionsValidators';
import { useLocation } from 'react-router-dom';
import { NumberParser } from '../../utils/NumberParser';
import CheckboxController from './checkbox/CheckboxController';
import useRemove from './remove';
import useDuplicate from './duplicate';
import ContextMenuController from './ContextMenu/controller/ContextMenuController';
import AllColumnSetting from './columns/AllColumnSetting';
import compareFunctionFactory from './sorting/sortFuntions';
import { setupAddCondition } from './filtering/setupAddCondition';
import ConditionRegisterer from './filtering/ConditionRegisterer';
import ModeViewTable, {
  ModeViewTableState,
} from './ModeViewTable/ModeViewTable';
import ColumnSettingHelper from './columns/ColumnSettingHelper';
import FreezeStrategy from './columns/FreezeStrategy';
import { useSearchAndReplace } from './TopAction/SearchAndReplace/useSearchAndReplace';
import { ISearchParams } from './TopAction/SearchAndReplace';
import { replaceWord } from '../utils';
import useScroll from './scroll';

type UseViewModelParams = {
  dataModels: DataModel[];
  onSubmit: (dataModelSheet: DataModelSheet) => void;
  onSubmitWhenInvalid?: (dataModelSheet: DataModelSheet) => void;
  configTheme?: ConfigTheme;
  dataInfos: Record<string, RecordInfo[]>;
  /* eslint-disable @typescript-eslint/no-explicit-any */
  dataSet: Record<string, any>[];
  handleChangeInfo: (
    entryChange: Record<string, HookedRecordResult>,
    rowIndex: number,
    sourceCol: string
  ) => void;
  validator: Validator;
  onValidateInitialFinish: (length: number) => void;
  errorCount: MutableRefObject<number>;
  columns: ColumnAPI[];
  isManualInput: boolean;
  updateTotalError: (errorCount: number) => void;
  htLicenseKey: string;
  identifier: string;
  baseColumns: ColumnAPI[];
  onEntryChange?: OnEntryChange;
  onEntryInit?: OnEntryInit;
  enableExamples?: boolean;
  removeRows?: number[];
  notValidate?: boolean;
  readOnly?: boolean;
  rowsLimit?: number;
  smartTable: boolean;
};

const useViewModel = ({
  configTheme,
  dataModels,
  onSubmit: onSubmitProp,
  onSubmitWhenInvalid,
  handleChangeInfo,
  dataInfos,
  dataSet,
  validator,
  onValidateInitialFinish,
  errorCount,
  columns,
  updateTotalError,
  htLicenseKey,
  onEntryChange,
  onEntryInit,
  enableExamples = false,
  identifier,
  baseColumns,
  removeRows,
  notValidate,
  readOnly,
  rowsLimit,
  isManualInput,
  smartTable,
}: UseViewModelParams) => {
  const { t } = useTranslation();
  const { state } = useLocation();
  const moreAction = useRef<HTMLElement>();
  const hotInstance = useRef<HotTable>(null);
  const [allowedRender, setAllowedRender] = useState(false);
  const [isLoadingDropdown, setIsLoadingDropdown] = useState(false);

  const allColumnSettingRef = useRef(
    new AllColumnSetting(
      columns.map((item, index) => {
        return {
          id: item.key,
          refIndex: index,
          freeze: false,
          hide: false,
          sort: null,
          filterState: null,
        };
      }),
      columns,
      readOnly
    )
  );

  const allColumnSetting = allColumnSettingRef.current;

  const modeViewTableRef = useRef(
    new ModeViewTable(allColumnSetting.getFilterStrategy(), !!readOnly)
  );
  const modeViewTable = modeViewTableRef.current;

  const checkboxController = useMemo(() => {
    const hotTable = hotInstance.current?.hotInstance;
    if (hotTable) {
      return new CheckboxController({
        hotInstance: hotTable,
        modeViewTable,
        allColumnSetting,
        dataSet,
      });
    }
    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allowedRender]);

  const contextMenuController = useMemo(() => {
    return new ContextMenuController();
  }, []);

  const stackedMessagePopper = useMemo(() => {
    return new StackedMessagePopper();
  }, []);
  const infoMessagePopper = useMemo(() => {
    return new InfoMessagePopper();
  }, []);
  const lastSelectedBySearchCell = useRef<{ row: number; col: number } | null>(
    null
  );
  const lastSelectedByFindErrorCell = useRef<{
    row: number;
    col: number;
  } | null>(null);
  const latestPopoverMessage = useRef<{ row: number; col: number } | null>();
  const wrapperElement = useRef<HTMLElement>();
  const currentSelectingCoord = useRef<{ row: number; col: number }>({
    row: 0,
    col: -1,
  });
  const htMasterBorderCornerCachedRef = useRef<HTMLElement | null>(null);
  const htCloneRefBorderCornerCachedRef = useRef<HTMLElement | null>(null);
  const popperElement = useRef<HTMLElement>();
  const parentElement = useRef<HTMLElement>();
  const parentTableElement = useRef<HTMLElement>();
  const htCloneLeftWtHolderElement = useRef<HTMLElement>();
  const dropdownElement = useRef<HTMLElement>();
  const htCloneLeft = useRef<HTMLElement>();
  const popoverMessage = useRef<FieldMeta[]>([]);
  const isLastRow = useRef(false);
  const popperInfoElement = useRef<HTMLElement>();
  const [exporting, setExporting] = useState(false);
  const {
    getAllSearchMatchCount,
    findSearchMatchPosition,
    replaceWordSearchMatchAllCells,
  } = useSearchAndReplace({
    allColumnSetting,
    hotInstance,
    modeViewTable,
    columns,
  });
  const { autoScroll } = useScroll({ hotInstance });

  const clearLastSelectedCell = () => {
    lastSelectedByFindErrorCell.current = null;
    lastSelectedBySearchCell.current = null;
  };

  const colWidths = useMemo(
    () =>
      dataModels.map((dataModel) => {
        const dataModelColumn = new DataModelColumn(dataModel);
        return dataModelColumn.calculateColumnSize();
      }) as number[],
    [dataModels]
  );

  const categoryValidationList = useMemo(() => {
    return dataModels.filter((dataModel) => {
      if (dataModel.getType() === DATATYPE.SINGLE_SELECT) {
        const categoryDataModel = dataModel as CategoryDataModel;

        return categoryDataModel
          .getOptions()
          .some((option) => option.validations?.length);
      } else {
        return false;
      }
    });
  }, [dataModels]);

  const [optionsDropdown, setOptionsDropdown] = useState<Option[]>([]);
  const isInitialValidateAndAddRow = useRef<boolean>();
  const mediaSize = useMediaQuery({
    query: '(max-width: 1440px)',
  });

  const searchValueRef = useRef('');
  const lgScreenRef = useRef<boolean>(true);
  const { isSxlargeScreen } = useScreenSize();

  const {
    currentEditingModelRef,
    currentEditingValueRef,
    onSelectOption,
    openDropdown,
    handleBeforeKeyDown,
    itemMenuPopper,
    handleDropdownMenuItem,
    currentSelectorRef,
    dropdownOptionsRef,
  } = useDropdown({
    colWidths,
    dataModels,
    dropdownElement,
    hotInstance,
    parentTableElement,
    currentSelectingCoord,
    searchValueRef,
    dataSet,
    enableExamples,
    htCloneLeftWtHolderElement,
    allColumnSetting,
  });

  const allDropdownOptions = useMemo(() => {
    const itemsByLabel: Record<string, string>[] = [];
    const itemsByValue: Record<string, string>[] = [];

    for (let i = 0; i < dataModels.length; ++i) {
      const optionsByLabel: Record<string, string> = {};
      const optionsByValue: Record<string, string> = {};
      if (dataModels[i].isCategoryType()) {
        for (
          let j = 0;
          j < (dataModels[i] as CategoryDataModel).getOptions().length;
          j++
        ) {
          const value = (dataModels[i] as CategoryDataModel)
            .getOptions()
            [j].value?.trim();
          const label = (dataModels[i] as CategoryDataModel)
            .getOptions()
            [j].label?.trim();
          optionsByLabel[label] = value;
          optionsByValue[value] = label;
        }
      } else if (dataModels[i].getType() === DATATYPE.BOOLEAN) {
        const options = booleanDropdownOptions();
        for (let j = 0; j < options.length; ++j) {
          const label = options[0].label;
          const value = options[0].value;
          optionsByLabel[label] = value;
          optionsByValue[value] = label;
        }
      }

      itemsByLabel.push(optionsByLabel);
      itemsByValue.push(optionsByValue);
    }
    return { itemsByLabel, itemsByValue };
  }, [dataModels]);

  const findDropdownOption = useCallback(
    (columnIndex: number, value: string, optionValueType: OptionKey) => {
      const options =
        optionValueType === OptionKey.LABEL
          ? allDropdownOptions.itemsByValue[columnIndex]
          : allDropdownOptions.itemsByLabel[columnIndex];
      if (options) {
        if (!isNil(value)) {
          return options[`${value}`.trim()] ?? value;
        } else {
          return value;
        }
      } else {
        return value;
      }
    },
    [allDropdownOptions]
  );

  const findDropdownOptionForSorting = useCallback(
    (columnIndex: number, value: string) => {
      const options = allDropdownOptions.itemsByValue[columnIndex];
      if (options) {
        return options[value?.trim()];
      } else {
        return null;
      }
    },
    [allDropdownOptions]
  );

  const handleSubmit = (identifier?: string) => {
    const dataModelSheet = new DataModelSheet({
      values: dataSet.slice(0, -1),
      dataInfos,
      errors: validator.getError(),
      dataModels,
      baseColumns: baseColumns,
      removeRows,
      identifier: identifier ?? '',
      translate: t,
      columns,
      matchedColumns: state?.dataModelSheetMatching?.dataModelSheetMatch,
    });

    if (errorCount.current > 0) {
      onSubmitWhenInvalid?.(dataModelSheet);
    } else {
      onSubmitProp(dataModelSheet);
    }
  };

  const changeModeView = (showOnlyError: boolean, isEmit?: boolean) => {
    if (!hotInstance.current?.hotInstance) {
      return;
    }

    if (showOnlyError) {
      modeViewTable.hideRows(isEmit);
    } else {
      modeViewTable.showRows(isEmit);
    }
    checkboxController?.clearCache();

    onRepositionPopper();
    hotInstance.current?.hotInstance?.render();
    clearLastSelectedCell();
  };

  const exportValuesToXlsx = (ignoreContentDropdown: boolean) => {
    const fileName = identifier ? `${identifier}` : 'model-data';
    const data = rowsLimit ? dataSet.slice(0, rowsLimit + 1) : dataSet;
    const values = {
      dataModels,
      data,
      options: {
        dataInfos: dataInfos,
        errors: validator.getError(),
        getMessage: (error: Error) => {
          return validator.getValidateMessage(t, error, columns, baseColumns);
        },
        ignoreContentDropdown,
      },
    };
    setExporting(true);
    setTimeout(() => {
      exportToXlsx(values, fileName).then(() => {
        setExporting(false);
      });
    }, 500);
  };

  const onHoverInfo = useCallback(
    (
      popperElement: HTMLElement,
      rootElement: HTMLElement,
      textValue: string
    ) => {
      infoMessagePopper.changeRootElement(
        rootElement,
        popperElement,
        textValue,
        'top-start'
      );
    },
    [infoMessagePopper]
  );

  const borderConner = useCallback(
    (coords: { row: number; col: number }) => {
      if (
        coords.row === null ||
        coords.col === null ||
        coords.row < 0 ||
        coords.col < 0
      ) {
        return;
      }
      const level = getCellMetaForRenderer(coords.row, coords.col);

      const cellProperties = hotInstance.current?.hotInstance?.getCellMeta(
        Number(coords.row),
        Number(coords.col)
      );

      const freezeColumns = allColumnSetting.getFreezeColumns();
      let borderCornerElement: HTMLElement;
      if (freezeColumns.includes(coords.col)) {
        if (!htCloneRefBorderCornerCachedRef.current) {
          htCloneRefBorderCornerCachedRef.current =
            hotInstance.current?.hotInstance?.rootElement.querySelector(
              '.ht_clone_left .htBorders'
            ) as HTMLElement;
        }

        borderCornerElement = htCloneRefBorderCornerCachedRef.current;
      } else {
        if (!htMasterBorderCornerCachedRef.current) {
          htMasterBorderCornerCachedRef.current =
            hotInstance.current?.hotInstance?.rootElement.querySelector(
              '.ht_master .htBorders'
            ) as HTMLElement;
        }

        borderCornerElement = htMasterBorderCornerCachedRef.current;
      }

      if (cellProperties?.readOnly) {
        borderCornerElement.classList.add('hide-corner');
      } else {
        borderCornerElement.classList.remove('hide-corner');
      }

      if (level === LEVEL.ERROR) {
        borderCornerElement.classList.add('error');
        borderCornerElement.classList.remove('warning', 'info');
      } else if (level === LEVEL.WARNING) {
        borderCornerElement.classList.add('warning');
        borderCornerElement.classList.remove('error', 'info');
      } else if (level === LEVEL.INFO) {
        borderCornerElement.classList.add('info');
        borderCornerElement.classList.remove('error', 'warning');
      } else {
        borderCornerElement.classList.remove('error', 'warning', 'info');
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dataInfos]
  );

  const getDataInfosByCell = useCallback(
    (row: number, col: number) => {
      const infos: { level: HookedRecordInfoLevel; message: string }[] = [];
      for (let i = 0; i < (dataInfos[row]?.length ?? 0); ++i) {
        const dataInfoItem = dataInfos[row][i];
        if (dataInfoItem.colIndex === col) {
          infos.push({
            level: dataInfoItem.popover.level,
            message: dataInfoItem.popover.message,
          });
        }
      }

      return infos;
    },
    [dataInfos]
  );

  const getCellMeta = useCallback(
    (row: number, col: number) => {
      const physicalRow =
        hotInstance.current?.hotInstance?.toPhysicalRow(row) ?? row;
      const physicalCol =
        hotInstance.current?.hotInstance?.toPhysicalColumn(col) ?? col;
      const meta = getDataInfosByCell(physicalRow, physicalCol);
      const error = validator.getError()?.[physicalRow]?.[physicalCol];
      const errorField = error
        ? {
            level: 'error' as HookedRecordInfoLevel,
            message: validator.getValidateMessage(
              t,
              error,
              columns,
              baseColumns
            ),
          }
        : null;

      if (errorField) {
        meta.push(errorField);
      }

      return {
        meta: meta.sort((a, b) => severity[b.level] - severity[a.level]),
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [columns, getDataInfosByCell, t, baseColumns]
  );

  const getCellMetaForRenderer = useCallback(
    (row: number, col: number): HookedRecordInfoLevel | undefined => {
      const physicalRow =
        hotInstance.current?.hotInstance?.toPhysicalRow(row) ?? row;
      const physicalCol =
        hotInstance.current?.hotInstance?.toPhysicalColumn(col) ?? col;
      const validateError = validator.getError()?.[physicalRow]?.[physicalCol];
      if (validateError) {
        return 'error';
      } else {
        let level: HookedRecordInfoLevel | undefined = undefined;
        for (let i = 0; i < (dataInfos[physicalRow]?.length ?? 0); ++i) {
          const dataInfoItem = dataInfos[physicalRow][i];
          if (dataInfoItem.colIndex === physicalCol) {
            if (dataInfoItem.popover.level === 'error') {
              level = 'error';
              break;
            } else if (dataInfoItem.popover.level === 'warning') {
              level = 'warning';
            } else if (
              level !== 'warning' &&
              dataInfoItem.popover.level === 'info'
            ) {
              level = 'info';
            }
          }
        }

        return level;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dataInfos]
  );

  const onRepositionPopper = useCallback(() => {
    const coords = currentSelectingCoord.current;
    if (!coords || coords?.row < 0 || coords?.col < 0) {
      stackedMessagePopper.destroy();
      latestPopoverMessage.current = null;
    }

    const rootElement = hotInstance.current?.hotInstance?.getCell(
      coords.row,
      coords.col,
      true
    );

    const activeEditor = hotInstance.current?.hotInstance?.getActiveEditor();
    const isTextEditing =
      activeEditor?.col === coords.col &&
      activeEditor?.row === coords.row &&
      activeEditor.state === 'STATE_EDITING';

    const physicalCol =
      hotInstance.current?.hotInstance?.toPhysicalColumn(coords.col) ?? 0;

    const isDropdownEditing =
      dataModels[physicalCol]?.isDropdown() && itemMenuPopper.isEditing;

    if (
      !rootElement ||
      !parentTableElement.current ||
      !popperElement.current ||
      isTextEditing ||
      isDropdownEditing ||
      !htCloneLeftWtHolderElement.current
    ) {
      latestPopoverMessage.current = null;
      return stackedMessagePopper.destroy();
    }

    const cellInfo = getCellMeta(coords.row, coords.col).meta;

    if (isEmpty(cellInfo)) {
      latestPopoverMessage.current = null;
      return stackedMessagePopper.destroy();
    }

    latestPopoverMessage.current = { row: coords.row, col: coords.col };
    popoverMessage.current = cellInfo;

    const freezeColumns = allColumnSetting.getFreezeColumns();
    const isFreeze = freezeColumns.includes(coords.col);

    stackedMessagePopper.changeRootElement(
      rootElement,
      popperElement.current,
      parentTableElement.current,
      popperRootClassName(cellInfo),
      !isFreeze ? htCloneLeftWtHolderElement.current : undefined,
      'right',
      enableExamples
    );
  }, [
    currentSelectingCoord,
    enableExamples,
    getCellMeta,
    parentTableElement,
    popperElement,
    stackedMessagePopper,
    itemMenuPopper,
    dataModels,
    allColumnSetting,
  ]);

  const { onRemove } = useRemove({
    columns,
    dataInfos,
    dataSet,
    hotInstance,
    isLastRow,
    onRepositionPopper,
    updateTotalError,
    validator,
    checkboxController,
    allColumnSetting,
    modeViewTable,
    dataModels,
    onEntryChange,
    onAfterRemove: () => {
      clearLastSelectedCell();
    },
  });

  const { onDuplicate } = useDuplicate({
    columns,
    dataInfos,
    dataSet,
    hotInstance,
    updateTotalError,
    validator,
    checkboxController,
    allColumnSetting,
    modeViewTable,
  });

  const handleChange = useCallback(
    (
      changes: CellChange[] | null,
      source: string,
      isChangesInPhysical: boolean
    ) => {
      if (
        source === CUSTOM_SOURCE_CHANGE.HOOK_ENTRY_CHANGE ||
        source === CUSTOM_SOURCE_CHANGE.INITIAL_ROW
      ) {
        return;
      }
      const instance = hotInstance.current?.hotInstance;
      if (changes && (changes?.length ?? 0) > 0 && instance) {
        const maxChangeRow = getMaxRowByRowChange(changes);
        const isCreateNewRow = maxChangeRow === dataSet.length - 1;
        const promiseTasks: Promise<void | null>[] = [];

        for (const change of changes) {
          // NOTE: rowIndex is visual row
          const [rowIndex, sourceCol, oldValue] = change;
          const physicalRowIndex = isChangesInPhysical
            ? rowIndex
            : instance.toPhysicalRow(rowIndex);
          const rowData: any[] = [];

          if (isChangesInPhysical) {
            const sourceRowData = instance.getSourceDataAtRow(
              rowIndex
            ) as RowObject;
            columns.forEach((column, i) => {
              rowData[i] = sourceRowData[column.key];
            });
          } else {
            const visualRowData = instance.getDataAtRow(rowIndex);
            visualRowData.forEach((col, visualCol) => {
              const physicalCol =
                hotInstance.current?.hotInstance?.toPhysicalColumn(visualCol) ??
                0;
              rowData[physicalCol] = col;
            });
          }

          const rowDataObj: Row = {};

          for (let i = 0; i < rowData.length; i++) {
            const key = columns[i].key;
            const dataModel = findDataModel(dataModels, key);
            rowDataObj[key] = dataModel
              ? ValueBeforeHookParser.parse(dataModel, rowData[i])
              : rowData[i];
          }

          const entryChange = onEntryChange?.(rowDataObj, rowIndex, {
            actionType: 'edit',
          });
          const handleEntryChange = (
            entryChange: Record<string, HookedRecordResult>
          ) => {
            const beforeRowErrorCount = validator.getErrorCountByRow(
              dataInfos,
              physicalRowIndex
            );
            const validateAndUpdateData = () => {
              const entryChangeKeys = Object.keys(entryChange ?? {});

              for (let i = 0; i < entryChangeKeys.length; ++i) {
                const key = entryChangeKeys[i];
                if (!isNil(entryChange[key]?.value)) {
                  const dataModel = findDataModel(dataModels, key);
                  if (dataModel) {
                    entryChange[key].value = ValueParser.parse(
                      entryChange[key].value,
                      {
                        dataModel,
                      }
                    );
                  }
                }
              }

              columns.forEach((column, columnIndex) => {
                const newValue =
                  entryChange?.[columns?.[columnIndex]?.key]?.value ??
                  rowData?.[columnIndex];

                if (
                  column.key === sourceCol ||
                  !isNil(entryChange?.[columns?.[columnIndex]?.key]?.value)
                ) {
                  const columnSetting =
                    allColumnSetting.getColumnSetting(columnIndex);

                  if (
                    ColumnSettingHelper.hasFilterValueSelected(columnSetting)
                  ) {
                    ColumnSettingHelper.addFilterValueSelected(
                      columnSetting,
                      newValue,
                      true
                    );
                  }

                  allColumnSetting
                    .getFilterStrategy()
                    .getFilterValueItems()
                    .clearValueItemsSnapshot(columnIndex);
                }

                const transformedValues = {
                  value: newValue,
                  key: column.key,
                  row: rowData?.map((value, index) => ({
                    rowIndex: physicalRowIndex,
                    key: columns?.[index]?.key,
                    value: entryChange?.[columns[index].key]?.value ?? value,
                  })),
                };

                handleChangeInfo(entryChange, physicalRowIndex, `${sourceCol}`);
                forEach(entryChange, (entry, key) => {
                  const colIndex = columns.findIndex((col) => col.key === key);
                  if (colIndex > -1) {
                    if (!isNil(entry.value)) {
                      dataSet[physicalRowIndex][key] = entry.value;
                    }
                  }
                });

                if (
                  column.key === sourceCol &&
                  column.validations?.some(
                    (item) => item.validate === 'unique'
                  ) &&
                  !column.isMultiSelect
                ) {
                  // NOTE: affectedRows is physical rows
                  const { affectedRows } = validator.validateUnique([
                    physicalRowIndex,
                    sourceCol,
                    oldValue,
                    newValue,
                  ]);
                  affectedRows.forEach((rowIndex) => {
                    // NOTE: rowIndex is physical rows
                    const beforeAffectedRowErrorCount =
                      validator.getErrorCountByRow({}, rowIndex);

                    const visualRowData = instance.getDataAtRow(
                      instance.toVisualRow(rowIndex)
                    );

                    const rowData: any[] = [];

                    if (isChangesInPhysical) {
                      const sourceRowData = instance.getSourceDataAtRow(
                        rowIndex
                      ) as RowObject;
                      columns.forEach((column, i) => {
                        rowData[i] = sourceRowData[column.key];
                      });
                    } else {
                      visualRowData.forEach((col, visualColIndex) => {
                        const physicalColIndex =
                          hotInstance.current?.hotInstance?.toPhysicalColumn(
                            visualColIndex
                          ) ?? 0;
                        rowData[physicalColIndex] = col;
                      });
                    }

                    const transformedValues = {
                      value: rowData?.[columnIndex],
                      key: column.key,
                      row: rowData?.map((value, index) => ({
                        rowIndex: rowIndex,
                        key: columns?.[index]?.key,
                        value: value,
                      })),
                    };

                    const { error } = validator.validateCell(
                      column,
                      columns,
                      transformedValues
                    );

                    validator.setError(error, columnIndex, rowIndex);

                    const afterAffectedRowErrorCount =
                      validator.getErrorCountByRow({}, rowIndex);

                    updateTotalError(
                      afterAffectedRowErrorCount - beforeAffectedRowErrorCount
                    );
                  });
                }

                const { error } = validator.validateCell(
                  column,
                  columns,
                  transformedValues
                );

                validator.setError(error, columnIndex, physicalRowIndex);
                const numOfDeleted = validator.removeUniqueErrorFirstRow(
                  column,
                  columnIndex,
                  physicalRowIndex
                );

                updateTotalError(numOfDeleted * -1);
              });

              handleChangeInfo(entryChange, physicalRowIndex, `${sourceCol}`);

              const afterRowErrorCount = validator.getErrorCountByRow(
                dataInfos,
                physicalRowIndex
              );

              updateTotalError(afterRowErrorCount - beforeRowErrorCount);
            };

            const handleEntryInit = (
              values: Record<string, HookedRecordResult>
            ) => {
              forEach(values, (item, key) => {
                if (!entryChange[key]) {
                  entryChange[key] = {};
                }
                if (item.value) {
                  entryChange[key].value = item.value;
                }

                if (item.info) {
                  if (entryChange[key].info) {
                    item.info.forEach((info) => {
                      entryChange[key].info!.push(info);
                    });
                  } else {
                    entryChange[key].info = item.info;
                  }
                }
              });
            };

            if (onEntryInit && isCreateNewRow && rowIndex === maxChangeRow) {
              const initRowData = { ...rowDataObj };

              forEach(entryChange, (change, key) => {
                initRowData[key] = change.value
                  ? change.value
                  : initRowData[key];
              });

              const result = onEntryInit(initRowData, rowIndex);

              if (result) {
                if (isPromise(result)) {
                  return result.then((values) => {
                    handleEntryInit(values);
                    validateAndUpdateData();
                  });
                } else {
                  handleEntryInit(result);
                }
              }
            }

            validateAndUpdateData();

            return null;
          };

          if (isPromise(entryChange)) {
            const promise = entryChange.then((item) => {
              return handleEntryChange(item ?? {});
            });
            promiseTasks.push(promise);
          } else {
            const resultEntryChange = handleEntryChange(entryChange ?? {});
            if (isPromise(resultEntryChange)) {
              promiseTasks.push(resultEntryChange);
            }
          }
        }

        const handleRender = () => {
          onRepositionPopper();
          instance.forceFullRender = true;

          if (isCreateNewRow) {
            addSpareRow();
            instance.scrollViewportTo(dataSet.length - 1);
            instance.selectCell(
              dataSet.length - 1,
              instance.getSelected()?.[0]?.[1] ?? 0,
              undefined,
              undefined,
              false
            );
          }
          if (
            modeViewTable.getViewState() === ModeViewTableState.ERROR &&
            errorCount.current <= 0
          ) {
            changeModeView(false, true);
          }
        };

        if (promiseTasks.length > 0) {
          Promise.all(promiseTasks).then(() => {
            handleRender();
            instance.render();
          });
        } else {
          handleRender();
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      onEntryChange,
      onEntryInit,
      validator,
      handleChangeInfo,
      columns,
      onValidateInitialFinish,
      dataSet.length,
      onRepositionPopper,
    ]
  );

  const handleBeforeChange = useCallback(
    (changes: CellChange[], source: string) => {
      if (
        source === CUSTOM_SOURCE_CHANGE.HOOK_ENTRY_CHANGE ||
        source === CUSTOM_SOURCE_CHANGE.INITIAL_ROW
      ) {
        return;
      }

      changes.forEach((change, index) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, sourceCol] = change;
        const dataModel = dataModels.find(
          (dataModel) => dataModel.getKey() === sourceCol
        );
        const value = change[3];
        if (dataModel) {
          switch (dataModel.getType()) {
            case DATATYPE.INTEGER:
            case DATATYPE.FLOAT:
            case DATATYPE.PERCENTAGE:
            case DATATYPE.CURRENCY_EUR:
            case DATATYPE.CURRENCY_USD: {
              const delimiters = NumberParser.getDelimiters(`${value}`);
              if (source === 'CopyPaste.paste' && delimiters.length === 1) {
                break;
              }
              const universalDecimal = NumberParser.convertToUsWithDecimal(
                `${value}`,
                dataModel.getNumberFormat()
              );
              if (universalDecimal === null) {
                changes[index][3] = value === null ? '' : `${value}`;
                break;
              }
              const convertedUS = NumberParser.convertStringToNumber(
                universalDecimal,
                {
                  format: NumberFormat.US,
                }
              );

              if (convertedUS !== null) {
                changes[index][3] = convertedUS;
              } else {
                changes[index][3] = value === null ? '' : `${value}`;
              }
              break;
            }
            case DATATYPE.TIME_HM:
            case DATATYPE.TIME_HMS:
            case DATATYPE.TIME_HM_24:
            case DATATYPE.TIME_HMS_24: {
              const timeFormat = MOMENT_FORMAT_TIME[dataModel.getType()] ?? '';
              changes[index][3] = formatTime(value, timeFormat);
              break;
            }
            case DATATYPE.DATE: {
              changes[index][3] = formatDateStringISO(
                value,
                dataModel.getOutputFormat()
              );
              break;
            }
            case DATATYPE.DATE_DMY:
            case DATATYPE.DATE_MDY:
            case DATATYPE.DATE_ISO:
            case DATATYPE.DATETIME: {
              changes[index][3] = formatDateStringByDateType(
                value,
                dataModel.getType()
              );
              break;
            }
            default: {
              if (dataModel.isDropdown()) {
                if (dataModel.getIsMultiSelection()) {
                  if (!isNil(value) && !isArray(value)) {
                    if (checkIsMultipleValues(changes[index][3])) {
                      changes[index][3] = separateMultipleValues(value);
                    } else {
                      changes[index][3] = [value];
                    }
                  }
                } else {
                  if (isArray(value)) {
                    changes[index][3] = value.join(', ');
                  }
                }
              } else {
                if (isArray(value)) {
                  changes[index][3] = value.join(', ');
                }
              }
              break;
            }
          }
        }
      });
    },
    [dataModels]
  );

  const notifyPopoverChanged = useCallback(
    (coords: CellCoords) => {
      setTimeout(() => {
        const rootElement = hotInstance.current?.hotInstance?.getCell(
          coords.row,
          coords.col,
          true
        );
        const activeEditor =
          hotInstance.current?.hotInstance?.getActiveEditor();

        const isTextEditing =
          activeEditor?.col === coords.col &&
          activeEditor?.row === coords.row &&
          activeEditor.state === 'STATE_EDITING';

        const physicalCol =
          hotInstance.current?.hotInstance?.toPhysicalColumn(coords.col) ??
          coords.col;
        const isDropdownEditing =
          dataModels[physicalCol]?.isDropdown() && itemMenuPopper.isEditing;

        if (
          !rootElement ||
          !popperElement.current ||
          !parentTableElement.current ||
          isTextEditing ||
          isDropdownEditing ||
          !htCloneLeftWtHolderElement.current
        ) {
          latestPopoverMessage.current = null;
          return stackedMessagePopper.destroy();
        }

        const cellInfo = getCellMeta(coords.row, coords.col).meta;

        if (isEmpty(cellInfo)) return onRepositionPopper();

        latestPopoverMessage.current = {
          row: coords.row,
          col: coords.col,
        };

        popoverMessage.current = cellInfo;

        const freezeColumns = allColumnSetting.getFreezeColumns();
        const isFreeze = freezeColumns.includes(coords.col);

        stackedMessagePopper.changeRootElement(
          rootElement,
          popperElement.current as HTMLElement,
          parentTableElement.current as HTMLElement,
          popperRootClassName(cellInfo),
          !isFreeze ? htCloneLeftWtHolderElement.current : undefined,
          'right',
          enableExamples
        );
      });
    },
    [
      enableExamples,
      getCellMeta,
      onRepositionPopper,
      stackedMessagePopper,
      dataModels,
      itemMenuPopper,
      allColumnSetting,
    ]
  );

  const updateOptions = useCallback(
    (coords, physicalCol: number, physicalRow: number) => {
      if (coords.row < 0 || coords.col < 0) {
        return;
      }
      const colIndex = physicalCol ?? coords.col;
      const optionsValidators = new OptionValidator();
      const options = hotInstance.current?.hotInstance?.getCellMeta(
        coords.row,
        coords.col
      )?.['dropdownOptions'] as unknown as Option[];

      if (!options) return;

      const header = dataModels?.[colIndex]?.getKey();
      setIsLoadingDropdown(true);
      if (dataModels?.[colIndex]?.isDropdown()) {
        if (categoryValidationList.some((entry) => entry.getKey() === header)) {
          const newOptions = optionsValidators.getFilterOptions(
            header,
            options,
            dataSet?.[physicalRow] ?? {}
          ).options;
          setOptionsDropdown(newOptions);
        } else {
          setOptionsDropdown(options);
        }
      }
      setIsLoadingDropdown(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const onCellSelected = useCallback(
    (coords: CellCoords, physicalCol: number, physicalRow: number) => {
      if (
        currentSelectingCoord.current.row === coords.row &&
        currentSelectingCoord.current.col === coords.col
      ) {
        updateOptions(coords, physicalCol, physicalRow);
        return;
      }

      if (coords.col < 0) {
        currentSelectingCoord.current = {
          row: coords.row,
          col: currentSelectingCoord.current.col,
        };
      } else {
        currentSelectingCoord.current = {
          row: coords.row,
          col: coords.col,
        };

        notifyPopoverChanged(coords);
        updateOptions(coords, physicalCol, physicalRow);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [notifyPopoverChanged, currentSelectingCoord]
  );

  const onGetAllSearchMatchCount = async (searchParams: ISearchParams) => {
    return getAllSearchMatchCount(dataSet, searchParams);
  };

  const onFindSearchMatch = (
    searchParams: ISearchParams,
    switchFocus?: boolean
  ) => {
    const hot = hotInstance.current?.hotInstance;

    const { row, col } = findSearchMatchPosition(hot, dataSet, searchParams, {
      currentRow: lastSelectedBySearchCell.current?.row ?? -1,
      currentCol: lastSelectedBySearchCell.current?.col ?? -1,
      dataModels,
    });
    if (row === -1 && col === -1) {
      lastSelectedBySearchCell.current = null;
    } else {
      lastSelectedBySearchCell.current = { row, col };
      autoScroll(row, col, switchFocus);
    }
  };

  const replaceSingleWordSearchMatchCell = (
    physicalRow: number,
    physicalCol: number,
    visualRow: number | null,
    visualCol: number | null,
    searchParams: ISearchParams
  ) => {
    const hot = hotInstance.current?.hotInstance;
    const targetDataModel = dataModels[physicalCol];
    const targetCol = searchParams.columns.find(
      (c) => c.key === targetDataModel.getKey()
    );

    if (!targetCol) {
      return;
    }

    const currentRawData = ValueParser.parseRawValueToDisplayValue(
      dataSet[physicalRow][targetDataModel.getKey()],
      targetCol
    );

    const newRawData = replaceWord(
      currentRawData,
      searchParams.value,
      searchParams.wordToReplace ?? ''
    );

    hot?.setSourceDataAtCell(
      physicalRow,
      targetDataModel.getKey(),
      ValueParser.parseDisplayValueToRawValue(newRawData, targetCol)
    );

    const hiddenColPlugins = hot?.getPlugin('hiddenColumns');
    const hiddenRowPlugins = hot?.getPlugin('hiddenRows');
    if (
      !isNil(visualRow) &&
      !isNil(visualCol) &&
      !hiddenRowPlugins?.isHidden(visualRow) &&
      !hiddenColPlugins?.isHidden(visualCol)
    ) {
      hot?.selectCell(visualRow, visualCol, undefined, undefined, false);
      onFindSearchMatch(searchParams);
    }
  };

  const onReplaceWordSearchMatch = (searchParams: ISearchParams) => {
    const task = new Promise<void>((resolve, reject) => {
      try {
        const hot = hotInstance.current?.hotInstance;
        const selectedCell = hotInstance.current?.hotInstance?.getSelected();

        if (!searchParams.isReplaceAll) {
          const { row, col } = findSearchMatchPosition(
            hot,
            dataSet,
            searchParams,
            {
              currentRow: lastSelectedBySearchCell.current?.row ?? 0,
              currentCol: lastSelectedBySearchCell.current?.col ?? 0,
              dataModels,
              isReplaceFunc: true,
            }
          );

          if (
            selectedCell?.[0]?.[0] !== row ||
            selectedCell?.[0]?.[1] !== col
          ) {
            autoScroll(row, col);
            lastSelectedBySearchCell.current = { row, col };
          } else {
            const physicalRow = hot?.toPhysicalRow(row) ?? 0;
            const physicalCol = hot?.toPhysicalColumn(col) ?? 0;
            replaceSingleWordSearchMatchCell(
              physicalRow,
              physicalCol,
              row,
              col,
              searchParams
            );
          }
          onRepositionPopper();
        } else {
          replaceWordSearchMatchAllCells(hot, dataSet, searchParams);
        }
        resolve();
      } catch (error) {
        reject(error);
      }
    });

    return task;
  };

  const selectNextError = () => {
    const selectedCell = hotInstance.current?.hotInstance?.getSelected();
    const hot = hotInstance.current?.hotInstance;
    if (hot) {
      const { nextRow, nextCol } = validator.getNextCursorError(
        lastSelectedByFindErrorCell.current?.row ?? 0,
        lastSelectedByFindErrorCell.current?.col ?? 0,
        !!selectedCell,
        dataInfos,
        hot
      );

      lastSelectedByFindErrorCell.current = { row: nextRow, col: nextCol };
      autoScroll(nextRow, nextCol);
    }
  };

  const onHoverCell = useCallback(
    (coords: CellCoords) => {
      if (!coords) return;

      if (coords.row < 0 || coords.col < 0) {
        if (currentSelectingCoord.current) {
          return onRepositionPopper();
        }
        latestPopoverMessage.current = null;
        return stackedMessagePopper.destroy();
      }

      notifyPopoverChanged(coords);
    },
    [
      currentSelectingCoord,
      notifyPopoverChanged,
      onRepositionPopper,
      stackedMessagePopper,
    ]
  );

  const handleStackedMessagePopper = useCallback(() => {
    if (latestPopoverMessage.current) {
      const cellElement = hotInstance.current?.hotInstance?.getCell(
        latestPopoverMessage.current.row,
        latestPopoverMessage.current.col,
        true
      );

      if (
        cellElement &&
        popperElement.current &&
        parentTableElement.current &&
        htCloneLeftWtHolderElement.current
      ) {
        const freezeColumns = allColumnSetting.getFreezeColumns();
        const isFreeze = freezeColumns.includes(
          latestPopoverMessage.current.col
        );

        stackedMessagePopper.changeRootElement(
          cellElement,
          popperElement.current,
          parentTableElement.current,
          '',
          !isFreeze ? htCloneLeftWtHolderElement.current : undefined,
          'right',
          enableExamples
        );
      } else {
        stackedMessagePopper.destroy();
      }
    } else {
      stackedMessagePopper.destroy();
    }
  }, [enableExamples, stackedMessagePopper, allColumnSetting]);

  const handleClearMultiSelectDropdown = useCallback(
    (isMultiSelect) => {
      if (isMultiSelect) {
        currentEditingValueRef.current = '';
      }
    },
    [currentEditingValueRef]
  );

  const parseValueToOptionValue = useCallback(
    (colIndex: number, value: string) => {
      const physicalCol =
        hotInstance.current?.hotInstance?.toPhysicalColumn(colIndex) ??
        colIndex;
      const datModel = dataModels[physicalCol];
      if (datModel.getIsMultiSelection() && checkIsMultipleValues(value)) {
        const splitTmp = separateMultipleValues(value);
        const tmpValue = [];
        for (let i = 0; i < splitTmp.length; ++i) {
          tmpValue.push(
            findDropdownOption(physicalCol, splitTmp[i], OptionKey.VALUE)
          );
        }
        return tmpValue.join(', ');
      } else {
        return findDropdownOption(physicalCol, value, OptionKey.VALUE);
      }
    },
    [findDropdownOption, dataModels]
  );

  const parseValueToOptionLabel = useCallback(
    (colIndex: number, value: string) => {
      const physicalCol =
        hotInstance.current?.hotInstance?.toPhysicalColumn(colIndex) ??
        colIndex;
      if (isArray(value)) {
        const tmpVale = [];
        for (let i = 0; i < value.length; ++i) {
          const targetValue = value[i];
          tmpVale.push(
            findDropdownOption(physicalCol, targetValue, OptionKey.LABEL)
          );
        }
        return tmpVale.join(', ');
      } else {
        return findDropdownOption(physicalCol, value, OptionKey.LABEL);
      }
    },
    [findDropdownOption]
  );

  const settingTable = useMemo(() => {
    const columnsDataModel: ColumnSettings[] = dataModels.map((dataModel) => {
      let source;
      const dataModelColumn = new DataModelColumn(dataModel);

      const isRequired = dataModel.getIsRequired();
      const description = dataModel.getDescription();
      const example = dataModel.getExample();
      const options =
        new DataModelContainer({ dataModels })
          .getCategoryModelByKey(dataModel.getKey())
          ?.getOptions() ?? [];

      if (dataModel.isCategoryType()) {
        source = options;
      } else if (dataModel.getType() === 'boolean') {
        source = booleanDropdownOptions();
      }

      const renderer = (() => {
        const numberFormat = dataModel.getNumberFormat();
        const columnType = dataModelColumn.getType();
        if (dataModelColumn.getType() === 'dropdown') {
          if (dataModel.getType() === DATATYPE.BOOLEAN) {
            return {
              renderer: new DropdownInputBooleanView(getCellMetaForRenderer)
                .renderer,
              editor: false,
            };
          } else {
            return {
              renderer: new DropdownInputView(getCellMetaForRenderer, () => {
                const coords = currentSelectingCoord.current;
                const physicalRow =
                  hotInstance.current?.hotInstance?.toPhysicalRow(coords.row) ??
                  coords.row;
                const physicalCol =
                  hotInstance.current?.hotInstance?.toPhysicalColumn(
                    coords.col
                  ) ?? coords.col;
                if (coords) {
                  updateOptions(coords, physicalCol, physicalRow);
                }
                handleClearMultiSelectDropdown(dataModel.getIsMultiSelection());
              }).renderer,
              editor: false,
            };
          }
        } else if (columnType === 'numeric') {
          return {
            type: 'numeric',
            renderer: SimpleTextInputView(getCellMetaForRenderer, {
              numberFormat,
              mediaSize: mediaSize,
            }),
            className: 'htNoWrap h-auto htMiddle htLeft px-3',
            editor: NumberInputEditor,
            validator: null as any,
          };
        } else if (columnType === 'percentage') {
          return {
            type: 'numeric',
            renderer: SimpleTextInputView(getCellMetaForRenderer, {
              numberFormat,
              numberAdornment: 'percentage',
              mediaSize: mediaSize,
            }),
            className: 'htNoWrap h-auto htMiddle htLeft px-3',
            editor: PercentageInputEditor,
            validator: null as any,
          };
        } else if (columnType === 'currency') {
          return {
            type: 'numeric',
            renderer: SimpleTextInputView(getCellMetaForRenderer, {
              numberFormat:
                numberFormat ||
                (dataModel.getType() === DATATYPE.CURRENCY_USD
                  ? NumberFormat.US
                  : NumberFormat.EU),
              numberAdornment: 'currency',
              symbol:
                dataModel.getType() === DATATYPE.CURRENCY_USD
                  ? NumberFormat.US
                  : NumberFormat.EU,
              mediaSize: mediaSize,
            }),
            className: 'htNoWrap h-auto htMiddle htLeft px-3',
            editor: CurrencyInputEditor,
            validator: null as any,
          };
        } else if (columnType === 'time') {
          return {
            type: 'text',
            renderer: SimpleTextInputView(getCellMetaForRenderer, {
              mediaSize: mediaSize,
            }),
            className: 'htNoWrap h-auto htMiddle htLeft px-3',
            editor: TimeInputEditor,
            validator: null as any,
          };
        } else if (columnType === 'date') {
          return {
            type: 'text',
            renderer: SimpleTextInputView(getCellMetaForRenderer, {
              mediaSize: mediaSize,
            }),
            className: 'htNoWrap h-auto htMiddle htLeft px-3',
            editor: DateInputEditor,
            validator: null as any,
          };
        } else {
          return {
            type: 'text',
            renderer: SimpleTextInputView(getCellMetaForRenderer, {
              mediaSize: mediaSize,
            }),
            className: 'htNoWrap h-auto htMiddle htLeft px-3',
            editor: TextInputEditor,
          };
        }
      })();

      return {
        data: dataModel.getKey(),
        type: 'text',
        dropdownOptions: source,
        isRequired: isRequired,
        description: description,
        example: example ?? '',
        ...renderer,
      };
    });

    const headerSvgClass = css`
      svg {
        width: ${mediaSize ? 16 : 20}px !important;
        height: ${mediaSize ? 17 : 21}px !important;
        transform: rotate(180deg);
      }
    `;

    const getColumnHeight = () => {
      return enableExamples ? (mediaSize ? 45 : 65) : mediaSize ? 23 : 33;
    };

    const switcherOptionValueBetweenLabel = ({
      selectionData,
      sourceRange,
      targetRange,
      isSelectionSingle = false,
      direction,
    }: {
      selectionData: CellValue[][];
      sourceRange: CellRange;
      targetRange: CellRange;
      isSelectionSingle?: boolean;
      direction: 'up' | 'down' | 'left' | 'right';
    }) => {
      const matrixSelectionData = [];
      for (let rowIndex = 0; rowIndex < selectionData.length; rowIndex++) {
        const tmpSelectionData: string[] = [];

        for (
          let colIndex = sourceRange.from.col;
          colIndex <= sourceRange.to.col;
          ++colIndex
        ) {
          const targetIndex = colIndex - sourceRange.from.col;
          const physicalCol =
            hotInstance.current?.hotInstance?.toPhysicalColumn(colIndex) ??
            colIndex;
          if (dataModels[physicalCol].isDropdown()) {
            tmpSelectionData.push(
              parseValueToOptionLabel(
                colIndex,
                selectionData[rowIndex][targetIndex]
              )
            );
          } else {
            tmpSelectionData.push(selectionData[rowIndex][targetIndex]);
          }
        }

        for (
          let colIndex = targetRange.from.col;
          colIndex <= targetRange.to.col;
          ++colIndex
        ) {
          const targetIndex = colIndex - targetRange.from.col;
          const physicalCol =
            hotInstance.current?.hotInstance?.toPhysicalColumn(colIndex) ??
            colIndex;
          if (dataModels[physicalCol].isDropdown()) {
            const tmp = isSelectionSingle
              ? tmpSelectionData[0]
              : tmpSelectionData[targetIndex];

            tmpSelectionData[targetIndex] = parseValueToOptionValue(
              colIndex,
              tmp
            );
          }
        }

        matrixSelectionData.push(
          direction === 'left' ? tmpSelectionData.reverse() : tmpSelectionData
        );
      }

      if (
        matrixSelectionData.filter(
          (item) => item.filter((subItem) => !!subItem).length > 0
        ).length === 0
      ) {
        return selectionData;
      }

      return matrixSelectionData;
    };

    const getResultCell = (target: any, factor: number) => {
      const str = target;
      let init = parseNumberStringToNumber(`${str}`);
      if (Number(str) && str % 1 !== 0) {
        const divider = Number(
          `1${new Array(`${str}`.split('.')?.length)
            .fill('0')
            .toString()
            .replace(/[\\,]/g, '')}`
        );
        factor /= divider;
        init = str + factor;
      } else if (
        new RegExp('[0-9]').test(str) &&
        new RegExp(',').test(str) &&
        !Number(str)
      ) {
        const newStr = str.replace(/[\\.]/g, '');
        const res = newStr.replace(/[\\,]/g, '.');
        const divider = Number(
          `1${new Array(`${res}`.split('.')?.length)
            .fill('0')
            .toString()
            .replace(/,/g, '')}`
        );
        factor /= divider;
        init = Number(res) + factor;
      } else if (!Number(str) && str.includes('%')) {
        const decimal = parseTextPercent(str, true);
        const divider = Number(
          `1${new Array(`${decimal}`.split('.')?.length)
            .fill('0')
            .toString()
            .replace(/[\\,]/g, '')}`
        );
        factor /= divider;
        init = Number(decimal) + factor;
      } else {
        init += factor;
      }

      return init;
    };

    const handleBeforeAutofill = (
      selectionData: CellValue[][],
      sourceRange: CellRange,
      targetRange: CellRange,
      direction: 'up' | 'down' | 'left' | 'right'
    ) => {
      const newValue: CellValue[][] = [];
      if (direction === 'down' || direction === 'up') {
        if (selectionData?.length === 1 && selectionData?.[0]?.length === 1) {
          return;
        }
        let limitLoop = targetRange.to.row - sourceRange.to.row - 1;
        const limitInnerLoop = sourceRange.to.col - sourceRange.from.col;

        if (direction === 'up') {
          limitLoop = sourceRange.to.row - targetRange.from.row;
        }

        for (let i = 0; i <= limitLoop; ++i) {
          const rowValue = [];
          for (let j = 0; j <= limitInnerLoop; ++j) {
            const colIndex = sourceRange.from.col + j;
            const physicalCol =
              hotInstance.current?.hotInstance?.toPhysicalColumn(colIndex) ??
              colIndex;
            let str = selectionData[selectionData.length - 1][j] ?? '';
            if (dataModels[physicalCol].isDropdown()) {
              rowValue.push(str);
              continue;
            }
            const zeroPrefix = `${str}`
              .split(/[^-?0+]/)?.[0]
              .replace(/[//-]/g, '');
            if (!new RegExp('^([0-9]|-|,|%)*$').test(str) && !Number(str)) {
              rowValue.push(str);
              continue;
            }

            if (direction === 'up') {
              str = selectionData[0][j] ?? '';
            }

            let factor = i + 1;

            if (selectionData.length === 1 && selectionData?.[0].length > 0) {
              factor = 0;
            }

            if (direction === 'up') {
              factor *= -1;
            }

            if (!str) return;

            const init = getResultCell(str, factor);

            if (zeroPrefix) {
              if (init < 0) {
                const parsedValue = NumberParser.convertToUsWithDecimal(
                  `${Math.abs(init)}`,
                  NumberFormat.US
                );

                const nextValue = parsedValue ? parsedValue : Math.abs(init);
                rowValue.push(`-${zeroPrefix}${nextValue}`);
              } else {
                const parsedValue = NumberParser.convertToUsWithDecimal(
                  `${init}`,
                  NumberFormat.US
                );
                const nextValue = parsedValue ? parsedValue : init;
                rowValue.push(`${zeroPrefix}${nextValue}`);
              }
            } else {
              const parsedValue = NumberParser.convertToUsWithDecimal(
                `${init}`,
                NumberFormat.US
              );
              const nextValue = parsedValue ? parsedValue : init;
              rowValue.push(`${nextValue}`);
            }
          }
          newValue.push(rowValue);
        }
        return direction === 'up' ? newValue.reverse() : newValue;
      } else {
        const nextRowValue: CellValue[] = [];

        if (selectionData[0].length === 1) {
          return switcherOptionValueBetweenLabel({
            selectionData,
            sourceRange,
            targetRange,
            isSelectionSingle: true,
            direction,
          });
        }

        selectionData = switcherOptionValueBetweenLabel({
          selectionData,
          sourceRange,
          targetRange,
          direction,
        });

        let limitLoop = targetRange.to.col - sourceRange.to.col;
        let str = selectionData[0][selectionData[0].length - 1] ?? '';

        const zeroPrefix = `${str}`.split(/[^-?0+]/)?.[0].replace(/[//-]/g, '');

        if (!new RegExp('^([0-9]|-|,|%)*$').test(str) && !Number(str)) {
          return selectionData;
        }
        if (direction === 'left') {
          limitLoop = sourceRange.to.col - targetRange.from.col;
          str = selectionData[0][0] ?? '';
        }
        for (let i = 0; i < limitLoop; ++i) {
          let factor = i + 1;
          if (direction === 'left') {
            factor *= -1;
          }

          if (!str) return;

          const init = getResultCell(str, factor);

          if (zeroPrefix) {
            if (init < 0) {
              const parsedValue = NumberParser.convertToUsWithDecimal(
                `${Math.abs(init)}`,
                NumberFormat.US
              );

              const nextValue = parsedValue ? parsedValue : Math.abs(init);
              nextRowValue.push(`-${zeroPrefix}${nextValue}`);
            } else {
              const parsedValue = NumberParser.convertToUsWithDecimal(
                `${init}`,
                NumberFormat.US
              );
              const nextValue = parsedValue ? parsedValue : init;
              nextRowValue.push(`${zeroPrefix}${nextValue}`);
            }
          } else {
            const parsedValue = NumberParser.convertToUsWithDecimal(
              `${init}`,
              NumberFormat.US
            );
            const nextValue = parsedValue ? parsedValue : init;
            nextRowValue.push(`${nextValue}`);
          }
        }
        return direction === 'left' ? [nextRowValue.reverse()] : [nextRowValue];
      }
    };

    const recoverValueAtNotDisplayInTable = (
      changes: Array<CellChange | null>,
      isDeleteRow = false
    ) => {
      const hiddenRows = modeViewTable.getHiddenRows();
      for (let i = 0; i < changes.length; i++) {
        const element = changes[i];
        const isAppliedOnlyDelete = isDeleteRow ? element?.[3] === null : true;
        if (element && hiddenRows.includes(element[0]) && isAppliedOnlyDelete) {
          element[3] = element[2];
        } else if (isDeleteRow && element?.[3] !== null) {
          handleBeforeChange(changes as Array<CellChange>, 'edit');
          return;
        }
      }
    };

    const renderAddNewRowButton = (key: string) => {
      return `<div key=${key} class="nuvo-add-row-button">
        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8 4.03711V13.3704" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M3.33331 8.70312H12.6666" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>`;
    };

    const bindAddNewButton = (th: HTMLTableHeaderCellElement) => {
      th.onclick = () => {
        const addRowButtonEle = th.getElementsByClassName(
          'nuvo-add-row-button'
        );
        if (addRowButtonEle?.length > 0) {
          addSpareRow(1);
          scrollToPosition(dataSet.length - 1);
        }
      };
    };

    const onAfterColumnUpdate = () => {
      setTimeout(() => {
        onRepositionPopper();
        const selectedCell = hotInstance.current?.hotInstance?.getSelected();
        if (selectedCell?.[0]) {
          borderConner({ col: selectedCell[0][1], row: selectedCell[0][0] });
        }
      });
      const hasFreeze = allColumnSetting.getFreezeColumns().length > 0;
      const container = hotInstance.current?.hotInstance?.rootElement;
      if (!container) {
        return;
      }
      if (hasFreeze) {
        container.classList.add(FreezeStrategy.containerHasFreezeClassName);
      } else {
        container.classList.remove(FreezeStrategy.containerHasFreezeClassName);
      }
    };

    const settings: GridSettings = {
      data: dataSet,
      columns: columnsDataModel,
      licenseKey: htLicenseKey,
      rowHeaderWidth: 60,
      rowHeights: mediaSize ? 23 : 33,
      minSpareRows: 0,
      height: '100%',
      width: '100%',
      stretchH: 'none',
      viewportColumnRenderingOffset: 18,
      columnHeaderHeight: getColumnHeight(),
      colWidths: colWidths,
      outsideClickDeselects: false,
      currentRowClassName: readOnly ? 'readOnly' : 'currentRow',
      selectionMode: readOnly ? 'single' : 'multiple',
      autoRowSize: false,
      manualRowResize: false,
      autoColumnSize: false,
      enterBeginsEditing: false,
      autoWrapRow: true,
      readOnly: readOnly,
      filters: true,
      columnSorting: {
        compareFunctionFactory: (sortOrder, columnMeta) => {
          const physicalCol =
            hotInstance.current?.hotInstance?.toPhysicalColumn(
              allColumnSetting.getSortingColumnIndex()
            ) ?? 0;

          return compareFunctionFactory(
            sortOrder,
            columnMeta,
            dataModels[physicalCol],
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            (value: any) => {
              return findDropdownOptionForSorting(physicalCol, value);
            }
          );
        },
      },
      fillHandle: readOnly
        ? false
        : {
            autoInsertRow: false,
          },
      hiddenRows: {
        rows: modeViewTable.getHiddenRows(),
        indicators: false,
      },
      hiddenColumns: {
        columns: allColumnSetting.getHiddenColumns(),
        indicators: false,
      },
      manualColumnFreeze: true,
      manualColumnMove: true,
      afterChange: (changes: CellChange[] | null, source: ChangeSource) => {
        handleChange(changes, source, false);
      },
      afterSetSourceDataAtCell: (changes: CellChange[]) => {
        handleChange(changes, '', true);
      },
      afterOnCellMouseDown: (event, coords) => {
        const physicalCol =
          hotInstance.current?.hotInstance?.toPhysicalColumn(coords.col) ??
          coords.col;
        const physicalRow =
          hotInstance.current?.hotInstance?.toPhysicalRow(coords.row) ??
          coords.row;
        if (coords.row >= 0) {
          openDropdown(coords.row, coords.col, physicalRow, physicalCol);
          onCellSelected(
            new CellCoords(coords.row, coords.col),
            physicalCol,
            physicalRow
          );
        }

        if (coords.row < 0) {
          event.stopImmediatePropagation();
        }
      },
      afterGetColHeader: (col, TH) => {
        if (col === -1 && !readOnly) {
          checkboxController?.getView().renderCheckboxAll(TH);
          checkboxController?.registerCheckboxAllEvent(TH, () => {
            const hot = hotInstance.current?.hotInstance;
            if (!hot) {
              return null;
            }
            const isViewModeError =
              modeViewTable.getViewState() === ModeViewTableState.ERROR;
            const hasFilter = allColumnSetting.hasFilter();

            if (!isViewModeError && !hasFilter) {
              return null;
            }

            const hiddenRows: number[] = [];

            if (isViewModeError) {
              const hideRows = modeViewTable.getHiddenRows();
              hideRows.forEach((row) => {
                hiddenRows.push(row);
              });
            }
            if (allColumnSetting.hasFilter()) {
              const filteredRows = allColumnSetting
                .getFilterStrategy()
                .getFilteredHideRowIndex();
              filteredRows.forEach((row) => {
                hiddenRows.push(row);
              });
            }

            const checkedRows: number[] = [];

            for (let i = 0; i < dataSet.length - 1; ++i) {
              const visualRow = hot.toVisualRow(i);
              if (visualRow !== null && !hiddenRows.includes(visualRow)) {
                checkedRows.push(visualRow);
              }
            }

            return checkedRows;
          });
        }

        setTimeout(() => {
          if (col > -1) {
            if (popperInfoElement.current) {
              const column = columns[col];
              customGetColHeader(
                column,
                TH,
                popperInfoElement.current,
                onHoverInfo,
                allColumnSetting,
                col,
                hotInstance
              );
            }
          }
        }, 0);

        if (col > -1) {
          const titleElement = TH.querySelector(
            '#text-title'
          ) as HTMLDivElement | null;

          if (smartTable) {
            titleElement?.style?.setProperty(
              '--text-header-pointer',
              'pointer'
            );
            titleElement?.style?.setProperty(
              '--text-header-decoration',
              'underline'
            );
            contextMenuController.registerEventHeaderMenuButton(TH, col);
            allColumnSetting.registerEventHeaderMenuButton(TH, col);
          }

          TH.addEventListener(
            'click',
            () => {
              infoMessagePopper.hideCurrentRoot();
            },
            false
          );
        }
      },
      afterOnCellMouseOver: (_event, coords) => onHoverCell(coords),
      afterSelection: (row, col) => {
        if (row >= 0) {
          const physicalCol =
            hotInstance.current?.hotInstance?.toPhysicalColumn(col) ?? col;
          const physicalRow =
            hotInstance.current?.hotInstance?.toPhysicalRow(row) ?? row;
          onCellSelected({ row, col } as CellCoords, physicalCol, physicalRow);
        }
        setTimeout(() => {
          isLastRow.current = row + 1 === dataSet.length;
        }, 0);
      },
      afterGetRowHeader: (row, th) => {
        if (row === dataSet.length - 1) {
          bindAddNewButton(th);
        }

        const selectedCell = hotInstance.current?.hotInstance?.getSelected();
        if (!selectedCell && row === 0 && !readOnly && dataSet.length > 1) {
          th.classList.add('currentRow');
        }
        checkboxController?.registerCheckboxPerRowEvent(row, th);
      },
      beforeKeyDown: handleBeforeKeyDown,
      beforeAutofill: handleBeforeAutofill,
      beforeChange: (
        changes: Array<CellChange | null>,
        source: ChangeSource
      ) => {
        // NOTE: The `changes` variable is 2D array [[rowIndex, colName, oldValue, newValue]]
        const hiddenRows = modeViewTable.getHiddenRows();
        if (source === 'edit' && hiddenRows.length > 0) {
          recoverValueAtNotDisplayInTable(changes, true);
        } else if (source === 'Autofill.fill') {
          if (hiddenRows.length === 0) return;

          const displayRows: CellChange[] = [];
          const displayValues: any = [];

          for (let i = 0; i < changes.length; i++) {
            const change = changes?.[i];
            if (!change) continue;
            displayValues.push(change[3]);
            if (!hiddenRows.includes(change[0])) {
              displayRows.push(change);
            }
          }

          for (let i = 0; i < displayRows.length; i++) {
            const index = changes.findIndex(
              (item) =>
                item?.[0] === displayRows?.[i]?.[0] &&
                item?.[1] === displayRows?.[i]?.[1]
            );
            const change = changes[index];
            if (!change) continue;
            change[3] = displayValues[i];
          }
          recoverValueAtNotDisplayInTable(changes);
        } else {
          if (changes.every((item) => !isNil(item))) {
            handleBeforeChange(changes as Array<CellChange>, source);
          }
        }
      },
      beforeDrawBorders: (corners) => {
        borderConner({ row: corners[0], col: corners[1] });
      },
      beforeRenderer: (td, row) => {
        const selectedCell = hotInstance.current?.hotInstance?.getSelected();
        if (!selectedCell && row === 0 && !readOnly && dataSet.length > 1) {
          td.classList.add('currentRow');
        }
      },
      beforeOnCellMouseDown: (event, coords) => {
        if (coords.row < 0) {
          event.stopImmediatePropagation();
        }
      },
      beforePaste: (data, coords) => {
        for (let rowIndex = 0; rowIndex < data.length; ++rowIndex) {
          const subMatrix = [];
          let tmp = '';

          for (let colIndex = 0; colIndex < data[rowIndex].length; ++colIndex) {
            const columnIndex = coords[0].startCol + colIndex;
            tmp = data[rowIndex][colIndex];
            subMatrix.push(parseValueToOptionValue(columnIndex, tmp));
          }

          data[rowIndex] = subMatrix;
        }

        return true;
      },
      beforeCopy: (data, coords) => {
        const endRow = coords[0].startRow + data.length - 1;
        for (
          let colIndex = coords[0].startCol;
          colIndex <= coords[0].endCol;
          ++colIndex
        ) {
          for (
            let rowIndex = coords[0].startRow;
            rowIndex <= endRow;
            ++rowIndex
          ) {
            const dataColIndex = colIndex - coords[0].startCol;
            const dataRowIndex = rowIndex - coords[0].startRow;
            data[dataRowIndex][dataColIndex] = parseValueToOptionLabel(
              colIndex,
              data[dataRowIndex][dataColIndex]
            );
          }
        }
      },
      cells(row) {
        if (removeRows?.includes(row)) {
          return {
            readOnly: true,
            editor: false,
          };
        }
        return {};
      },
      colHeaders: (index: number) => {
        const column = columns[index];
        const visualCool =
          hotInstance.current?.hotInstance?.toVisualColumn(index) ?? 0;
        const sortState =
          allColumnSetting.getColumnSetting(visualCool)?.sort ?? null;
        const icon = allColumnSetting.getDisplayHideIcon()[visualCool];
        const hasFiltered =
          allColumnSetting.getColumnSetting(visualCool)?.filterState !== null;
        return customColumnHeader(column, {
          smartTable,
          enableExample: enableExamples,
          classes: {
            headerSvgClass,
            infoStyleClass,
            exampleStyleClass,
          },
          sorting: sortState,
          hasFiltered,
          hideIcon: icon,
        });
      },
      rowHeaders: (rowIndex) => {
        if (readOnly) {
          return `${rowIndex + 1}`;
        }
        if (rowIndex !== dataSet.length - 1) {
          return (
            checkboxController?.getView().renderCheckboxPerRow(rowIndex) ?? ''
          );
        }
        return renderAddNewRowButton(`${rowIndex}`);
      },
      afterColumnFreeze: () => {
        onAfterColumnUpdate();
      },
      afterColumnUnfreeze: () => {
        onAfterColumnUpdate();
      },
      afterHideColumns: (_, __, ___, isStateChange) => {
        if (isStateChange) {
          onAfterColumnUpdate();
          clearLastSelectedCell();
        }
      },
      afterUnhideColumns: (_, __, ___, isStateChange) => {
        if (isStateChange) {
          onAfterColumnUpdate();
          clearLastSelectedCell();
        }
      },
    };

    return settings;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mediaSize, checkboxController]);

  const onInitElement = useCallback(() => {
    if (!allowedRender) {
      return;
    }

    stackedMessagePopper.clearInstance();

    popperInfoElement.current = document.getElementById(
      elementId.columnInfoElementId
    ) as HTMLElement;

    parentElement.current = document.getElementById(
      elementId.popoverElementId.parentElementId
    ) as HTMLElement;

    popperElement.current = document.getElementById(
      elementId.cellPopperElementId.root
    ) as HTMLElement;

    dropdownElement.current = document.getElementById(
      elementId.dropdownElementId.parent
    ) as HTMLElement;

    wrapperElement.current = document.getElementById(
      'data-model-sheet-form'
    ) as HTMLElement;

    moreAction.current = document.getElementById('action-more') as HTMLElement;

    htCloneLeft.current = document.querySelector(
      '.ht_clone_left'
    ) as HTMLElement;
  }, [stackedMessagePopper, allowedRender]);

  useEffect(() => {
    onInitElement();
  }, [onInitElement]);

  useEffect(() => {
    const itemMenuPopperCurrent = itemMenuPopper;
    return () => {
      stackedMessagePopper.clearInstance();
      itemMenuPopperCurrent?.clearInstance();
    };
  }, [itemMenuPopper, stackedMessagePopper, validator]);

  useEffect(() => {
    if (!allowedRender) return;
    parentTableElement.current = document.querySelector(
      `.ht_master .wtHolder`
    ) as HTMLElement;

    htCloneLeftWtHolderElement.current = document.querySelector(
      `.ht_clone_left .wtHolder`
    ) as HTMLElement;

    const onScrollTable = () => {
      handleDropdownMenuItem();
      handleStackedMessagePopper();
    };

    lgScreenRef.current = window.innerWidth >= breakpointsNumber.lg;

    const handleResize = () => {
      lgScreenRef.current = window.innerWidth >= breakpointsNumber.lg;
    };

    window.addEventListener('resize', handleResize);

    parentTableElement.current.addEventListener('scroll', onScrollTable, {
      passive: true,
    });

    return () => {
      if (parentTableElement.current) {
        parentTableElement.current.removeEventListener('scroll', onScrollTable);
      }
      window.removeEventListener('resize', handleResize);
    };
  }, [allowedRender, handleStackedMessagePopper, handleDropdownMenuItem]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (dataSet.length === 0 || isInitialValidateAndAddRow.current) {
        setAllowedRender(true);
        return;
      }

      if (!notValidate) {
        /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
        const items = dataSet?.map((item: any, rowIndex) => {
          return columns.map((column, index) => {
            return {
              rowIndex,
              colIndex: index,
              valid: true,
              key: column.key,
              value: item[column.key],
            };
          });
        });
        if (isManualInput) {
          items?.splice(-1);
        }
        validator.validateInitial(items, columns, removeRows);
      }

      allColumnSetting
        .getFilterStrategy()
        .getFilterValueItems()
        .setDataSet(dataSet);
      modeViewTable.setDataSet(dataSet);
      modeViewTable.setDataInfos(dataInfos);
      modeViewTable.setValidator(validator);

      onValidateInitialFinish(dataSet.length);
      setAllowedRender(true);

      if (!isInitialValidateAndAddRow.current) {
        if (!readOnly && !isManualInput) {
          addSpareRow(1);
        }
        isInitialValidateAndAddRow.current = true;
      }
    }, 0);

    return () => {
      clearTimeout(timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columns, dataSet, validator, removeRows, dataInfos]);

  useEffect(() => {
    if (checkboxController) {
      const subscription = checkboxController.subscribeToUpdateUICheckAll();
      return () => {
        subscription.unsubscribe();
      };
    }
    return () => {};
  }, [checkboxController]);

  settingTable.hiddenRows = {
    rows: modeViewTable.getHiddenRows(),
  };

  settingTable.hiddenColumns = {
    columns: allColumnSetting.getHiddenColumns(),
    indicators: false,
  };

  useEffect(() => {
    if (allowedRender) {
      const container = hotInstance.current?.hotInstance
        ?.rootElement as HTMLDivElement;
      const scrollContainer = container?.querySelector(
        '.wtHolder'
      ) as HTMLDivElement;

      contextMenuController.setTableContainer(container);

      const handleTableSpacing = () => {
        const hasScrollVertical =
          scrollContainer?.scrollHeight > scrollContainer?.clientHeight;

        const hasScrollVerticalReachEnd =
          hasScrollVertical &&
          scrollContainer.offsetHeight + scrollContainer.scrollTop >=
            scrollContainer?.scrollHeight + HIGHT_SCROLL_BAR;

        const hasScrollHorizontal =
          scrollContainer?.scrollWidth > scrollContainer?.clientWidth;

        if (hasScrollVertical) {
          requestAnimationFrame(() => {
            container.classList.add('add-space-scrollbar-w');
          });
        } else {
          requestAnimationFrame(() => {
            container.classList.remove('add-space-scrollbar-w');
          });
        }

        if (hasScrollHorizontal) {
          requestAnimationFrame(() => {
            container.classList.add('hide-border-last-column');
          });
        } else {
          requestAnimationFrame(() => {
            container.classList.remove('hide-border-last-column');
          });
        }

        if (hasScrollHorizontal && !hasScrollVerticalReachEnd) {
          requestAnimationFrame(() => {
            container.classList.add('add-space-scrollbar-h');
          });
        } else if (hasScrollHorizontal && hasScrollVerticalReachEnd) {
          requestAnimationFrame(() => {
            container.classList.remove('add-space-scrollbar-h');
          });
        } else if (!hasScrollHorizontal) {
          requestAnimationFrame(() => {
            container.classList.remove('add-space-scrollbar-h');
          });
        }
      };

      handleTableSpacing();

      scrollContainer?.addEventListener('scroll', handleTableSpacing, {
        passive: true,
      });

      window.addEventListener('resize', handleTableSpacing, {
        passive: true,
      });

      return () => {
        scrollContainer.removeEventListener('scroll', handleTableSpacing);
        window.removeEventListener('resize', handleTableSpacing);
      };
    }

    return () => {};
  }, [hotInstance, allowedRender, contextMenuController]);

  useEffect(() => {
    const hot = hotInstance.current?.hotInstance;
    if (hot) {
      allColumnSetting.setHotInstance(hot);
      modeViewTable.setHotInstance(hot);
      allColumnSetting.getSortStrategy().registerOnSorting(() => {
        modeViewTable.recalculate();
      });

      const columnFiltering = hot.getPlugin('filters');
      if (columnFiltering) {
        const conditionRegisterer = new ConditionRegisterer(
          dataInfos,
          validator,
          dataModels
        );
        conditionRegisterer.setup();
        setupAddCondition(columnFiltering, conditionRegisterer);
      }
      const filterStrategy = allColumnSetting.getFilterStrategy();
      filterStrategy.setHotInstance(hot);
      filterStrategy.getFilterValueItems().setHotInstance(hot);
      const subscription = filterStrategy.filterObservable().subscribe(() => {
        clearLastSelectedCell();
        modeViewTable.recalculate();
        checkboxController?.clearCheckedMaps();
        hot.render();
      });

      return () => {
        subscription.unsubscribe();
      };
    }

    return () => {};
  }, [
    hotInstance,
    allColumnSetting,
    dataInfos,
    dataSet.length,
    validator,
    checkboxController,
    modeViewTable,
    dataModels,
  ]);

  const getMaxRowByRowChange = (changes: CellChange[]) => {
    let max = changes[0][0];
    for (let i = 0; i < changes.length; ++i) {
      if (changes[i][0] > max) {
        max = changes[i][0];
      }
    }
    return max;
  };

  const scrollToPosition = (position: number) => {
    if (hotInstance?.current?.hotInstance) {
      hotInstance.current.hotInstance.forceFullRender = true;
      hotInstance?.current?.hotInstance?.scrollViewportTo(position);
      hotInstance?.current?.hotInstance?.selectCell(
        dataSet.length - 1,
        hotInstance?.current?.hotInstance?.getSelected()?.[0]?.[1] ?? 0
      );
    }
  };

  const addSpareRow = (amount = 1) => {
    const lastRow = dataSet.length;
    const hot = hotInstance.current?.hotInstance;
    hot?.alter('insert_row_below', dataSet.length, amount);

    setTimeout(() => {
      hot?.batchRender(() => {
        for (let i = lastRow; i < lastRow + amount; i++) {
          for (let col = 0; col < columns.length; col++) {
            if (columns[col] && columns[col].isMultiSelect) {
              hot?.setDataAtCell(
                i,
                col,
                null,
                CUSTOM_SOURCE_CHANGE.INITIAL_ROW
              );
            }
          }
        }
      });
    }, 0);
  };

  const infoStyleClass = useMemo(
    () => css({ '&&': configTheme?.reviewEntriesTheme?.infoIcon }),
    [configTheme?.reviewEntriesTheme?.infoIcon]
  );

  const exampleStyleClass = useMemo(
    () => css({ '&&': configTheme?.reviewEntriesTheme?.table?.example }),
    [configTheme?.reviewEntriesTheme?.table?.example]
  );

  const onClearAllFilter = () => {
    allColumnSetting.clearFilterToAllColumnSettings(dataModels);
  };

  return {
    handleSubmit,
    changeModeView,
    exportValuesToXlsx,
    isSxlargeScreen,
    hotInstance,
    settingTable,
    currentEditingValueRef,
    dropdownOptionsRef,
    onSelectOption,
    allowedRender,
    popoverMessage,
    selectNextError,
    onDuplicate,
    onRemove,
    exporting,
    currentSelectorRef,
    stackedMessagePopper,
    optionsDropdown,
    mediaSize,
    searchValueRef,
    currentEditingModelRef,
    isLoadingDropdown,
    checkboxController,
    contextMenuController,
    parentTableElement,
    allColumnSetting,
    findDropdownOption,
    htCloneLeftWtHolderElement,
    onGetAllSearchMatchCount,
    onFindSearchMatch,
    onReplaceWordSearchMatch,
    onClearAllFilter,
    lastSelectedBySearchCell,
    modeViewTable,
  };
};

export default useViewModel;
