import { css, CSSInterpolation, cx } from 'core/emotion';
import { UseSelectPropGetters } from 'downshift';
import { Fragment, ReactNode, useMemo, useRef } from 'react';
import SheetColumn from './../../../../../../sheetImporter/SheetColumn';
import { MenuItem } from '../MenuItem';
import { Popper } from '../../Dropdown/popper';
import { Option } from '../types';
import Container from './Container';
import SimpleBar from 'simplebar-react';
import Header from './Header';
import SearchHeader from './SearchHeader';
import { maxBy } from 'lodash';
import { THRESHOLD } from 'core/constants/similarity';
import { useTheme } from 'theme';

type MenuItemsProps = {
  popper: Popper;
  options: Option[];
  searchOptions: Option[];
  highlightedIndex: number | null;
  selectedItem: Option | null;
  getItemProps: UseSelectPropGetters<Option>['getItemProps'];
  renderSearch?: () => ReactNode;
  renderNewColumn?: () => ReactNode;
  onRemoveMatchColumn: (value: SheetColumn) => void;
  isSearching: boolean;
  configThemeDropdownMenuItems?: {
    root?: string;
    borderColorRoot?: string;
    navigatorColor?: string;
    backgroundColor?: string;
    header?: string;
    menuItem?: {
      selectedOption?: string;
      option?: string;
      badge?: { root?: CSSInterpolation; icon?: CSSInterpolation };
    };
    popoverInfoIcon?: string;
    popoverInfoTheme?: {
      root?: string;
      arrowIcon?: string;
    };
  };
  allowCustomColumns?: boolean;
  setHighlightedIndex?: (index: number) => void;
};

const MenuItems = ({
  popper,
  options,
  searchOptions,
  highlightedIndex,
  selectedItem,
  getItemProps,
  renderSearch,
  onRemoveMatchColumn,
  isSearching,
  configThemeDropdownMenuItems,
  renderNewColumn,
  allowCustomColumns,
  setHighlightedIndex,
}: MenuItemsProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const theme = useTheme();
  const popperBorderColor = useMemo(() => {
    return theme.getGlobalTheme().getDark50Color();
  }, [theme]);
  const maxRecommendItems =
    options.filter(
      (item) =>
        item.similarity.getSimilarity() ===
          maxBy(options, (item) =>
            item.similarity.getSimilarity()
          )?.similarity.getSimilarity() &&
        item.similarity.getSimilarity() > THRESHOLD
    ).length - 1;
  let recommendItemCounter = 0;

  const hasDescription = () => {
    if (options.find((option) => option.value.getDescription())) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Container popper={popper}>
      <div
        className={cx(
          `rounded-medium dropdown-scroll border-1 last:rounded-b-medium bg-white ${configThemeDropdownMenuItems?.root} ${configThemeDropdownMenuItems?.borderColorRoot} ${configThemeDropdownMenuItems?.navigatorColor} ${configThemeDropdownMenuItems?.backgroundColor}`,
          css({
            width: '393px',
            borderColor: popperBorderColor,
          })
        )}
      >
        <div>{options.length > 0 && renderSearch && renderSearch()}</div>
        <SimpleBar
          forceVisible={false}
          autoHide={true}
          id="menuItems"
          className="max-h-210 4xl:max-h-72"
          scrollableNodeProps={{
            ref,
          }}
        >
          <SearchHeader
            configThemeHeader={configThemeDropdownMenuItems?.header}
            isSearching={isSearching}
            searchOptions={searchOptions}
            options={options}
          />
          {options.map((option, index) => {
            let isRenderOther = false;
            if (!option.isRecommend) {
              isRenderOther = !!(
                recommendItemCounter === maxRecommendItems ||
                (maxRecommendItems === -1 && recommendItemCounter === 0)
              );
              recommendItemCounter++;
            }

            const isIncludedInSearchOptions = !searchOptions.some(
              (searchOption) => searchOption.value === option.value
            );

            if (isIncludedInSearchOptions) {
              return null;
            }

            return (
              <Fragment key={`${option.value}${index}`}>
                <Header
                  option={option}
                  isRenderOther={isRenderOther}
                  isSearching={isSearching}
                  theme={configThemeDropdownMenuItems?.header}
                />
                <MenuItem
                  configThemeDropdownMenuItem={
                    configThemeDropdownMenuItems?.menuItem
                  }
                  popoverInfoIcon={
                    configThemeDropdownMenuItems?.popoverInfoIcon
                  }
                  popoverInfoTheme={
                    configThemeDropdownMenuItems?.popoverInfoTheme
                  }
                  key={`${option.value}${index}`}
                  option={option}
                  active={highlightedIndex === index}
                  selected={option === selectedItem}
                  onRemoveMatchColumn={onRemoveMatchColumn}
                  boundary={ref.current}
                  hasDescription={hasDescription}
                  setHighlightedIndex={setHighlightedIndex}
                  {...getItemProps({ item: option, index })}
                />
              </Fragment>
            );
          })}
        </SimpleBar>
        <div>
          {allowCustomColumns && renderNewColumn ? renderNewColumn() : null}
        </div>
      </div>
    </Container>
  );
};

export default MenuItems;
