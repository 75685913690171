import { CSSInterpolation } from '@nuvo-importer/common';
import Card from '../Card';
import {
  MutableRefObject,
  ReactNode,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import HeaderTable from '../Header';
import { Sheet, SheetColumn } from 'sheetImporter';
import MatchStatus from '../MatchStatus';
import { calculateMatchPercentage } from '../../utils';
import { useTheme } from 'theme';
import { makeCancelable } from 'core/promiseUtils';

type CollapseCardProps = {
  sheet: Sheet;
  sheetColumn?: SheetColumn;
  baseSheetColumn?: SheetColumn;
  children?: ReactNode;
  configTheme?: {
    root: CSSInterpolation;
    content: CSSInterpolation;
    sheet: {
      name?: CSSInterpolation;
      fileName?: CSSInterpolation;
      icon?: { valid?: string; invalid?: string };
    };
  };
  toggleCollapse: () => void;
  isCollapse: boolean;
  sheetIndex: number;
  setSelectedPercentage: (sheetIndex: number, percentage: number) => void;
};

const CollapseCard = ({
  sheet,
  sheetColumn,
  baseSheetColumn,
  configTheme,
  toggleCollapse,
  isCollapse,
  sheetIndex,
  setSelectedPercentage,
  children,
}: CollapseCardProps) => {
  const [processingPercentage, setProcessingPercentage] = useState(false);
  const [initialProcessingPercentage, setInitialProcessingPercentage] =
    useState(true);
  const [matchPercentage, setMatchPercentage] = useState(0);
  const theme = useTheme();

  const baseSheet = useMemo(() => {
    return baseSheetColumn?.getSheet();
  }, [baseSheetColumn]);

  const isSelected = useMemo(() => {
    return !!baseSheet && !!sheetColumn;
  }, [baseSheet, sheetColumn]);

  const calculationTimer: MutableRefObject<NodeJS.Timeout | undefined> = useRef<
    NodeJS.Timeout | undefined
  >(undefined);
  const matchPercentageTimer: MutableRefObject<NodeJS.Timeout | undefined> =
    useRef<NodeJS.Timeout | undefined>(undefined);

  useEffect(() => {
    if (isSelected && baseSheetColumn !== sheetColumn) {
      setProcessingPercentage(true);
      setInitialProcessingPercentage(false);

      const calcPromise = makeCancelable(
        calculateMatchPercentage(
          matchPercentageTimer,
          baseSheetColumn,
          sheetColumn
        )
      );

      setSelectedPercentage(sheetIndex, 0);
      setMatchPercentage(0);

      calcPromise.promise
        .then((matchPercentage) => {
          calculationTimer.current = setTimeout(() => {
            setSelectedPercentage(sheetIndex, matchPercentage);
            setMatchPercentage(matchPercentage);
            setProcessingPercentage(false);
          }, 200);
        })
        .catch(() => {
          setInitialProcessingPercentage(false);
        });

      return () => {
        calcPromise?.cancel();
        setProcessingPercentage(false);
        calculationTimer?.current && clearTimeout(calculationTimer?.current);
        matchPercentageTimer?.current &&
          // eslint-disable-next-line react-hooks/exhaustive-deps
          clearTimeout(matchPercentageTimer?.current);
      };
    }

    return () => {};
  }, [
    baseSheetColumn,
    sheetColumn,
    setSelectedPercentage,
    sheetIndex,
    isSelected,
  ]);

  return (
    <Card>
      <div className="w-full">
        <HeaderTable
          isCollapse={isCollapse}
          toggleCollapse={toggleCollapse}
          sheet={sheet}
          isSelected={isSelected}
          sheetIndex={sheetIndex}
          matchPercentage={matchPercentage}
          configTheme={{
            sheetName: configTheme?.sheet.name,
            fileName: configTheme?.sheet.fileName,
            icon: configTheme?.sheet.icon,
          }}
        />
        {sheetIndex !== 0 && isSelected && !initialProcessingPercentage ? (
          <MatchStatus
            isCollapse={isCollapse}
            percent={matchPercentage}
            fileName={baseSheet!.getSpreadSheet().getFilename()}
            columnKey={baseSheetColumn?.getColumnKey() ?? ''}
            sheetName={baseSheet!.getName() ?? ''}
            configTheme={{
              matchStatus: theme.getJoinSheetTheme().matchStatus,
            }}
            isProcessing={processingPercentage}
          />
        ) : null}
        {children}
      </div>
    </Card>
  );
};
export default CollapseCard;
