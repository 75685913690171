import {
  DataModelSerializer,
  NumberFormat,
} from '@nuvo-importer/common/worker/matching-value';
import {
  Matching,
  MatchingJSON,
} from '../../reviewEntries/DataModelSheetMatchingValues';
import { SheetColumnSerializer } from '../../sheetImporter/serializer';

export class MatchingSerializer {
  static serialize = (instance: {
    matching: Matching[];
    numberFormat: NumberFormat;
  }) => {
    const result: { matching: MatchingJSON[]; numberFormat: NumberFormat } = {
      matching: [],
      numberFormat: instance.numberFormat,
    };

    result.matching = instance.matching.map((match) => {
      return {
        sheetColumn: SheetColumnSerializer.serialize(match.sheetColumn),
        matchedDataModel: match.matchedDataModel
          ? {
              dataModel: match.matchedDataModel!.dataModel
                ? DataModelSerializer.serialize(
                    match.matchedDataModel!.dataModel
                  )
                : match.matchedDataModel!.dataModel,
              matchedOptions: match.matchedDataModel.matchedOptions,
            }
          : match.matchedDataModel,
      };
    });

    return [result, []];
  };

  static deserialize = (obj: {
    matching: MatchingJSON[];
    numberFormat: NumberFormat;
  }): { matching: Matching[]; numberFormat: NumberFormat } => {
    return {
      matching: obj.matching.map((match) => {
        return {
          sheetColumn: SheetColumnSerializer.deSerialize(match.sheetColumn, {
            notSetUnique: true,
          }),
          matchedDataModel: match.matchedDataModel
            ? {
                dataModel: match.matchedDataModel!.dataModel
                  ? DataModelSerializer.deSerialize(
                      match.matchedDataModel!.dataModel
                    )
                  : match.matchedDataModel!.dataModel,
                matchedOptions: match.matchedDataModel.matchedOptions,
              }
            : match.matchedDataModel,
        };
      }),
      numberFormat: obj.numberFormat,
    };
  };
}
