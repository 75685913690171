import {
  CalculateSimilarityResult,
  ColumnConfig,
  ExecuteOptions,
  JSONValue,
  Value,
} from '@nuvo-importer/common/core';
import { PROCESSING_API_URL, licenseKeyHeader } from 'core/constants/service';
import Rxios from 'core/services/Rxios';
import Tracking from '../../../tracking/Tracking';
import { map } from 'rxjs';

class BackendAPI extends Rxios {
  constructor() {
    super({
      baseURL: PROCESSING_API_URL,
    });
  }

  matchColumns = (
    headers: Value[],
    columns: ColumnConfig[],
    licenseKey: string,
    options: ExecuteOptions
  ) => {
    return this.post<CalculateSimilarityResult>(
      '/mapping/columns',
      {
        headers,
        columns,
        options,
      } as unknown as JSONValue,
      {
        headers: {
          [licenseKeyHeader]: licenseKey,
          framework: Tracking.getFrameWork(),
          originRequest: Tracking.getOrigin(),
        },
      }
    ).pipe(
      map((res) => {
        return [res];
      })
    );
  };

  matchOptions = (
    fileId: string,
    mappingHeaderColumns: {
      column_index: number;
      column: ColumnConfig;
    }[],
    licenseKey: string,
    options: ExecuteOptions
  ) => {
    return this.post<CalculateSimilarityResult[]>(
      '/mapping/options',
      {
        temp_id: fileId,
        mapping_columns: mappingHeaderColumns,
        options,
      } as unknown as JSONValue,
      {
        headers: {
          [licenseKeyHeader]: licenseKey,
          framework: Tracking.getFrameWork(),
          originRequest: Tracking.getOrigin(),
        },
      }
    );
  };
}

export default BackendAPI;
