import { NumberFormat } from '../dataModel/columnsAPI';
import { DATATYPE } from '../dataType';
import { DataModelSheetMatch } from '../matching/DataModelSheetMatching';
import { Value } from '../sheet/Sheet';
import { NumberParser } from './NumberParser';

/** Detect regional settings of the Sheet from the first 25 rows of FLOAT or INT columns */
export function detectSheetRegion(
  matching: DataModelSheetMatch[]
): NumberFormat {
  const PROBE_ROWS_COUNT = 25;
  let result: NumberFormat = NumberFormat.EU;
  let rowsWithFloatType: Value[] = [];
  let rowsWithIntType: Value[] = [];
  let rowsWithCurrencyEUType: Value[] = [];
  let rowsWithCurrencyUSType: Value[] = [];
  let rowsWithPercentageType: Value[] = [];

  for (let i = 0; i < matching.length; i++) {
    const type: DATATYPE | undefined =
      matching[i].matchedDataModel?.dataModel?.getType();

    if (type === DATATYPE.FLOAT) {
      rowsWithFloatType = matching[i].sheetColumn
        .getRows()
        .slice(0, PROBE_ROWS_COUNT);
    }

    if (type === DATATYPE.CURRENCY_EUR) {
      rowsWithCurrencyEUType = matching[i].sheetColumn
        .getRows()
        .slice(0, PROBE_ROWS_COUNT);
    }

    if (type === DATATYPE.CURRENCY_USD) {
      rowsWithCurrencyUSType = matching[i].sheetColumn
        .getRows()
        .slice(0, PROBE_ROWS_COUNT);
    }

    if (type === DATATYPE.INTEGER) {
      rowsWithIntType = matching[i].sheetColumn
        .getRows()
        .slice(0, PROBE_ROWS_COUNT);
    }

    if (type === DATATYPE.PERCENTAGE) {
      rowsWithPercentageType = matching[i].sheetColumn
        .getRows()
        .slice(0, PROBE_ROWS_COUNT);
    }
  }

  const probes: Value[][] = [
    rowsWithFloatType,
    rowsWithCurrencyEUType,
    rowsWithCurrencyUSType,
    rowsWithIntType,
    rowsWithPercentageType,
  ];

  for (let i = 0; i < probes.length; i++) {
    const format = detectNumberFormat(probes[i]);

    if (format !== undefined) {
      result = format;
      break;
    }
  }

  return result;
}

function detectNumberFormat(values: Value[]): NumberFormat | undefined {
  let euFormatsCount = 0;
  let usFormatsCount = 0;

  for (let i = 0; i < values.length; i++) {
    const value: Value = values[i];
    if (typeof value !== 'string') {
      continue;
    }
    const format: NumberFormat | null | undefined =
      NumberParser.detectNumberFormat(value, { resolveUncertainty: false });

    if (format === NumberFormat.EU) {
      euFormatsCount++;
    }

    if (format === NumberFormat.US) {
      usFormatsCount++;
    }
  }

  if (usFormatsCount === 0 && euFormatsCount === 0) {
    return undefined;
  }

  if (usFormatsCount > euFormatsCount) {
    return NumberFormat.US;
  }

  return NumberFormat.EU;
}
