import { ModelTemplate } from 'core/constants/modelTemplate';
import { exportToCsv, exportToXlsx } from 'core/downloadTemplateSheet';
import { useDataModels } from 'dataModel';
import { useCallback, useMemo } from 'react';
import { useSettings } from 'settings';

const useViewModel = () => {
  const dataModelAPIMapper = useDataModels();
  const { identifier, disableTemplateDropdowns } = useSettings();

  const fileName = identifier ?? 'model';

  const models = useMemo(() => {
    return dataModelAPIMapper.getDataModels();
  }, [dataModelAPIMapper]);

  const downloadTemplateSheet = useCallback(
    (type: ModelTemplate) => {
      if (type === ModelTemplate.XLSX) {
        exportToXlsx(fileName, models, disableTemplateDropdowns ?? false);
      } else {
        exportToCsv(fileName, models);
      }
    },
    [models, fileName, disableTemplateDropdowns]
  );

  return {
    downloadTemplateSheet,
  };
};

export default useViewModel;
