import { DataModelJSON } from './type';
import CategoryDataModel from '../model/CategoryDataModel';
import { CategoryDataModelType, DataModel } from '../model/DataModel';
import Validator from '../validator/Validator';

export class DataModelSerializer {
  static serialize = (dataModel: DataModel) => {
    return dataModel.serialize();
  };

  static deSerialize = (dataModelJSON: DataModelJSON) => {
    if (DataModel.isTypeCategory(dataModelJSON.type)) {
      return new CategoryDataModel({
        description: dataModelJSON.description,
        example: dataModelJSON.example,
        key: dataModelJSON.key,
        label: dataModelJSON.label,
        type: dataModelJSON.type as CategoryDataModelType,
        options: dataModelJSON.options!,
        validators: [] as Validator[],
        alternativeMatches: dataModelJSON.alternativeMatches,
        columnSize: dataModelJSON.columnSize,
        isMultiSelection: dataModelJSON.isMultiSelection ?? false,
        allowCustomOptions: dataModelJSON.allowCustomOptions || false,
      });
    } else {
      return new DataModel({
        description: dataModelJSON.description,
        example: dataModelJSON.example,
        key: dataModelJSON.key,
        label: dataModelJSON.label,
        type: dataModelJSON.type,
        validators: [] as Validator[],
        alternativeMatches: dataModelJSON.alternativeMatches,
        columnSize: dataModelJSON.columnSize,
        outputFormat: dataModelJSON.outputFormat,
        numberFormat: dataModelJSON.numberFormat,
      });
    }
  };
}
