import BackendAPI from './BackendMlAPI';
import { ColumnConfig, ExecuteOptions } from '../../types/ml';
import { Value } from '@nuvo-importer/common/sdk';

class BackendMl {
  private backendMlAPI: BackendAPI;

  constructor(backendMlAPI: BackendAPI) {
    this.backendMlAPI = backendMlAPI;
  }

  matchColumns = (
    headers: Value[],
    columns: ColumnConfig[],
    licenseKey: string,
    options: ExecuteOptions
  ) => {
    return this.backendMlAPI.matchColumns(
      headers,
      columns,
      licenseKey,
      options
    );
  };

  matchOptions = (
    fileId: string,
    mappingHeaderColumns: {
      column_index: number;
      column: ColumnConfig;
    }[],
    licenseKey: string,
    options: ExecuteOptions
  ) => {
    return this.backendMlAPI.matchOptions(
      fileId,
      mappingHeaderColumns,
      licenseKey,
      options
    );
  };
}

export default BackendMl;
