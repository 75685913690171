/* istanbul ignore file */

import { FC, ReactNode, useEffect, useRef } from 'react';
import { css, CSSInterpolation, cx } from 'core/emotion';
import { Card, CloseButton } from './BaseModal.view';
import DevelopMode from 'baseUI/Banner/index';
import { NUVO_TAILWIND_CLASS } from 'core/constants/style';
import { BaseModalCommon } from '@nuvo-importer/common';

type BaseModalProps = {
  children: ReactNode;
  gravity: 'top' | 'bottom' | 'middle';
  isOpen: boolean;
  className?: string;
  disabledClickOutside?: boolean;
  wrapperClassName?: string;
  onCloseModal: () => void;
  overlayStyled?: CSSInterpolation;
  bodyStyled?: CSSInterpolation;
  closeButtonClassName?: string;
  isShowBanner?: boolean;
  contentClassName?: string;
  closeIcon?: ReactNode;
  isShowCloseIcon?: boolean;
  closeIconStyled?: CSSInterpolation;
  headerClassName?: string;
  id?: string;
};

const BaseModal: FC<BaseModalProps> = ({
  children,
  gravity,
  isOpen,
  className,
  wrapperClassName,
  disabledClickOutside,
  onCloseModal,
  overlayStyled,
  closeButtonClassName,
  isShowBanner,
  contentClassName,
  closeIcon,
  isShowCloseIcon,
  bodyStyled,
  closeIconStyled,
  headerClassName,
  id,
}) => {
  const closeButtonRef = useRef(null);
  const shouldClose = useRef<boolean | null>(true);

  useEffect(() => {
    if (!isOpen) {
      document.body.classList.remove('nuvo-ReactModal__Body--open');
    }
  }, [isOpen]);

  return (
    <BaseModalCommon
      styledClass={NUVO_TAILWIND_CLASS}
      isOpen={isOpen}
      overlayStyled={overlayStyled}
      wrapperClassName={wrapperClassName}
      id={id}
    >
      <div
        className="h-screen items-end justify-center px-4 pt-4 text-center sm:p-0"
        onClick={() => {
          if (!shouldClose.current) {
            shouldClose.current = true;
            return;
          }
          if (!disabledClickOutside) {
            onCloseModal();
          }
          shouldClose.current = true;
        }}
      >
        <span
          className={cx(
            {
              'md:align-top': gravity === 'top',
              'md:align-middle': gravity === 'middle',
              'md:align-bottom': gravity === 'bottom',
            },
            'inline-block h-screen align-middle'
          )}
          aria-hidden="true"
        >
          &#8203;
        </span>
        <Card
          className={cx(className, css({ '&&': bodyStyled }))}
          onClick={() => {
            shouldClose.current = true;
          }}
          onMouseDown={() => {
            shouldClose.current = false;
          }}
        >
          <div
            className={cx(
              'flex items-center pr-3 pt-3',
              isShowBanner ? 'justify-between' : 'justify-end',
              headerClassName
            )}
          >
            {isShowBanner && (
              <div className="mt-5 pl-8">
                <DevelopMode />
              </div>
            )}
            {isShowCloseIcon ? (
              <CloseButton
                closeIcon={closeIcon}
                ref={closeButtonRef}
                onClick={() => {
                  onCloseModal();
                }}
                className={cx(
                  closeButtonClassName,
                  css({ '&& line': closeIconStyled })
                )}
              />
            ) : (
              <div ref={closeButtonRef} />
            )}
          </div>
          <div
            className={cx(
              'flex flex-col items-center justify-center',
              contentClassName,
              css({ '&&': bodyStyled })
            )}
          >
            {children}
          </div>
        </Card>
      </div>
    </BaseModalCommon>
  );
};

export default BaseModal;
