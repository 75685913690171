import { CSSInterpolation } from '@nuvo-importer/common';
import { COLOR_JOIN_SHEET } from 'core/constants/colors';
import { useMemo } from 'react';

type viewModelProps = {
  percent: number;
  configTheme?: {
    matchStatus?: {
      success?: {
        root?: CSSInterpolation;
        icon?: { color?: string };
        percent?: CSSInterpolation;
      };
      warning?: {
        root?: CSSInterpolation;
        icon?: { color?: string };
        percent?: CSSInterpolation;
      };
      error?: {
        root?: CSSInterpolation;
        icon?: { color?: string };
        percent?: CSSInterpolation;
      };
    };
  };
};
const useViewModel = ({ percent, configTheme }: viewModelProps) => {
  const styled = useMemo(() => {
    if (percent >= 40) {
      return {
        root: configTheme?.matchStatus?.success?.root,
        icon:
          configTheme?.matchStatus?.success?.icon?.color ||
          COLOR_JOIN_SHEET.SUCCESS_TEXT,
        percent: configTheme?.matchStatus?.success?.percent || {
          color: COLOR_JOIN_SHEET.SUCCESS_TEXT,
        },
        background: COLOR_JOIN_SHEET.SUCCESS_BG,
        color: COLOR_JOIN_SHEET.SUCCESS_TEXT,
      };
    } else if (percent < 40 && percent > 0) {
      return {
        root: configTheme?.matchStatus?.warning?.root,
        icon:
          configTheme?.matchStatus?.warning?.icon?.color ||
          COLOR_JOIN_SHEET.WARNING_TEXT,
        percent: configTheme?.matchStatus?.warning?.percent || {
          color: COLOR_JOIN_SHEET.WARNING_TEXT,
        },
        background: COLOR_JOIN_SHEET.WARNING_BG,
        color: COLOR_JOIN_SHEET.WARNING_TEXT,
      };
    } else {
      return {
        root: configTheme?.matchStatus?.error?.root,
        icon:
          configTheme?.matchStatus?.error?.icon?.color ||
          COLOR_JOIN_SHEET.ERROR_TEXT,
        percent: configTheme?.matchStatus?.error?.percent || {
          color: COLOR_JOIN_SHEET.ERROR_TEXT,
        },
        background: COLOR_JOIN_SHEET.ERROR_BG,
        color: COLOR_JOIN_SHEET.ERROR_TEXT,
      };
    }
  }, [configTheme, percent]);

  return {
    styled,
  };
};
export default useViewModel;
