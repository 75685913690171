import { css, cx } from '@nuvo-importer/common';
import { ActionFooter, Header } from 'navigation';
import { useTranslation } from 'react-i18next';
import { useMainView } from 'settings';
import { useTheme } from 'theme';
import Table from './components/Table';
import CollapseCard from './components/CollapseCard';
import useViewModel from './viewModel';
import { useMemo } from 'react';

const JoinColumnPage = () => {
  const {
    onBackClick,
    onContinueClick,
    sheets,
    selectedSheetColumns,
    onSelectSheetColumn,
    isCollapse,
    toggleCollapse,
    baseSheetColumn,
    setSelectedPercentage,
  } = useViewModel();

  const { t } = useTranslation();
  const theme = useTheme();
  const { modal } = useMainView();
  const wrapperClassName = modal ? 'absolute' : 'fixed';
  const widthWrapperClassName = modal ? 'w-full' : 'px-0';

  const tableTheme = useMemo(() => {
    return {
      container: theme.getJoinSheetTheme().root,
      root: theme.getJoinSheetTheme().table,
      content: theme.getJoinSheetTheme().content,
      selectColumnColor: theme.getJoinSheetTheme().selectColumnColor,
      hoverColumnColor: theme.getJoinSheetTheme().hoverColumnColor,
      scrollbarNavigatorColor:
        theme.getJoinSheetTheme().scrollbarNavigatorColor,
      scrollbarBackgroundColor:
        theme.getJoinSheetTheme().scrollbarBackgroundColor,
      th: theme.getJoinSheetTheme().th,
      td: theme.getJoinSheetTheme().td,
    };
  }, [theme]);

  const pageHeaderTheme = theme.getJoinSheetTheme().pageHeader;

  return (
    <div>
      <div className={cx('flex flex-col', modal ? 'h-full' : '')}>
        <Header
          title={t('txt_join_sheets')}
          description={t('txt_join_sheets_desc')}
          className="flex-shrink-0"
          headerTheme={pageHeaderTheme}
        />
        <div
          className={cx(
            'header-selection-page mb-4 flex flex-col space-y-6',
            modal ? 'min-h-0 flex-shrink flex-grow' : 'px-6',
            css`
              min-height: 320px;
            `
          )}
        >
          {sheets.map((sheet, index) => {
            const selectedSheetColumn = selectedSheetColumns.find(
              (selectedSheetColumn) => selectedSheetColumn.getSheet() === sheet
            );

            return (
              <CollapseCard
                configTheme={{
                  root: theme.getJoinSheetTheme().table,
                  content: theme.getJoinSheetTheme().content,
                  sheet: theme.getJoinSheetTheme().sheet,
                }}
                key={`${sheet.getFullName() + index}`}
                sheet={sheet}
                sheetColumn={selectedSheetColumn}
                baseSheetColumn={baseSheetColumn}
                toggleCollapse={() => toggleCollapse(index)}
                isCollapse={isCollapse[index]}
                sheetIndex={index}
                setSelectedPercentage={setSelectedPercentage}
              >
                <Table
                  configTheme={tableTheme}
                  sheet={sheet}
                  tableIndex={index}
                  isCollapse={isCollapse[index]}
                  onSelectSheetColumn={(sheetColumn) => {
                    onSelectSheetColumn(sheetColumn);
                  }}
                  selectedSheetColumns={selectedSheetColumns}
                />
              </CollapseCard>
            );
          })}
        </div>

        <ActionFooter
          footerStyle="px-8"
          className={cx(
            'bottom-0 left-0 right-0 z-10 flex flex-col bg-white',
            wrapperClassName,
            widthWrapperClassName,
            css`
              filter: drop-shadow(0px -13px 50px rgba(17, 48, 68, 0.08));
            `
          )}
          positiveButtonText={t('txt_continue')}
          onPositiveClick={onContinueClick}
          onNegativeClick={onBackClick}
        />
      </div>
    </div>
  );
};

export default JoinColumnPage;
