import { isNil } from 'lodash';
import { useMemo } from 'react';
import {
  useConfigure,
  useFeatureWhiteList,
} from '../configure/ConfigureProvider';
import { ProcessorType, SettingsAPI } from './settingsAPI';

export type {
  SettingsAPI,
  CompleteImportType,
  LanguageType,
  ProcessorType,
} from './settingsAPI';
export { useMainView } from './MainView';
export { useIsShowSimilarity } from './similarity';

export const useSettings = () => {
  const configure = useConfigure();
  const { featureWhiteList } = useFeatureWhiteList();

  const settings = useMemo(() => {
    const settings = { ...configure.settings };

    if (isNil(settings.completeImportAction)) {
      settings.completeImportAction = 'discard';
    }
    if (isNil(settings.modal)) {
      settings.modal = true;
    }
    if (isNil(settings.multipleFileUpload)) {
      settings.multipleFileUpload = false;
    }

    if (!featureWhiteList.getEnableMultiFileUpload()) {
      settings.multipleFileUpload = false;
    }

    if (!featureWhiteList.getEnableI18nOverrides()) {
      settings.i18nOverrides = {};
    }

    if (!featureWhiteList.getAutomaticMapping()) {
      settings.automaticMapping = false;
    }

    if (!featureWhiteList.getAdvancedParsing()) {
      settings.advancedParsing = false;
    } else {
      // TODO: we should get rid of allowNestedData when its fully deprecated
      settings.advancedParsing =
        settings.advancedParsing || settings.allowNestedData;
    }
    delete settings.allowNestedData;

    if (isNil(settings.processingEngine)) {
      settings.processingEngine = 'default';
    }

    if (!featureWhiteList.getEnableSmartTable()) {
      settings.smartTable = false;
    }

    return settings;
  }, [configure, featureWhiteList]);

  return {
    ...(settings as SettingsAPI & {
      multipleFileUpload: boolean;
    } & { processingEngine: ProcessorType }),
    dynamicImport: featureWhiteList.isDynamicImport(),
    enableReuseMapping: featureWhiteList.getEnableReuseMapping(),
  };
};
