import { css, CSSInterpolation, cx } from '@nuvo-importer/common';
import { ReactNode } from 'react';

type CardStyledProps = {
  children: ReactNode;
  configTheme?: {
    root: CSSInterpolation;
    content: CSSInterpolation;
    sheetName: CSSInterpolation;
    th: CSSInterpolation;
    td: CSSInterpolation;
    selectRowColor: CSSInterpolation;
    hoverRowColor: CSSInterpolation;
    fullScreen: CSSInterpolation;
  };
};

const Card = ({ children, configTheme }: CardStyledProps) => {
  return (
    <div
      className={cx(
        'border-1 rounded-medium border-gray-120 relative items-start p-4',
        css({ '&&': configTheme?.root })
      )}
    >
      {children}
    </div>
  );
};
export default Card;
