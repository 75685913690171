import { css, cx } from 'core/emotion';
import DevelopMode from 'baseUI/Banner';
import { CloseButton } from 'baseUI/Modal/BaseModal.view';
import { breakpoints } from 'core/constants/breakpoints';
import { NUVO_TAILWIND_CLASS } from 'core/constants/style';
import { ReactNode } from 'react';
import { isSafari } from 'react-device-detect';
import Modal from 'react-modal';
import { useTheme } from 'theme';
import useLocationPathname from 'core/location';

Modal.setAppElement(document.body);

const ModalView = ({
  children,
  isOpen,
  onCloseModal,
  isShowBanner,
  isFullScreen,
}: {
  children: ReactNode;
  isOpen: boolean;
  onCloseModal: () => void;
  isShowBanner: boolean;
  isFullScreen: boolean;
}) => {
  const theme = useTheme();
  const { pathname } = useLocationPathname();

  const largeScreenClassName = isFullScreen
    ? css`
        @media (min-width: ${breakpoints.xlarge}) {
          max-width: none;
        }
      `
    : 'max-w-screen-3xl';

  const checkOverlay = pathname === '/match-column' && isSafari;

  return (
    <Modal
      portalClassName={NUVO_TAILWIND_CLASS}
      isOpen={isOpen}
      onRequestClose={onCloseModal}
      overlayClassName={{
        base: cx(
          'modal-scroller',
          `fixed inset-0 bg-black transition-opacity ease-out duration-300 overflow-x-hidden p-10`,
          checkOverlay ? 'bg-opacity-50' : 'bg-opacity-75',
          css({ '&&': theme.getDialogTheme().overlay }),
          css`
            opacity: 0;
            z-index: 9999;
            @media (max-width: ${breakpoints.slarge}) {
              display: none !important;
            }
            will-change: transform;
          `
        ),
        afterOpen: '!opacity-100',
        beforeClose: '',
      }}
      bodyOpenClassName="overflow-hidden"
      htmlOpenClassName="nuvo-importer-modal"
      className={{
        base: cx(
          'modal-view mx-auto flex h-full min-h-0 w-full scale-95 transform flex-col overflow-hidden rounded-lg text-center shadow-xl transition-transform duration-300 ease-out',
          css(
            { backgroundColor: theme.getGlobalTheme().getBackgroundColor() },
            theme.getDialogTheme().root
          ),
          css({
            '&&': {
              borderRadius: theme.getGlobalTheme().getBorderRadius(),
            },
          }),
          largeScreenClassName
        ),
        afterOpen: css`
          transform: scale(1) !important;
        `,
        beforeClose: '',
      }}
      preventScroll
    >
      <div>
        <div className={cx('mx-auto flex items-center px-3 pt-3 lg:px-8')}>
          {isShowBanner && (
            <div className="mt-5">
              <DevelopMode />
            </div>
          )}
        </div>
        <CloseButton
          onClick={() => {
            onCloseModal();
          }}
          className={cx(
            'absolute top-8 right-8 h-3 w-3',
            css({ '&& line': theme.getDialogTheme().closeIcon })
          )}
        />
      </div>
      <div className="min-h-0 flex-shrink flex-grow justify-center">
        {children}
      </div>
    </Modal>
  );
};

export default ModalView;
