import { CompleteImportLog } from '../Types';
import { encode } from 'core/base64Utils';
import { API_URL, licenseKeyHeader } from 'core/constants/service';
import Rxios from 'core/services/Rxios';
import { Observable } from 'rxjs';

class CompleteImportLogService extends Rxios {
  constructor() {
    super({
      baseURL: API_URL,
    });
  }

  sendData = (body: CompleteImportLog, license: string): Observable<void> => {
    return this.post<void>(
      '/complete-import',
      { data: encode(JSON.stringify(body)) },
      {
        headers: {
          [licenseKeyHeader]: license,
        },
      }
    );
  };
}

export default CompleteImportLogService;
