import { css, cx } from 'core/emotion';
import { useMemo } from 'react';
import { useTheme } from 'theme';
import { StepperView } from '@nuvo-importer/common';
import useViewModel from './viewModel';

type StepperProps = {
  className?: string;
  isLoading?: boolean;
};

const Stepper = ({ className, isLoading }: StepperProps) => {
  const { navigation, getStatus } = useViewModel();
  const theme = useTheme();

  const stepperTheme = useMemo(() => {
    return {
      incomplete: theme.getProgressBarTheme().incomplete,
      current: theme.getProgressBarTheme().current,
      complete: theme.getProgressBarTheme().complete,
      icon: theme.getProgressBarTheme().icon,
    };
  }, [theme]);

  return (
    <div className={cx('w-fit mb-4 flex justify-start', className)}>
      <nav
        aria-label="Breadcrumb"
        className={cx(
          'h-full',
          css({ '&&': theme.getProgressBarTheme().root })
        )}
      >
        <ol className="flex h-full w-full min-w-0 flex-shrink items-center justify-start overflow-x-auto px-0">
          {navigation.map((step, index) => {
            return (
              <li key={step.path}>
                <div className="flex items-center">
                  <StepperView
                    isLoading={isLoading}
                    configTheme={stepperTheme}
                    label={step.label}
                    status={getStatus(index)}
                    rightIcon={step.rightIcon}
                  />
                </div>
              </li>
            );
          })}
        </ol>
      </nav>
    </div>
  );
};

export default Stepper;
