import { useEffect, useMemo, useRef } from 'react';
import { css, cx } from '../../../../../emotion';
import { convertHexColorToUrlSVGColor } from '../../../../../style';

type CheckboxProps = {
  checked: boolean;
  onChecked?: (checked: boolean) => void;
  indeterminate?: boolean;
  disabled?: boolean;
  className?: string;
  id?: string;
  tickColor?: string;
};

const Checkbox = ({
  checked,
  onChecked,
  indeterminate,
  disabled,
  className,
  id,
  tickColor = '#ffffff',
}: CheckboxProps) => {
  const ref = useRef<HTMLInputElement>(null);

  const checkboxStyle = useMemo(() => {
    return css`
      &.nuvo-checkbox {
        box-shadow: none !important;
        &[type='checkbox'] {
          background-color: white;
        }
        &:indeterminate {
          background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 16 16'%3E%3Cpath stroke='%23c5c5c5' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M4 8h8'/%3E%3C/svg%3E") !important;
          color: white;
          border-color: #c5c5c5 !important;
        }

        &[type='checkbox']:checked {
          background-color: #082561;
          background-image: url("data:image/svg+xml;charset=utf-8,<svg viewBox='0 0 16 16' xmlns='http://www.w3.org/2000/svg'><path fill='${convertHexColorToUrlSVGColor(
            tickColor
          )}' d='M12.207 4.793a1 1 0 0 1 0 1.414l-5 5a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L6.5 9.086l4.293-4.293a1 1 0 0 1 1.414 0z'/></svg>");
        }
      }
    `;
  }, [tickColor]);

  useEffect(() => {
    const inputCurrent = ref.current;
    if (inputCurrent) {
      if (indeterminate) {
        inputCurrent.indeterminate = true;
      } else {
        inputCurrent.indeterminate = false;
      }
    }
  }, [ref, indeterminate]);

  const borderStyle = useMemo(() => {
    if (checked) {
      if (disabled) {
        return 'border-gray-210';
      } else {
        return 'border-transparent';
      }
    } else {
      return 'border-gray-210';
    }
  }, [checked, disabled]);

  return (
    <input
      ref={ref}
      checked={checked}
      type="checkbox"
      onChange={(event) => {
        onChecked?.(event.target.checked);
      }}
      className={cx(
        'nuvo-checkbox focus:outline-none w-4.5 h-4.5 rounded-medium mr-3',
        checkboxStyle,
        disabled ? 'bg-gray-120 cursor-not-allowed' : 'cursor-pointer',
        borderStyle,
        className
      )}
      disabled={disabled}
      id={id}
    />
  );
};

export default Checkbox;
