import { useMemo, useState } from 'react';
import usePopper from './popper';
import { useClickAway } from 'react-use';
import { useTranslation } from 'react-i18next';

export type Option<T> = {
  label: string;
  value: T;
};

type ViewModelProps<T> = {
  options: Option<T>[];
  value: T[];
};

const useViewModel = <T>({ options, value }: ViewModelProps<T>) => {
  const popper = usePopper();
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  useClickAway(popper.popperElement, (event) => {
    if (
      popper.popperElement &&
      !popper.referenceElement.current?.contains(event.target as HTMLDivElement)
    ) {
      setIsOpen(false);
    }
  });

  const isAllSelected = useMemo(
    () => options.every((item) => value.includes(item.value)),
    [options, value]
  );

  const shownValue = useMemo(() => {
    if (isAllSelected) {
      return t('txt_all');
    } else {
      return value
        .map((item) => {
          return options.find((option) => option.value === item)?.label;
        })
        .join(', ');
    }
  }, [options, value, isAllSelected, t]);

  return {
    popper,
    isOpen,
    setIsOpen,
    value,
    shownValue,
  };
};

export default useViewModel;
