import { ColumnType } from '../../../matching/types/ml';
import { DATATYPE } from 'core/dataType';
import { DataModel } from 'dataModel';
import { ColumnTypeValue } from 'dataModel';
import DataModelSheetMatching, {
  DataModelSheetMatch,
} from '../../../matching/DataModelSheetMatching';
import DataModelSheetMatcher from '../../../matching/DataModelSheetMatcher';
import SheetColumn from '../../../sheetImporter/SheetColumn';
import {
  ConfigLog,
  CompleteImportLog,
  StylesLog,
  DataModelLog,
  MappingsLog,
  NumberFormatLog,
  numberFormatColumnTypes,
} from '../../Types';
import { ConfigureAPI } from 'configure';
import { FunctionsUsage } from 'main/WidgetProvider';
import { isArray } from 'lodash';
import Tracking from '../../../tracking/Tracking';

export class CompleteImportDTO {
  private dataModels: DataModel[];
  private matching: DataModelSheetMatch[];
  private dataModelMatcher: DataModelSheetMatcher;
  private totalRow: number;
  private headerRow: string[];
  private isDynamicImport: boolean;
  private configure: ConfigureAPI;
  private widgetFunctionsUsage?: FunctionsUsage;
  private isWidget: boolean;

  constructor(
    dataModelSheetMatching: DataModelSheetMatching,
    dataModelMatcher: DataModelSheetMatcher,
    totalRow: number,
    headerRow: string[],
    isDynamicImport: boolean,
    configure: ConfigureAPI,
    isWidget: boolean,
    widgetFunctionsUsage?: FunctionsUsage
  ) {
    this.dataModels = dataModelSheetMatching.getDataModels();
    this.matching = dataModelSheetMatching.getMatching();
    this.dataModelMatcher = dataModelMatcher;
    this.totalRow = totalRow;
    this.headerRow = headerRow;
    this.isDynamicImport = isDynamicImport;
    this.configure = configure;
    this.widgetFunctionsUsage = widgetFunctionsUsage;
    this.isWidget = isWidget;
  }

  getBodyJSON = (
    disabledColumnHeaderProcessing: boolean
  ): CompleteImportLog => {
    return {
      data_model: disabledColumnHeaderProcessing
        ? []
        : this.getMatchedDataModels(),
      mappings: disabledColumnHeaderProcessing ? [] : this.getMappings(),
      header_row: this.headerRow,
      total_row: this.totalRow,
      config: this.getConfig(),
      version: Tracking.getVersionNumber(),
      framework: Tracking.getFrameWork(),
      domain: Tracking.getOrigin(),
      framework_version: Tracking.getFrameworkVersion(),
    };
  };

  getLicenseKey = (): string => {
    return this.configure.licenseKey;
  };

  private getColumnType = (dataModel: DataModel) => {
    const type = dataModel.getType();

    switch (type) {
      case DATATYPE.BOOLEAN:
        return ColumnTypeValue.BOOLEAN;
      case DATATYPE.SINGLE_SELECT:
        return ColumnType.CATEGORY;
      case DATATYPE.INTEGER:
        return ColumnTypeValue.INT;
      case DATATYPE.FLOAT:
        return ColumnTypeValue.FLOAT;
      case DATATYPE.STRING:
        return ColumnTypeValue.STRING;
      case DATATYPE.DATE:
        return ColumnTypeValue.DATE;
      case DATATYPE.DATE_DMY:
        return ColumnTypeValue.DATE;
      case DATATYPE.DATE_MDY:
        return ColumnTypeValue.DATE;
      case DATATYPE.DATE_ISO:
        return ColumnTypeValue.DATE;
      case DATATYPE.DATETIME:
        return ColumnTypeValue.DATE;
      case DATATYPE.TIME_HMS:
        return ColumnTypeValue.DATE;
      case DATATYPE.TIME_HMS_24:
        return ColumnTypeValue.DATE;
      case DATATYPE.TIME_HM:
        return ColumnTypeValue.DATE;
      case DATATYPE.TIME_HM_24:
        return ColumnTypeValue.DATE;
      case DATATYPE.EMAIL:
        return ColumnTypeValue.EMAIL;
      case DATATYPE.URL_WWW:
        return ColumnTypeValue.URL_WWW;
      case DATATYPE.URL_HTTPS:
        return ColumnTypeValue.URL_HTTPS;
      case DATATYPE.URL:
        return ColumnTypeValue.URL;
      case DATATYPE.PHONE:
        return ColumnTypeValue.PHONE;
      case DATATYPE.ZIP_CODE_DE:
        return ColumnTypeValue.ZIP_CODE_DE;
      case DATATYPE.PERCENTAGE:
        return ColumnTypeValue.PERCENTAGE;
      case DATATYPE.COUNTRY_CODE_ALPHA_2:
        return ColumnTypeValue.COUNTRY_CODE_ALPHA_2;
      case DATATYPE.COUNTRY_CODE_ALPHA_3:
        return ColumnTypeValue.COUNTRY_CODE_ALPHA_3;
      case DATATYPE.CURRENCY_CODE:
        return ColumnTypeValue.CURRENCY_CODE;
      case DATATYPE.CURRENCY_EUR:
        return ColumnTypeValue.CURRENCY_EUR;
      case DATATYPE.CURRENCY_USD:
        return ColumnTypeValue.CURRENCY_USD;
      case DATATYPE.BIC:
        return ColumnTypeValue.BIC;
      case DATATYPE.VAT_EU:
        return ColumnTypeValue.VAT_EU;
      case DATATYPE.GTIN:
        return ColumnTypeValue.GTIN;
      case DATATYPE.IBAN:
        return ColumnTypeValue.IBAN;
      default:
        return ColumnTypeValue.STRING;
    }
  };

  private getOutputFormat = (dataModel: DataModel) => {
    const type = dataModel.getType();
    switch (type) {
      case DATATYPE.DATE:
        return dataModel.getOutputFormat();
      case DATATYPE.DATETIME:
        return 'YYYY-MM-DD HH:mm:ss';
      case DATATYPE.DATE_DMY:
        return 'DD.MM.YYYY';
      case DATATYPE.DATE_ISO:
        return 'YYYY-MM-DD';
      case DATATYPE.DATE_MDY:
        return 'MM.DD.YYYY';
      case DATATYPE.TIME_HM:
        return 'hh:mm';
      case DATATYPE.TIME_HMS:
        return 'hh:mm:ss';
      case DATATYPE.TIME_HMS_24:
        return 'HH:mm:ss';
      case DATATYPE.TIME_HM_24:
        return 'HH:mm';
      default:
        return undefined;
    }
  };

  private getMatchedDataModels = (): DataModelLog[] => {
    const items = [];
    for (let i = 0; i < this.dataModels.length; i++) {
      const dataModel = this.dataModels[i];
      const validateItems = [];

      for (let j = 0; j < dataModel?.getValidators()?.length; j++) {
        const element = dataModel?.getValidators()?.[j];
        validateItems.push(`${element?.getValidation()}`.toLowerCase());
      }

      items.push({
        columnType: this.getColumnType(dataModel),
        key: dataModel.getKey(),
        label: dataModel.getLabel(),
        isMultiselect: dataModel.getIsMultiSelection(),
        alternativeMatches: dataModel.getAlternativeMatches(),
        validations: validateItems,
        outputFormat: this.getOutputFormat(dataModel),
      } as DataModelLog);
    }
    return items;
  };

  private getMlMatchingBySheetColumn = (sheetColumn: SheetColumn) => {
    const matching = this.dataModelMatcher.getMatching();
    return matching.getMatchingBySheetColumn(sheetColumn);
  };

  public getMappings = (): MappingsLog[] => {
    return this.matching.map((match) => {
      const matchDataModel = match.matchedDataModel?.dataModel;
      return {
        column: match.sheetColumn.getColumnKey(),
        user_select_input: matchDataModel?.getLabel(),
        suggestion_result: this.getMlMatchingBySheetColumn(
          match.sheetColumn
        )?.matchedDataModel?.dataModel?.getLabel(),
      };
    });
  };

  private getStylesUsage = (): StylesLog => {
    const stylesUsage = this.configure.settings.style;
    return {
      globals: !!stylesUsage?.globals ?? false,
      root: !!stylesUsage?.root ?? false,
      buttons: !!stylesUsage?.buttons ?? false,
      progressBar: !!stylesUsage?.progressBar ?? false,
      header: !!stylesUsage?.header ?? false,
      dropzone: !!stylesUsage?.dropzone ?? false,
      footer: !!stylesUsage?.footer ?? false,
      sheetSelect: !!stylesUsage?.sheetSelect ?? false,
      headerSelect: !!stylesUsage?.headerSelect ?? false,
      joinSheet: !!stylesUsage?.joinSheet ?? false,
      columnMatch: !!stylesUsage?.columnMatch ?? false,
      reviewEntries: !!stylesUsage?.reviewEntries ?? false,
      popover: !!stylesUsage?.popover ?? false,
      dialog: !!stylesUsage?.dialog ?? false,
      messagePopup: !!stylesUsage?.messagePopup ?? false,
      popoverInfo: !!stylesUsage?.popoverInfo ?? false,
      loader: !!stylesUsage?.loader ?? false,
    };
  };

  private getConditionalRenderingUsage = (): boolean => {
    let conditionalRenderingExists = false;
    this.configure.settings.columns.forEach((column) =>
      column?.dropdownOptions?.some((item) => {
        return (conditionalRenderingExists =
          isArray(item.validations) && item.validations.length > 0);
      })
    );
    return conditionalRenderingExists;
  };

  private getLocalAllowCustomOption = (): boolean => {
    let allowCustomOptionExists = false;
    for (let i = 0; i < this.configure.settings.columns.length; i++) {
      const dataModel = this.configure.settings.columns[i];
      if (
        dataModel.columnType === ColumnTypeValue.CATEGORY &&
        dataModel.allowCustomOptions
      ) {
        allowCustomOptionExists = true;
        break;
      }
    }
    return allowCustomOptionExists;
  };

  private getNumberFormatCount = (): NumberFormatLog => {
    let eu = 0;
    let us = 0;
    this.configure.settings.columns.forEach((column) => {
      if (
        column.columnType &&
        numberFormatColumnTypes.includes(column.columnType)
      ) {
        if (column.numberFormat === 'us') {
          us += 1;
        } else {
          eu += 1;
        }
      }
    });
    return { eu, us };
  };

  private getConfig = (): ConfigLog => {
    const settings = this.configure.settings;
    return {
      allow_custom_columns: settings.allowCustomColumns ?? false,
      allow_custom_options: settings.allowCustomOptions
        ? settings.allowCustomOptions
        : this.getLocalAllowCustomOption(),
      allow_manual_input: settings.allowManualInput ?? false,
      automatic_header_detection: settings.automaticHeaderDetection ?? false,
      automatic_mapping: settings.automaticMapping ?? false,
      button_mode: settings.buttonMode ?? 'import',
      complete_import_action: settings.completeImportAction ?? 'discard',
      disable_excel_template: settings.disableExcelTemplate ?? false,
      disable_success_modal: settings.disableSuccessModal ?? false,
      disable_templates: settings.disableTemplates ?? false,
      embed_upload_area: settings.embedUploadArea ?? false,
      enable_examples: settings.enableExamples ?? false,
      enable_massive_error_alert: Number(settings.enableMassiveErrorAlert ?? 0),
      identifier: settings.identifier,
      max_entries: Number(settings.maxEntries ?? 0),
      modal: settings.modal ?? true,
      only_mapped_columns: settings.onlyMappedColumns ?? false,
      title: settings.title ?? '',
      multiple_file_upload: settings.multipleFileUpload ?? false,
      advanced_parsing: settings.allowNestedData
        ? true
        : settings.advancedParsing ?? false,
      language: settings.language ?? 'en',
      disable_template_dropdowns: settings.disableTemplateDropdowns ?? false,
      processing_engine:
        !settings.processingEngine || settings.processingEngine === 'default'
          ? 'browser'
          : settings.processingEngine,
      i18n_usage: !!settings.i18nOverrides,
      preload_data_usage: !!settings.preloadData,
      style_usage: this.getStylesUsage(),
      on_entry_init_usage: this.isWidget
        ? !!this.widgetFunctionsUsage?.cleaningFunctions?.onEntryInit
        : !!this.configure.onEntryInit,
      on_entry_change_usage: this.isWidget
        ? !!this.widgetFunctionsUsage?.cleaningFunctions?.onEntryChange
        : !!this.configure.onEntryChange,
      column_hooks_usage: this.isWidget
        ? !!this.widgetFunctionsUsage?.cleaningFunctions?.columnHooks
        : !!this.configure.columnHooks,
      data_handler_header_step_usage: this.isWidget
        ? this.widgetFunctionsUsage?.dataHandler?.headerStep ?? false
        : !!this.configure.dataHandler?.headerStep,
      data_handler_review_step_usage: this.isWidget
        ? this.widgetFunctionsUsage?.dataHandler?.reviewStep ?? false
        : !!this.configure.dataHandler?.reviewStep,
      dynamic_import_usage: this.isDynamicImport,
      conditional_rendering_usage: this.getConditionalRenderingUsage(),
      render_upload_button_usage: !!this.configure.renderUploadButton,
      number_format: this.getNumberFormatCount(),
      smart_table: settings.smartTable ?? false,
    };
  };
}
