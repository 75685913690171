import { get, isNil } from 'lodash';
import { FormValues } from '../../viewModel';
import { useFormState } from 'react-final-form';
import { useFieldArray } from 'react-final-form-arrays';
import { MatchedOption } from './../../../../matching/DataModelSheetMatching';

const useOptionMatches = ({ prefixName }: { prefixName: string }) => {
  const matchOptionSelectorName = `${prefixName}.matchedDataModel.matchedOptions`;
  const { fields } = useFieldArray(matchOptionSelectorName);
  const { values } = useFormState<FormValues>({
    subscription: {
      values: true,
    },
  });

  const matchedOptions = (get(values, matchOptionSelectorName) ??
    []) as MatchedOption[];

  const matchingLength = matchedOptions.filter(
    (matchedOption) => !isNil(matchedOption.dataModelOption)
  ).length;

  return { fields, matchingLength };
};

export default useOptionMatches;
