import { DataModel, Sheet, SheetColumn } from '@nuvo-importer/common/sdk';
import { firstValueFrom, of } from 'rxjs';
import MatchingDTO from '../dto/MatchingDTO';
import BackendMl from '../ml/backend/BackendMl';
import BaseMatchingRepository, { MLExecuteOptions } from './MatchingRepository';
import UploadInputSheetRepository from './UploadInputSheetRepository';
import { DataModelSheetMatch } from '../DataModelSheetMatching';

class BackendMatchingRepository extends BaseMatchingRepository {
  private uploadInputSheetRepository: UploadInputSheetRepository;
  private ml: BackendMl;
  private ignoreReuseMappingColumn = true;

  constructor(
    uploadInputSheetRepository: UploadInputSheetRepository,
    ml: BackendMl
  ) {
    super();
    this.uploadInputSheetRepository = uploadInputSheetRepository;
    this.ml = ml;
  }

  protected matchMlColumns = (
    matchingMapperDTO: MatchingDTO,
    options: MLExecuteOptions
  ) => {
    const columns = matchingMapperDTO.getColumns().map((column) => {
      return { ...column, dropdownOptions: [] };
    });
    const licenseKey = matchingMapperDTO.getLicenseKey();
    const headers = matchingMapperDTO.getInputHeaderRow();
    return this.ml.matchColumns(
      headers,
      columns,
      licenseKey,
      this.parseMlOptions(options)
    );
  };

  protected matchMlOptionsInitial = (
    dataModelSheetMatch: DataModelSheetMatch[],
    matchingMapperDTO: MatchingDTO,
    options: MLExecuteOptions,
    sheets: Sheet[]
  ) => {
    const licenseKey = matchingMapperDTO.getLicenseKey();
    const allSheetColumns: SheetColumn[] = [];

    sheets.forEach((sheet) =>
      sheet.getColumns().forEach((sheetColumn) => {
        allSheetColumns.push(sheetColumn);
      })
    );

    const mappingHeaderColumns = dataModelSheetMatch.map((match) => {
      const column = MatchingDTO.mapDataModelToColumn(
        match.matchedDataModel!.dataModel!
      );

      const columnIndex = allSheetColumns.findIndex((item) => {
        return item === match.sheetColumn;
      });

      return {
        column_index: columnIndex,
        column: column,
      };
    });

    return this.ml.matchOptions(
      this.uploadInputSheetRepository.getFileId(),
      mappingHeaderColumns,
      licenseKey,
      this.parseMlOptions({
        ...options,
        ignoreReuseMappingColumn: this.ignoreReuseMappingColumn,
        isNetwork3G: false,
      })
    );
  };

  protected matchMlOptions = ({
    dataModel,
    sheets,
    sheetColumn,
    licenseKey,
    options,
  }: {
    dataModel: DataModel;
    sheetColumn: SheetColumn;
    sheets: Sheet[];
    licenseKey: string;
    options: MLExecuteOptions;
  }) => {
    const column = MatchingDTO.mapDataModelToColumn(dataModel);
    const allSheetColumns: SheetColumn[] = [];

    sheets.forEach((sheet) =>
      sheet.getColumns().forEach((sheetColumn) => {
        allSheetColumns.push(sheetColumn);
      })
    );

    const columnIndex = allSheetColumns.findIndex((item) => {
      return item === sheetColumn;
    });

    return this.ml.matchOptions(
      this.uploadInputSheetRepository.getFileId(),
      [
        {
          column,
          column_index: columnIndex,
        },
      ],
      licenseKey,
      this.parseMlOptions({
        ...options,
        ignoreReuseMappingColumn: this.ignoreReuseMappingColumn,
        isNetwork3G: false,
      })
    );
  };

  prepareData = (allSheets: Sheet[], licenseKey: string) => {
    return firstValueFrom(
      this.uploadInputSheetRepository.upload(allSheets, licenseKey)
    ).then(() => {});
  };

  load = () => {
    return of();
  };

  initialize = () => {
    this.uploadInputSheetRepository.initialize();
  };

  clearData = (licenseKey: string) => {
    this.uploadInputSheetRepository.clearData(licenseKey);
  };

  complete = (licenseKey: string) => {
    this.uploadInputSheetRepository.complete(licenseKey);
  };

  setIgnoreReuseMappingColumn = (ignore: boolean) => {
    this.ignoreReuseMappingColumn = ignore;
  };
}

export default BackendMatchingRepository;
