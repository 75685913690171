import {
  MutableRefObject,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import ContextMenuController from './controller/ContextMenuController';
import { useClickAway } from 'react-use';
import usePopper from './components/popper';
import AllColumnSetting, { ColumnSetting } from '../columns/AllColumnSetting';
import { DataModel } from '../../../dataModel/model/DataModel';
import { FindDropdownOptionFn } from '../type';
import { FilterValueItems } from '../columns/FilterStrategy';
import ColumnSettingHelper from '../columns/ColumnSettingHelper';
import { HotTable } from '@handsontable/react';

type ViewModelProps = {
  contextMenuController: ContextMenuController;
  parentTableElement: MutableRefObject<HTMLElement | undefined>;
  allColumnSetting: AllColumnSetting;
  dataModels: DataModel[];
  findDropdownOption: FindDropdownOptionFn;
  hotInstance: RefObject<HotTable>;
  htCloneLeftWtHolderElement: MutableRefObject<HTMLElement | undefined>;
  readOnly?: boolean;
};

const useViewModel = ({
  contextMenuController,
  parentTableElement,
  allColumnSetting,
  dataModels,
  findDropdownOption,
  hotInstance,
  htCloneLeftWtHolderElement,
  readOnly,
}: ViewModelProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const [columnSetting, setColumnSetting] = useState<{
    state: ColumnSetting | null;
  }>({
    state: null,
  });
  const [valueItems, setValueItems] = useState<FilterValueItems>([]);
  const [currentColumnIndex, setCurrentColumnIndex] = useState<number | null>(
    null
  );

  const {
    setPopperElement,
    popperElement,
    styles: popperStyles,
    attributes: popperAttributes,
    isShow: popperIsShow,
  } = usePopper({
    contextMenuController,
    parentTableElement,
    htCloneLeftWtHolderElement,
    isOpen,
    currentColumnIndex,
    allColumnSetting,
  });

  useClickAway(
    {
      current: popperElement,
    },
    () => {
      if (isOpen) {
        contextMenuController.closeMenu();
      }
    }
  );

  const handleColumnSetting = useCallback(
    (currentOpenMenu: number | null) => {
      if (currentOpenMenu !== null) {
        setColumnSetting({
          state: allColumnSetting.getColumnSetting(currentOpenMenu),
        });
      } else {
        setColumnSetting({ state: null });
      }
    },
    [allColumnSetting]
  );

  useEffect(() => {
    const subscription = contextMenuController
      .contextMenuObservable()
      .subscribe(() => {
        const currentOpenMenu = contextMenuController.getCurrentOpenMenu();
        if (currentOpenMenu !== null) {
          setIsOpen(true);
          const columnSetting =
            allColumnSetting.getColumnSetting(currentOpenMenu);
          const physicalColumnIndex =
            hotInstance.current?.hotInstance?.toPhysicalColumn(
              currentOpenMenu
            ) ?? 0;

          const valueItems = allColumnSetting
            .getFilterStrategy()
            .getFilterValueItems()
            .getValueItems(
              physicalColumnIndex,
              dataModels[physicalColumnIndex],
              findDropdownOption,
              ColumnSettingHelper.getFilterByValueSelected(columnSetting)
                ? true
                : false,
              allColumnSetting.hasFilter(),
              !!readOnly
            );

          setValueItems(valueItems);
        } else {
          setIsOpen(false);
          setValueItems([]);
        }
        setCurrentColumnIndex(currentOpenMenu);
        handleColumnSetting(currentOpenMenu);
      });

    return () => {
      return subscription.unsubscribe();
    };
  }, [
    contextMenuController,
    allColumnSetting,
    handleColumnSetting,
    dataModels,
    findDropdownOption,
    hotInstance,
    readOnly,
  ]);

  useEffect(() => {
    const subscription = allColumnSetting
      .contextMenuObservable()
      .subscribe(() => {
        const currentOpenMenu = contextMenuController.getCurrentOpenMenu();
        handleColumnSetting(currentOpenMenu);
      });

    return () => {
      subscription.unsubscribe();
    };
  }, [allColumnSetting, contextMenuController, handleColumnSetting]);

  const physicalCurrentColumnIndex = useMemo(() => {
    if (currentColumnIndex !== null) {
      return (
        hotInstance.current?.hotInstance?.toPhysicalColumn(
          currentColumnIndex
        ) ?? null
      );
    }
    return null;
  }, [currentColumnIndex, hotInstance]);

  return {
    isOpen,
    ref,
    setPopperElement,
    popperStyles,
    popperAttributes,
    popperIsShow,
    columnSetting,
    valueItems,
    currentColumnIndex,
    physicalCurrentColumnIndex,
  };
};

export default useViewModel;
