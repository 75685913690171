import { RefObject, useEffect, useMemo, useRef } from 'react';
import { usePopper as useBasePopper } from 'react-popper';

export type Popper = {
  referenceElement: React.RefObject<HTMLDivElement>;
  styles: { [key: string]: React.CSSProperties };
  attributes: { [key: string]: { [key: string]: string } | undefined };
  popperElement: RefObject<HTMLDivElement>;
};

const usePopper = () => {
  const referenceElement = useRef<HTMLDivElement>(null);
  const popperElement = useRef<HTMLDivElement>(null);

  const modifiers = useMemo(
    () => [
      {
        name: 'offset',
        options: {
          offset: [0, 5],
        },
      },
      {
        name: 'preventOverflow',
        enabled: true,
      },
      {
        name: 'flip',
        enabled: true,
      },
    ],
    []
  );

  const { styles, attributes, update } = useBasePopper(
    referenceElement.current,
    popperElement.current,
    {
      placement: 'bottom-end',
      modifiers,
    }
  );

  useEffect(() => {
    update?.();
  }, [update]);

  return {
    referenceElement,
    styles,
    attributes,
    popperElement,
  };
};

export default usePopper;
