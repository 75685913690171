import { useConfigure } from 'configure';
import DataModelSheetMatcher from '../matching/DataModelSheetMatcher';
import DataModelSheetMatching from '../matching/DataModelSheetMatching';
import { useCallback, useMemo } from 'react';
import { firstValueFrom } from 'rxjs';
import { CompleteImportDTO } from './services/dto';
import CompleteImportLogService from './services';
import { useLocation } from 'react-router-dom';
import { useWidgetContext } from 'main/WidgetProvider';

export const CompleteImportLogs = ({
  dataModelSheetMatching,
  dataModelSheetMatcher,
  headerRow,
}: {
  dataModelSheetMatching: DataModelSheetMatching;
  dataModelSheetMatcher: DataModelSheetMatcher;
  headerRow: string[];
}) => {
  const completeImportLogService = useMemo(() => {
    return new CompleteImportLogService();
  }, []);
  const { state: locationState } = useLocation();
  const configure = useConfigure();
  const { functionsUsage, isWidget } = useWidgetContext();

  const createLog = useCallback(
    (totalRow: number, disabledColumnHeaderProcessing: boolean) => {
      const body = new CompleteImportDTO(
        dataModelSheetMatching,
        dataModelSheetMatcher,
        totalRow,
        headerRow,
        !!locationState.dynamicUploadStart,
        configure,
        isWidget,
        functionsUsage
      );
      return firstValueFrom(
        completeImportLogService.sendData(
          body.getBodyJSON(disabledColumnHeaderProcessing),
          body.getLicenseKey()
        )
      );
    },
    [
      dataModelSheetMatching,
      dataModelSheetMatcher,
      headerRow,
      locationState.dynamicUploadStart,
      configure,
      functionsUsage,
      isWidget,
      completeImportLogService,
    ]
  );

  return { createLog };
};
