import { ReactComponent as SearchIcon } from '../../../../../assets/icon/search.svg';
import { useState, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { css, cx } from '../../../../emotion';
import { useTheme } from '../../../../../theme';

const SearchInput = forwardRef<
  HTMLInputElement,
  {
    onChange: (value: string) => void;
    theme?: {
      root?: string;
      icon?: string;
      placeholder?: string;
    };
    headerTheme?: string;
    value?: string;
  }
>(({ onChange, theme, headerTheme, value }, ref) => {
  const { t } = useTranslation();
  const configureTheme = useTheme();

  const [isFocused, setIsFocused] = useState(false);
  return (
    <div
      id="search-wrapper-sheet"
      tabIndex={1}
      className={cx(
        'rounded-t-medium p-2',
        headerTheme,
        css({
          width: `calc(100% - 16px)`,
          backgroundColor: configureTheme.getGlobalTheme().getDark50Color(),
        })
      )}
    >
      <div
        className={cx(
          'flew-row h-26 rounded-medium box-border flex items-center border bg-white px-3',
          isFocused
            ? css({
                borderColor: configureTheme.getGlobalTheme().getDark600Color(),
              })
            : css({
                borderColor: configureTheme.getGlobalTheme().getDark400Color(),
              }),
          theme?.root,
          css`
            svg {
              min-width: 14px;
              max-width: 14px;
            }
          `
        )}
      >
        <SearchIcon className={theme?.icon} />
        <input
          ref={ref}
          value={value}
          className={cx(
            'search-input text-color-primary text-xssmall outline-none h-full flex-1 border-none bg-transparent pl-2 focus:ring-0',
            theme?.root,
            theme?.placeholder
          )}
          placeholder={t('txt_search')}
          onChange={(event) => {
            onChange(event.target.value);
          }}
          onKeyDown={(event) => {
            if (event.key === 'Backspace') {
              event.stopPropagation();
            }
          }}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
        />
      </div>
    </div>
  );
});

export default SearchInput;
