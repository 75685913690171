import {
  CSSProperties,
  MutableRefObject,
  RefObject,
  useMemo,
  useRef,
} from 'react';
import HotTable from '@handsontable/react';
import Button from '../../../../../../components/Button';
import { css, cx } from '../../../../../emotion';
import { ReactComponent as CloseIcon } from '../../../../../../assets/icon/close-gray.svg';
import TextInput from '../../../ContextMenu/components/TextInput';
import CheckboxDropdown from '../../../../../../components/CheckboxDropdown';
import Checkbox from '../../../ContextMenu/components/Checkbox';
import useViewModel from './viewModel';
import { DataModel } from '../../../../../dataModel/model/DataModel';
import { ISearchParams } from '..';
import { useTranslation } from 'react-i18next';
import { ReviewEntriesThemeAPI } from '../../../../../../theme/themeAPI';
import { ConfirmModalProps } from '../../../confirmModal';
import { useScreenSize } from '../../../../../constants/screensSize';

export type Popper = {
  referenceElement: React.RefObject<HTMLDivElement>;
  styles: { [key: string]: React.CSSProperties };
  attributes: { [key: string]: { [key: string]: string } | undefined };
  popperElement: RefObject<HTMLDivElement>;
};

type MenuProps = {
  popper: Popper;
  isOpenMenu: boolean;
  dataModels: DataModel[];
  onFindSearchMatch: (searchParams: ISearchParams) => void;
  onGetAllSearchMatchCount: (
    searchParams: ISearchParams
  ) => Promise<{ counter: number; hidden: number; skip: number } | undefined>;
  onReplaceWordSearchMatch: (searchParams: ISearchParams) => Promise<void>;
  setIsOpenMenu: (open: boolean) => void;
  configTheme?: ReviewEntriesThemeAPI;
  readOnly?: boolean;
  lastSelectedBySearchCell: MutableRefObject<{
    row: number;
    col: number;
  } | null>;
  showConfirmModal: (props: ConfirmModalProps) => void;
  setWaitingConfirmReplace: (waiting: boolean) => void;
  hotInstance: RefObject<HotTable>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dataSet: Record<string, any>[];
};

const Menu = ({
  popper,
  isOpenMenu,
  dataModels,
  onFindSearchMatch,
  onGetAllSearchMatchCount,
  onReplaceWordSearchMatch,
  setIsOpenMenu,
  configTheme,
  readOnly,
  lastSelectedBySearchCell,
  showConfirmModal,
  setWaitingConfirmReplace,
  hotInstance,
  dataSet,
}: MenuProps) => {
  const options = useMemo(() => {
    return dataModels.map((dataModel) => {
      return {
        label: dataModel.getLabel(),
        value: dataModel.getKey(),
      };
    });
  }, [dataModels]);

  const popperStyle = useMemo(() => {
    return css`
      z-index: 180;
      box-shadow: 0px 0px 8px -4px rgba(16, 24, 40, 0.03),
        0px 0px 24px -4px rgba(16, 24, 40, 0.08);
    `;
  }, []);

  const findButtonStyle = useMemo(() => {
    return css`
      padding-top: 5px !important;
      padding-bottom: 5px !important;
    `;
  }, []);

  const {
    matchCount,
    searchValue,
    searchDataModelKeys,
    isExactMatch,
    wordToReplace,
    setSearchDataModelKeys,
    setSearchValue,
    setIsExactMatch,
    setWordToReplace,
    onReplaceClick,
    onReplaceAllClick,
    onFindClick,
    actionDisabled,
    replaceAllButtonLoading,
    findInputRef,
    getMatchLoading,
    onCloseClick,
    hiddenCount,
    errorMessage,
    replaceDisabled,
  } = useViewModel({
    onFindSearchMatch,
    onGetAllSearchMatchCount,
    onReplaceWordSearchMatch,
    dataModels,
    isOpenMenu,
    setIsOpenMenu,
    lastSelectedBySearchCell,
    showConfirmModal,
    setWaitingConfirmReplace,
    hotInstance,
    dataSet,
  });
  const { t } = useTranslation();
  const { maxWidth3xl } = useScreenSize();
  const pendingEnter = useRef(false);

  return (
    <div
      ref={popper.popperElement}
      className={cx(
        'border-gray-120 min3xl:p-5 rounded-lg border bg-white p-3  text-left',
        popperStyle,
        css({ '&&': configTheme?.smartTable?.findAndReplaceMenu?.root })
      )}
      style={{
        width: maxWidth3xl ? 340 : 420,
        ...popper.styles['popper'],
        visibility: isOpenMenu ? 'visible' : 'hidden',
        pointerEvents: isOpenMenu ? 'auto' : 'none',
      }}
    >
      <div className="mb-3 flex items-center justify-between">
        <p
          className={cx(
            'text-blue-dark-900 text-sm',
            css({ '&&': configTheme?.smartTable?.findAndReplaceMenu?.title })
          )}
        >
          {readOnly ? t('txt_find') : t('txt_find_and_replace')}
        </p>
        <CloseIcon
          className={cx(
            'text-gray-560 h-4 w-4 cursor-pointer',
            css({
              '&&': configTheme?.smartTable?.findAndReplaceMenu?.closeIcon,
            })
          )}
          onClick={onCloseClick}
        />
      </div>
      <div>
        <p
          className={cx(
            'text-xss mb-1 leading-none text-gray-700',
            css({ '&&': configTheme?.smartTable?.findAndReplaceMenu?.label })
          )}
        >
          {t('txt_find')}
        </p>
        <div className="flex">
          <TextInput
            ref={findInputRef}
            wrapperClassName="mr-2"
            value={searchValue}
            size="small"
            onKeyDown={(event) => {
              event.stopPropagation();
            }}
            onPaste={(event) => {
              event.stopPropagation();
            }}
            onChange={(value: string) => {
              setSearchValue(value);
            }}
            onKeyDownCapture={(event) => {
              event.stopPropagation();
              if (event.key === 'Enter' && !pendingEnter.current) {
                pendingEnter.current = true;
                onFindClick();
              }
              setTimeout(() => {
                findInputRef.current?.focus();
                pendingEnter.current = false;
              }, 100);
            }}
            className={cx(
              css({
                '&&': configTheme?.smartTable?.findAndReplaceMenu?.input,
              })
            )}
          />
          <Button
            className={cx(
              'w-20',
              findButtonStyle,
              css({
                '&&': configTheme?.smartTable?.findAndReplaceMenu
                  ?.primaryButton,
              })
            )}
            disabled={actionDisabled || getMatchLoading}
            onClick={onFindClick}
            loadingStyle="!ml-0 !mr-0"
            loading={getMatchLoading}
            size="small"
          >
            {getMatchLoading ? '' : t('txt_find')}
          </Button>
        </div>
      </div>
      <div className="mt-2">
        <p
          className={cx(
            'text-xss mb-1 leading-none text-gray-700',
            css({ '&&': configTheme?.smartTable?.findAndReplaceMenu?.label })
          )}
        >
          {t('txt_find_by_column')}
        </p>
        <CheckboxDropdown<string>
          options={options}
          placeholder={t('txt_select_column_placeholder')}
          value={searchDataModelKeys}
          onChange={setSearchDataModelKeys}
          size="small"
          configTheme={configTheme?.smartTable?.findAndReplaceMenu?.dropdown}
        />
      </div>
      <div className="mt-2">
        <div className="flex items-center">
          <Checkbox
            checked={isExactMatch}
            tickColor={
              (
                configTheme?.smartTable?.findAndReplaceMenu
                  ?.checkbox as CSSProperties
              )?.['color']
            }
            className={cx(
              '!mr-2',
              css({
                '&&.nuvo-checkbox':
                  configTheme?.smartTable?.findAndReplaceMenu?.checkbox,
              })
            )}
            id="isExactMatch-checkbox"
            onChecked={(checked) => {
              setIsExactMatch(checked);
            }}
          />
          <label
            htmlFor="isExactMatch-checkbox"
            className={cx(
              'text-blue-dark-900 inline text-xs',
              css({
                '&&': configTheme?.smartTable?.findAndReplaceMenu
                  ?.checkboxLabel,
              })
            )}
          >
            {t('txt_exact_match')}
          </label>
        </div>
      </div>
      {!readOnly ? (
        <div className="mt-3">
          <p
            className={cx(
              'text-xss mb-1 leading-none text-gray-700',
              css({ '&&': configTheme?.smartTable?.findAndReplaceMenu?.label })
            )}
          >
            {t('txt_replace_with')}
          </p>
          <TextInput
            value={wordToReplace}
            onChange={setWordToReplace}
            size="small"
            className={cx(
              css({
                '&&': configTheme?.smartTable?.findAndReplaceMenu?.input,
              })
            )}
          />
          <p
            className={cx(
              'text-gray-560 text-xss mt-1 leading-3',
              css({
                '&&': configTheme?.smartTable?.findAndReplaceMenu?.infoText,
              })
            )}
          >
            {errorMessage || <>&nbsp;</>}
          </p>
        </div>
      ) : null}
      <div className="mt-3 flex items-center text-right">
        <div className="mr-auto">
          <p
            className={cx(
              'text-gray-560 text-xss flex flex-wrap',
              css({
                '&&': configTheme?.smartTable?.findAndReplaceMenu?.infoText,
              })
            )}
          >
            <span className="mr-0.5 leading-3">
              {t('txt_matches_found', { count: matchCount })}
            </span>
            {hiddenCount > 0 ? (
              <span className="leading-3">
                ({t('txt_matches_hidden', { count: hiddenCount })})
              </span>
            ) : (
              ''
            )}
          </p>
        </div>

        {readOnly ? null : (
          <div className="flex flex-shrink-0">
            <Button
              className={cx(
                'mr-2 w-16',
                css({
                  '&&': configTheme?.smartTable?.findAndReplaceMenu
                    ?.primaryButton,
                })
              )}
              disabled={actionDisabled || replaceDisabled}
              onClick={onReplaceClick}
              size="small"
            >
              {t('txt_replace')}
            </Button>
            <Button
              className={cx(
                'w-20',
                css({
                  '&&': configTheme?.smartTable?.findAndReplaceMenu
                    ?.primaryButton,
                })
              )}
              disabled={
                actionDisabled || replaceAllButtonLoading || replaceDisabled
              }
              onClick={onReplaceAllClick}
              loading={replaceAllButtonLoading}
              loadingStyle="!ml-0 !mr-0"
              size="small"
            >
              {replaceAllButtonLoading ? '' : t('txt_replace_all')}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Menu;
