import TruncatePopover from 'baseUI/TruncatePopover';
import { css, cx } from 'core/emotion';
import { useMemo, RefObject, useCallback, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import Checkbox from '../Checkbox';
import useViewModel, { SheetTableProps } from '../viewModel';
import { useTheme } from 'theme';
import chroma from 'chroma-js';
import SheetImage from './SheetImage';
import { Eye } from 'lucide-react';
import { Button } from '@nuvo-importer/common';
import { useSheetPreview } from '../SheetPreview/useSheetPreview';
import { SheetPreview } from '../SheetPreview';

const SheetTableGrid = ({
  dataSheet,
  sheet,
  onSelected,
  selected,
  configTheme,
  index,
}: SheetTableProps) => {
  const { id, sheetName, filename } = dataSheet;
  const { isSelected } = useViewModel({ selected, id });
  const { t } = useTranslation();
  const theme = useTheme();
  const sheetPreviewTheme = theme.getSheetSelectTheme().sheetPreview;

  const secondaryColor = theme.getGlobalTheme().getSecondaryColor();

  const lightSecondaryColor = useMemo(() => {
    const secondaryChroma = chroma(secondaryColor);

    return chroma
      .hsl(
        secondaryChroma.get('hsl.h') + 0.6896,
        secondaryChroma.get('hsl.s') - 0.0606,
        secondaryChroma.get('hsl.l') + 0.04314
      )
      .hex();
  }, [secondaryColor]);

  const checkboxThemeColor = useMemo(() => {
    return theme.getGlobalTheme().getCheckboxThemeColor();
  }, [theme]);

  const gradientStyled = useMemo(() => {
    const fromColor = theme.getGlobalTheme().getPrimaryColor();
    const toColor = chroma(theme.getGlobalTheme().getPrimaryColor()).alpha(0);

    return css({
      backgroundImage: `linear-gradient(to top, ${fromColor}, ${toColor})`,
    });
  }, [theme]);

  const sheetOutlineColor: string = isSelected
    ? configTheme?.checked?.illustration?.outlineColor || lightSecondaryColor
    : configTheme?.illustration?.outlineColor || lightSecondaryColor;
  const sheetColorSchema = isSelected
    ? configTheme?.checked?.illustration
    : configTheme?.illustration;

  const { showPreview, openPreview, dismissPreview } = useSheetPreview();

  const handleOpenPreview = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation();
      openPreview();
    },
    [openPreview]
  );

  return (
    <div
      key={id}
      className={cx(
        'rounded-medium border-1 hover:bg-gray-55 group relative z-10 w-full cursor-pointer overflow-hidden bg-white',
        isSelected
          ? css({
              boxShadow: `rgb(255, 255, 255) 0px 0px 0px 0px, ${theme
                .getGlobalTheme()
                .getDark50Color()} 0px 0px 0px 4px, rgba(0, 0, 0, 0) 0px 0px 0px 0px`,
            })
          : '',
        isSelected
          ? css({
              borderColor: theme.getGlobalTheme().getDark500Color(),
            })
          : 'border-gray-200',
        isSelected
          ? css({ '&&': configTheme?.checked?.root })
          : css({ '&&': configTheme?.root })
      )}
      onClick={() => {
        onSelected(id);
      }}
    >
      <Checkbox
        className={cx(
          '!rounded-medium absolute top-3 left-3 z-10',
          !isSelected
            ? css({
                '&&': {
                  borderColor: '#C5C5C5',
                },
              })
            : css({
                '&&:checked': {
                  borderColor: '#6B7280',
                },
              }),
          isSelected
            ? css({ '&&': { '&&:checked': configTheme?.checked?.checkbox } })
            : css({ '&&': { '&&': configTheme?.checkbox } }),
          isSelected
            ? css({
                '&&': {
                  '&&:checked': {
                    backgroundColor: checkboxThemeColor,
                    borderColor: checkboxThemeColor,
                  },
                },
              })
            : css``
        )}
        checked={isSelected}
        readOnly
      />
      <div
        className={cx(
          'relative',
          css({
            backgroundColor: sheetOutlineColor,
            '& svg': {
              position: 'relative',
              left: '-1px',
            },
          })
        )}
      >
        <SheetImage customStyle={sheetColorSchema} />
        <div
          className={cx(
            'absolute top-0 left-0 right-0 bottom-0 z-20 opacity-20 group-hover:visible',
            gradientStyled,
            isSelected ? 'visible' : 'invisible'
          )}
        />

        <Button
          onClick={handleOpenPreview}
          variant="secondary"
          className={cx(
            'invisible absolute bottom-1.5 right-2 z-20 !h-8 !w-8 !p-0 group-hover:visible',
            css({ '&&': sheetPreviewTheme.gridButton })
          )}
        >
          <Eye size={16} />
        </Button>
      </div>

      <div className="rounded-b-lg p-4">
        <TruncatePopover
          detectChangeText={sheetName}
          render={({ referenceElement, textRef, getHover }) => {
            return (
              <div
                className={cx('flex w-full items-center justify-between')}
                ref={referenceElement as RefObject<HTMLDivElement>}
                {...getHover()}
              >
                <span
                  ref={textRef}
                  className={cx(
                    'text-color-primary truncate text-left text-sm font-medium',
                    isSelected
                      ? css({ '&&': configTheme?.checked?.title })
                      : css({ '&&': configTheme?.title })
                  )}
                >
                  {sheetName ||
                    `${t('txt_default_sheet_prefix_name')} ${index + 1}`}
                </span>
              </div>
            );
          }}
        />

        <TruncatePopover
          render={({ referenceElement, textRef, getHover }) => {
            return (
              <div
                ref={referenceElement as RefObject<HTMLDivElement>}
                {...getHover()}
              >
                <p
                  ref={textRef as RefObject<HTMLDivElement>}
                  className={cx(
                    'text-color-secondary truncate text-left text-xs',
                    isSelected
                      ? css({ '&&': configTheme?.checked?.description })
                      : css({ '&&': configTheme?.description })
                  )}
                >
                  {filename}
                </p>
              </div>
            );
          }}
        />
      </div>

      {showPreview && (
        <SheetPreview
          sheet={sheet}
          isOpen={showPreview}
          onCloseModal={dismissPreview}
          sheetName={
            sheetName || `${t('txt_default_sheet_prefix_name')} ${index + 1}`
          }
          filename={filename}
        />
      )}
    </div>
  );
};

export default SheetTableGrid;
