/* eslint-disable @typescript-eslint/no-explicit-any */
import ColumnsAPIMapper from '../../dataModel/ColumnsAPIMapper';
import { CellChange } from 'handsontable/common';
import { TFunction } from 'i18next';
import { forEach, isArray } from 'lodash';
import { isValid as isGTINValid } from 'gtin';
import {
  ColumnAPI,
  InternalValidationTypeAPI,
  ValidatorAPI,
} from '../../dataModel/columnsAPI';
import { VALIDATION_KEY } from '../constant';
import { RecordInfo } from '../type';
import {
  getRowValue,
  isEmpty,
  someFields,
  testRegex,
} from '../utils/validator';
import { DATATYPE } from '../../dataType';
import { isDropdownOptionEqual } from '../../dataModel/utils';
import ValidateMessageUtil from './ValidateMessageUtil';
import { OptionValidator } from './optionsValidators';
import { findItemByCondition } from '../../forLoop';
import moment from 'moment';
import { DataModel } from '../../dataModel/model/DataModel';
import { NumberParser } from '../../utils/NumberParser';
import Handsontable from 'handsontable';

const validateOrders = [
  'required',
  'required_with',
  'required_without',
  'required_without_values',
  'required_with_all_values',
  'required_without_all_values',
  'required_with_all',
  'required_without_all',
  'required_with_values',
  'regex',
  'unique',
];

export type RowItem = {
  rowIndex: number;
  colIndex: number;
  valid: true;
  key: string;
  value: any;
};

export type Error = {
  validate: InternalValidationTypeAPI;
  value: any;
  rowIndex: number;
  colIndex: number;
  validateMsg?: string;
};

export type LeanError = {
  validate: InternalValidationTypeAPI;
  colIndex: number;
  validateMsg?: string;
};

type ValidatorOptions = {
  isDataPipeline: boolean;
  leanError: boolean;
};

export class Validator {
  itemList: any[][] = [];
  uniqueList: Record<string, Record<string, number[] | undefined>> = {};
  errors: (Error | null)[][] = [];
  currentRow = 0;
  currentCol = 0;
  options: ValidatorOptions;
  private booleanWords: (string | boolean)[] = ['true', 'false'];
  private optionsValidators = new OptionValidator();

  constructor(
    options: ValidatorOptions = { isDataPipeline: false, leanError: false }
  ) {
    this.options = options;

    if (this.options.isDataPipeline) {
      this.booleanWords = [...this.booleanWords, 'TRUE', 'FALSE', true, false];
    }
  }

  generateUniqueListFromDataSet = (
    dataSet: Record<string, any>[],
    columns: ColumnAPI[]
  ) => {
    this.uniqueList = {};
    for (let i = 0; i < columns.length; ++i) {
      const column = columns[i];
      const isUniqueColumn = column.validations?.some(
        (item) => item.validate === 'unique'
      );

      if (!column.isMultiSelect && isUniqueColumn) {
        const columnKey = column.key;
        this.uniqueList[columnKey] = {};
        for (let rowIndex = 0; rowIndex < dataSet.length - 1; ++rowIndex) {
          const cellValue = dataSet[rowIndex][columnKey];
          if (cellValue) {
            if (!isEmpty(this.uniqueList[columnKey][cellValue])) {
              this.uniqueList[columnKey][cellValue]!.push(rowIndex);
            } else {
              this.uniqueList[columnKey][cellValue] = [rowIndex];
            }
          }
        }
      }
    }
  };

  /**
   * It takes a 2D array of items and a list of columns and validates each cell in the 2D array against
   * the column it belongs to
   * @param {any[][]} items - any[][] - this is the data that is passed to the grid.
   * @param {ColumnAPI[]} columns - ColumnAPI[] - the columns of the grid
   */
  validateInitial(items: any[][], columns: ColumnAPI[], removeRows?: number[]) {
    this.clearError();

    columns.forEach((column, colIndex) => {
      const isUniqueColumn = column.validations?.find(
        (item) => item.validate === 'unique'
      );

      const columnKey = column.key;

      if (!column.isMultiSelect) {
        if (isUniqueColumn) {
          this.getUniqueList()[columnKey] = {};
        }

        items.forEach((row, rowIndex) => {
          if (isUniqueColumn) {
            const col = row.find((subItem) => subItem.colIndex === colIndex);
            if (col?.value) {
              if (!isEmpty(this.getUniqueList()[columnKey][col.value])) {
                this.getUniqueList()[columnKey][col.value]!.push(rowIndex);
              } else {
                this.getUniqueList()[columnKey][col.value] = [rowIndex];
              }
            }
          }
        });
      }

      items.forEach((item) => {
        const itemColumn = item.find(
          (subItem) => subItem.colIndex === colIndex
        );
        const { error } = this.validateCell(
          column,
          columns,
          {
            value: itemColumn.value,
            key: itemColumn.key,
            row: item,
          },
          removeRows
        );

        if (error) {
          this.setError(error, colIndex, itemColumn.rowIndex);
        }
      });
    });

    columns.forEach((column, columnIndex) => {
      this.removeUniqueErrorFirstRow(column, columnIndex, 0);
    });
  }

  /**
   * Removing the unique error from the first row of the column.
   * @param {ColumnAPI} column - ColumnAPI - this is the data object of cell
   * @param {number} columnIndex - number - this is a index (position) of cell
   */
  removeUniqueErrorFirstRow = (
    column: ColumnAPI,
    columnIndex: number,
    currentRowIndex: number
  ) => {
    if (
      !column.validations?.find(
        (validation) => validation.validate === 'unique'
      )
    ) {
      return 0;
    }
    const uniqueValues = this.uniqueList[column.key];
    let numOfDeleted = 0;

    forEach(uniqueValues, (rowIndexes) => {
      if (rowIndexes) {
        const minRowIndex = this.findMinFromArray(rowIndexes);
        const error = this.errors[minRowIndex]?.[columnIndex];
        if (error?.validate === 'unique') {
          this.setError(null, columnIndex, minRowIndex);
          if (currentRowIndex !== minRowIndex) {
            numOfDeleted++;
          }
        }
      }
    });

    return numOfDeleted;
  };

  /**
   * A function that is used to validate the uniqueness of a value in a column.
   * @param {string} columnKey - string - this is a column key
   * @param {number} rowIndex - number - this is a index (position) of cell
   */
  validateUniqueOnDuplicate = (
    columnKey: string,
    rowIndex: number,
    value: any
  ) => {
    const uniqueCol = this.uniqueList[columnKey];

    forEach(uniqueCol, (unique) => {
      if (!unique) {
        return;
      }

      for (let i = 0; i < (unique?.length ?? 0); i++) {
        if (unique[i] > rowIndex - 1) {
          unique[i] += 1;
        }
      }
    });

    this.uniqueList[columnKey][value]?.push(rowIndex);
  };

  /**
   * Validating the uniqueness of the data in the grid.
   * @param {CellChange} change - CellChange - this is an object of cell
   */
  validateUnique = (change: CellChange) => {
    // NOTE: rowIndex must be physical row
    const [rowIndex, baseColumnKey, oldValue, newValue] = change;
    let affectedRows: number[] = [];
    const columnKey = baseColumnKey as string;

    if (oldValue !== newValue) {
      if (newValue) {
        if (!this.uniqueList[columnKey]) {
          this.uniqueList[columnKey] = {};
        }
        const newValueInUnique = this.uniqueList[columnKey][newValue];

        if (newValueInUnique) {
          if (newValueInUnique.length === 1) {
            affectedRows = affectedRows.concat(newValueInUnique);
          }
          const minRowIndexNewValueInUnique = newValueInUnique.sort(
            (a, b) => a - b
          )[0];
          if (rowIndex < minRowIndexNewValueInUnique) {
            affectedRows.push(minRowIndexNewValueInUnique);
          }
          newValueInUnique.push(rowIndex);
        } else {
          this.uniqueList[columnKey][newValue] = [rowIndex];
        }
      }

      if (oldValue) {
        const oldValueInUnique = this.uniqueList?.[columnKey]?.[oldValue];

        if ((oldValueInUnique?.length ?? 0) > 0) {
          const index =
            oldValueInUnique?.findIndex(
              (rowItemIndex) => rowItemIndex === rowIndex
            ) ?? -1;
          if (index > -1) {
            oldValueInUnique?.splice(index, 1);

            if (oldValueInUnique?.length === 1) {
              affectedRows = affectedRows.concat(oldValueInUnique);
            }
            if (oldValueInUnique && oldValueInUnique?.length > 0) {
              const minOldValueInUnique = oldValueInUnique.sort(
                (a, b) => a - b
              )[0];
              affectedRows.push(minOldValueInUnique);
            }
          }
        }
      }

      if ((this.uniqueList[columnKey][oldValue]?.length ?? 0) === 0) {
        delete this.uniqueList[columnKey][oldValue];
      }

      if ((this.uniqueList[columnKey][newValue]?.length ?? 0) === 0) {
        delete this.uniqueList[columnKey][newValue];
      }
    }

    return { affectedRows };
  };

  /**
   * It takes a column and a row, and returns an object with a boolean value and an error object
   * @param {ColumnAPI} column - ColumnAPI - The column that is being validated.
   * @param {any} values - {
   */
  validateCell(
    column: ColumnAPI,
    columns: ColumnAPI[],
    values: any,
    removeRows?: number[]
  ) {
    let valid = true;
    let error: {
      validate: InternalValidationTypeAPI;
      value: any;
      validateMsg?: string;
    } | null = null;

    const validators = column.validations ?? [];
    const sortValidateTypes = validators.sort((a, b) => {
      return (
        validateOrders.indexOf(a.validate) - validateOrders.indexOf(b.validate)
      );
    });

    if (
      this.options.isDataPipeline &&
      removeRows &&
      removeRows.includes(values.row[0].rowIndex)
    ) {
      return { error };
    }

    switch (column.columnType) {
      case 'currency_code':
      case 'country_code_alpha_2':
      case 'country_code_alpha_3':
      case 'category': {
        if (!isEmpty(values.value)) {
          const options = ColumnsAPIMapper.createDropdownOptions(column);

          if (isArray(values.value)) {
            for (let i = 0; i < values.value.length; i++) {
              const value = values.value[i];

              const targetOption = findItemByCondition(options, (item) =>
                isDropdownOptionEqual(item, value)
              );

              const recordValues = {};

              for (let i = 0; i < values.row.length; i++) {
                const element = values.row[i];
                Object.assign(recordValues, { [element.key]: element.value });
              }

              if (!targetOption) {
                valid = false;
                error = {
                  validate: VALIDATION_KEY.NOT_IN_OPTIONS,
                  value: value,
                };
              } else if (
                targetOption?.validations?.length &&
                this.optionsValidators.hasOptionsFilter(
                  column.key,
                  options,
                  recordValues
                )
              ) {
                const validateOptions = this.optionsValidators.validateOptions(
                  recordValues,
                  targetOption?.validations
                );
                if (!validateOptions.valid) {
                  valid = validateOptions.valid;
                  error = {
                    validate: VALIDATION_KEY.INVALID_OPTIONS_CONDITION,
                    value: values.value,
                    validateMsg: validateOptions.errorMessage,
                  };
                  break;
                }
              } else {
                valid = true;
              }
            }
          } else {
            const targetOption = findItemByCondition(options, (item) =>
              isDropdownOptionEqual(item, values.value)
            );

            const recordValues = {};

            for (let i = 0; i < values.row.length; i++) {
              const element = values.row[i];
              Object.assign(recordValues, { [element.key]: element.value });
            }

            if (!targetOption) {
              valid = false;
              error = {
                validate: VALIDATION_KEY.NOT_IN_OPTIONS,
                value: values.value,
              };
            } else if (
              targetOption?.validations?.length &&
              this.optionsValidators.hasOptionsFilter(
                column.key,
                options,
                recordValues
              )
            ) {
              const validateOptions = this.optionsValidators.validateOptions(
                recordValues,
                targetOption?.validations
              );
              if (!validateOptions.valid) {
                valid = validateOptions.valid;
                error = {
                  validate: VALIDATION_KEY.INVALID_OPTIONS_CONDITION,
                  value: values.value,
                  validateMsg: validateOptions.errorMessage,
                };
              }
            } else {
              valid = true;
            }
          }
        }
        break;
      }
      case 'boolean': {
        if (!isEmpty(values.value)) {
          if (!this.booleanWords?.includes(values.value)) {
            valid = false;
            error = {
              validate: VALIDATION_KEY.NOT_IN_OPTIONS,
              value: values.value,
            };
          }
        }
        break;
      }

      case 'int': {
        if (!isEmpty(values.value)) {
          const converted = NumberParser.convertStringToNumber(values.value);

          if (converted === null) {
            valid = false;
            error = {
              validate: VALIDATION_KEY.REQUIRED_NUMBER,
              value: values.value,
            };
          }

          if (NumberParser.hasDecimalPart(values.value)) {
            valid = false;
            error = {
              validate: VALIDATION_KEY.INVALID_FORMAT_NUMBER_INT,
              value: values.value,
            };
          }
        }
        break;
      }

      case 'float': {
        if (!isEmpty(values.value)) {
          const converted = NumberParser.convertStringToNumber(values.value);

          if (converted === null) {
            valid = false;
            error = {
              validate: VALIDATION_KEY.REQUIRED_NUMBER,
              value: values.value,
            };
          }
        }
        break;
      }

      case 'date': {
        if (!isEmpty(values.value)) {
          if (
            !moment(
              values.value,
              DataModel.getOutputFormat(column.outputFormat),
              true
            ).isValid()
          ) {
            valid = false;
            error = {
              validate: VALIDATION_KEY.INVALID_FORMAT_DATE,
              value: values.value,
            };
          }
          break;
        }
        break;
      }

      case 'date_dmy': {
        if (!isEmpty(values.value)) {
          if (
            !/^(?:(?:31(\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0[1-9]|1\d|2[0-8])(\.)(?:(?:0[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{4})$/.test(
              values.value
            )
          ) {
            valid = false;
            error = {
              validate: VALIDATION_KEY.INVALID_FORMAT_DATE_DMY,
              value: values.value,
            };
          }
          break;
        }
        break;
      }

      case 'date_mdy': {
        if (!isEmpty(values.value)) {
          if (
            !/^(?:(?:(?:0[13578]|1[02])(\.)31)\1|(?:(?:0[1,3-9]|1[0-2])(\.)(?:29|30)\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:0?2(\.)29\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:(?:0[1-9])|(?:1[0-2]))(\.)(?:0[1-9]|1\d|2[0-8])\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/.test(
              values.value
            )
          ) {
            valid = false;
            error = {
              validate: VALIDATION_KEY.INVALID_FORMAT_DATE_MDY,
              value: values.value,
            };
          }
          break;
        }
        break;
      }

      case 'date_iso': {
        if (!isEmpty(values.value)) {
          if (
            !/^((((19|[2-9]\d)\d{2})-(0[13578]|1[02])-(0[1-9]|[12]\d|3[01]))|(((19|[2-9]\d)\d{2})-(0[13456789]|1[012])-(0[1-9]|[12]\d|30))|(((19|[2-9]\d)\d{2})-02-(0[1-9]|1\d|2[0-8]))|(((1[6-9]|[2-9]\d)(0[48]|[2468][048]|[13579][26])|((16|[2468][048]|[3579][26])00))-02-29))$/.test(
              values.value
            )
          ) {
            valid = false;
            error = {
              validate: VALIDATION_KEY.INVALID_FORMAT_DATE_ISO,
              value: values.value,
            };
          }
          break;
        }
        break;
      }

      case 'datetime': {
        if (!isEmpty(values.value)) {
          if (
            !/^(((((19|[2-9]\d)\d{2})-(0[13578]|1[02])-(0[1-9]|[12]\d|3[01]))|(((19|[2-9]\d)\d{2})-(0[13456789]|1[012])-(0[1-9]|[12]\d|30))|(((19|[2-9]\d)\d{2})-02-(0[1-9]|1\d|2[0-8]))|(((1[6-9]|[2-9]\d)(0[48]|[2468][048]|[13579][26])|((16|[2468][048]|[3579][26])00))-02-29)) (?:[01]\d|2[0123]):(?:[012345]\d):(?:[012345]\d))$/.test(
              values.value
            )
          ) {
            valid = false;
            error = {
              validate: VALIDATION_KEY.INVALID_FORMAT_DATETIME,
              value: values.value,
            };
          }
          break;
        }
        break;
      }

      case 'time_hms': {
        if (!isEmpty(values.value)) {
          if (
            !/^(?:1[0-2]|0?[1-9]):[0-5]\d:[0-5]\d\s(A|P|a|p)(M|m)$/.test(
              values.value
            )
          ) {
            valid = false;
            error = {
              validate: VALIDATION_KEY.INVALID_FORMAT_TIME_HMS,
              value: values.value,
            };
          }
          break;
        }
        break;
      }

      case 'time_hms_24': {
        if (!isEmpty(values.value)) {
          if (
            !/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$/.test(values.value)
          ) {
            valid = false;
            error = {
              validate: VALIDATION_KEY.INVALID_FORMAT_TIME_HMS_24,
              value: values.value,
            };
          }
          break;
        }
        break;
      }

      case 'time_hm': {
        if (!isEmpty(values.value)) {
          if (
            !/^(?:1[0-2]|0?[1-9]):[0-5]\d\s(A|P|a|p)(M|m)$/.test(values.value)
          ) {
            valid = false;
            error = {
              validate: VALIDATION_KEY.INVALID_FORMAT_TIME_HM,
              value: values.value,
            };
          }
          break;
        }
        break;
      }

      case 'time_hm_24': {
        if (!isEmpty(values.value)) {
          if (!/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/.test(values.value)) {
            valid = false;
            error = {
              validate: VALIDATION_KEY.INVALID_FORMAT_TIME_HM_24,
              value: values.value,
            };
          }
          break;
        }
        break;
      }

      case 'email': {
        if (!isEmpty(values.value)) {
          if (
            // eslint-disable-next-line no-control-regex
            !/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|'(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\[\x01-\x09\x0b\x0c\x0e-\x7f])*')@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/.test(
              values.value
            )
          ) {
            valid = false;
            error = {
              validate: VALIDATION_KEY.INVALID_FORMAT_EMAIL,
              value: values.value,
            };
          }
          break;
        }
        break;
      }

      case 'url_www': {
        if (!isEmpty(values.value)) {
          if (
            !/^(www\.)[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)$/.test(
              values.value
            )
          ) {
            valid = false;
            error = {
              validate: VALIDATION_KEY.INVALID_FORMAT_URL_WWW,
              value: values.value,
            };
          }
          break;
        }
        break;
      }

      case 'url_https': {
        if (!isEmpty(values.value)) {
          if (
            !/^https:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)$/.test(
              values.value
            )
          ) {
            valid = false;
            error = {
              validate: VALIDATION_KEY.INVALID_FORMAT_URL_HTTPS,
              value: values.value,
            };
          }
          break;
        }
        break;
      }

      case 'url': {
        if (!isEmpty(values.value)) {
          if (
            !/^(?:(?!www))[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)$/.test(
              values.value
            )
          ) {
            valid = false;
            error = {
              validate: VALIDATION_KEY.INVALID_FORMAT_URL,
              value: values.value,
            };
          }
          break;
        }
        break;
      }

      case 'phone': {
        if (!isEmpty(values.value)) {
          if (!/^(?:\+|00)\d{2}[ ]?([ ]?\d[ ]?){4,15}$/.test(values.value)) {
            valid = false;
            error = {
              validate: VALIDATION_KEY.INVALID_FORMAT_PHONE,
              value: values.value,
            };
          }
          break;
        }
        break;
      }

      case 'zip_code_de': {
        if (!isEmpty(values.value)) {
          if (!/^\d{4,5}$/.test(values.value)) {
            valid = false;
            error = {
              validate: VALIDATION_KEY.INVALID_FORMAT_ZIP_CODE_DE,
              value: values.value,
            };
          }
          break;
        }
        break;
      }

      case 'percentage': {
        if (!isEmpty(values.value)) {
          const converted = NumberParser.convertStringToNumber(values.value);
          if (converted === null) {
            valid = false;
            error = {
              validate: VALIDATION_KEY.INVALID_FORMAT_PERCENTAGE,
              value: values.value,
            };
          }
        }

        break;
      }

      case 'currency_eur': {
        if (!isEmpty(values.value)) {
          const converted = NumberParser.convertStringToNumber(values.value);
          if (converted === null) {
            valid = false;
            error = {
              validate: VALIDATION_KEY.INVALID_FORMAT_CURRENCY_EUR,
              value: values.value,
            };
          }
        }

        break;
      }

      case 'currency_usd': {
        if (!isEmpty(values.value)) {
          const converted = NumberParser.convertStringToNumber(values.value);
          if (converted === null) {
            valid = false;
            error = {
              validate: VALIDATION_KEY.INVALID_FORMAT_CURRENCY_USD,
              value: values.value,
            };
          }
        }
        break;
      }

      case 'bic': {
        if (!isEmpty(values.value)) {
          if (
            !/^[a-zA-Z]{4}[a-zA-Z]{2}[a-zA-Z0-9]{2}[a-zA-Z0-9]{0,3}$/.test(
              values.value
            )
          ) {
            valid = false;
            error = {
              validate: VALIDATION_KEY.INVALID_FORMAT_BIC,
              value: values.value,
            };
          }
          break;
        }
        break;
      }

      case 'vat_eu': {
        if (!isEmpty(values.value)) {
          if (
            !/^((AT)?U[0-9]{8}|(BE)?0[0-9]{9}|(BG)?[0-9]{9,10}|(CY)?[0-9]{8}L|↵(CZ)?[0-9]{8,10}|(DE)?[0-9]{9}|(DK)?[0-9]{8}|(EE)?[0-9]{9}|↵(EL|GR)?[0-9]{9}|(ES)?[0-9A-Z][0-9]{7}[0-9A-Z]|(FI)?[0-9]{8}|↵(FR)?[0-9A-Z]{2}[0-9]{9}|(GB)?([0-9]{9}([0-9]{3})?|[A-Z]{2}[0-9]{3})|↵(HU)?[0-9]{8}|(IE)?[0-9]S[0-9]{5}L|(IT)?[0-9]{11}|↵(LT)?([0-9]{9}|[0-9]{12})|(LU)?[0-9]{8}|(LV)?[0-9]{11}|(MT)?[0-9]{8}|↵(NL)?[0-9]{9}B[0-9]{2}|(PL)?[0-9]{10}|(PT)?[0-9]{9}|(RO)?[0-9]{2,10}|↵(SE)?[0-9]{12}|(SI)?[0-9]{8}|(SK)?[0-9]{10}|(BE)?[0-9]{10}|(SE)?[0-9]{12}|(FR)?[0-9A-Z]{2}[0-9]{9}|(NL)?[0-9]{9}B[0-9]{2}|(IE)?[0-9][A-Z][0-9]{5}[A-Z]|(IE)?[0-9]{7}[A-Z]{1,2})$/.test(
              values.value
            )
          ) {
            valid = false;
            error = {
              validate: VALIDATION_KEY.INVALID_FORMAT_VAT_EU,
              value: values.value,
            };
          }
          break;
        }
        break;
      }

      case 'gtin': {
        if (!isEmpty(values.value)) {
          try {
            const isValid = isGTINValid(values.value);
            if (!isValid) {
              throw new Error();
            }
          } catch (err) {
            valid = false;
            error = {
              validate: VALIDATION_KEY.INVALID_FORMAT_GTIN,
              value: values.value,
            };
          }
          break;
        }
        break;
      }

      case 'iban': {
        if (!isEmpty(values.value)) {
          if (
            !/^((?:(?:IT|SM)\d{2}[A-Z]\d{22}|CY\d{2}[A-Z]\d{23}|NL\d{2}[A-Z]{4}\d{10}|LV\d{2}[A-Z]{4}\d{13}|(?:BG|BH|GB|IE)\d{2}[A-Z]{4}\d{14}|GI\d{2}[A-Z]{4}\d{15}|RO\d{2}[A-Z]{4}\d{16}|KW\d{2}[A-Z]{4}\d{22}|MT\d{2}[A-Z]{4}\d{23}|NO\d{13}|(?:DK|FI|GL|FO)\d{16}|MK\d{17}|(?:AT|EE|KZ|LU|XK)\d{18}|(?:BA|HR|LI|CH|CR)\d{19}|(?:GE|DE|LT|ME|RS)\d{20}|IL\d{21}|(?:AD|CZ|ES|MD|SA)\d{22}|PT\d{23}|(?:BE|IS)\d{24}|(?:MR|MC)\d{25}|^FR\d{2}\w{5}\w{5}\w{11}\w{2}|(?:AL|DO|LB|PL)\d{26}|(?:AZ|HU)\d{27}|(?:GR|MU)\d{28})|(?:SI\d{17})|(?:SE\d{22})|(?:BE\d{14})|(?:CY\d{26})|(?:LT\d{18})|(?:SK\d{22})|(?:GR\d{25})|(?:HU\d{26}))$/.test(
              values.value
            )
          ) {
            valid = false;
            error = {
              validate: VALIDATION_KEY.INVALID_FORMAT_IBAN,
              value: values.value,
            };
          }
          break;
        }
        break;
      }

      default:
    }

    const cellValueValidation = (
      expected: any[],
      cellValue: any | any[],
      validateKey: ValidatorAPI['validate']
    ) => {
      if (isArray(cellValue)) {
        if (
          validateKey === VALIDATION_KEY.REQUIRED_WITH_ALL_VALUES ||
          validateKey === VALIDATION_KEY.REQUIRED_WITHOUT_ALL_VALUES ||
          validateKey === VALIDATION_KEY.REQUIRED_WITH_VALUES ||
          validateKey === VALIDATION_KEY.REQUIRED_WITHOUT_VALUES
        ) {
          if (cellValue.length === expected.length) {
            const filtered = [];
            for (let i = 0; i < cellValue.length; i++) {
              const value = cellValue[i];
              if (expected.includes(value)) {
                filtered.push(value);
              }
            }
            return filtered.length === expected?.length;
          } else {
            return false;
          }
        }

        return cellValue.some((value) => expected.includes(value));
      }
      return expected.includes(cellValue);
    };

    for (let i = 0; i < sortValidateTypes.length; ++i) {
      const validator = sortValidateTypes[i];
      if (!valid) {
        return { valid, error };
      }
      switch (validator.validate) {
        case VALIDATION_KEY.REQUIRED:
          valid = !isEmpty(values.value);
          if (!valid) {
            error = {
              validate: VALIDATION_KEY.REQUIRED,
              value: values.value,
              validateMsg: validator?.errorMessage,
            };
          }
          break;
        case VALIDATION_KEY.REGEX: {
          if (column.columnType === DATATYPE.STRING) {
            valid = testRegex(validator.regex ?? '', values.value);
            if (!valid) {
              error = {
                validate: VALIDATION_KEY.REGEX,
                value: values.value,
                validateMsg: validator?.errorMessage,
              };
            }
          }
          break;
        }
        case VALIDATION_KEY.REQUIRED_WITH:
          valid =
            !isEmpty(values.value) ||
            (!validator?.columns?.some(
              (v: any) => !isEmpty(getRowValue(values.row, v))
            ) ??
              false);
          if (!valid) {
            error = {
              validate: VALIDATION_KEY.REQUIRED_WITH,
              value: values.value,
              validateMsg: validator?.errorMessage,
            };
          }
          break;
        case VALIDATION_KEY.REQUIRED_WITHOUT:
          valid =
            !isEmpty(values.value) ||
            (!validator?.columns?.some((v: any) =>
              isEmpty(getRowValue(values.row, v))
            ) ??
              false);
          if (!valid) {
            error = {
              validate: VALIDATION_KEY.REQUIRED_WITHOUT,
              value: values.value,
              validateMsg: validator?.errorMessage,
            };
          }
          break;
        case VALIDATION_KEY.REQUIRED_WITHOUT_VALUES: {
          valid =
            !isEmpty(values.value) ||
            someFields(
              validator.columnValues!,
              (expected, cellValue, fieldKey) => {
                const refColumn = columns.find((item) => item.key === fieldKey);
                return Array.isArray(expected)
                  ? refColumn?.isMultiSelect
                    ? cellValueValidation(
                        expected,
                        cellValue,
                        validator.validate
                      )
                    : expected.includes(cellValue)
                  : expected === cellValue;
              },
              values.row
            );
          if (!valid) {
            error = {
              validate: VALIDATION_KEY.REQUIRED_WITHOUT_VALUES,
              value: values.value,
              validateMsg: validator?.errorMessage,
            };
          }
          break;
        }
        case VALIDATION_KEY.REQUIRED_WITH_ALL_VALUES: {
          valid =
            !isEmpty(values.value) ||
            someFields(
              validator.columnValues!,
              (expected, cellValue, fieldKey) => {
                const refColumn = columns.find((item) => item.key === fieldKey);
                return Array.isArray(expected)
                  ? refColumn?.isMultiSelect
                    ? !cellValueValidation(
                        expected,
                        cellValue,
                        validator.validate
                      )
                    : !expected.includes(cellValue)
                  : expected !== cellValue;
              },
              values.row
            );
          if (!valid) {
            error = {
              validate: VALIDATION_KEY.REQUIRED_WITH_ALL_VALUES,
              value: values.value,
              validateMsg: validator?.errorMessage,
            };
          }
          break;
        }
        case VALIDATION_KEY.REQUIRED_WITHOUT_ALL_VALUES:
          valid =
            !isEmpty(values.value) ||
            someFields(
              validator.columnValues!,
              (expected, cellValue, fieldKey) => {
                const refColumn = columns.find((item) => item.key === fieldKey);
                return Array.isArray(expected)
                  ? refColumn?.isMultiSelect
                    ? cellValueValidation(
                        expected,
                        cellValue,
                        validator.validate
                      )
                    : expected.includes(cellValue)
                  : expected === cellValue;
              },
              values.row
            );
          if (!valid) {
            error = {
              validate: VALIDATION_KEY.REQUIRED_WITHOUT_ALL_VALUES,
              value: values.value,
              validateMsg: validator?.errorMessage,
            };
          }
          break;
        case VALIDATION_KEY.UNIQUE: {
          const uniques = this.uniqueList[values.key]?.[values.value];
          if ((uniques?.length ?? 0) > 1) {
            valid = false;
          } else {
            valid = true;
          }

          if (!valid) {
            error = {
              validate: VALIDATION_KEY.UNIQUE,
              value: values.value,
              validateMsg: validator?.errorMessage,
            };
          }
          break;
        }
        case VALIDATION_KEY.REQUIRED_WITH_ALL:
          valid =
            !isEmpty(values.value) ||
            (validator?.columns?.some((v: any) =>
              isEmpty(getRowValue(values.row, v))
            ) ??
              false);
          if (!valid) {
            error = {
              validate: VALIDATION_KEY.REQUIRED_WITH_ALL,
              value: values.value,
              validateMsg: validator?.errorMessage,
            };
          }
          break;
        case VALIDATION_KEY.REQUIRED_WITHOUT_ALL:
          valid =
            !isEmpty(values.value) ||
            (validator?.columns?.some(
              (v: any) => !isEmpty(getRowValue(values.row, v))
            ) ??
              false);
          if (!valid) {
            error = {
              validate: VALIDATION_KEY.REQUIRED_WITHOUT_ALL,
              value: values.value,
              validateMsg: validator?.errorMessage,
            };
          }
          break;
        case VALIDATION_KEY.REQUIRED_WITH_VALUES:
          valid =
            !isEmpty(values.value) ||
            someFields(
              validator.columnValues,
              (expected, cellValue, fieldKey) => {
                const refColumn = columns.find((item) => item.key === fieldKey);
                return Array.isArray(expected)
                  ? refColumn?.isMultiSelect
                    ? !cellValueValidation(
                        expected,
                        cellValue,
                        validator.validate
                      )
                    : !expected.includes(cellValue)
                  : expected !== cellValue;
              },
              values.row
            );

          if (!valid) {
            error = {
              validate: VALIDATION_KEY.REQUIRED_WITH_VALUES,
              value: values.value,
              validateMsg: validator?.errorMessage,
            };
          }
          break;
        default:
          break;
      }
    }

    return { error };
  }

  /**
   * It sets the error for a given cell
   * @param {{ validate: InternalValidationTypeAPI; value: any } | null} error - { validate:
   * InternalValidationTypeAPI; value: any } | null
   * @param {number} colIndex - The column index of the cell that has the error.
   * @param {number} rowIndex - The row index of the cell that has the error.
   */
  setError(
    error: {
      validate: InternalValidationTypeAPI;
      value: any;
      validateMsg?: string;
    } | null,
    colIndex: number,
    rowIndex: number
  ) {
    if (!this.errors[rowIndex]) {
      this.errors[rowIndex] = [];
    }

    if (this.options.leanError) {
      this.errors[rowIndex][colIndex] = error
        ? ({
            validate: error?.validate,
            validateMsg: error?.validateMsg,
            colIndex,
          } as any)
        : null;
    } else {
      this.errors[rowIndex][colIndex] = error
        ? {
            validate: error?.validate,
            value: error?.value,
            rowIndex,
            colIndex,
            validateMsg: error?.validateMsg,
          }
        : null;
    }
  }

  /**
   * > Find the first row that has an error in the given range
   * @param {number} start - The starting row index
   * @param {number} end - number - the last row to check
   * @param dataInfos - Record<string, RecordInfo[]>
   * @returns The index of the first row that has an error.
   */
  getTargetRowError(
    start: number,
    end: number,
    dataInfos: Record<string, RecordInfo[]>,
    hotInstance: Handsontable
  ) {
    const pluginHideColumns = hotInstance.getPlugin('hiddenColumns');
    for (let i = start; i < end; ++i) {
      const physicalRow = hotInstance.toPhysicalRow(i);
      const hasErrorCol = (this.errors[physicalRow] ?? []).filter(
        (item) =>
          !!item &&
          !pluginHideColumns.isHidden(hotInstance.toVisualColumn(item.colIndex))
      );
      const hasErrorInfo = dataInfos[physicalRow]?.filter((item) => {
        return (
          item.popover.level === 'error' &&
          item.colIndex > -1 &&
          !pluginHideColumns.isHidden(hotInstance.toVisualColumn(item.colIndex))
        );
      });
      const hasError =
        hasErrorCol.length > 0 || (hasErrorInfo?.length ?? 0) > 0;
      if (hasError) {
        return i;
      }
    }

    return undefined;
  }

  checkErrorRowWithHiddenColByIndex(
    selectRow: number,
    dataInfos: Record<string, RecordInfo[]>,
    hotInstance: Handsontable
  ) {
    const pluginHideColumns = hotInstance.getPlugin('hiddenColumns');
    const selectedPhysicalRow = hotInstance.toPhysicalRow(selectRow);

    const hasErrorCol = (this.errors[selectedPhysicalRow] ?? []).filter(
      (item) =>
        !!item &&
        !pluginHideColumns.isHidden(hotInstance.toVisualColumn(item.colIndex))
    );

    const hasErrorInfo = dataInfos[selectedPhysicalRow]?.filter((item) => {
      return (
        item.popover.level === 'error' &&
        item.colIndex > -1 &&
        !pluginHideColumns.isHidden(hotInstance.toVisualColumn(item.colIndex))
      );
    });

    return { hasErrorCol, hasErrorInfo };
  }

  /**
   * It returns the index of the first row that has an error
   * @param {number} selectRow - The current row number
   * @param dataInfos - The data information of the current row, including the data of the current row,
   * the data of the current row, and the data of the current row.
   * @returns The row index of the first error in the table.
   */
  getErrorRowIndex(
    selectRow: number,
    dataInfos: Record<string, RecordInfo[]>,
    hotInstance: Handsontable
  ) {
    const { hasErrorCol, hasErrorInfo } =
      this.checkErrorRowWithHiddenColByIndex(selectRow, dataInfos, hotInstance);
    const hasError = hasErrorCol?.length > 0 || hasErrorInfo?.length > 0;

    if (hasError) {
      return selectRow;
    } else {
      const errorLength = this.errors.length;
      const errorInfoLength = this.findLastErrorRowByDataInfo(
        dataInfos,
        errorLength,
        hotInstance
      );
      const length =
        errorLength > errorInfoLength ? errorLength : errorInfoLength;
      const row = this.getTargetRowError(
        selectRow,
        length,
        dataInfos,
        hotInstance
      );
      if (row) return row;
    }
    const row = this.getTargetRowError(0, selectRow, dataInfos, hotInstance);
    return row ? row : 0;
  }

  /**
   * It returns the index of the next column with an error in the selected row
   * @param {number} selectRow - The row index of the currently selected row.
   * @param {number} selectedCol - The column index of the currently selected cell
   * @param dataInfos - Record<string, RecordInfo[]>
   * @param [isNewRow=false] - Whether the current row is a new row
   * @returns The index of the first error in the row.
   */
  getErrorColIndex(
    selectRow: number,
    selectedCol: number,
    dataInfos: Record<string, RecordInfo[]>,
    isNewRow = false,
    hotInstance: Handsontable
  ) {
    const selectedPhysicalRow = hotInstance.toPhysicalRow(selectRow);
    const errorByColumn: (Error | null)[] = [];
    const errorInfo: (true | undefined)[] = [];
    const pluginHideColumns = hotInstance.getPlugin('hiddenColumns');

    dataInfos[selectedPhysicalRow]
      ?.filter((item) => item.popover.level === 'error')
      ?.forEach(
        (info) => (errorInfo[hotInstance.toVisualColumn(info.colIndex)] = true)
      );

    this.errors[selectedPhysicalRow]?.forEach((item) => {
      if (item) {
        errorByColumn[hotInstance.toVisualColumn(item.colIndex)] = item;
      }
    });

    const errorLength = errorByColumn.length ?? 0;
    const errorInfoLength = errorInfo?.length ?? 0;
    const length =
      errorLength > errorInfoLength ? errorLength : errorInfoLength;

    let startColIndex = selectedCol + 1;
    if (isNewRow) startColIndex = 0;

    for (let i = startColIndex; i < length; ++i) {
      if (!isEmpty(errorByColumn?.[i]) || errorInfo?.[i]) {
        if (!pluginHideColumns.isHidden(i)) {
          return i;
        }
      }
    }

    return selectedCol;
  }

  /**
   * Checking if a row is valid.
   * @param {number} columnKey - number - this is a column key
   * @param {Record<string, RecordInfo[]>} dataInfos - Record<string, RecordInfo[]> - this is a object data information (popover) of cell
   */
  isRowValid(row: number, dataInfos: Record<string, RecordInfo[]>) {
    const hasErrorCol = (this.errors[row] ?? [])?.filter((item) => !!item);
    const hasErrorInfo = dataInfos[row]?.filter(
      (item) => item.popover.level === 'error' && item.colIndex > -1
    );
    const hasError =
      (hasErrorCol?.length ?? 0) > 0 || (hasErrorInfo?.length ?? 0) > 0;

    return !hasError;
  }

  /**
   * It returns an array of row indices that are valid
   * @param {number} length - the number of rows in the data
   * @param dataInfos - This is the object that contains the data for each column.
   * @returns An array of row numbers that are valid.
   */
  getCorrectRows(
    length: number,
    dataInfos: Record<string, RecordInfo[]>,
    hotInstance: Handsontable
  ) {
    const rows = [];
    for (let i = 0; i < length; ++i) {
      const rowIndex = hotInstance.toVisualRow(i);
      if (rowIndex !== null && this.isRowValid(i, dataInfos)) {
        rows.push(rowIndex);
      }
    }
    return rows;
  }

  getError() {
    return this.errors;
  }

  clearError() {
    this.errors = [];
  }

  setItems(items: any[][]) {
    this.itemList = items;
  }

  getItems(): any[][] {
    return this.itemList;
  }

  getUniqueList(): Record<string, Record<string, number[] | undefined>> {
    return this.uniqueList;
  }

  /**
   * > Find the last row with an error in the dataInfos object
   * @param dataInfos - Record<string, RecordInfo[]>
   * @param {number} lastRowErr - The last row that has an error.
   * @returns The last row that has an error in it.
   */
  findLastErrorRowByDataInfo(
    dataInfos: Record<string, RecordInfo[]>,
    lastRowErr: number,
    hotInstance: Handsontable
  ): number {
    let lastErrorRow = lastRowErr;
    const pluginHideColumns = hotInstance.getPlugin('hiddenColumns');
    for (const visualRow of Object.keys(dataInfos)) {
      const selectedPhysicalRow = hotInstance.toPhysicalRow(Number(visualRow));
      if (
        !!dataInfos[selectedPhysicalRow] &&
        !!dataInfos[selectedPhysicalRow].find(
          (item) =>
            item.popover.level === 'error' &&
            !pluginHideColumns.isHidden(
              hotInstance.toVisualColumn(item.colIndex)
            )
        )
      ) {
        if (Number(visualRow) + 1 > lastErrorRow) {
          lastErrorRow = Number(visualRow) + 1;
        }
      }
    }
    return lastErrorRow;
  }

  findLastColIndexWithoutHidden = (
    arr: RecordInfo[] | (Error | null)[],
    hotInstance: Handsontable
  ) => {
    const pluginHideColumns = hotInstance.getPlugin('hiddenColumns');
    const sortedByVisual = arr
      ? [...arr].sort((a, b) => {
          const visualColA = hotInstance.toVisualColumn(a?.colIndex ?? 0);
          const visualColB = hotInstance.toVisualColumn(b?.colIndex ?? 0);
          return visualColA - visualColB;
        })
      : [];
    const lastIndexTemp = sortedByVisual[sortedByVisual.length - 1]
      ? hotInstance.toVisualColumn(
          sortedByVisual[sortedByVisual.length - 1]?.colIndex ?? 0
        )
      : 0;
    let lastIndex = lastIndexTemp;

    if (pluginHideColumns.isHidden(lastIndexTemp)) {
      for (let i = sortedByVisual.length - 1; i >= 0; i--) {
        const colIndex = sortedByVisual[i]?.colIndex ?? 0;
        const visualCol = hotInstance.toVisualColumn(colIndex);
        if (!pluginHideColumns.isHidden(visualCol)) {
          lastIndex = visualCol;
          break;
        } else {
          lastIndex = 0;
        }
      }
    } else {
      lastIndex = lastIndexTemp;
    }

    return lastIndex;
  };

  /**
   * It takes a row and column index, and returns the next row and column index of the next error cell
   * @param {number | undefined} row - number | undefined,
   * @param {number | undefined} col - number | undefined
   * @param {boolean} isSelectedCell - boolean - Whether the cell is selected or not
   * @param dataInfos - Record<string, RecordInfo[]>
   * @returns {
   *     nextRow: this.currentRow,
   *     nextCol: this.currentCol,
   *   }
   */
  getNextCursorError(
    row: number | undefined,
    col: number | undefined,
    isSelectedCell: boolean,
    dataInfos: Record<string, RecordInfo[]>,
    hotInstance: Handsontable
  ): {
    nextRow: number;
    nextCol: number;
  } {
    if (isSelectedCell) {
      const selectedRow = row!;
      const selectedCol = col!;
      const selectedPhysicalRow = hotInstance.toPhysicalRow(selectedRow);

      this.currentRow = this.getErrorRowIndex(
        selectedRow,
        dataInfos,
        hotInstance
      );
      const isNewRow = this.currentRow !== selectedRow;

      this.currentCol = this.getErrorColIndex(
        this.currentRow,
        selectedCol,
        dataInfos,
        isNewRow,
        hotInstance
      );
      const lastErrorCell =
        (this.getError()[selectedPhysicalRow] ?? [])?.filter((col) => !!col) ??
        0;

      const lastErrorColInfo =
        dataInfos[selectedPhysicalRow]
          ?.filter((col) => col.popover.level === 'error')
          ?.sort((a, b) => a.colIndex - b.colIndex) ?? 0;

      const lastErrorObjColIndex = this.findLastColIndexWithoutHidden(
        lastErrorCell,
        hotInstance
      );
      const lastErrorInfoColIndex = this.findLastColIndexWithoutHidden(
        lastErrorColInfo,
        hotInstance
      );

      const lastErrorColIndex =
        lastErrorObjColIndex > lastErrorInfoColIndex
          ? lastErrorObjColIndex
          : lastErrorInfoColIndex;

      if (selectedCol >= lastErrorColIndex && !isNewRow) {
        const tempIndex = this.currentRow + 1;
        let lastErrorRow = 0;

        for (let i = 0; i < this.getError().length; i++) {
          const physicalRow = hotInstance.toPhysicalRow(i);
          if (
            (this.getError()[physicalRow] ?? []).filter((item) => !!item)
              .length > 0
          ) {
            lastErrorRow = i + 1;
          }
        }

        lastErrorRow = this.findLastErrorRowByDataInfo(
          dataInfos,
          lastErrorRow,
          hotInstance
        );

        this.currentRow = this.getErrorRowIndex(
          lastErrorRow === selectedRow
            ? 0
            : tempIndex < lastErrorRow
            ? tempIndex
            : 0,
          dataInfos,
          hotInstance
        );
        this.currentCol = this.getErrorColIndex(
          this.currentRow,
          selectedCol,
          dataInfos,
          true,
          hotInstance
        );
      }

      const { hasErrorCol, hasErrorInfo } =
        this.checkErrorRowWithHiddenColByIndex(
          this.currentRow,
          dataInfos,
          hotInstance
        );

      if (hasErrorCol.length === 0 && hasErrorInfo.length === 0) {
        this.currentRow = selectedRow;
      }

      return {
        nextRow: this.currentRow,
        nextCol: this.currentCol,
      };
    } else {
      const nextRow = this.getErrorRowIndex(0, dataInfos, hotInstance);
      return {
        nextRow: nextRow,
        nextCol: this.getErrorColIndex(
          nextRow,
          0,
          dataInfos,
          true,
          hotInstance
        ),
      };
    }
  }

  /**
   * A function that returns a validation message cell.
   * @param {TFunction} translate - TFunction - this is a function for i18n
   * @param {Error} error - Error - this is a error oject for all cell
   * @param {ColumnAPI[]} columns - ColumnAPI[] - this is a column object
   * @returns A message of validation cell
   */
  getValidateMessage = (
    translate: TFunction,
    error: Error,
    columns: ColumnAPI[],
    baseColumns: ColumnAPI[]
  ) => {
    return ValidateMessageUtil.getValidateMessage(
      translate,
      error,
      columns,
      baseColumns
    );
  };

  /**
   * A function that returns number of validation errors and dataInfos errors
   * @param dataInfos
   * @param rowIndex
   * @returns
   */
  getErrorCountByRow = (
    dataInfos: Record<string, RecordInfo[]>,
    rowIndex: number
  ) => {
    const dataInfoError =
      dataInfos[rowIndex]
        ?.filter((item) => {
          return item.popover.level === 'error' && item.colIndex > -1;
        })
        .map((item) => item.colIndex) ?? [];

    const errors =
      this.errors[rowIndex]
        ?.filter((item) => !!item)
        .map((item) => item?.colIndex) ?? [];

    const allErrors = [...dataInfoError, ...errors].sort(
      (a, b) => (a ?? 0) - (b ?? 0)
    );

    let counter = 0;
    let tmp: number | undefined;
    for (let i = 0; i < allErrors.length; ++i) {
      if (
        typeof allErrors[i] !== undefined &&
        allErrors[i] !== tmp &&
        allErrors[i] !== -1
      ) {
        ++counter;
        tmp = allErrors[i];
      }
    }

    return counter;
  };

  /**
   * It clears the cache
   */
  clearCache() {
    this.itemList = [];
    this.uniqueList = {};
    this.errors = [];
    this.currentRow = 0;
    this.currentCol = 0;
  }

  setErrors = (errors: (Error | null)[][]) => {
    this.errors = errors;
  };

  private findMinFromArray = (arr: number[]) => {
    let min = arr[0];

    for (let i = 1; i < arr.length; i++) {
      if (arr[i] < min) {
        min = arr[i];
      }
    }

    return min;
  };
}
