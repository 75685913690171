import { css, cx } from 'core/emotion';
import { ReactComponent as ArrowUpIcon } from '../../../../assets/icon/arrow-up.svg';

const ToggleButton = ({
  onClick,
  disabled,
  isCollapse,
}: {
  onClick: () => void;
  disabled: boolean;
  isCollapse: boolean;
}) => {
  return (
    <button
      type="button"
      className={cx(
        '-mt-px -ml-px',
        css({
          '&&': {
            color: '#383838',
          },
        })
      )}
      onClick={onClick}
      disabled={disabled}
    >
      <ArrowUpIcon className={isCollapse ? '' : 'rotate-180 transform'} />
    </button>
  );
};

export default ToggleButton;
