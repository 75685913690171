import { useTranslation } from 'react-i18next';
import HotTable from '@handsontable/react';
import { MutableRefObject, RefObject, useMemo } from 'react';
import useViewModel from './viewModel';
import { EVENT_LOG } from '../../../constants/eventLog';
import InfoBox from '../../../../components/InfoBox';
import { css, CSSObject, cx } from '../../../emotion';
import { useTheme } from '../../../../theme/theme';
import Button from '../../../../components/Button';
import FullScreenButton from '../../../../components/FullScreenButton';
import Message from '../../../../components/Popover/Message';
import Popover from '../../../../components/Popover';
import { ReactComponent as DownloadIcon } from '../../../../assets/icon/download.svg';
import ToggleViewButton from './ToggleViewButton';
import SelectActions from './SelectActions';
import CheckboxController from '../checkbox/CheckboxController';
import { ConfirmModalProps } from '../confirmModal';
import { DataModel } from '../../../dataModel/model/DataModel';
import SearchAndReplace, { ISearchParams } from './SearchAndReplace';
import ContextMenuController from '../ContextMenu/controller/ContextMenuController';
import { ReactComponent as ClearFilterIcon } from '../../../../assets/icon/filter-icon.svg';
import AllColumnSetting from '../columns/AllColumnSetting';
import ModeViewTable from '../ModeViewTable/ModeViewTable';

type TopActionProps = {
  changeModeView: (showOnlyError: boolean) => void;
  hideShowErrorDropdown: boolean;
  exportValuesToXlsx: (ignoreContentDropdown: boolean) => void;
  validating: boolean;
  errorCount: MutableRefObject<number>;
  onSearchError: () => void;
  hasValidated: boolean;
  exporting: boolean;
  disableExportHasMoreDataRef: MutableRefObject<boolean>;
  toggleFullScreen: () => void;
  isFullScreen?: boolean;
  sendLog: (event: string) => Promise<void>;
  isBypass: boolean;
  setBypass: (value: boolean) => void;
  hidedFullScreenButton?: boolean;
  hidedExportButton?: boolean;
  modal?: boolean;
  disableTemplateDropdowns: boolean;
  checkboxController?: CheckboxController;
  onRemove: (rows: number[]) => void;
  onDuplicate: (rows: number[]) => void;
  showConfirmModal: (props: ConfirmModalProps) => void;
  smartTable: boolean;
  onFindSearchMatch: (
    searchParams: ISearchParams,
    switchFocus?: boolean
  ) => void;
  onGetAllSearchMatchCount: (
    searchParams: ISearchParams
  ) => Promise<{ counter: number; hidden: number; skip: number } | undefined>;
  onReplaceWordSearchMatch: (searchParams: ISearchParams) => Promise<void>;
  dataModels: DataModel[];
  contextMenuController: ContextMenuController;
  onClearAllFilter: () => void;
  allColumnSetting: AllColumnSetting;
  readOnly?: boolean;
  lastSelectedBySearchCell: MutableRefObject<{
    row: number;
    col: number;
  } | null>;
  modeViewTable: ModeViewTable;
  hotInstance: RefObject<HotTable>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dataSet: Record<string, any>[];
};

const buttonStyle = css`
  :disabled {
    background-color: #dfe2e4;
    color: #88959c;
  }
`;

const TopAction = ({
  changeModeView,
  exportValuesToXlsx,
  validating,
  errorCount,
  onSearchError,
  hasValidated,
  exporting = false,
  disableExportHasMoreDataRef,
  toggleFullScreen,
  isFullScreen = false,
  sendLog,
  isBypass,
  setBypass,
  hidedFullScreenButton = false,
  hidedExportButton = false,
  modal,
  disableTemplateDropdowns,
  checkboxController,
  onRemove,
  onDuplicate,
  showConfirmModal,
  onFindSearchMatch,
  onGetAllSearchMatchCount,
  onReplaceWordSearchMatch,
  smartTable,
  dataModels,
  contextMenuController,
  onClearAllFilter,
  allColumnSetting,
  readOnly,
  lastSelectedBySearchCell,
  modeViewTable,
  hotInstance,
  dataSet,
}: TopActionProps) => {
  const {
    isExportDisabled,
    isErrorCount,
    errorCountNumber,
    isShowOnlyError,
    disableExportHasMoreData,
    isExportFilePopoverShow,
    getOnExportFileHoverProps,
    onToggleViewChange,
    selectedCount,
    onDeleteClick,
    onDuplicateClick,
    isLoadingDuplicate,
    filterCount,
  } = useViewModel({
    errorCount,
    validating,
    disableExportHasMoreDataRef,
    changeModeView,
    checkboxController,
    onRemove,
    onDuplicate,
    showConfirmModal,
    allColumnSetting,
    modeViewTable,
  });
  const { t } = useTranslation();
  const configTheme = useTheme();
  const disabledClassName = useMemo(
    () =>
      cx(
        `cursor-not-allowed bg-opacity-75`,
        css`
          background-color: ${(
            configTheme.getButtonTheme().secondary as CSSObject
          )?.backgroundColor ?? '#DFE2E4'} !important;
          color: #88959c;
          border-color: #dfe2e4;
        `
      ),
    [configTheme]
  );

  const reviewTheme = useMemo(
    () => configTheme.getReviewEntriesTheme(),
    [configTheme]
  );

  return (
    <div>
      {isBypass ? (
        <InfoBox
          customStyle={cx(
            'pb-4',
            css`
              max-width: 60%;
              min-width: 650px;
            `
          )}
          title={t('txt_title_info_box_automatic_mapping')}
          description={t('txt_description_info_box_automatic_mapping')}
          onClickClose={() => {
            setBypass(false);
            window.dispatchEvent(new Event('resize'));
          }}
        />
      ) : null}
      <div className="mb-4 flex items-end justify-between">
        <div className="flex items-center space-x-3">
          <Button
            type="button"
            disabled={isErrorCount}
            className={buttonStyle}
            onClick={onSearchError}
          >
            {t('txt_find_error')}
          </Button>
          {hasValidated ? (
            selectedCount > 0 ? (
              <SelectActions
                selectedCount={selectedCount}
                onDeleteClick={onDeleteClick}
                onDuplicateClick={onDuplicateClick}
                isLoadingDuplicate={isLoadingDuplicate}
                customTheme={reviewTheme}
              />
            ) : (
              <div className="flex">
                <ToggleViewButton
                  errorCountNumber={errorCountNumber}
                  isShowOnlyError={isShowOnlyError}
                  onToggleViewChange={onToggleViewChange}
                />
                {filterCount > 0 ? (
                  <Button
                    className={cx(
                      'ml-3 !border-0 !px-4',
                      css({ '&&': reviewTheme?.clearFilterButton })
                    )}
                    variant="secondary"
                    onClick={onClearAllFilter}
                  >
                    <span className="flex items-center">
                      <ClearFilterIcon
                        className={cx(
                          (reviewTheme?.clearFilterButton as CSSObject)?.color
                            ? ''
                            : 'text-neutral-gray'
                        )}
                      />{' '}
                      <span className="ml-2 inline-block">
                        {t('txt_filter_clear', { count: filterCount })}
                      </span>
                    </span>
                  </Button>
                ) : null}
              </div>
            )
          ) : null}
        </div>
        <div className="flex items-center">
          {!hidedFullScreenButton && (
            <FullScreenButton
              className="relative top-px mr-4"
              theme={reviewTheme.fullScreen}
              toggleFullScreen={toggleFullScreen}
              isFullScreen={isFullScreen}
              modal={modal}
            />
          )}
          {smartTable ? (
            <SearchAndReplace
              className="mr-4"
              dataModels={dataModels}
              onFindSearchMatch={onFindSearchMatch}
              onGetAllSearchMatchCount={onGetAllSearchMatchCount}
              onReplaceWordSearchMatch={onReplaceWordSearchMatch}
              contextMenuController={contextMenuController}
              configTheme={reviewTheme}
              readOnly={readOnly}
              lastSelectedBySearchCell={lastSelectedBySearchCell}
              showConfirmModal={showConfirmModal}
              hotInstance={hotInstance}
              dataSet={dataSet}
            />
          ) : null}
          {!hidedExportButton && (
            <Popover
              isShow={isExportFilePopoverShow && disableExportHasMoreData}
              message={
                <Message className="max-w-355 h-full w-full" variant="info">
                  {t('txt_not_export_file')}
                </Message>
              }
              flip
              direction="top"
              render={({ referenceElement }) => {
                return (
                  <span ref={referenceElement}>
                    <Button
                      {...getOnExportFileHoverProps()}
                      variant="secondary"
                      onClick={() => {
                        if (!disableExportHasMoreData) {
                          exportValuesToXlsx(disableTemplateDropdowns ?? false);
                          sendLog(EVENT_LOG.EXPORT_EXCEL_FILE)
                            .then(() => {})
                            .catch((err) => {
                              console.error('nuvo send event-log error: ', err);
                            });
                        }
                      }}
                      disabled={
                        !disableExportHasMoreData &&
                        (exporting || isExportDisabled)
                      }
                      hideDisableStyle={disableExportHasMoreData}
                      loading={exporting}
                      className={`${
                        disableExportHasMoreData && disabledClassName
                      }`}
                    >
                      <div
                        className={cx(
                          'flex items-center',
                          disableExportHasMoreData || exporting
                            ? 'text-gray-260'
                            : ''
                        )}
                      >
                        <DownloadIcon className="mr-2 mb-px" />
                        {t('txt_export_as_excel')}
                      </div>
                    </Button>
                  </span>
                );
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default TopAction;
