import { isNil, chunk } from 'lodash';
import { MutableRefObject } from 'react';
import Sheet from './../../Sheet';
import SheetColumn from './../../SheetColumn';

export const sortSheetColumnBySheet = (
  sheetColumns: SheetColumn[],
  sheets: Sheet[]
) => {
  return sheetColumns
    .map((sheetColumn) => {
      const sheetIndex = sheets.findIndex(
        (sheet) => sheet === sheetColumn?.getSheet()
      );
      return {
        sheetColumn,
        sheetIndex,
      };
    })
    .sort((sheetColA, sheetColB) => {
      return sheetColA.sheetIndex - sheetColB.sheetIndex;
    })
    .map((sheetObj) => sheetObj.sheetColumn);
};

export const calculateMatchPercentage = async (
  timer: MutableRefObject<NodeJS.Timeout | undefined>,
  baseSheet?: SheetColumn,
  matchSheet?: SheetColumn
) => {
  const baseSheetRows = baseSheet?.getRows() || [];
  const matchSheetRows = matchSheet?.getRows() || [];

  let matchAmount = 0;

  const rowIndexes = [];

  for (let i = 0; i < matchSheetRows?.length; i++) {
    rowIndexes.push(i);
  }

  const chunkRowIndexs = chunk(rowIndexes, 1000);

  const allResults: number[] = [];

  for (const chunkRowIndexItem of chunkRowIndexs) {
    await new Promise<void>((resolve) => {
      timer.current = setTimeout(() => {
        const results = chunkRowIndexItem.map((i) => {
          for (let j = 0; j < baseSheetRows.length; j++) {
            const baseSheetValue = `${baseSheetRows[j]}`;
            const baseMatchSheetRow = `${matchSheetRows[i]}`;

            if (
              baseSheetValue === baseMatchSheetRow &&
              baseSheetValue !== '' &&
              baseMatchSheetRow !== '' &&
              !isNil(baseSheetRows[j]) &&
              !isNil(matchSheetRows[i])
            ) {
              return 1;
            }
          }

          return 0;
        });

        results.forEach((result) => {
          allResults.push(result);
        });

        resolve();
      }, 1);
    });
  }

  allResults.forEach((item) => {
    matchAmount += item;
  });

  return Number(((matchAmount / matchSheetRows?.length) * 100).toFixed(2)) || 0;
};
