import SpreadSheetList from './../../../../sheetImporter/SpreadSheetList';
import { useUploadDataFormConfig } from '../uploadDataFormConfig';
import SpreadSheetNavigate from '../../../../uploadData/SelectHeaderPage/SpreadSheetNavigate';
import { useSettings } from 'settings';
import { useUploadFilePolicy } from 'sheetImporter';
import { useNavigatePolicy } from 'navigation';
import useInAppNavigate from 'core/navigate';
import { useFeatureWhiteList } from '../../../../configure/ConfigureProvider';
import { useHooks } from 'hooks';
import { useContextConfirmModalManager } from 'baseUI/Confirm/context';
import { useTranslation } from 'react-i18next';
import { UPLOAD_PAGE_PATH } from 'core/constants/route';
import { usePage } from 'main/MainView';

const useViewModel = ({
  onUploadSuccess,
  onUploadFailure,
}: {
  onUploadSuccess: () => void;
  onUploadFailure: () => void;
}) => {
  const navigate = useInAppNavigate();
  const { title, modal } = useUploadDataFormConfig();
  const { automaticHeaderDetection } = useSettings();
  const { checkPolicy } = useUploadFilePolicy();
  const { getHasSheetSelectionPage } = useNavigatePolicy();
  const { featureWhiteList } = useFeatureWhiteList();
  const { dataHandlerMapper } = useHooks();
  const { showConfirmModal } = useContextConfirmModalManager();
  const { t } = useTranslation();
  const { cancel } = usePage();

  const onUploadFileSuccess = (spreadSheetList: SpreadSheetList) => {
    if (checkPolicy(spreadSheetList)) {
      if (!getHasSheetSelectionPage(spreadSheetList)) {
        spreadSheetList.selectAllSheets(true);
        if (featureWhiteList.getDataHandler()) {
          const spreadSheetNavigate = new SpreadSheetNavigate({
            spreadSheetList: spreadSheetList,
          });

          dataHandlerMapper.headerStep
            .getDataModifier()({
              spreadSheets: spreadSheetNavigate
                .getSpreadSheetList()
                .getSpreadSheets(),
            })
            .then((spreadSheets) => {
              let newSpreadSheetNavigate = spreadSheetNavigate;
              if (spreadSheets?.length) {
                newSpreadSheetNavigate = new SpreadSheetNavigate({
                  spreadSheetList: new SpreadSheetList({ spreadSheets }),
                });
              }

              if (automaticHeaderDetection) {
                newSpreadSheetNavigate
                  ?.getCurrentSheet()
                  ?.setHeaderByAutoDetection();
              }

              onUploadSuccess();
              navigate(
                {
                  pathname: automaticHeaderDetection
                    ? '/match-column'
                    : '/select-header',
                },
                {
                  state: {
                    spreadSheetNavigate: newSpreadSheetNavigate,
                  },
                }
              );
            })
            .catch(() => {
              showConfirmModal({
                title: t('txt_default_title_error'),
                description: `<div>
                <p className="font-bold">
               ${t('txt_matching_error_description')}
                </p>
              </div>`,
                textNegativeButton: t('txt_close'),
                isShowIcon: true,
                isShowPositiveButton: false,
                onClickNegativeButton: () => {
                  cancel();
                  navigate({ pathname: UPLOAD_PAGE_PATH });
                },
              });
            });
        } else {
          onUploadSuccess();
          navigate(
            {
              pathname: automaticHeaderDetection
                ? '/match-column'
                : '/select-header',
            },
            {
              state: {
                spreadSheetNavigate: new SpreadSheetNavigate({
                  spreadSheetList: spreadSheetList,
                }),
              },
            }
          );
        }
      } else {
        onUploadSuccess();
        navigate(
          {
            pathname: '/select-sheet',
          },
          {
            state: {
              spreadSheetList: spreadSheetList,
            },
          }
        );
      }
    } else {
      onUploadFailure();
    }
  };

  const showTitle = modal === false ? title : '';

  return { onUploadFileSuccess, showTitle };
};

export default useViewModel;
