import { css, CSSInterpolation, cx } from '@nuvo-importer/common';
import { Loader } from 'baseUI/Button';
import { TextLoading } from 'baseUI/TextLoading';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CheckMatched } from '../../../../assets/icon/matched.svg';
import useViewModel from './viewModel';

type MatchStatusProps = {
  percent: number;
  fileName: string;
  columnKey: string;
  sheetName: string;
  isCollapse: boolean;
  isProcessing: boolean;
  configTheme?: {
    matchStatus?: {
      success?: {
        root?: CSSInterpolation;
        icon?: { color?: string };
        percent?: CSSInterpolation;
      };
      warning?: {
        root?: CSSInterpolation;
        icon?: { color?: string };
        percent?: CSSInterpolation;
      };
      error?: {
        root?: CSSInterpolation;
        icon?: { color?: string };
        percent?: CSSInterpolation;
      };
      loading?: {
        root?: CSSInterpolation;
        icon?: { color?: string };
      };
    };
  };
};

const MatchStatus = ({
  percent,
  fileName,
  columnKey,
  sheetName,
  isCollapse,
  configTheme,
  isProcessing,
}: MatchStatusProps) => {
  const { t } = useTranslation();
  const { styled } = useViewModel({ percent, configTheme });

  return (
    <div>
      {isProcessing ? (
        <div
          className={cx(
            'rounded-medium text-primary flex items-center justify-start space-x-2 px-6 py-3',
            isCollapse ? 'mb-2' : 'mt-4',
            css({ '&&': configTheme?.matchStatus?.loading?.root })
          )}
        >
          <div>
            <Loader
              className={cx(
                'text-primary top-px',
                css({ '&&': configTheme?.matchStatus?.loading?.icon })
              )}
            />
          </div>
          <TextLoading className="text-base font-semibold">
            {t('txt_data_processing')}
          </TextLoading>
        </div>
      ) : (
        <div
          className={cx(
            'rounded-medium flex items-center space-x-2 px-6 py-3.5',
            isCollapse ? 'mb-2' : 'mt-4',
            css({
              '&&': css`
                background: ${styled.background};
              `,
            }),
            css({ '&&': styled.root })
          )}
        >
          <CheckMatched
            className={cx(
              css({
                '&&': css`
                  path {
                    stroke: ${styled.icon};
                  }
                `,
              })
            )}
          />
          <div className="flex flex-row items-center">
            <div
              className={cx(
                'text-base font-semibold',
                css({ '&&': styled.percent })
              )}
            >
              {percent > 0
                ? t('txt_join_sheets_percent_matched', {
                    percent,
                  })
                : t('txt_join_sheets_percent_not_matched')}
            </div>
            <div className="ml-2 mt-px text-xs font-normal">
              <div
                dangerouslySetInnerHTML={{
                  __html: t('txt_match_percent_description', {
                    columnKey: columnKey,
                    name: sheetName,
                    fileName: fileName,
                  }),
                }}
              ></div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default MatchStatus;
