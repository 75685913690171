import { css, CSSInterpolation, cx } from '../../core/emotion';
import { detectOverflow, Boundary, Padding } from '@popperjs/core';
import { Placement, RootBoundary } from '@popperjs/core/lib/enums';
import { Options } from '@popperjs/core/lib/modifiers/offset';
import { isNil } from 'lodash';
import {
  ReactNode,
  RefObject,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { createPortal } from 'react-dom';
import { usePopper } from 'react-popper';
import { NUVO_TAILWIND_CLASS } from '../../core/constants/style';
import { useTheme } from '../../theme';

interface OverflowCheckOffset {
  top: number;
  left: number;
  right: number;
  bottom: number;
}

const popperStyledPlacement = css`
  &[data-popper-placement^='top'] > #arrow {
    bottom: -6px;
    left: 0px !important;
  }

  &[data-popper-placement^='bottom'] > #arrow {
    top: -6px;
  }

  &[data-popper-placement^='left'] > #arrow {
    right: -4px;
    top: 0;
  }

  &[data-popper-placement^='right'] > #arrow {
    left: -6px;
  }
`;

const arrowStyledElement = css`
  visibility: hidden;
  background: transparent;
  z-index: 5;

  ::before {
    position: absolute;
    width: 10px;
    height: 10px;
    visibility: visible;
    content: '';
    transform: rotate(45deg);
  }
`;

interface PopoverProps {
  message?: ReactNode;
  isShow: boolean;
  direction?: Placement;
  offset?: Options['offset'];
  render: ({
    referenceElement,
  }: {
    referenceElement: RefObject<HTMLDivElement> | RefObject<HTMLElement> | null;
  }) => ReactNode;
  boundary?: Boundary;
  offsetTop?: number;
  overflowCheckOffset?: Partial<OverflowCheckOffset>;
  className?: string;
  overflowPadding?: Padding;
  preventOverflow?: boolean;
  rootBoundary?: RootBoundary;
  flip?: boolean;
  fallbackPlacements?: Placement[];
  flipPadding?: number;
  isShowArrow?: boolean;
  arrowClassName?: CSSInterpolation;
  rootElement?: HTMLElement;
}

const relativeStyle = css`
  position: relative;
`;

const Popover = ({
  isShow,
  message,
  direction,
  offset,
  boundary,
  render,
  overflowCheckOffset = { top: 0, left: -59, bottom: 60, right: 100 },
  className,
  overflowPadding,
  rootBoundary,
  preventOverflow = true,
  flip,
  fallbackPlacements,
  flipPadding,
  isShowArrow = false,
  arrowClassName,
  rootElement,
}: PopoverProps) => {
  const referenceElement = useRef<HTMLElement>(null);
  const popperElement = useRef<HTMLDivElement>(null);
  const arrowElement = useRef<HTMLDivElement>(null);
  const [isDisplay, setIsDisplay] = useState(false);
  const theme = useTheme();
  const { styles, attributes, state } = usePopper(
    referenceElement.current,
    popperElement.current,
    {
      placement: direction || 'right-end',
      strategy: 'absolute',
      modifiers: [
        { name: 'eventListeners', enabled: isShow },
        {
          name: 'offset',
          options: {
            offset: offset || [3, 8],
          },
        },
        {
          name: 'flip',
          enabled: flip ?? false,
          options: {
            fallbackPlacements,
            boundary,
            padding: flipPadding ?? 0,
          },
        },
        {
          name: 'preventOverflow',
          enabled: !!(boundary && preventOverflow),
          options: {
            boundary,
          },
        },
        {
          name: 'arrow',
          enabled: isShowArrow,
          phase: 'main',
          options: {
            element: arrowElement.current,
          },
        },
      ],
    }
  );

  useLayoutEffect(() => {
    setIsDisplay(isShow);
  }, [isShow]);

  const getIsOverflow = () => {
    if (state && isShow && boundary) {
      try {
        const overflowPosition = detectOverflow(state, {
          elementContext: 'popper',
          boundary,
          padding: overflowPadding,
          rootBoundary,
        });
        return (
          Object.keys(overflowCheckOffset) as Array<keyof OverflowCheckOffset>
        ).some((side) => {
          if (!isNil(overflowCheckOffset[side])) {
            return (
              overflowPosition[side] >
              (overflowCheckOffset as OverflowCheckOffset)[side]
            );
          } else {
            return false;
          }
        });
      } catch (e) {
        return false;
      }
    }
    return false;
  };

  const popperStyle = useMemo(() => {
    return css`
      ::before {
        background: ${arrowClassName ??
        theme.getGlobalTheme().getDark500Color()};
      }
    `;
  }, [arrowClassName, theme]);

  return (
    <>
      {render({ referenceElement })}
      {isShow
        ? createPortal(
            <div className={cx(NUVO_TAILWIND_CLASS, relativeStyle)}>
              <div
                ref={popperElement}
                style={{
                  ...styles.popper,
                  visibility:
                    isDisplay && !getIsOverflow() ? 'visible' : 'hidden',
                  opacity: isDisplay && !getIsOverflow() ? 1 : 0,
                  zIndex: 10000,
                }}
                className={cx(className, popperStyledPlacement)}
                {...attributes.popper}
              >
                {message}
                {isShowArrow ? (
                  <div
                    id="arrow"
                    ref={arrowElement}
                    style={{
                      ...styles.arrow,
                      visibility: 'visible',
                      width: '12px',
                      height: '12px',
                      opacity: 1,
                    }}
                    className={cx(arrowStyledElement, popperStyle)}
                    data-popper-arrow
                  />
                ) : null}
              </div>
            </div>,
            rootElement || document.body
          )
        : null}
    </>
  );
};

export default Popover;
