import { PossibleValue } from '../dataModelSheet/valueParser/IValueParser';
import { cleanSpecialCharAndToLowercase } from '../replaceRegex';
import { Option } from './model/CategoryDataModel';
import { DataModel } from './model/DataModel';

export const findDataModel = (
  dataModels: DataModel[],
  key: string,
  isAutoMapping?: boolean
) => {
  for (let i = 0; i < dataModels.length; i++) {
    let dataModelKey = dataModels[i].getKey();
    let targetKey = key;

    if (isAutoMapping) {
      dataModelKey = cleanSpecialCharAndToLowercase(dataModelKey);
      targetKey = cleanSpecialCharAndToLowercase(targetKey);
    }

    if (dataModelKey === targetKey) {
      return dataModels[i];
    }
  }
  return null;
};

export const findColIndexByKey = (key: string, dataModels: DataModel[]) => {
  for (let i = 0; i < dataModels.length; i++) {
    if (dataModels[i].getKey() === key) {
      return i;
    }
  }

  return -1;
};

export const isDropdownOptionEqual = (option: Option, value: PossibleValue) => {
  return `${option.value}`.toLowerCase() === `${value}`.toLowerCase();
};
