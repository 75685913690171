import { css, CSSInterpolation, CSSObject, cx } from '@nuvo-importer/common';
import useViewModel from './viewModel';
import { Sheet, SheetColumn } from 'sheetImporter';
import SimpleBar from 'simplebar-react';
import { useMemo } from 'react';
import { COLOR_JOIN_SHEET, COLOR_SCROLL_BAR } from 'core/constants/colors';

type TableJoinSheetProps = {
  sheet: Sheet;
  onSelectSheetColumn: (sheetColumn: SheetColumn, tableIndex: number) => void;
  selectedSheetColumns: SheetColumn[];
  tableIndex: number;
  isCollapse: boolean;
  configTheme?: {
    container: CSSInterpolation;
    root: CSSInterpolation;
    content: CSSInterpolation;
    selectColumnColor: string;
    hoverColumnColor: string;
    scrollbarNavigatorColor: string;
    scrollbarBackgroundColor: string;
    th: CSSInterpolation;
    td: CSSInterpolation;
  };
};

const Table = ({
  sheet,
  configTheme,
  onSelectSheetColumn,
  selectedSheetColumns,
  tableIndex,
  isCollapse,
}: TableJoinSheetProps) => {
  const {
    columnHeaders,
    currentHoverCol,
    customHoverTableStyle,
    customSelectedTableStyle,
    mediaSize,
    setCurrentHoverCol,
    rows,
  } = useViewModel({ sheet, configTheme });

  const tableMinWidth = useMemo(() => {
    let minWidth = 50;
    columnHeaders.forEach(() => {
      minWidth += 251;
    });

    return minWidth;
  }, [columnHeaders]);

  const tableStyled = useMemo(() => {
    return css`
      .simplebar-content-wrapper {
        border-width: 1px;
        border-color: ${COLOR_JOIN_SHEET.GRAY};
        border-radius: 4px;
      }

      .simplebar-horizontal {
        height: 8px;
        .simplebar-scrollbar {
          height: 8px;
          top: 0px;
        }
      }

      .simplebar-track {
        background-color: ${configTheme?.scrollbarBackgroundColor ||
        COLOR_SCROLL_BAR.BACKGROUND};
        opacity: 1;
        border-radius: 60px;
      }
      .simplebar-scrollbar:before {
        background-color: ${configTheme?.scrollbarNavigatorColor ||
        COLOR_SCROLL_BAR.NAVIGATOR};
        opacity: 1;
        border-radius: 60px;
        width: 100%;
        left: 0px !important;
        right: 0px !important;
      }

      .simplebar-vertical {
        display: none;
      }

      .simplebar-offset {
        bottom: 13px !important;
      }
    `;
  }, [configTheme]);

  if (!isCollapse) return <div />;

  return (
    <div
      className={cx(
        'relative flex rounded-lg bg-gray-50 p-2',
        css({ '&&': configTheme?.container })
      )}
    >
      <div
        className={cx(
          'rounded-medium w-full pb-3',
          tableStyled,
          css({ '&& .simplebar-content-wrapper': configTheme?.root })
        )}
      >
        <SimpleBar
          forceVisible={false}
          autoHide={false}
          className={cx(css`
            height: calc(100% + 15px);
          `)}
        >
          <div className={cx('relative w-full text-left')}>
            <table
              className={cx(
                'w-full border-collapse',
                css`
                  min-width: ${tableMinWidth}px;

                  table-layout: fixed;
                  tbody tr:last-child td,
                  tbody tr:last-child th {
                    border-bottom: none;
                  }
                  thead tr th:last-child,
                  tbody tr td:last-child {
                    border-right: none;
                  }
                  thead tr th:first-child {
                    position: sticky;
                    left: 0;
                    box-shadow: inset -1px 0px 0px ${(configTheme?.th as CSSObject).borderColor ? (configTheme?.th as CSSObject).borderColor : '#e1e1e1'};
                  }

                  tbody tr td:first-child {
                    position: sticky;
                    left: 0;
                    box-shadow: inset -1px 0px 0px ${(configTheme?.td as CSSObject).borderColor ? (configTheme?.td as CSSObject).borderColor : '#e1e1e1'};
                  }
                  thead tr td:nth-child(2) {
                    border-left: none !important;
                  }
                  tbody tr td:nth-child(2) {
                    border-left: none !important;
                  }
                `
              )}
            >
              <thead>
                <tr>
                  <th
                    className={cx(
                      'column-index min-w-56 w-14 bg-white',
                      css({ '&&': configTheme?.th })
                    )}
                  ></th>
                  {columnHeaders.map((item, index) => {
                    return (
                      <th
                        key={item}
                        className={cx(
                          'text-gray-560 leading-5.5 min-w-251 w-full cursor-pointer border !border-t-0 !border-l-0 px-4 text-sm font-normal',
                          mediaSize ? 'h-30' : 'h-10',
                          css({ '&&': configTheme?.th }),
                          customSelectedTableStyle(
                            selectedSheetColumns,
                            sheet.getColumnsWithRemoved()[index]
                          ),
                          customHoverTableStyle(currentHoverCol, index)
                        )}
                        onClick={() => {
                          onSelectSheetColumn(
                            sheet.getColumnsWithRemoved()[index],
                            tableIndex
                          );
                        }}
                        onMouseOver={() => {
                          setCurrentHoverCol(index);
                        }}
                        onMouseLeave={() => {
                          setCurrentHoverCol(null);
                        }}
                      >
                        {item}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {rows.map((row, index) => {
                  return (
                    <tr key={index}>
                      <td
                        className={cx(
                          'column-index leading-5.5 text-blue-dark-900 border !border-l-0 !border-r-0 bg-white text-center text-sm font-normal',
                          css({ '&&': configTheme?.td }),
                          css({ '&&': configTheme?.content })
                        )}
                      >
                        {index + 1}
                      </td>
                      {row.map((col, colIndex) => {
                        return (
                          <td
                            key={`${col}${colIndex}`}
                            onClick={() => {
                              onSelectSheetColumn(
                                sheet.getColumnsWithRemoved()[colIndex],
                                tableIndex
                              );
                            }}
                            onMouseOver={() => {
                              setCurrentHoverCol(colIndex);
                            }}
                            onMouseLeave={() => {
                              setCurrentHoverCol(null);
                            }}
                            className={cx(
                              'text-blue-dark-900 leading-5.5 min-w-251 box-border w-full border-collapse cursor-pointer truncate border !border-l-0 !px-4 text-sm font-normal',
                              mediaSize ? 'h-23' : 'h-33',
                              css({ '&&': configTheme?.td }),
                              css({ '&&': configTheme?.content }),
                              customSelectedTableStyle(
                                selectedSheetColumns,
                                sheet.getColumnsWithRemoved()[colIndex]
                              ),
                              customHoverTableStyle(currentHoverCol, colIndex)
                            )}
                          >
                            {col ?? ''}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </SimpleBar>
      </div>
    </div>
  );
};

export default Table;
