export const calculateSimilarityResults = [
  {
    result: [
      {
        label: 'Storage temperature 1',
        suggestions: [
          {
            key: 'storage_temperature',
            value: 1,
          },
        ],
        regexMatching: [],
        choice: {
          storage_temperature: {
            'fresh: 1° to 7°C': {
              'frisch: 0° to 2°C': 0,
              'fresh: 1° to 4°C': 0,
              'fresh: 1° to 7°C': 0.61,
              'dry: 5° to 25°C': 0,
            },
            'frisch: 0° to 3°C': {
              'frisch: 0° to 2°C': 0.4,
              'fresh: 1° to 4°C': 0,
              'fresh: 1° to 7°C': 0,
              'dry: 5° to 25°C': 1,
            },
            'frisch: 0° to 2°C': {
              'frisch: 0° to 2°C': 1,
              'fresh: 1° to 4°C': 0,
              'fresh: 1° to 7°C': 0,
              'dry: 5° to 25°C': 0,
            },
            'frisch: 0° to 4°C': {
              'frisch: 0° to 2°C': 0,
              'fresh: 1° to 4°C': 1,
              'fresh: 1° to 7°C': 0,
              'dry: 5° to 25°C': 0,
            },
          },
          Product_size_metric: {
            'fresh: 1° to 7°C': {
              ml: 0,
              g: 0,
              kg: 0.04861174285218095,
              piece: 0,
            },
            'frisch: 0° to 2°C': {
              ml: 0,
              g: 0,
              kg: 0.05182202214927809,
              piece: 0,
            },
            true: {
              ml: 0,
              g: 0,
              kg: 0.00839637649406694,
              piece: 0,
            },
            false: {
              ml: 0,
              g: 0,
              kg: 0.009127468728341201,
              piece: 0,
            },
          },
          deposit_per_unit: {
            'fresh: 1° to 7°C': {
              '0,02': 0,
              '0,03': 0,
              '0,08': 0,
              '0,15': 0,
              '0,25': 0,
            },
            'frisch: 0° to 2°C': {
              '0,02': 0,
              '0,03': 0,
              '0,08': 0,
              '0,15': 0,
              '0,25': 0,
            },
            true: {
              '0,02': 0,
              '0,03': 0,
              '0,08': 0,
              '0,15': 0,
              '0,25': 0,
            },
            false: {
              '0,02': 0,
              '0,03': 0,
              '0,08': 0,
              '0,15': 0,
              '0,25': 0,
            },
          },
        },
      },
      {
        label: 'Storage temperature',
        suggestions: [
          {
            key: 'storage_temperature',
            value: 1,
          },
        ],
        regexMatching: [],
        choice: {
          storage_temperature: {
            'fresh: 1° to 7°C': {
              'frisch: 0° to 2°C': 0,
              'fresh: 1° to 4°C': 0,
              'fresh: 1° to 7°C': 0.61,
              'dry: 5° to 25°C': 0,
            },
            'frisch: 0° to 3°C': {
              'frisch: 0° to 2°C': 0.61,
              'fresh: 1° to 4°C': 0,
              'fresh: 1° to 7°C': 0,
              'dry: 5° to 25°C': 1,
            },
            'frisch: 0° to 2°C': {
              'frisch: 0° to 2°C': 1,
              'fresh: 1° to 4°C': 0,
              'fresh: 1° to 7°C': 0,
              'dry: 5° to 25°C': 0,
            },
            'frisch: 0° to 4°C': {
              'frisch: 0° to 2°C': 0,
              'fresh: 1° to 4°C': 1,
              'fresh: 1° to 7°C': 0,
              'dry: 5° to 25°C': 0,
            },
          },
          Product_size_metric: {
            'fresh: 1° to 7°C': {
              ml: 0,
              g: 0,
              kg: 0.04861174285218095,
              piece: 0,
            },
            'frisch: 0° to 2°C': {
              ml: 0,
              g: 0,
              kg: 0.05182202214927809,
              piece: 0,
            },
            true: {
              ml: 0,
              g: 0,
              kg: 0.00839637649406694,
              piece: 0,
            },
            false: {
              ml: 0,
              g: 0,
              kg: 0.009127468728341201,
              piece: 0,
            },
          },
          deposit_per_unit: {
            'fresh: 1° to 7°C': {
              '0,02': 0,
              '0,03': 0,
              '0,08': 0,
              '0,15': 0,
              '0,25': 0,
            },
            'frisch: 0° to 2°C': {
              '0,02': 0,
              '0,03': 0,
              '0,08': 0,
              '0,15': 0,
              '0,25': 0,
            },
            true: {
              '0,02': 0,
              '0,03': 0,
              '0,08': 0,
              '0,15': 0,
              '0,25': 0,
            },
            false: {
              '0,02': 0,
              '0,03': 0,
              '0,08': 0,
              '0,15': 0,
              '0,25': 0,
            },
          },
        },
      },
      {
        label: 'Vendor name',
        suggestions: [
          {
            key: 'vendor_name',
            value: 1,
          },
        ],
        regexMatching: [],
        choice: {
          storage_temperature: {
            compA: {
              'frisch: 0° to 2°C': 0,
              'fresh: 1° to 4°C': 0,
              'fresh: 1° to 7°C': 0,
              'dry: 5° to 25°C': 0,
            },
            compB: {
              'frisch: 0° to 2°C': 0,
              'fresh: 1° to 4°C': 0,
              'fresh: 1° to 7°C': 0,
              'dry: 5° to 25°C': 0,
            },
            compC: {
              'frisch: 0° to 2°C': 0,
              'fresh: 1° to 4°C': 0,
              'fresh: 1° to 7°C': 0,
              'dry: 5° to 25°C': 0,
            },
          },
          Product_size_metric: {
            compA: {
              ml: 0,
              g: 0,
              kg: 0,
              piece: 0,
            },
            compB: {
              ml: 0,
              g: 0,
              kg: 0,
              piece: 0,
            },
            compC: {
              ml: 0,
              g: 0,
              kg: 0,
              piece: 0,
            },
          },
          deposit_per_unit: {
            compA: {
              '0,02': 0,
              '0,03': 0,
              '0,08': 0,
              '0,15': 0,
              '0,25': 0,
            },
            compB: {
              '0,02': 0,
              '0,03': 0,
              '0,08': 0,
              '0,15': 0,
              '0,25': 0,
            },
            compC: {
              '0,02': 0,
              '0,03': 0,
              '0,08': 0,
              '0,15': 0,
              '0,25': 0,
            },
          },
        },
      },
      {
        label: 'Product size',
        suggestions: [
          {
            key: 'product_size',
            value: 1,
          },
        ],
        regexMatching: [],
        choice: {
          storage_temperature: {
            '33': {
              'frisch: 0° to 2°C': 0,
              'fresh: 1° to 4°C': 0,
              'fresh: 1° to 7°C': 0,
              'dry: 5° to 25°C': 0,
            },
          },
          Product_size_metric: {
            '33': {
              ml: 0,
              g: 0,
              kg: 0,
              piece: 0,
            },
          },
          deposit_per_unit: {
            '33': {
              '0,02': 0,
              '0,03': 0,
              '0,08': 0,
              '0,15': 0,
              '0,25': 0,
            },
          },
        },
      },
      {
        label: 'Product size metric',
        suggestions: [
          {
            key: 'Product_size_metric',
            value: 1,
          },
          {
            key: 'product_size',
            value: 1,
          },
        ],
        regexMatching: [],
        choice: {
          storage_temperature: {
            ml: {
              'frisch: 0° to 2°C': 0,
              'fresh: 1° to 4°C': 0,
              'fresh: 1° to 7°C': 0,
              'dry: 5° to 25°C': 0,
            },
          },
          Product_size_metric: {
            ml: {
              ml: 1,
              g: 0,
              kg: 0,
              piece: 0,
            },
          },
          deposit_per_unit: {
            ml: {
              '0,02': 0,
              '0,03': 0,
              '0,08': 0,
              '0,15': 0,
              '0,25': 0,
            },
          },
        },
      },
      {
        label: 'Calorific value (kcal)',
        suggestions: [
          {
            key: 'calorific_value',
            value: 1,
          },
        ],
        regexMatching: [],
        choice: {
          storage_temperature: {
            '240 kcal': {
              'frisch: 0° to 2°C': 0,
              'fresh: 1° to 4°C': 0,
              'fresh: 1° to 7°C': 0,
              'dry: 5° to 25°C': 0,
            },
          },
          Product_size_metric: {
            '240 kcal': {
              ml: 0,
              g: 0,
              kg: 0,
              piece: 0,
            },
          },
          deposit_per_unit: {
            '240 kcal': {
              '0,02': 0,
              '0,03': 0,
              '0,08': 0,
              '0,15': 0,
              '0,25': 0,
            },
          },
        },
      },
      {
        label: 'Deposit of the outer packaging unit',
        suggestions: [
          {
            key: 'deposit',
            value: 1,
          },
        ],
        regexMatching: [],
        choice: {
          storage_temperature: {
            true: {
              'frisch: 0° to 2°C': 0.12708090540129535,
              'fresh: 1° to 4°C': 0.16984572944962448,
              'fresh: 1° to 7°C': 0.16984572944962448,
              'dry: 5° to 25°C': 0.19968162848390178,
            },
            false: {
              'frisch: 0° to 2°C': 0.023949875086202343,
              'fresh: 1° to 4°C': 0.13567984417064322,
              'fresh: 1° to 7°C': 0.13567984417064322,
              'dry: 5° to 25°C': 0.0657250216192965,
            },
          },
          Product_size_metric: {
            true: {
              ml: 0,
              g: 0,
              kg: 0.00839637649406694,
              piece: 0,
            },
            false: {
              ml: 0,
              g: 0,
              kg: 0.009127468728341201,
              piece: 0,
            },
          },
          deposit_per_unit: {
            true: {
              '0,02': 0,
              '0,03': 0,
              '0,08': 0,
              '0,15': 0,
              '0,25': 0,
            },
            false: {
              '0,02': 0,
              '0,03': 0,
              '0,08': 0,
              '0,15': 0,
              '0,25': 0,
            },
          },
        },
      },
      {
        label: 'Number of dispatch stage 1',
        suggestions: [
          {
            key: 'dispatch',
            value: 1,
          },
        ],
        regexMatching: [],
        choice: {
          storage_temperature: {
            '1': {
              'frisch: 0° to 2°C': 0,
              'fresh: 1° to 4°C': 0,
              'fresh: 1° to 7°C': 0,
              'dry: 5° to 25°C': 0,
            },
            '2': {
              'frisch: 0° to 2°C': 0,
              'fresh: 1° to 4°C': 0,
              'fresh: 1° to 7°C': 0,
              'dry: 5° to 25°C': 0,
            },
            '3': {
              'frisch: 0° to 2°C': 0,
              'fresh: 1° to 4°C': 0,
              'fresh: 1° to 7°C': 0,
              'dry: 5° to 25°C': 0,
            },
          },
          Product_size_metric: {
            '1': {
              ml: 0,
              g: 0,
              kg: 0,
              piece: 0,
            },
            '2': {
              ml: 0,
              g: 0,
              kg: 0,
              piece: 0,
            },
            '3': {
              ml: 0,
              g: 0,
              kg: 0,
              piece: 0,
            },
          },
          deposit_per_unit: {
            '1': {
              '0,02': 0,
              '0,03': 0,
              '0,08': 0,
              '0,15': 0,
              '0,25': 0,
            },
            '2': {
              '0,02': 0,
              '0,03': 0,
              '0,08': 0,
              '0,15': 0,
              '0,25': 0,
            },
            '3': {
              '0,02': 0,
              '0,03': 0,
              '0,08': 0,
              '0,15': 0,
              '0,25': 0,
            },
          },
        },
      },
      {
        label: 'Deposit per unit',
        suggestions: [
          {
            key: 'deposit_per_unit',
            value: 1,
          },
        ],
        regexMatching: [],
        choice: {
          storage_temperature: {
            '0,02': {
              'frisch: 0° to 2°C': 0,
              'fresh: 1° to 4°C': 0,
              'fresh: 1° to 7°C': 0,
              'dry: 5° to 25°C': 0,
            },
          },
          Product_size_metric: {
            '0,02': {
              ml: 0,
              g: 0,
              kg: 0,
              piece: 0,
            },
          },
          deposit_per_unit: {
            '0,02': {
              '0,02': 1,
              '0,03': 0.6666666666666666,
              '0,08': 0.6666666666666666,
              '0,15': 0.3333333333333333,
              '0,25': 0.3333333333333333,
            },
          },
        },
      },
      {
        label: 'deposit',
        suggestions: [
          {
            key: 'deposit',
            value: 0.7,
          },
        ],
        regexMatching: [],
        choice: {},
      },
    ],
  },
];

export const calculateSimilarityResult2 = {
  result: [
    {
      label: 'storage temperature',
      suggestions: [
        {
          key: 'storage_temperature',
          value: 0.999998,
          isSameWord: true,
        },
      ],
      regexMatching: [],
      choice: {
        storage_temperature: {
          'frisch: 0° to 2°C': {
            'frisch: 0° to 2°C': 1,
            'fresh: 1° to 4°C': 0.75,
            'fresh: 1° to 7°C': 0.75,
            'dry: 5° to 25°C': 0.5625,
            '2°C': 0.1875,
            '4°C': 0.125,
            '7°C': 0.125,
            '25°C': 0.125,
          },
          'frisch: 1° to 4°C': {
            'frisch: 0° to 2°C': 0.875,
            'fresh: 1° to 4°C': 0.875,
            'fresh: 1° to 7°C': 0.8125,
            'dry: 5° to 25°C': 0.5,
            '2°C': 0.125,
            '4°C': 0.1875,
            '7°C': 0.125,
            '25°C': 0.125,
          },
          '': {
            'frisch: 0° to 2°C': 0,
            'fresh: 1° to 4°C': 0,
            'fresh: 1° to 7°C': 0,
            'dry: 5° to 25°C': 0,
            '2°C': 0,
            '4°C': 0,
            '7°C': 0,
            '25°C': 0,
          },
        },
        deposit_per_unit: {
          'frisch: 0° to 2°C': {
            '0,02': 0.125,
            '0,03': 0.0625,
            '0,08': 0.0625,
            '0,15': 0.0625,
            '0,25': 0.125,
          },
          'frisch: 1° to 4°C': {
            '0,02': 0,
            '0,03': 0,
            '0,08': 0,
            '0,15': 0.0625,
            '0,25': 0,
          },
          '': {
            '0,02': 0,
            '0,03': 0,
            '0,08': 0,
            '0,15': 0,
            '0,25': 0,
          },
        },
      },
    },
    {
      label: 'Product ID',
      suggestions: [
        {
          key: 'product_id',
          value: 1,
          isSameWord: true,
        },
        {
          key: 'item1',
          value: 0.833949,
          isSameWord: false,
        },
        {
          key: 'item2',
          value: 0.833949,
          isSameWord: false,
        },
        {
          key: 'item3',
          value: 0.833949,
          isSameWord: false,
        },
      ],
      regexMatching: [],
      choice: {
        deposit_per_unit: {
          'Product 1': {
            '0,02': 0,
            '0,03': 0,
            '0,08': 0,
            '0,15': 0,
            '0,25': 0,
          },
          'Product 2': {
            '0,02': 0.11111111111111116,
            '0,03': 0,
            '0,08': 0,
            '0,15': 0,
            '0,25': 0,
          },
          'Product 3': {
            '0,02': 0,
            '0,03': 0.11111111111111116,
            '0,08': 0,
            '0,15': 0,
            '0,25': 0,
          },
          'Product 4': {
            '0,02': 0,
            '0,03': 0,
            '0,08': 0,
            '0,15': 0,
            '0,25': 0,
          },
          'Product 5': {
            '0,02': 0,
            '0,03': 0,
            '0,08': 0,
            '0,15': 0.11111111111111116,
            '0,25': 0.11111111111111116,
          },
          'Product 6': {
            '0,02': 0,
            '0,03': 0,
            '0,08': 0,
            '0,15': 0,
            '0,25': 0,
          },
          'Product 7': {
            '0,02': 0,
            '0,03': 0,
            '0,08': 0,
            '0,15': 0,
            '0,25': 0,
          },
        },
        Product_size_metric: {
          'Product 1': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0.2222222222222222,
          },
          'Product 2': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0.2222222222222222,
          },
          'Product 3': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0.2222222222222222,
          },
          'Product 4': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0.2222222222222222,
          },
          'Product 5': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0.2222222222222222,
          },
          'Product 6': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0.2222222222222222,
          },
          'Product 7': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0.2222222222222222,
          },
        },
        item1: {
          'Product 1': {
            'frisch: 0° to 2°C': 0.19999999999999996,
            'fresh: 1° to 4°C': 0.19999999999999996,
            'fresh: 1° to 7°C': 0.19999999999999996,
            'dry: 5° to 25°C': 0.2142857142857143,
            '2°C': 0.11111111111111116,
            '4°C': 0.11111111111111116,
            '7°C': 0.11111111111111116,
            '25°C': 0.11111111111111116,
          },
          'Product 2': {
            'frisch: 0° to 2°C': 0.2666666666666667,
            'fresh: 1° to 4°C': 0.19999999999999996,
            'fresh: 1° to 7°C': 0.19999999999999996,
            'dry: 5° to 25°C': 0.2857142857142857,
            '2°C': 0.11111111111111116,
            '4°C': 0.11111111111111116,
            '7°C': 0.11111111111111116,
            '25°C': 0.11111111111111116,
          },
          'Product 3': {
            'frisch: 0° to 2°C': 0.19999999999999996,
            'fresh: 1° to 4°C': 0.19999999999999996,
            'fresh: 1° to 7°C': 0.19999999999999996,
            'dry: 5° to 25°C': 0.2142857142857143,
            '2°C': 0.11111111111111116,
            '4°C': 0.11111111111111116,
            '7°C': 0.11111111111111116,
            '25°C': 0.11111111111111116,
          },
          'Product 4': {
            'frisch: 0° to 2°C': 0.19999999999999996,
            'fresh: 1° to 4°C': 0.2666666666666667,
            'fresh: 1° to 7°C': 0.19999999999999996,
            'dry: 5° to 25°C': 0.2142857142857143,
            '2°C': 0.11111111111111116,
            '4°C': 0.11111111111111116,
            '7°C': 0.11111111111111116,
            '25°C': 0.11111111111111116,
          },
          'Product 5': {
            'frisch: 0° to 2°C': 0.19999999999999996,
            'fresh: 1° to 4°C': 0.19999999999999996,
            'fresh: 1° to 7°C': 0.19999999999999996,
            'dry: 5° to 25°C': 0.2857142857142857,
            '2°C': 0.11111111111111116,
            '4°C': 0.11111111111111116,
            '7°C': 0.11111111111111116,
            '25°C': 0.11111111111111116,
          },
          'Product 6': {
            'frisch: 0° to 2°C': 0.19999999999999996,
            'fresh: 1° to 4°C': 0.19999999999999996,
            'fresh: 1° to 7°C': 0.19999999999999996,
            'dry: 5° to 25°C': 0.2142857142857143,
            '2°C': 0.11111111111111116,
            '4°C': 0.11111111111111116,
            '7°C': 0.11111111111111116,
            '25°C': 0.11111111111111116,
          },
          'Product 7': {
            'frisch: 0° to 2°C': 0.19999999999999996,
            'fresh: 1° to 4°C': 0.19999999999999996,
            'fresh: 1° to 7°C': 0.2666666666666667,
            'dry: 5° to 25°C': 0.2142857142857143,
            '2°C': 0.11111111111111116,
            '4°C': 0.11111111111111116,
            '7°C': 0.11111111111111116,
            '25°C': 0.11111111111111116,
          },
        },
        item2: {
          'Product 1': {
            'frisch: 0° to 2°C': 0.19999999999999996,
            'fresh: 1° to 4°C': 0.19999999999999996,
            'fresh: 1° to 7°C': 0.19999999999999996,
            'dry: 5° to 25°C': 0.2142857142857143,
            '2°C': 0.11111111111111116,
            '4°C': 0.11111111111111116,
            '7°C': 0.11111111111111116,
            '25°C': 0.11111111111111116,
          },
          'Product 2': {
            'frisch: 0° to 2°C': 0.2666666666666667,
            'fresh: 1° to 4°C': 0.19999999999999996,
            'fresh: 1° to 7°C': 0.19999999999999996,
            'dry: 5° to 25°C': 0.2857142857142857,
            '2°C': 0.11111111111111116,
            '4°C': 0.11111111111111116,
            '7°C': 0.11111111111111116,
            '25°C': 0.11111111111111116,
          },
          'Product 3': {
            'frisch: 0° to 2°C': 0.19999999999999996,
            'fresh: 1° to 4°C': 0.19999999999999996,
            'fresh: 1° to 7°C': 0.19999999999999996,
            'dry: 5° to 25°C': 0.2142857142857143,
            '2°C': 0.11111111111111116,
            '4°C': 0.11111111111111116,
            '7°C': 0.11111111111111116,
            '25°C': 0.11111111111111116,
          },
          'Product 4': {
            'frisch: 0° to 2°C': 0.19999999999999996,
            'fresh: 1° to 4°C': 0.2666666666666667,
            'fresh: 1° to 7°C': 0.19999999999999996,
            'dry: 5° to 25°C': 0.2142857142857143,
            '2°C': 0.11111111111111116,
            '4°C': 0.11111111111111116,
            '7°C': 0.11111111111111116,
            '25°C': 0.11111111111111116,
          },
          'Product 5': {
            'frisch: 0° to 2°C': 0.19999999999999996,
            'fresh: 1° to 4°C': 0.19999999999999996,
            'fresh: 1° to 7°C': 0.19999999999999996,
            'dry: 5° to 25°C': 0.2857142857142857,
            '2°C': 0.11111111111111116,
            '4°C': 0.11111111111111116,
            '7°C': 0.11111111111111116,
            '25°C': 0.11111111111111116,
          },
          'Product 6': {
            'frisch: 0° to 2°C': 0.19999999999999996,
            'fresh: 1° to 4°C': 0.19999999999999996,
            'fresh: 1° to 7°C': 0.19999999999999996,
            'dry: 5° to 25°C': 0.2142857142857143,
            '2°C': 0.11111111111111116,
            '4°C': 0.11111111111111116,
            '7°C': 0.11111111111111116,
            '25°C': 0.11111111111111116,
          },
          'Product 7': {
            'frisch: 0° to 2°C': 0.19999999999999996,
            'fresh: 1° to 4°C': 0.19999999999999996,
            'fresh: 1° to 7°C': 0.2666666666666667,
            'dry: 5° to 25°C': 0.2142857142857143,
            '2°C': 0.11111111111111116,
            '4°C': 0.11111111111111116,
            '7°C': 0.11111111111111116,
            '25°C': 0.11111111111111116,
          },
        },
        item3: {
          'Product 1': {
            'frisch: 0° to 2°C': 0.19999999999999996,
            'fresh: 1° to 4°C': 0.19999999999999996,
            'fresh: 1° to 7°C': 0.19999999999999996,
            'dry: 5° to 25°C': 0.2142857142857143,
            '2°C': 0.11111111111111116,
            '4°C': 0.11111111111111116,
            '7°C': 0.11111111111111116,
            '25°C': 0.11111111111111116,
          },
          'Product 2': {
            'frisch: 0° to 2°C': 0.2666666666666667,
            'fresh: 1° to 4°C': 0.19999999999999996,
            'fresh: 1° to 7°C': 0.19999999999999996,
            'dry: 5° to 25°C': 0.2857142857142857,
            '2°C': 0.11111111111111116,
            '4°C': 0.11111111111111116,
            '7°C': 0.11111111111111116,
            '25°C': 0.11111111111111116,
          },
          'Product 3': {
            'frisch: 0° to 2°C': 0.19999999999999996,
            'fresh: 1° to 4°C': 0.19999999999999996,
            'fresh: 1° to 7°C': 0.19999999999999996,
            'dry: 5° to 25°C': 0.2142857142857143,
            '2°C': 0.11111111111111116,
            '4°C': 0.11111111111111116,
            '7°C': 0.11111111111111116,
            '25°C': 0.11111111111111116,
          },
          'Product 4': {
            'frisch: 0° to 2°C': 0.19999999999999996,
            'fresh: 1° to 4°C': 0.2666666666666667,
            'fresh: 1° to 7°C': 0.19999999999999996,
            'dry: 5° to 25°C': 0.2142857142857143,
            '2°C': 0.11111111111111116,
            '4°C': 0.11111111111111116,
            '7°C': 0.11111111111111116,
            '25°C': 0.11111111111111116,
          },
          'Product 5': {
            'frisch: 0° to 2°C': 0.19999999999999996,
            'fresh: 1° to 4°C': 0.19999999999999996,
            'fresh: 1° to 7°C': 0.19999999999999996,
            'dry: 5° to 25°C': 0.2857142857142857,
            '2°C': 0.11111111111111116,
            '4°C': 0.11111111111111116,
            '7°C': 0.11111111111111116,
            '25°C': 0.11111111111111116,
          },
          'Product 6': {
            'frisch: 0° to 2°C': 0.19999999999999996,
            'fresh: 1° to 4°C': 0.19999999999999996,
            'fresh: 1° to 7°C': 0.19999999999999996,
            'dry: 5° to 25°C': 0.2142857142857143,
            '2°C': 0.11111111111111116,
            '4°C': 0.11111111111111116,
            '7°C': 0.11111111111111116,
            '25°C': 0.11111111111111116,
          },
          'Product 7': {
            'frisch: 0° to 2°C': 0.19999999999999996,
            'fresh: 1° to 4°C': 0.19999999999999996,
            'fresh: 1° to 7°C': 0.2666666666666667,
            'dry: 5° to 25°C': 0.2142857142857143,
            '2°C': 0.11111111111111116,
            '4°C': 0.11111111111111116,
            '7°C': 0.11111111111111116,
            '25°C': 0.11111111111111116,
          },
        },
        item4: {
          'Product 1': {
            'frisch: 0° to 2°C': 0.19999999999999996,
            'fresh: 1° to 4°C': 0.19999999999999996,
            'fresh: 1° to 7°C': 0.19999999999999996,
            'dry: 5° to 25°C': 0.2142857142857143,
            '2°C': 0.11111111111111116,
            '4°C': 0.11111111111111116,
            '7°C': 0.11111111111111116,
            '25°C': 0.11111111111111116,
          },
          'Product 2': {
            'frisch: 0° to 2°C': 0.2666666666666667,
            'fresh: 1° to 4°C': 0.19999999999999996,
            'fresh: 1° to 7°C': 0.19999999999999996,
            'dry: 5° to 25°C': 0.2857142857142857,
            '2°C': 0.11111111111111116,
            '4°C': 0.11111111111111116,
            '7°C': 0.11111111111111116,
            '25°C': 0.11111111111111116,
          },
          'Product 3': {
            'frisch: 0° to 2°C': 0.19999999999999996,
            'fresh: 1° to 4°C': 0.19999999999999996,
            'fresh: 1° to 7°C': 0.19999999999999996,
            'dry: 5° to 25°C': 0.2142857142857143,
            '2°C': 0.11111111111111116,
            '4°C': 0.11111111111111116,
            '7°C': 0.11111111111111116,
            '25°C': 0.11111111111111116,
          },
          'Product 4': {
            'frisch: 0° to 2°C': 0.19999999999999996,
            'fresh: 1° to 4°C': 0.2666666666666667,
            'fresh: 1° to 7°C': 0.19999999999999996,
            'dry: 5° to 25°C': 0.2142857142857143,
            '2°C': 0.11111111111111116,
            '4°C': 0.11111111111111116,
            '7°C': 0.11111111111111116,
            '25°C': 0.11111111111111116,
          },
          'Product 5': {
            'frisch: 0° to 2°C': 0.19999999999999996,
            'fresh: 1° to 4°C': 0.19999999999999996,
            'fresh: 1° to 7°C': 0.19999999999999996,
            'dry: 5° to 25°C': 0.2857142857142857,
            '2°C': 0.11111111111111116,
            '4°C': 0.11111111111111116,
            '7°C': 0.11111111111111116,
            '25°C': 0.11111111111111116,
          },
          'Product 6': {
            'frisch: 0° to 2°C': 0.19999999999999996,
            'fresh: 1° to 4°C': 0.19999999999999996,
            'fresh: 1° to 7°C': 0.19999999999999996,
            'dry: 5° to 25°C': 0.2142857142857143,
            '2°C': 0.11111111111111116,
            '4°C': 0.11111111111111116,
            '7°C': 0.11111111111111116,
            '25°C': 0.11111111111111116,
          },
          'Product 7': {
            'frisch: 0° to 2°C': 0.19999999999999996,
            'fresh: 1° to 4°C': 0.19999999999999996,
            'fresh: 1° to 7°C': 0.2666666666666667,
            'dry: 5° to 25°C': 0.2142857142857143,
            '2°C': 0.11111111111111116,
            '4°C': 0.11111111111111116,
            '7°C': 0.11111111111111116,
            '25°C': 0.11111111111111116,
          },
        },
        item5: {
          'Product 1': {
            'frisch: 0° to 2°C': 0.19999999999999996,
            'fresh: 1° to 4°C': 0.19999999999999996,
            'fresh: 1° to 7°C': 0.19999999999999996,
            'dry: 5° to 25°C': 0.2142857142857143,
            '2°C': 0.11111111111111116,
            '4°C': 0.11111111111111116,
            '7°C': 0.11111111111111116,
            '25°C': 0.11111111111111116,
          },
          'Product 2': {
            'frisch: 0° to 2°C': 0.2666666666666667,
            'fresh: 1° to 4°C': 0.19999999999999996,
            'fresh: 1° to 7°C': 0.19999999999999996,
            'dry: 5° to 25°C': 0.2857142857142857,
            '2°C': 0.11111111111111116,
            '4°C': 0.11111111111111116,
            '7°C': 0.11111111111111116,
            '25°C': 0.11111111111111116,
          },
          'Product 3': {
            'frisch: 0° to 2°C': 0.19999999999999996,
            'fresh: 1° to 4°C': 0.19999999999999996,
            'fresh: 1° to 7°C': 0.19999999999999996,
            'dry: 5° to 25°C': 0.2142857142857143,
            '2°C': 0.11111111111111116,
            '4°C': 0.11111111111111116,
            '7°C': 0.11111111111111116,
            '25°C': 0.11111111111111116,
          },
          'Product 4': {
            'frisch: 0° to 2°C': 0.19999999999999996,
            'fresh: 1° to 4°C': 0.2666666666666667,
            'fresh: 1° to 7°C': 0.19999999999999996,
            'dry: 5° to 25°C': 0.2142857142857143,
            '2°C': 0.11111111111111116,
            '4°C': 0.11111111111111116,
            '7°C': 0.11111111111111116,
            '25°C': 0.11111111111111116,
          },
          'Product 5': {
            'frisch: 0° to 2°C': 0.19999999999999996,
            'fresh: 1° to 4°C': 0.19999999999999996,
            'fresh: 1° to 7°C': 0.19999999999999996,
            'dry: 5° to 25°C': 0.2857142857142857,
            '2°C': 0.11111111111111116,
            '4°C': 0.11111111111111116,
            '7°C': 0.11111111111111116,
            '25°C': 0.11111111111111116,
          },
          'Product 6': {
            'frisch: 0° to 2°C': 0.19999999999999996,
            'fresh: 1° to 4°C': 0.19999999999999996,
            'fresh: 1° to 7°C': 0.19999999999999996,
            'dry: 5° to 25°C': 0.2142857142857143,
            '2°C': 0.11111111111111116,
            '4°C': 0.11111111111111116,
            '7°C': 0.11111111111111116,
            '25°C': 0.11111111111111116,
          },
          'Product 7': {
            'frisch: 0° to 2°C': 0.19999999999999996,
            'fresh: 1° to 4°C': 0.19999999999999996,
            'fresh: 1° to 7°C': 0.2666666666666667,
            'dry: 5° to 25°C': 0.2142857142857143,
            '2°C': 0.11111111111111116,
            '4°C': 0.11111111111111116,
            '7°C': 0.11111111111111116,
            '25°C': 0.11111111111111116,
          },
        },
        item21: {
          'Product 1': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0.2222222222222222,
          },
          'Product 2': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0.2222222222222222,
          },
          'Product 3': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0.2222222222222222,
          },
          'Product 4': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0.2222222222222222,
          },
          'Product 5': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0.2222222222222222,
          },
          'Product 6': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0.2222222222222222,
          },
          'Product 7': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0.2222222222222222,
          },
        },
        item22: {
          'Product 1': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0.2222222222222222,
          },
          'Product 2': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0.2222222222222222,
          },
          'Product 3': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0.2222222222222222,
          },
          'Product 4': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0.2222222222222222,
          },
          'Product 5': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0.2222222222222222,
          },
          'Product 6': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0.2222222222222222,
          },
          'Product 7': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0.2222222222222222,
          },
        },
        item23: {
          'Product 1': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0.2222222222222222,
          },
          'Product 2': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0.2222222222222222,
          },
          'Product 3': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0.2222222222222222,
          },
          'Product 4': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0.2222222222222222,
          },
          'Product 5': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0.2222222222222222,
          },
          'Product 6': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0.2222222222222222,
          },
          'Product 7': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0.2222222222222222,
          },
        },
        item24: {
          'Product 1': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0.2222222222222222,
          },
          'Product 2': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0.2222222222222222,
          },
          'Product 3': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0.2222222222222222,
          },
          'Product 4': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0.2222222222222222,
          },
          'Product 5': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0.2222222222222222,
          },
          'Product 6': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0.2222222222222222,
          },
          'Product 7': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0.2222222222222222,
          },
        },
        item25: {
          'Product 1': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0.2222222222222222,
          },
          'Product 2': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0.2222222222222222,
          },
          'Product 3': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0.2222222222222222,
          },
          'Product 4': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0.2222222222222222,
          },
          'Product 5': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0.2222222222222222,
          },
          'Product 6': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0.2222222222222222,
          },
          'Product 7': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0.2222222222222222,
          },
        },
      },
    },
    {
      label: 'Product Name',
      suggestions: [
        {
          key: 'item1',
          value: 0.799871,
          isSameWord: false,
        },
        {
          key: 'item2',
          value: 0.799871,
          isSameWord: false,
        },
        {
          key: 'item3',
          value: 0.799871,
          isSameWord: false,
        },
        {
          key: 'item4',
          value: 0.799871,
          isSameWord: false,
        },
      ],
      regexMatching: [],
      choice: {
        deposit_per_unit: {
          '20scoopsCNX-0': {
            '0,02': 0.08333333333333337,
            '0,03': 0.08333333333333337,
            '0,08': 0.08333333333333337,
            '0,15': 0.08333333333333337,
            '0,25': 0.08333333333333337,
          },
          'Google-1': {
            '0,02': 0,
            '0,03': 0,
            '0,08': 0,
            '0,15': 0,
            '0,25': 0,
          },
          'Facebook-2': {
            '0,02': 0.11111111111111116,
            '0,03': 0,
            '0,08': 0,
            '0,15': 0,
            '0,25': 0,
          },
          '20scoopsCNX-3': {
            '0,02': 0.08333333333333337,
            '0,03': 0.16666666666666663,
            '0,08': 0.08333333333333337,
            '0,15': 0.08333333333333337,
            '0,25': 0.08333333333333337,
          },
          'Google-4': {
            '0,02': 0,
            '0,03': 0,
            '0,08': 0,
            '0,15': 0,
            '0,25': 0,
          },
          'Facebook-5': {
            '0,02': 0,
            '0,03': 0,
            '0,08': 0,
            '0,15': 0.11111111111111116,
            '0,25': 0.11111111111111116,
          },
          '20scoopsCNX-6': {
            '0,02': 0.08333333333333337,
            '0,03': 0.08333333333333337,
            '0,08': 0.08333333333333337,
            '0,15': 0.08333333333333337,
            '0,25': 0.08333333333333337,
          },
        },
        Product_size_metric: {
          '20scoopsCNX-0': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0.08333333333333337,
          },
          'Google-1': {
            ml: 0.1428571428571429,
            g: 0.1428571428571429,
            kg: 0.1428571428571429,
            piece: 0.1428571428571429,
          },
          'Facebook-2': {
            ml: 0,
            g: 0,
            kg: 0.11111111111111116,
            piece: 0.11111111111111116,
          },
          '20scoopsCNX-3': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0.08333333333333337,
          },
          'Google-4': {
            ml: 0.1428571428571429,
            g: 0.1428571428571429,
            kg: 0.1428571428571429,
            piece: 0.1428571428571429,
          },
          'Facebook-5': {
            ml: 0,
            g: 0,
            kg: 0.11111111111111116,
            piece: 0.11111111111111116,
          },
          '20scoopsCNX-6': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0.08333333333333337,
          },
        },
        item1: {
          '20scoopsCNX-0': {
            'frisch: 0° to 2°C': 0.125,
            'fresh: 1° to 4°C': 0.06666666666666665,
            'fresh: 1° to 7°C': 0.06666666666666665,
            'dry: 5° to 25°C': 0,
            '2°C': 0.16666666666666663,
            '4°C': 0.08333333333333337,
            '7°C': 0.08333333333333337,
            '25°C': 0.16666666666666663,
          },
          'Google-1': {
            'frisch: 0° to 2°C': 0.06666666666666665,
            'fresh: 1° to 4°C': 0.06666666666666665,
            'fresh: 1° to 7°C': 0.06666666666666665,
            'dry: 5° to 25°C': 0.0714285714285714,
            '2°C': 0,
            '4°C': 0,
            '7°C': 0,
            '25°C': 0,
          },
          'Facebook-2': {
            'frisch: 0° to 2°C': 0.25,
            'fresh: 1° to 4°C': 0.1333333333333333,
            'fresh: 1° to 7°C': 0.1333333333333333,
            'dry: 5° to 25°C': 0.1428571428571429,
            '2°C': 0.11111111111111116,
            '4°C': 0.11111111111111116,
            '7°C': 0.11111111111111116,
            '25°C': 0,
          },
          '20scoopsCNX-3': {
            'frisch: 0° to 2°C': 0.125,
            'fresh: 1° to 4°C': 0.06666666666666665,
            'fresh: 1° to 7°C': 0.06666666666666665,
            'dry: 5° to 25°C': 0,
            '2°C': 0.16666666666666663,
            '4°C': 0.08333333333333337,
            '7°C': 0.08333333333333337,
            '25°C': 0.16666666666666663,
          },
          'Google-4': {
            'frisch: 0° to 2°C': 0.06666666666666665,
            'fresh: 1° to 4°C': 0.06666666666666665,
            'fresh: 1° to 7°C': 0.06666666666666665,
            'dry: 5° to 25°C': 0.0714285714285714,
            '2°C': 0,
            '4°C': 0,
            '7°C': 0,
            '25°C': 0,
          },
          'Facebook-5': {
            'frisch: 0° to 2°C': 0.1875,
            'fresh: 1° to 4°C': 0.1333333333333333,
            'fresh: 1° to 7°C': 0.1333333333333333,
            'dry: 5° to 25°C': 0.1428571428571429,
            '2°C': 0.11111111111111116,
            '4°C': 0.11111111111111116,
            '7°C': 0.11111111111111116,
            '25°C': 0,
          },
          '20scoopsCNX-6': {
            'frisch: 0° to 2°C': 0.125,
            'fresh: 1° to 4°C': 0.06666666666666665,
            'fresh: 1° to 7°C': 0.06666666666666665,
            'dry: 5° to 25°C': 0,
            '2°C': 0.16666666666666663,
            '4°C': 0.08333333333333337,
            '7°C': 0.08333333333333337,
            '25°C': 0.16666666666666663,
          },
        },
        item2: {
          '20scoopsCNX-0': {
            'frisch: 0° to 2°C': 0.125,
            'fresh: 1° to 4°C': 0.06666666666666665,
            'fresh: 1° to 7°C': 0.06666666666666665,
            'dry: 5° to 25°C': 0,
            '2°C': 0.16666666666666663,
            '4°C': 0.08333333333333337,
            '7°C': 0.08333333333333337,
            '25°C': 0.16666666666666663,
          },
          'Google-1': {
            'frisch: 0° to 2°C': 0.06666666666666665,
            'fresh: 1° to 4°C': 0.06666666666666665,
            'fresh: 1° to 7°C': 0.06666666666666665,
            'dry: 5° to 25°C': 0.0714285714285714,
            '2°C': 0,
            '4°C': 0,
            '7°C': 0,
            '25°C': 0,
          },
          'Facebook-2': {
            'frisch: 0° to 2°C': 0.25,
            'fresh: 1° to 4°C': 0.1333333333333333,
            'fresh: 1° to 7°C': 0.1333333333333333,
            'dry: 5° to 25°C': 0.1428571428571429,
            '2°C': 0.11111111111111116,
            '4°C': 0.11111111111111116,
            '7°C': 0.11111111111111116,
            '25°C': 0,
          },
          '20scoopsCNX-3': {
            'frisch: 0° to 2°C': 0.125,
            'fresh: 1° to 4°C': 0.06666666666666665,
            'fresh: 1° to 7°C': 0.06666666666666665,
            'dry: 5° to 25°C': 0,
            '2°C': 0.16666666666666663,
            '4°C': 0.08333333333333337,
            '7°C': 0.08333333333333337,
            '25°C': 0.16666666666666663,
          },
          'Google-4': {
            'frisch: 0° to 2°C': 0.06666666666666665,
            'fresh: 1° to 4°C': 0.06666666666666665,
            'fresh: 1° to 7°C': 0.06666666666666665,
            'dry: 5° to 25°C': 0.0714285714285714,
            '2°C': 0,
            '4°C': 0,
            '7°C': 0,
            '25°C': 0,
          },
          'Facebook-5': {
            'frisch: 0° to 2°C': 0.1875,
            'fresh: 1° to 4°C': 0.1333333333333333,
            'fresh: 1° to 7°C': 0.1333333333333333,
            'dry: 5° to 25°C': 0.1428571428571429,
            '2°C': 0.11111111111111116,
            '4°C': 0.11111111111111116,
            '7°C': 0.11111111111111116,
            '25°C': 0,
          },
          '20scoopsCNX-6': {
            'frisch: 0° to 2°C': 0.125,
            'fresh: 1° to 4°C': 0.06666666666666665,
            'fresh: 1° to 7°C': 0.06666666666666665,
            'dry: 5° to 25°C': 0,
            '2°C': 0.16666666666666663,
            '4°C': 0.08333333333333337,
            '7°C': 0.08333333333333337,
            '25°C': 0.16666666666666663,
          },
        },
        item3: {
          '20scoopsCNX-0': {
            'frisch: 0° to 2°C': 0.125,
            'fresh: 1° to 4°C': 0.06666666666666665,
            'fresh: 1° to 7°C': 0.06666666666666665,
            'dry: 5° to 25°C': 0,
            '2°C': 0.16666666666666663,
            '4°C': 0.08333333333333337,
            '7°C': 0.08333333333333337,
            '25°C': 0.16666666666666663,
          },
          'Google-1': {
            'frisch: 0° to 2°C': 0.06666666666666665,
            'fresh: 1° to 4°C': 0.06666666666666665,
            'fresh: 1° to 7°C': 0.06666666666666665,
            'dry: 5° to 25°C': 0.0714285714285714,
            '2°C': 0,
            '4°C': 0,
            '7°C': 0,
            '25°C': 0,
          },
          'Facebook-2': {
            'frisch: 0° to 2°C': 0.25,
            'fresh: 1° to 4°C': 0.1333333333333333,
            'fresh: 1° to 7°C': 0.1333333333333333,
            'dry: 5° to 25°C': 0.1428571428571429,
            '2°C': 0.11111111111111116,
            '4°C': 0.11111111111111116,
            '7°C': 0.11111111111111116,
            '25°C': 0,
          },
          '20scoopsCNX-3': {
            'frisch: 0° to 2°C': 0.125,
            'fresh: 1° to 4°C': 0.06666666666666665,
            'fresh: 1° to 7°C': 0.06666666666666665,
            'dry: 5° to 25°C': 0,
            '2°C': 0.16666666666666663,
            '4°C': 0.08333333333333337,
            '7°C': 0.08333333333333337,
            '25°C': 0.16666666666666663,
          },
          'Google-4': {
            'frisch: 0° to 2°C': 0.06666666666666665,
            'fresh: 1° to 4°C': 0.06666666666666665,
            'fresh: 1° to 7°C': 0.06666666666666665,
            'dry: 5° to 25°C': 0.0714285714285714,
            '2°C': 0,
            '4°C': 0,
            '7°C': 0,
            '25°C': 0,
          },
          'Facebook-5': {
            'frisch: 0° to 2°C': 0.1875,
            'fresh: 1° to 4°C': 0.1333333333333333,
            'fresh: 1° to 7°C': 0.1333333333333333,
            'dry: 5° to 25°C': 0.1428571428571429,
            '2°C': 0.11111111111111116,
            '4°C': 0.11111111111111116,
            '7°C': 0.11111111111111116,
            '25°C': 0,
          },
          '20scoopsCNX-6': {
            'frisch: 0° to 2°C': 0.125,
            'fresh: 1° to 4°C': 0.06666666666666665,
            'fresh: 1° to 7°C': 0.06666666666666665,
            'dry: 5° to 25°C': 0,
            '2°C': 0.16666666666666663,
            '4°C': 0.08333333333333337,
            '7°C': 0.08333333333333337,
            '25°C': 0.16666666666666663,
          },
        },
        item4: {
          '20scoopsCNX-0': {
            'frisch: 0° to 2°C': 0.125,
            'fresh: 1° to 4°C': 0.06666666666666665,
            'fresh: 1° to 7°C': 0.06666666666666665,
            'dry: 5° to 25°C': 0,
            '2°C': 0.16666666666666663,
            '4°C': 0.08333333333333337,
            '7°C': 0.08333333333333337,
            '25°C': 0.16666666666666663,
          },
          'Google-1': {
            'frisch: 0° to 2°C': 0.06666666666666665,
            'fresh: 1° to 4°C': 0.06666666666666665,
            'fresh: 1° to 7°C': 0.06666666666666665,
            'dry: 5° to 25°C': 0.0714285714285714,
            '2°C': 0,
            '4°C': 0,
            '7°C': 0,
            '25°C': 0,
          },
          'Facebook-2': {
            'frisch: 0° to 2°C': 0.25,
            'fresh: 1° to 4°C': 0.1333333333333333,
            'fresh: 1° to 7°C': 0.1333333333333333,
            'dry: 5° to 25°C': 0.1428571428571429,
            '2°C': 0.11111111111111116,
            '4°C': 0.11111111111111116,
            '7°C': 0.11111111111111116,
            '25°C': 0,
          },
          '20scoopsCNX-3': {
            'frisch: 0° to 2°C': 0.125,
            'fresh: 1° to 4°C': 0.06666666666666665,
            'fresh: 1° to 7°C': 0.06666666666666665,
            'dry: 5° to 25°C': 0,
            '2°C': 0.16666666666666663,
            '4°C': 0.08333333333333337,
            '7°C': 0.08333333333333337,
            '25°C': 0.16666666666666663,
          },
          'Google-4': {
            'frisch: 0° to 2°C': 0.06666666666666665,
            'fresh: 1° to 4°C': 0.06666666666666665,
            'fresh: 1° to 7°C': 0.06666666666666665,
            'dry: 5° to 25°C': 0.0714285714285714,
            '2°C': 0,
            '4°C': 0,
            '7°C': 0,
            '25°C': 0,
          },
          'Facebook-5': {
            'frisch: 0° to 2°C': 0.1875,
            'fresh: 1° to 4°C': 0.1333333333333333,
            'fresh: 1° to 7°C': 0.1333333333333333,
            'dry: 5° to 25°C': 0.1428571428571429,
            '2°C': 0.11111111111111116,
            '4°C': 0.11111111111111116,
            '7°C': 0.11111111111111116,
            '25°C': 0,
          },
          '20scoopsCNX-6': {
            'frisch: 0° to 2°C': 0.125,
            'fresh: 1° to 4°C': 0.06666666666666665,
            'fresh: 1° to 7°C': 0.06666666666666665,
            'dry: 5° to 25°C': 0,
            '2°C': 0.16666666666666663,
            '4°C': 0.08333333333333337,
            '7°C': 0.08333333333333337,
            '25°C': 0.16666666666666663,
          },
        },
        item5: {
          '20scoopsCNX-0': {
            'frisch: 0° to 2°C': 0.125,
            'fresh: 1° to 4°C': 0.06666666666666665,
            'fresh: 1° to 7°C': 0.06666666666666665,
            'dry: 5° to 25°C': 0,
            '2°C': 0.16666666666666663,
            '4°C': 0.08333333333333337,
            '7°C': 0.08333333333333337,
            '25°C': 0.16666666666666663,
          },
          'Google-1': {
            'frisch: 0° to 2°C': 0.06666666666666665,
            'fresh: 1° to 4°C': 0.06666666666666665,
            'fresh: 1° to 7°C': 0.06666666666666665,
            'dry: 5° to 25°C': 0.0714285714285714,
            '2°C': 0,
            '4°C': 0,
            '7°C': 0,
            '25°C': 0,
          },
          'Facebook-2': {
            'frisch: 0° to 2°C': 0.25,
            'fresh: 1° to 4°C': 0.1333333333333333,
            'fresh: 1° to 7°C': 0.1333333333333333,
            'dry: 5° to 25°C': 0.1428571428571429,
            '2°C': 0.11111111111111116,
            '4°C': 0.11111111111111116,
            '7°C': 0.11111111111111116,
            '25°C': 0,
          },
          '20scoopsCNX-3': {
            'frisch: 0° to 2°C': 0.125,
            'fresh: 1° to 4°C': 0.06666666666666665,
            'fresh: 1° to 7°C': 0.06666666666666665,
            'dry: 5° to 25°C': 0,
            '2°C': 0.16666666666666663,
            '4°C': 0.08333333333333337,
            '7°C': 0.08333333333333337,
            '25°C': 0.16666666666666663,
          },
          'Google-4': {
            'frisch: 0° to 2°C': 0.06666666666666665,
            'fresh: 1° to 4°C': 0.06666666666666665,
            'fresh: 1° to 7°C': 0.06666666666666665,
            'dry: 5° to 25°C': 0.0714285714285714,
            '2°C': 0,
            '4°C': 0,
            '7°C': 0,
            '25°C': 0,
          },
          'Facebook-5': {
            'frisch: 0° to 2°C': 0.1875,
            'fresh: 1° to 4°C': 0.1333333333333333,
            'fresh: 1° to 7°C': 0.1333333333333333,
            'dry: 5° to 25°C': 0.1428571428571429,
            '2°C': 0.11111111111111116,
            '4°C': 0.11111111111111116,
            '7°C': 0.11111111111111116,
            '25°C': 0,
          },
          '20scoopsCNX-6': {
            'frisch: 0° to 2°C': 0.125,
            'fresh: 1° to 4°C': 0.06666666666666665,
            'fresh: 1° to 7°C': 0.06666666666666665,
            'dry: 5° to 25°C': 0,
            '2°C': 0.16666666666666663,
            '4°C': 0.08333333333333337,
            '7°C': 0.08333333333333337,
            '25°C': 0.16666666666666663,
          },
        },
        item21: {
          '20scoopsCNX-0': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0.08333333333333337,
          },
          'Google-1': {
            ml: 0.1428571428571429,
            g: 0.1428571428571429,
            kg: 0.1428571428571429,
            piece: 0.1428571428571429,
          },
          'Facebook-2': {
            ml: 0,
            g: 0,
            kg: 0.11111111111111116,
            piece: 0.11111111111111116,
          },
          '20scoopsCNX-3': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0.08333333333333337,
          },
          'Google-4': {
            ml: 0.1428571428571429,
            g: 0.1428571428571429,
            kg: 0.1428571428571429,
            piece: 0.1428571428571429,
          },
          'Facebook-5': {
            ml: 0,
            g: 0,
            kg: 0.11111111111111116,
            piece: 0.11111111111111116,
          },
          '20scoopsCNX-6': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0.08333333333333337,
          },
        },
        item22: {
          '20scoopsCNX-0': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0.08333333333333337,
          },
          'Google-1': {
            ml: 0.1428571428571429,
            g: 0.1428571428571429,
            kg: 0.1428571428571429,
            piece: 0.1428571428571429,
          },
          'Facebook-2': {
            ml: 0,
            g: 0,
            kg: 0.11111111111111116,
            piece: 0.11111111111111116,
          },
          '20scoopsCNX-3': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0.08333333333333337,
          },
          'Google-4': {
            ml: 0.1428571428571429,
            g: 0.1428571428571429,
            kg: 0.1428571428571429,
            piece: 0.1428571428571429,
          },
          'Facebook-5': {
            ml: 0,
            g: 0,
            kg: 0.11111111111111116,
            piece: 0.11111111111111116,
          },
          '20scoopsCNX-6': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0.08333333333333337,
          },
        },
        item23: {
          '20scoopsCNX-0': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0.08333333333333337,
          },
          'Google-1': {
            ml: 0.1428571428571429,
            g: 0.1428571428571429,
            kg: 0.1428571428571429,
            piece: 0.1428571428571429,
          },
          'Facebook-2': {
            ml: 0,
            g: 0,
            kg: 0.11111111111111116,
            piece: 0.11111111111111116,
          },
          '20scoopsCNX-3': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0.08333333333333337,
          },
          'Google-4': {
            ml: 0.1428571428571429,
            g: 0.1428571428571429,
            kg: 0.1428571428571429,
            piece: 0.1428571428571429,
          },
          'Facebook-5': {
            ml: 0,
            g: 0,
            kg: 0.11111111111111116,
            piece: 0.11111111111111116,
          },
          '20scoopsCNX-6': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0.08333333333333337,
          },
        },
        item24: {
          '20scoopsCNX-0': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0.08333333333333337,
          },
          'Google-1': {
            ml: 0.1428571428571429,
            g: 0.1428571428571429,
            kg: 0.1428571428571429,
            piece: 0.1428571428571429,
          },
          'Facebook-2': {
            ml: 0,
            g: 0,
            kg: 0.11111111111111116,
            piece: 0.11111111111111116,
          },
          '20scoopsCNX-3': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0.08333333333333337,
          },
          'Google-4': {
            ml: 0.1428571428571429,
            g: 0.1428571428571429,
            kg: 0.1428571428571429,
            piece: 0.1428571428571429,
          },
          'Facebook-5': {
            ml: 0,
            g: 0,
            kg: 0.11111111111111116,
            piece: 0.11111111111111116,
          },
          '20scoopsCNX-6': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0.08333333333333337,
          },
        },
        item25: {
          '20scoopsCNX-0': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0.08333333333333337,
          },
          'Google-1': {
            ml: 0.1428571428571429,
            g: 0.1428571428571429,
            kg: 0.1428571428571429,
            piece: 0.1428571428571429,
          },
          'Facebook-2': {
            ml: 0,
            g: 0,
            kg: 0.11111111111111116,
            piece: 0.11111111111111116,
          },
          '20scoopsCNX-3': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0.08333333333333337,
          },
          'Google-4': {
            ml: 0.1428571428571429,
            g: 0.1428571428571429,
            kg: 0.1428571428571429,
            piece: 0.1428571428571429,
          },
          'Facebook-5': {
            ml: 0,
            g: 0,
            kg: 0.11111111111111116,
            piece: 0.11111111111111116,
          },
          '20scoopsCNX-6': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0.08333333333333337,
          },
        },
      },
    },
    {
      label: 'Description',
      suggestions: [
        {
          key: 'description',
          value: 1,
          isSameWord: true,
        },
        {
          key: 'note',
          value: 0.798857,
          isSameWord: false,
        },
      ],
      regexMatching: [],
      choice: {
        deposit_per_unit: {
          'Lorem datum': {
            '0,02': 0,
            '0,03': 0,
            '0,08': 0,
            '0,15': 0,
            '0,25': 0,
          },
          '': {
            '0,02': 0,
            '0,03': 0,
            '0,08': 0,
            '0,15': 0,
            '0,25': 0,
          },
        },
        Product_size_metric: {
          'Lorem datum': {
            ml: 0.09090909090909094,
            g: 0,
            kg: 0,
            piece: 0.09090909090909094,
          },
          '': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0,
          },
        },
        item1: {
          'Lorem datum': {
            'frisch: 0° to 2°C': 0.19999999999999996,
            'fresh: 1° to 4°C': 0.19999999999999996,
            'fresh: 1° to 7°C': 0.19999999999999996,
            'dry: 5° to 25°C': 0.0714285714285714,
            '2°C': 0,
            '4°C': 0,
            '7°C': 0,
            '25°C': 0,
          },
          '': {
            'frisch: 0° to 2°C': 0,
            'fresh: 1° to 4°C': 0,
            'fresh: 1° to 7°C': 0,
            'dry: 5° to 25°C': 0,
            '2°C': 0,
            '4°C': 0,
            '7°C': 0,
            '25°C': 0,
          },
        },
        item2: {
          'Lorem datum': {
            'frisch: 0° to 2°C': 0.19999999999999996,
            'fresh: 1° to 4°C': 0.19999999999999996,
            'fresh: 1° to 7°C': 0.19999999999999996,
            'dry: 5° to 25°C': 0.0714285714285714,
            '2°C': 0,
            '4°C': 0,
            '7°C': 0,
            '25°C': 0,
          },
          '': {
            'frisch: 0° to 2°C': 0,
            'fresh: 1° to 4°C': 0,
            'fresh: 1° to 7°C': 0,
            'dry: 5° to 25°C': 0,
            '2°C': 0,
            '4°C': 0,
            '7°C': 0,
            '25°C': 0,
          },
        },
        item3: {
          'Lorem datum': {
            'frisch: 0° to 2°C': 0.19999999999999996,
            'fresh: 1° to 4°C': 0.19999999999999996,
            'fresh: 1° to 7°C': 0.19999999999999996,
            'dry: 5° to 25°C': 0.0714285714285714,
            '2°C': 0,
            '4°C': 0,
            '7°C': 0,
            '25°C': 0,
          },
          '': {
            'frisch: 0° to 2°C': 0,
            'fresh: 1° to 4°C': 0,
            'fresh: 1° to 7°C': 0,
            'dry: 5° to 25°C': 0,
            '2°C': 0,
            '4°C': 0,
            '7°C': 0,
            '25°C': 0,
          },
        },
        item4: {
          'Lorem datum': {
            'frisch: 0° to 2°C': 0.19999999999999996,
            'fresh: 1° to 4°C': 0.19999999999999996,
            'fresh: 1° to 7°C': 0.19999999999999996,
            'dry: 5° to 25°C': 0.0714285714285714,
            '2°C': 0,
            '4°C': 0,
            '7°C': 0,
            '25°C': 0,
          },
          '': {
            'frisch: 0° to 2°C': 0,
            'fresh: 1° to 4°C': 0,
            'fresh: 1° to 7°C': 0,
            'dry: 5° to 25°C': 0,
            '2°C': 0,
            '4°C': 0,
            '7°C': 0,
            '25°C': 0,
          },
        },
        item5: {
          'Lorem datum': {
            'frisch: 0° to 2°C': 0.19999999999999996,
            'fresh: 1° to 4°C': 0.19999999999999996,
            'fresh: 1° to 7°C': 0.19999999999999996,
            'dry: 5° to 25°C': 0.0714285714285714,
            '2°C': 0,
            '4°C': 0,
            '7°C': 0,
            '25°C': 0,
          },
          '': {
            'frisch: 0° to 2°C': 0,
            'fresh: 1° to 4°C': 0,
            'fresh: 1° to 7°C': 0,
            'dry: 5° to 25°C': 0,
            '2°C': 0,
            '4°C': 0,
            '7°C': 0,
            '25°C': 0,
          },
        },
        item21: {
          'Lorem datum': {
            ml: 0.09090909090909094,
            g: 0,
            kg: 0,
            piece: 0.09090909090909094,
          },
          '': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0,
          },
        },
        item22: {
          'Lorem datum': {
            ml: 0.09090909090909094,
            g: 0,
            kg: 0,
            piece: 0.09090909090909094,
          },
          '': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0,
          },
        },
        item23: {
          'Lorem datum': {
            ml: 0.09090909090909094,
            g: 0,
            kg: 0,
            piece: 0.09090909090909094,
          },
          '': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0,
          },
        },
        item24: {
          'Lorem datum': {
            ml: 0.09090909090909094,
            g: 0,
            kg: 0,
            piece: 0.09090909090909094,
          },
          '': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0,
          },
        },
        item25: {
          'Lorem datum': {
            ml: 0.09090909090909094,
            g: 0,
            kg: 0,
            piece: 0.09090909090909094,
          },
          '': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0,
          },
        },
      },
    },
    {
      label: 'Product size',
      suggestions: [
        {
          key: 'product_size',
          value: 1,
          isSameWord: true,
        },
        {
          key: 'Product_size_metric',
          value: 0.951645,
          isSameWord: false,
        },
        {
          key: 'item1',
          value: 0.826418,
          isSameWord: false,
        },
        {
          key: 'item2',
          value: 0.826418,
          isSameWord: false,
        },
      ],
      regexMatching: [],
      choice: {
        deposit_per_unit: {
          '22': {
            '0,02': 0.33333333333333337,
            '0,03': 0,
            '0,08': 0,
            '0,15': 0,
            '0,25': 0.33333333333333337,
          },
          '33': {
            '0,02': 0,
            '0,03': 0.33333333333333337,
            '0,08': 0,
            '0,15': 0,
            '0,25': 0,
          },
          '44': {
            '0,02': 0,
            '0,03': 0,
            '0,08': 0,
            '0,15': 0,
            '0,25': 0,
          },
          '123': {
            '0,02': 0.25,
            '0,03': 0.33333333333333337,
            '0,08': 0,
            '0,15': 0.25,
            '0,25': 0.33333333333333337,
          },
          '': {
            '0,02': 0,
            '0,03': 0,
            '0,08': 0,
            '0,15': 0,
            '0,25': 0,
          },
        },
        Product_size_metric: {
          '22': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0,
          },
          '33': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0,
          },
          '44': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0,
          },
          '123': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0,
          },
          '': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0,
          },
        },
        item1: {
          '22': {
            'frisch: 0° to 2°C': 0.06666666666666665,
            'fresh: 1° to 4°C': 0,
            'fresh: 1° to 7°C': 0,
            'dry: 5° to 25°C': 0.0714285714285714,
            '2°C': 0.33333333333333337,
            '4°C': 0,
            '7°C': 0,
            '25°C': 0.25,
          },
          '33': {
            'frisch: 0° to 2°C': 0,
            'fresh: 1° to 4°C': 0,
            'fresh: 1° to 7°C': 0,
            'dry: 5° to 25°C': 0,
            '2°C': 0,
            '4°C': 0,
            '7°C': 0,
            '25°C': 0,
          },
          '44': {
            'frisch: 0° to 2°C': 0,
            'fresh: 1° to 4°C': 0.06666666666666665,
            'fresh: 1° to 7°C': 0,
            'dry: 5° to 25°C': 0,
            '2°C': 0,
            '4°C': 0.33333333333333337,
            '7°C': 0,
            '25°C': 0,
          },
          '123': {
            'frisch: 0° to 2°C': 0.06666666666666665,
            'fresh: 1° to 4°C': 0.06666666666666665,
            'fresh: 1° to 7°C': 0.06666666666666665,
            'dry: 5° to 25°C': 0.0714285714285714,
            '2°C': 0,
            '4°C': 0,
            '7°C': 0,
            '25°C': 0,
          },
          '': {
            'frisch: 0° to 2°C': 0,
            'fresh: 1° to 4°C': 0,
            'fresh: 1° to 7°C': 0,
            'dry: 5° to 25°C': 0,
            '2°C': 0,
            '4°C': 0,
            '7°C': 0,
            '25°C': 0,
          },
        },
        item2: {
          '22': {
            'frisch: 0° to 2°C': 0.06666666666666665,
            'fresh: 1° to 4°C': 0,
            'fresh: 1° to 7°C': 0,
            'dry: 5° to 25°C': 0.0714285714285714,
            '2°C': 0.33333333333333337,
            '4°C': 0,
            '7°C': 0,
            '25°C': 0.25,
          },
          '33': {
            'frisch: 0° to 2°C': 0,
            'fresh: 1° to 4°C': 0,
            'fresh: 1° to 7°C': 0,
            'dry: 5° to 25°C': 0,
            '2°C': 0,
            '4°C': 0,
            '7°C': 0,
            '25°C': 0,
          },
          '44': {
            'frisch: 0° to 2°C': 0,
            'fresh: 1° to 4°C': 0.06666666666666665,
            'fresh: 1° to 7°C': 0,
            'dry: 5° to 25°C': 0,
            '2°C': 0,
            '4°C': 0.33333333333333337,
            '7°C': 0,
            '25°C': 0,
          },
          '123': {
            'frisch: 0° to 2°C': 0.06666666666666665,
            'fresh: 1° to 4°C': 0.06666666666666665,
            'fresh: 1° to 7°C': 0.06666666666666665,
            'dry: 5° to 25°C': 0.0714285714285714,
            '2°C': 0,
            '4°C': 0,
            '7°C': 0,
            '25°C': 0,
          },
          '': {
            'frisch: 0° to 2°C': 0,
            'fresh: 1° to 4°C': 0,
            'fresh: 1° to 7°C': 0,
            'dry: 5° to 25°C': 0,
            '2°C': 0,
            '4°C': 0,
            '7°C': 0,
            '25°C': 0,
          },
        },
        item3: {
          '22': {
            'frisch: 0° to 2°C': 0.06666666666666665,
            'fresh: 1° to 4°C': 0,
            'fresh: 1° to 7°C': 0,
            'dry: 5° to 25°C': 0.0714285714285714,
            '2°C': 0.33333333333333337,
            '4°C': 0,
            '7°C': 0,
            '25°C': 0.25,
          },
          '33': {
            'frisch: 0° to 2°C': 0,
            'fresh: 1° to 4°C': 0,
            'fresh: 1° to 7°C': 0,
            'dry: 5° to 25°C': 0,
            '2°C': 0,
            '4°C': 0,
            '7°C': 0,
            '25°C': 0,
          },
          '44': {
            'frisch: 0° to 2°C': 0,
            'fresh: 1° to 4°C': 0.06666666666666665,
            'fresh: 1° to 7°C': 0,
            'dry: 5° to 25°C': 0,
            '2°C': 0,
            '4°C': 0.33333333333333337,
            '7°C': 0,
            '25°C': 0,
          },
          '123': {
            'frisch: 0° to 2°C': 0.06666666666666665,
            'fresh: 1° to 4°C': 0.06666666666666665,
            'fresh: 1° to 7°C': 0.06666666666666665,
            'dry: 5° to 25°C': 0.0714285714285714,
            '2°C': 0,
            '4°C': 0,
            '7°C': 0,
            '25°C': 0,
          },
          '': {
            'frisch: 0° to 2°C': 0,
            'fresh: 1° to 4°C': 0,
            'fresh: 1° to 7°C': 0,
            'dry: 5° to 25°C': 0,
            '2°C': 0,
            '4°C': 0,
            '7°C': 0,
            '25°C': 0,
          },
        },
        item4: {
          '22': {
            'frisch: 0° to 2°C': 0.06666666666666665,
            'fresh: 1° to 4°C': 0,
            'fresh: 1° to 7°C': 0,
            'dry: 5° to 25°C': 0.0714285714285714,
            '2°C': 0.33333333333333337,
            '4°C': 0,
            '7°C': 0,
            '25°C': 0.25,
          },
          '33': {
            'frisch: 0° to 2°C': 0,
            'fresh: 1° to 4°C': 0,
            'fresh: 1° to 7°C': 0,
            'dry: 5° to 25°C': 0,
            '2°C': 0,
            '4°C': 0,
            '7°C': 0,
            '25°C': 0,
          },
          '44': {
            'frisch: 0° to 2°C': 0,
            'fresh: 1° to 4°C': 0.06666666666666665,
            'fresh: 1° to 7°C': 0,
            'dry: 5° to 25°C': 0,
            '2°C': 0,
            '4°C': 0.33333333333333337,
            '7°C': 0,
            '25°C': 0,
          },
          '123': {
            'frisch: 0° to 2°C': 0.06666666666666665,
            'fresh: 1° to 4°C': 0.06666666666666665,
            'fresh: 1° to 7°C': 0.06666666666666665,
            'dry: 5° to 25°C': 0.0714285714285714,
            '2°C': 0,
            '4°C': 0,
            '7°C': 0,
            '25°C': 0,
          },
          '': {
            'frisch: 0° to 2°C': 0,
            'fresh: 1° to 4°C': 0,
            'fresh: 1° to 7°C': 0,
            'dry: 5° to 25°C': 0,
            '2°C': 0,
            '4°C': 0,
            '7°C': 0,
            '25°C': 0,
          },
        },
        item5: {
          '22': {
            'frisch: 0° to 2°C': 0.06666666666666665,
            'fresh: 1° to 4°C': 0,
            'fresh: 1° to 7°C': 0,
            'dry: 5° to 25°C': 0.0714285714285714,
            '2°C': 0.33333333333333337,
            '4°C': 0,
            '7°C': 0,
            '25°C': 0.25,
          },
          '33': {
            'frisch: 0° to 2°C': 0,
            'fresh: 1° to 4°C': 0,
            'fresh: 1° to 7°C': 0,
            'dry: 5° to 25°C': 0,
            '2°C': 0,
            '4°C': 0,
            '7°C': 0,
            '25°C': 0,
          },
          '44': {
            'frisch: 0° to 2°C': 0,
            'fresh: 1° to 4°C': 0.06666666666666665,
            'fresh: 1° to 7°C': 0,
            'dry: 5° to 25°C': 0,
            '2°C': 0,
            '4°C': 0.33333333333333337,
            '7°C': 0,
            '25°C': 0,
          },
          '123': {
            'frisch: 0° to 2°C': 0.06666666666666665,
            'fresh: 1° to 4°C': 0.06666666666666665,
            'fresh: 1° to 7°C': 0.06666666666666665,
            'dry: 5° to 25°C': 0.0714285714285714,
            '2°C': 0,
            '4°C': 0,
            '7°C': 0,
            '25°C': 0,
          },
          '': {
            'frisch: 0° to 2°C': 0,
            'fresh: 1° to 4°C': 0,
            'fresh: 1° to 7°C': 0,
            'dry: 5° to 25°C': 0,
            '2°C': 0,
            '4°C': 0,
            '7°C': 0,
            '25°C': 0,
          },
        },
        item21: {
          '22': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0,
          },
          '33': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0,
          },
          '44': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0,
          },
          '123': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0,
          },
          '': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0,
          },
        },
        item22: {
          '22': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0,
          },
          '33': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0,
          },
          '44': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0,
          },
          '123': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0,
          },
          '': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0,
          },
        },
        item23: {
          '22': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0,
          },
          '33': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0,
          },
          '44': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0,
          },
          '123': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0,
          },
          '': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0,
          },
        },
        item24: {
          '22': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0,
          },
          '33': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0,
          },
          '44': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0,
          },
          '123': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0,
          },
          '': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0,
          },
        },
        item25: {
          '22': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0,
          },
          '33': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0,
          },
          '44': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0,
          },
          '123': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0,
          },
          '': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0,
          },
        },
      },
    },
    {
      label: 'Product size metric',
      suggestions: [
        {
          key: 'Product_size_metric',
          value: 1,
          isSameWord: true,
        },
        {
          key: 'product_size',
          value: 0.951645,
          isSameWord: false,
        },
        {
          key: 'calorific_value',
          value: 0.842019,
          isSameWord: false,
        },
        {
          key: 'item1',
          value: 0.672397,
          isSameWord: false,
        },
      ],
      regexMatching: [],
      choice: {
        deposit_per_unit: {
          mooo: {
            '0,02': 0,
            '0,03': 0,
            '0,08': 0,
            '0,15': 0,
            '0,25': 0,
          },
          '': {
            '0,02': 0,
            '0,03': 0,
            '0,08': 0,
            '0,15': 0,
            '0,25': 0,
          },
        },
        Product_size_metric: {
          mooo: {
            ml: 0.25,
            g: 0,
            kg: 0,
            piece: 0,
          },
          '': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0,
          },
        },
        item1: {
          mooo: {
            'frisch: 0° to 2°C': 0.06666666666666665,
            'fresh: 1° to 4°C': 0.06666666666666665,
            'fresh: 1° to 7°C': 0.06666666666666665,
            'dry: 5° to 25°C': 0.0714285714285714,
            '2°C': 0,
            '4°C': 0,
            '7°C': 0,
            '25°C': 0,
          },
          '': {
            'frisch: 0° to 2°C': 0,
            'fresh: 1° to 4°C': 0,
            'fresh: 1° to 7°C': 0,
            'dry: 5° to 25°C': 0,
            '2°C': 0,
            '4°C': 0,
            '7°C': 0,
            '25°C': 0,
          },
        },
        item2: {
          mooo: {
            'frisch: 0° to 2°C': 0.06666666666666665,
            'fresh: 1° to 4°C': 0.06666666666666665,
            'fresh: 1° to 7°C': 0.06666666666666665,
            'dry: 5° to 25°C': 0.0714285714285714,
            '2°C': 0,
            '4°C': 0,
            '7°C': 0,
            '25°C': 0,
          },
          '': {
            'frisch: 0° to 2°C': 0,
            'fresh: 1° to 4°C': 0,
            'fresh: 1° to 7°C': 0,
            'dry: 5° to 25°C': 0,
            '2°C': 0,
            '4°C': 0,
            '7°C': 0,
            '25°C': 0,
          },
        },
        item3: {
          mooo: {
            'frisch: 0° to 2°C': 0.06666666666666665,
            'fresh: 1° to 4°C': 0.06666666666666665,
            'fresh: 1° to 7°C': 0.06666666666666665,
            'dry: 5° to 25°C': 0.0714285714285714,
            '2°C': 0,
            '4°C': 0,
            '7°C': 0,
            '25°C': 0,
          },
          '': {
            'frisch: 0° to 2°C': 0,
            'fresh: 1° to 4°C': 0,
            'fresh: 1° to 7°C': 0,
            'dry: 5° to 25°C': 0,
            '2°C': 0,
            '4°C': 0,
            '7°C': 0,
            '25°C': 0,
          },
        },
        item4: {
          mooo: {
            'frisch: 0° to 2°C': 0.06666666666666665,
            'fresh: 1° to 4°C': 0.06666666666666665,
            'fresh: 1° to 7°C': 0.06666666666666665,
            'dry: 5° to 25°C': 0.0714285714285714,
            '2°C': 0,
            '4°C': 0,
            '7°C': 0,
            '25°C': 0,
          },
          '': {
            'frisch: 0° to 2°C': 0,
            'fresh: 1° to 4°C': 0,
            'fresh: 1° to 7°C': 0,
            'dry: 5° to 25°C': 0,
            '2°C': 0,
            '4°C': 0,
            '7°C': 0,
            '25°C': 0,
          },
        },
        item5: {
          mooo: {
            'frisch: 0° to 2°C': 0.06666666666666665,
            'fresh: 1° to 4°C': 0.06666666666666665,
            'fresh: 1° to 7°C': 0.06666666666666665,
            'dry: 5° to 25°C': 0.0714285714285714,
            '2°C': 0,
            '4°C': 0,
            '7°C': 0,
            '25°C': 0,
          },
          '': {
            'frisch: 0° to 2°C': 0,
            'fresh: 1° to 4°C': 0,
            'fresh: 1° to 7°C': 0,
            'dry: 5° to 25°C': 0,
            '2°C': 0,
            '4°C': 0,
            '7°C': 0,
            '25°C': 0,
          },
        },
        item21: {
          mooo: {
            ml: 0.25,
            g: 0,
            kg: 0,
            piece: 0,
          },
          '': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0,
          },
        },
        item22: {
          mooo: {
            ml: 0.25,
            g: 0,
            kg: 0,
            piece: 0,
          },
          '': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0,
          },
        },
        item23: {
          mooo: {
            ml: 0.25,
            g: 0,
            kg: 0,
            piece: 0,
          },
          '': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0,
          },
        },
        item24: {
          mooo: {
            ml: 0.25,
            g: 0,
            kg: 0,
            piece: 0,
          },
          '': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0,
          },
        },
        item25: {
          mooo: {
            ml: 0.25,
            g: 0,
            kg: 0,
            piece: 0,
          },
          '': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0,
          },
        },
      },
    },
    {
      label: 'Calorific value (kcal)',
      suggestions: [
        {
          key: 'calorific_value',
          value: 1,
          isSameWord: true,
        },
        {
          key: 'Product_size_metric',
          value: 0.842019,
          isSameWord: false,
        },
        {
          key: 'product_size',
          value: 0.689865,
          isSameWord: false,
        },
        {
          key: 'item1',
          value: 0.261034,
          isSameWord: false,
        },
      ],
      regexMatching: [],
      choice: {
        storage_temperature: {
          '100 kcal': {
            'frisch: 0° to 2°C': 0.1333333333333333,
            'fresh: 1° to 4°C': 0.06666666666666665,
            'fresh: 1° to 7°C': 0.06666666666666665,
            'dry: 5° to 25°C': 0.0714285714285714,
            '2°C': 0.125,
            '4°C': 0.125,
            '7°C': 0.125,
            '25°C': 0.125,
          },
          '200 kcal': {
            'frisch: 0° to 2°C': 0.1333333333333333,
            'fresh: 1° to 4°C': 0.06666666666666665,
            'fresh: 1° to 7°C': 0.06666666666666665,
            'dry: 5° to 25°C': 0.0714285714285714,
            '2°C': 0.25,
            '4°C': 0.125,
            '7°C': 0.125,
            '25°C': 0.25,
          },
          '300 kcal': {
            'frisch: 0° to 2°C': 0.1333333333333333,
            'fresh: 1° to 4°C': 0.06666666666666665,
            'fresh: 1° to 7°C': 0.06666666666666665,
            'dry: 5° to 25°C': 0.0714285714285714,
            '2°C': 0.125,
            '4°C': 0.125,
            '7°C': 0.125,
            '25°C': 0.125,
          },
          '': {
            'frisch: 0° to 2°C': 0,
            'fresh: 1° to 4°C': 0,
            'fresh: 1° to 7°C': 0,
            'dry: 5° to 25°C': 0,
            '2°C': 0,
            '4°C': 0,
            '7°C': 0,
            '25°C': 0,
          },
        },
        deposit_per_unit: {
          '100 kcal': {
            '0,02': 0.25,
            '0,03': 0.25,
            '0,08': 0.25,
            '0,15': 0.125,
            '0,25': 0.125,
          },
          '200 kcal': {
            '0,02': 0.25,
            '0,03': 0.25,
            '0,08': 0.25,
            '0,15': 0.125,
            '0,25': 0.125,
          },
          '300 kcal': {
            '0,02': 0.25,
            '0,03': 0.25,
            '0,08': 0.25,
            '0,15': 0.125,
            '0,25': 0.125,
          },
          '': {
            '0,02': 0,
            '0,03': 0,
            '0,08': 0,
            '0,15': 0,
            '0,25': 0,
          },
        },
        Product_size_metric: {
          '100 kcal': {
            ml: 0.125,
            g: 0,
            kg: 0.125,
            piece: 0.125,
          },
          '200 kcal': {
            ml: 0.125,
            g: 0,
            kg: 0.125,
            piece: 0.125,
          },
          '300 kcal': {
            ml: 0.125,
            g: 0,
            kg: 0.125,
            piece: 0.125,
          },
          '': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0,
          },
        },
        item1: {
          '100 kcal': {
            'frisch: 0° to 2°C': 0.1333333333333333,
            'fresh: 1° to 4°C': 0.06666666666666665,
            'fresh: 1° to 7°C': 0.06666666666666665,
            'dry: 5° to 25°C': 0.0714285714285714,
            '2°C': 0.125,
            '4°C': 0.125,
            '7°C': 0.125,
            '25°C': 0.125,
          },
          '200 kcal': {
            'frisch: 0° to 2°C': 0.1333333333333333,
            'fresh: 1° to 4°C': 0.06666666666666665,
            'fresh: 1° to 7°C': 0.06666666666666665,
            'dry: 5° to 25°C': 0.0714285714285714,
            '2°C': 0.25,
            '4°C': 0.125,
            '7°C': 0.125,
            '25°C': 0.25,
          },
          '300 kcal': {
            'frisch: 0° to 2°C': 0.1333333333333333,
            'fresh: 1° to 4°C': 0.06666666666666665,
            'fresh: 1° to 7°C': 0.06666666666666665,
            'dry: 5° to 25°C': 0.0714285714285714,
            '2°C': 0.125,
            '4°C': 0.125,
            '7°C': 0.125,
            '25°C': 0.125,
          },
          '': {
            'frisch: 0° to 2°C': 0,
            'fresh: 1° to 4°C': 0,
            'fresh: 1° to 7°C': 0,
            'dry: 5° to 25°C': 0,
            '2°C': 0,
            '4°C': 0,
            '7°C': 0,
            '25°C': 0,
          },
        },
        item2: {
          '100 kcal': {
            'frisch: 0° to 2°C': 0.1333333333333333,
            'fresh: 1° to 4°C': 0.06666666666666665,
            'fresh: 1° to 7°C': 0.06666666666666665,
            'dry: 5° to 25°C': 0.0714285714285714,
            '2°C': 0.125,
            '4°C': 0.125,
            '7°C': 0.125,
            '25°C': 0.125,
          },
          '200 kcal': {
            'frisch: 0° to 2°C': 0.1333333333333333,
            'fresh: 1° to 4°C': 0.06666666666666665,
            'fresh: 1° to 7°C': 0.06666666666666665,
            'dry: 5° to 25°C': 0.0714285714285714,
            '2°C': 0.25,
            '4°C': 0.125,
            '7°C': 0.125,
            '25°C': 0.25,
          },
          '300 kcal': {
            'frisch: 0° to 2°C': 0.1333333333333333,
            'fresh: 1° to 4°C': 0.06666666666666665,
            'fresh: 1° to 7°C': 0.06666666666666665,
            'dry: 5° to 25°C': 0.0714285714285714,
            '2°C': 0.125,
            '4°C': 0.125,
            '7°C': 0.125,
            '25°C': 0.125,
          },
          '': {
            'frisch: 0° to 2°C': 0,
            'fresh: 1° to 4°C': 0,
            'fresh: 1° to 7°C': 0,
            'dry: 5° to 25°C': 0,
            '2°C': 0,
            '4°C': 0,
            '7°C': 0,
            '25°C': 0,
          },
        },
        item3: {
          '100 kcal': {
            'frisch: 0° to 2°C': 0.1333333333333333,
            'fresh: 1° to 4°C': 0.06666666666666665,
            'fresh: 1° to 7°C': 0.06666666666666665,
            'dry: 5° to 25°C': 0.0714285714285714,
            '2°C': 0.125,
            '4°C': 0.125,
            '7°C': 0.125,
            '25°C': 0.125,
          },
          '200 kcal': {
            'frisch: 0° to 2°C': 0.1333333333333333,
            'fresh: 1° to 4°C': 0.06666666666666665,
            'fresh: 1° to 7°C': 0.06666666666666665,
            'dry: 5° to 25°C': 0.0714285714285714,
            '2°C': 0.25,
            '4°C': 0.125,
            '7°C': 0.125,
            '25°C': 0.25,
          },
          '300 kcal': {
            'frisch: 0° to 2°C': 0.1333333333333333,
            'fresh: 1° to 4°C': 0.06666666666666665,
            'fresh: 1° to 7°C': 0.06666666666666665,
            'dry: 5° to 25°C': 0.0714285714285714,
            '2°C': 0.125,
            '4°C': 0.125,
            '7°C': 0.125,
            '25°C': 0.125,
          },
          '': {
            'frisch: 0° to 2°C': 0,
            'fresh: 1° to 4°C': 0,
            'fresh: 1° to 7°C': 0,
            'dry: 5° to 25°C': 0,
            '2°C': 0,
            '4°C': 0,
            '7°C': 0,
            '25°C': 0,
          },
        },
        item4: {
          '100 kcal': {
            'frisch: 0° to 2°C': 0.1333333333333333,
            'fresh: 1° to 4°C': 0.06666666666666665,
            'fresh: 1° to 7°C': 0.06666666666666665,
            'dry: 5° to 25°C': 0.0714285714285714,
            '2°C': 0.125,
            '4°C': 0.125,
            '7°C': 0.125,
            '25°C': 0.125,
          },
          '200 kcal': {
            'frisch: 0° to 2°C': 0.1333333333333333,
            'fresh: 1° to 4°C': 0.06666666666666665,
            'fresh: 1° to 7°C': 0.06666666666666665,
            'dry: 5° to 25°C': 0.0714285714285714,
            '2°C': 0.25,
            '4°C': 0.125,
            '7°C': 0.125,
            '25°C': 0.25,
          },
          '300 kcal': {
            'frisch: 0° to 2°C': 0.1333333333333333,
            'fresh: 1° to 4°C': 0.06666666666666665,
            'fresh: 1° to 7°C': 0.06666666666666665,
            'dry: 5° to 25°C': 0.0714285714285714,
            '2°C': 0.125,
            '4°C': 0.125,
            '7°C': 0.125,
            '25°C': 0.125,
          },
          '': {
            'frisch: 0° to 2°C': 0,
            'fresh: 1° to 4°C': 0,
            'fresh: 1° to 7°C': 0,
            'dry: 5° to 25°C': 0,
            '2°C': 0,
            '4°C': 0,
            '7°C': 0,
            '25°C': 0,
          },
        },
        item5: {
          '100 kcal': {
            'frisch: 0° to 2°C': 0.1333333333333333,
            'fresh: 1° to 4°C': 0.06666666666666665,
            'fresh: 1° to 7°C': 0.06666666666666665,
            'dry: 5° to 25°C': 0.0714285714285714,
            '2°C': 0.125,
            '4°C': 0.125,
            '7°C': 0.125,
            '25°C': 0.125,
          },
          '200 kcal': {
            'frisch: 0° to 2°C': 0.1333333333333333,
            'fresh: 1° to 4°C': 0.06666666666666665,
            'fresh: 1° to 7°C': 0.06666666666666665,
            'dry: 5° to 25°C': 0.0714285714285714,
            '2°C': 0.25,
            '4°C': 0.125,
            '7°C': 0.125,
            '25°C': 0.25,
          },
          '300 kcal': {
            'frisch: 0° to 2°C': 0.1333333333333333,
            'fresh: 1° to 4°C': 0.06666666666666665,
            'fresh: 1° to 7°C': 0.06666666666666665,
            'dry: 5° to 25°C': 0.0714285714285714,
            '2°C': 0.125,
            '4°C': 0.125,
            '7°C': 0.125,
            '25°C': 0.125,
          },
          '': {
            'frisch: 0° to 2°C': 0,
            'fresh: 1° to 4°C': 0,
            'fresh: 1° to 7°C': 0,
            'dry: 5° to 25°C': 0,
            '2°C': 0,
            '4°C': 0,
            '7°C': 0,
            '25°C': 0,
          },
        },
        item21: {
          '100 kcal': {
            ml: 0.125,
            g: 0,
            kg: 0.125,
            piece: 0.125,
          },
          '200 kcal': {
            ml: 0.125,
            g: 0,
            kg: 0.125,
            piece: 0.125,
          },
          '300 kcal': {
            ml: 0.125,
            g: 0,
            kg: 0.125,
            piece: 0.125,
          },
          '': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0,
          },
        },
        item22: {
          '100 kcal': {
            ml: 0.125,
            g: 0,
            kg: 0.125,
            piece: 0.125,
          },
          '200 kcal': {
            ml: 0.125,
            g: 0,
            kg: 0.125,
            piece: 0.125,
          },
          '300 kcal': {
            ml: 0.125,
            g: 0,
            kg: 0.125,
            piece: 0.125,
          },
          '': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0,
          },
        },
        item23: {
          '100 kcal': {
            ml: 0.125,
            g: 0,
            kg: 0.125,
            piece: 0.125,
          },
          '200 kcal': {
            ml: 0.125,
            g: 0,
            kg: 0.125,
            piece: 0.125,
          },
          '300 kcal': {
            ml: 0.125,
            g: 0,
            kg: 0.125,
            piece: 0.125,
          },
          '': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0,
          },
        },
        item24: {
          '100 kcal': {
            ml: 0.125,
            g: 0,
            kg: 0.125,
            piece: 0.125,
          },
          '200 kcal': {
            ml: 0.125,
            g: 0,
            kg: 0.125,
            piece: 0.125,
          },
          '300 kcal': {
            ml: 0.125,
            g: 0,
            kg: 0.125,
            piece: 0.125,
          },
          '': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0,
          },
        },
        item25: {
          '100 kcal': {
            ml: 0.125,
            g: 0,
            kg: 0.125,
            piece: 0.125,
          },
          '200 kcal': {
            ml: 0.125,
            g: 0,
            kg: 0.125,
            piece: 0.125,
          },
          '300 kcal': {
            ml: 0.125,
            g: 0,
            kg: 0.125,
            piece: 0.125,
          },
          '': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0,
          },
        },
      },
    },
    {
      label: 'Deposit of the outer packaging unit',
      suggestions: [
        {
          key: 'deposit',
          value: 1,
          isSameWord: true,
        },
      ],
      regexMatching: [],
      choice: {
        storage_temperature: {
          '': {
            'frisch: 0° to 2°C': 0,
            'fresh: 1° to 4°C': 0,
            'fresh: 1° to 7°C': 0,
            'dry: 5° to 25°C': 0,
            '2°C': 0,
            '4°C': 0,
            '7°C': 0,
            '25°C': 0,
          },
        },
        deposit_per_unit: {
          '': {
            '0,02': 0,
            '0,03': 0,
            '0,08': 0,
            '0,15': 0,
            '0,25': 0,
          },
        },
        Product_size_metric: {
          '': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0,
          },
        },
        item1: {
          '': {
            'frisch: 0° to 2°C': 0,
            'fresh: 1° to 4°C': 0,
            'fresh: 1° to 7°C': 0,
            'dry: 5° to 25°C': 0,
            '2°C': 0,
            '4°C': 0,
            '7°C': 0,
            '25°C': 0,
          },
        },
        item2: {
          '': {
            'frisch: 0° to 2°C': 0,
            'fresh: 1° to 4°C': 0,
            'fresh: 1° to 7°C': 0,
            'dry: 5° to 25°C': 0,
            '2°C': 0,
            '4°C': 0,
            '7°C': 0,
            '25°C': 0,
          },
        },
        item3: {
          '': {
            'frisch: 0° to 2°C': 0,
            'fresh: 1° to 4°C': 0,
            'fresh: 1° to 7°C': 0,
            'dry: 5° to 25°C': 0,
            '2°C': 0,
            '4°C': 0,
            '7°C': 0,
            '25°C': 0,
          },
        },
        item4: {
          '': {
            'frisch: 0° to 2°C': 0,
            'fresh: 1° to 4°C': 0,
            'fresh: 1° to 7°C': 0,
            'dry: 5° to 25°C': 0,
            '2°C': 0,
            '4°C': 0,
            '7°C': 0,
            '25°C': 0,
          },
        },
        item5: {
          '': {
            'frisch: 0° to 2°C': 0,
            'fresh: 1° to 4°C': 0,
            'fresh: 1° to 7°C': 0,
            'dry: 5° to 25°C': 0,
            '2°C': 0,
            '4°C': 0,
            '7°C': 0,
            '25°C': 0,
          },
        },
        item21: {
          '': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0,
          },
        },
        item22: {
          '': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0,
          },
        },
        item23: {
          '': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0,
          },
        },
        item24: {
          '': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0,
          },
        },
        item25: {
          '': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0,
          },
        },
      },
    },
    {
      label: 'Number of dispatch stage 1',
      suggestions: [
        {
          key: 'dispatch',
          value: 1,
          isSameWord: true,
        },
      ],
      regexMatching: [],
      choice: {
        deposit_per_unit: {
          '2': {
            '0,02': 0.33333333333333337,
            '0,03': 0,
            '0,08': 0,
            '0,15': 0,
            '0,25': 0.33333333333333337,
          },
          '4': {
            '0,02': 0,
            '0,03': 0,
            '0,08': 0,
            '0,15': 0,
            '0,25': 0,
          },
          '5': {
            '0,02': 0,
            '0,03': 0,
            '0,08': 0,
            '0,15': 0.33333333333333337,
            '0,25': 0.33333333333333337,
          },
          '': {
            '0,02': 0,
            '0,03': 0,
            '0,08': 0,
            '0,15': 0,
            '0,25': 0,
          },
        },
        Product_size_metric: {
          '2': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0,
          },
          '4': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0,
          },
          '5': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0,
          },
          '': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0,
          },
        },
        item1: {
          '2': {
            'frisch: 0° to 2°C': 0.06666666666666665,
            'fresh: 1° to 4°C': 0,
            'fresh: 1° to 7°C': 0,
            'dry: 5° to 25°C': 0.0714285714285714,
            '2°C': 0.33333333333333337,
            '4°C': 0,
            '7°C': 0,
            '25°C': 0.25,
          },
          '4': {
            'frisch: 0° to 2°C': 0,
            'fresh: 1° to 4°C': 0.06666666666666665,
            'fresh: 1° to 7°C': 0,
            'dry: 5° to 25°C': 0,
            '2°C': 0,
            '4°C': 0.33333333333333337,
            '7°C': 0,
            '25°C': 0,
          },
          '5': {
            'frisch: 0° to 2°C': 0,
            'fresh: 1° to 4°C': 0,
            'fresh: 1° to 7°C': 0,
            'dry: 5° to 25°C': 0.0714285714285714,
            '2°C': 0,
            '4°C': 0,
            '7°C': 0,
            '25°C': 0.25,
          },
          '': {
            'frisch: 0° to 2°C': 0,
            'fresh: 1° to 4°C': 0,
            'fresh: 1° to 7°C': 0,
            'dry: 5° to 25°C': 0,
            '2°C': 0,
            '4°C': 0,
            '7°C': 0,
            '25°C': 0,
          },
        },
        item2: {
          '2': {
            'frisch: 0° to 2°C': 0.06666666666666665,
            'fresh: 1° to 4°C': 0,
            'fresh: 1° to 7°C': 0,
            'dry: 5° to 25°C': 0.0714285714285714,
            '2°C': 0.33333333333333337,
            '4°C': 0,
            '7°C': 0,
            '25°C': 0.25,
          },
          '4': {
            'frisch: 0° to 2°C': 0,
            'fresh: 1° to 4°C': 0.06666666666666665,
            'fresh: 1° to 7°C': 0,
            'dry: 5° to 25°C': 0,
            '2°C': 0,
            '4°C': 0.33333333333333337,
            '7°C': 0,
            '25°C': 0,
          },
          '5': {
            'frisch: 0° to 2°C': 0,
            'fresh: 1° to 4°C': 0,
            'fresh: 1° to 7°C': 0,
            'dry: 5° to 25°C': 0.0714285714285714,
            '2°C': 0,
            '4°C': 0,
            '7°C': 0,
            '25°C': 0.25,
          },
          '': {
            'frisch: 0° to 2°C': 0,
            'fresh: 1° to 4°C': 0,
            'fresh: 1° to 7°C': 0,
            'dry: 5° to 25°C': 0,
            '2°C': 0,
            '4°C': 0,
            '7°C': 0,
            '25°C': 0,
          },
        },
        item3: {
          '2': {
            'frisch: 0° to 2°C': 0.06666666666666665,
            'fresh: 1° to 4°C': 0,
            'fresh: 1° to 7°C': 0,
            'dry: 5° to 25°C': 0.0714285714285714,
            '2°C': 0.33333333333333337,
            '4°C': 0,
            '7°C': 0,
            '25°C': 0.25,
          },
          '4': {
            'frisch: 0° to 2°C': 0,
            'fresh: 1° to 4°C': 0.06666666666666665,
            'fresh: 1° to 7°C': 0,
            'dry: 5° to 25°C': 0,
            '2°C': 0,
            '4°C': 0.33333333333333337,
            '7°C': 0,
            '25°C': 0,
          },
          '5': {
            'frisch: 0° to 2°C': 0,
            'fresh: 1° to 4°C': 0,
            'fresh: 1° to 7°C': 0,
            'dry: 5° to 25°C': 0.0714285714285714,
            '2°C': 0,
            '4°C': 0,
            '7°C': 0,
            '25°C': 0.25,
          },
          '': {
            'frisch: 0° to 2°C': 0,
            'fresh: 1° to 4°C': 0,
            'fresh: 1° to 7°C': 0,
            'dry: 5° to 25°C': 0,
            '2°C': 0,
            '4°C': 0,
            '7°C': 0,
            '25°C': 0,
          },
        },
        item4: {
          '2': {
            'frisch: 0° to 2°C': 0.06666666666666665,
            'fresh: 1° to 4°C': 0,
            'fresh: 1° to 7°C': 0,
            'dry: 5° to 25°C': 0.0714285714285714,
            '2°C': 0.33333333333333337,
            '4°C': 0,
            '7°C': 0,
            '25°C': 0.25,
          },
          '4': {
            'frisch: 0° to 2°C': 0,
            'fresh: 1° to 4°C': 0.06666666666666665,
            'fresh: 1° to 7°C': 0,
            'dry: 5° to 25°C': 0,
            '2°C': 0,
            '4°C': 0.33333333333333337,
            '7°C': 0,
            '25°C': 0,
          },
          '5': {
            'frisch: 0° to 2°C': 0,
            'fresh: 1° to 4°C': 0,
            'fresh: 1° to 7°C': 0,
            'dry: 5° to 25°C': 0.0714285714285714,
            '2°C': 0,
            '4°C': 0,
            '7°C': 0,
            '25°C': 0.25,
          },
          '': {
            'frisch: 0° to 2°C': 0,
            'fresh: 1° to 4°C': 0,
            'fresh: 1° to 7°C': 0,
            'dry: 5° to 25°C': 0,
            '2°C': 0,
            '4°C': 0,
            '7°C': 0,
            '25°C': 0,
          },
        },
        item5: {
          '2': {
            'frisch: 0° to 2°C': 0.06666666666666665,
            'fresh: 1° to 4°C': 0,
            'fresh: 1° to 7°C': 0,
            'dry: 5° to 25°C': 0.0714285714285714,
            '2°C': 0.33333333333333337,
            '4°C': 0,
            '7°C': 0,
            '25°C': 0.25,
          },
          '4': {
            'frisch: 0° to 2°C': 0,
            'fresh: 1° to 4°C': 0.06666666666666665,
            'fresh: 1° to 7°C': 0,
            'dry: 5° to 25°C': 0,
            '2°C': 0,
            '4°C': 0.33333333333333337,
            '7°C': 0,
            '25°C': 0,
          },
          '5': {
            'frisch: 0° to 2°C': 0,
            'fresh: 1° to 4°C': 0,
            'fresh: 1° to 7°C': 0,
            'dry: 5° to 25°C': 0.0714285714285714,
            '2°C': 0,
            '4°C': 0,
            '7°C': 0,
            '25°C': 0.25,
          },
          '': {
            'frisch: 0° to 2°C': 0,
            'fresh: 1° to 4°C': 0,
            'fresh: 1° to 7°C': 0,
            'dry: 5° to 25°C': 0,
            '2°C': 0,
            '4°C': 0,
            '7°C': 0,
            '25°C': 0,
          },
        },
        item21: {
          '2': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0,
          },
          '4': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0,
          },
          '5': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0,
          },
          '': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0,
          },
        },
        item22: {
          '2': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0,
          },
          '4': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0,
          },
          '5': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0,
          },
          '': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0,
          },
        },
        item23: {
          '2': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0,
          },
          '4': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0,
          },
          '5': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0,
          },
          '': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0,
          },
        },
        item24: {
          '2': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0,
          },
          '4': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0,
          },
          '5': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0,
          },
          '': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0,
          },
        },
        item25: {
          '2': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0,
          },
          '4': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0,
          },
          '5': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0,
          },
          '': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0,
          },
        },
      },
    },
    {
      label: 'Price in US',
      suggestions: [
        {
          key: 'price_in_us',
          value: 1,
          isSameWord: true,
        },
        {
          key: 'deposit_per_unit',
          value: 0.610611,
          isSameWord: false,
        },
      ],
      regexMatching: [],
      choice: {
        storage_temperature: {
          '1': {
            'frisch: 0° to 2°C': 0,
            'fresh: 1° to 4°C': 0.06666666666666665,
            'fresh: 1° to 7°C': 0.06666666666666665,
            'dry: 5° to 25°C': 0,
            '2°C': 0,
            '4°C': 0,
            '7°C': 0,
            '25°C': 0,
          },
          '': {
            'frisch: 0° to 2°C': 0,
            'fresh: 1° to 4°C': 0,
            'fresh: 1° to 7°C': 0,
            'dry: 5° to 25°C': 0,
            '2°C': 0,
            '4°C': 0,
            '7°C': 0,
            '25°C': 0,
          },
        },
        deposit_per_unit: {
          '1': {
            '0,02': 0,
            '0,03': 0,
            '0,08': 0,
            '0,15': 0.33333333333333337,
            '0,25': 0,
          },
          '': {
            '0,02': 0,
            '0,03': 0,
            '0,08': 0,
            '0,15': 0,
            '0,25': 0,
          },
        },
        Product_size_metric: {
          '1': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0,
          },
          '': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0,
          },
        },
        item1: {
          '1': {
            'frisch: 0° to 2°C': 0,
            'fresh: 1° to 4°C': 0.06666666666666665,
            'fresh: 1° to 7°C': 0.06666666666666665,
            'dry: 5° to 25°C': 0,
            '2°C': 0,
            '4°C': 0,
            '7°C': 0,
            '25°C': 0,
          },
          '': {
            'frisch: 0° to 2°C': 0,
            'fresh: 1° to 4°C': 0,
            'fresh: 1° to 7°C': 0,
            'dry: 5° to 25°C': 0,
            '2°C': 0,
            '4°C': 0,
            '7°C': 0,
            '25°C': 0,
          },
        },
        item2: {
          '1': {
            'frisch: 0° to 2°C': 0,
            'fresh: 1° to 4°C': 0.06666666666666665,
            'fresh: 1° to 7°C': 0.06666666666666665,
            'dry: 5° to 25°C': 0,
            '2°C': 0,
            '4°C': 0,
            '7°C': 0,
            '25°C': 0,
          },
          '': {
            'frisch: 0° to 2°C': 0,
            'fresh: 1° to 4°C': 0,
            'fresh: 1° to 7°C': 0,
            'dry: 5° to 25°C': 0,
            '2°C': 0,
            '4°C': 0,
            '7°C': 0,
            '25°C': 0,
          },
        },
        item3: {
          '1': {
            'frisch: 0° to 2°C': 0,
            'fresh: 1° to 4°C': 0.06666666666666665,
            'fresh: 1° to 7°C': 0.06666666666666665,
            'dry: 5° to 25°C': 0,
            '2°C': 0,
            '4°C': 0,
            '7°C': 0,
            '25°C': 0,
          },
          '': {
            'frisch: 0° to 2°C': 0,
            'fresh: 1° to 4°C': 0,
            'fresh: 1° to 7°C': 0,
            'dry: 5° to 25°C': 0,
            '2°C': 0,
            '4°C': 0,
            '7°C': 0,
            '25°C': 0,
          },
        },
        item4: {
          '1': {
            'frisch: 0° to 2°C': 0,
            'fresh: 1° to 4°C': 0.06666666666666665,
            'fresh: 1° to 7°C': 0.06666666666666665,
            'dry: 5° to 25°C': 0,
            '2°C': 0,
            '4°C': 0,
            '7°C': 0,
            '25°C': 0,
          },
          '': {
            'frisch: 0° to 2°C': 0,
            'fresh: 1° to 4°C': 0,
            'fresh: 1° to 7°C': 0,
            'dry: 5° to 25°C': 0,
            '2°C': 0,
            '4°C': 0,
            '7°C': 0,
            '25°C': 0,
          },
        },
        item5: {
          '1': {
            'frisch: 0° to 2°C': 0,
            'fresh: 1° to 4°C': 0.06666666666666665,
            'fresh: 1° to 7°C': 0.06666666666666665,
            'dry: 5° to 25°C': 0,
            '2°C': 0,
            '4°C': 0,
            '7°C': 0,
            '25°C': 0,
          },
          '': {
            'frisch: 0° to 2°C': 0,
            'fresh: 1° to 4°C': 0,
            'fresh: 1° to 7°C': 0,
            'dry: 5° to 25°C': 0,
            '2°C': 0,
            '4°C': 0,
            '7°C': 0,
            '25°C': 0,
          },
        },
        item21: {
          '1': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0,
          },
          '': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0,
          },
        },
        item22: {
          '1': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0,
          },
          '': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0,
          },
        },
        item23: {
          '1': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0,
          },
          '': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0,
          },
        },
        item24: {
          '1': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0,
          },
          '': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0,
          },
        },
        item25: {
          '1': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0,
          },
          '': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0,
          },
        },
        deposit: {
          '1': {
            true: 1,
          },
        },
      },
    },
    {
      label: 'Shipping location',
      suggestions: [
        {
          key: 'shipping_location',
          value: 1,
          isSameWord: true,
        },
      ],
      regexMatching: [],
      choice: {
        storage_temperature: {
          USA: {
            'frisch: 0° to 2°C': 0.06666666666666665,
            'fresh: 1° to 4°C': 0.06666666666666665,
            'fresh: 1° to 7°C': 0.06666666666666665,
            'dry: 5° to 25°C': 0,
            '2°C': 0,
            '4°C': 0,
            '7°C': 0,
            '25°C': 0,
          },
          GERMAN: {
            'frisch: 0° to 2°C': 0.06666666666666665,
            'fresh: 1° to 4°C': 0.06666666666666665,
            'fresh: 1° to 7°C': 0.06666666666666665,
            'dry: 5° to 25°C': 0,
            '2°C': 0,
            '4°C': 0,
            '7°C': 0,
            '25°C': 0,
          },
          Thailand: {
            'frisch: 0° to 2°C': 0.06666666666666665,
            'fresh: 1° to 4°C': 0.06666666666666665,
            'fresh: 1° to 7°C': 0.06666666666666665,
            'dry: 5° to 25°C': 0,
            '2°C': 0,
            '4°C': 0,
            '7°C': 0,
            '25°C': 0,
          },
          '': {
            'frisch: 0° to 2°C': 0,
            'fresh: 1° to 4°C': 0,
            'fresh: 1° to 7°C': 0,
            'dry: 5° to 25°C': 0,
            '2°C': 0,
            '4°C': 0,
            '7°C': 0,
            '25°C': 0,
          },
        },
        deposit_per_unit: {
          USA: {
            '0,02': 0,
            '0,03': 0,
            '0,08': 0,
            '0,15': 0,
            '0,25': 0,
          },
          GERMAN: {
            '0,02': 0,
            '0,03': 0,
            '0,08': 0,
            '0,15': 0,
            '0,25': 0,
          },
          Thailand: {
            '0,02': 0,
            '0,03': 0,
            '0,08': 0,
            '0,15': 0,
            '0,25': 0,
          },
          '': {
            '0,02': 0,
            '0,03': 0,
            '0,08': 0,
            '0,15': 0,
            '0,25': 0,
          },
        },
        item1: {
          USA: {
            'frisch: 0° to 2°C': 0.06666666666666665,
            'fresh: 1° to 4°C': 0.06666666666666665,
            'fresh: 1° to 7°C': 0.06666666666666665,
            'dry: 5° to 25°C': 0,
            '2°C': 0,
            '4°C': 0,
            '7°C': 0,
            '25°C': 0,
          },
          GERMAN: {
            'frisch: 0° to 2°C': 0.06666666666666665,
            'fresh: 1° to 4°C': 0.06666666666666665,
            'fresh: 1° to 7°C': 0.06666666666666665,
            'dry: 5° to 25°C': 0,
            '2°C': 0,
            '4°C': 0,
            '7°C': 0,
            '25°C': 0,
          },
          Thailand: {
            'frisch: 0° to 2°C': 0.06666666666666665,
            'fresh: 1° to 4°C': 0.06666666666666665,
            'fresh: 1° to 7°C': 0.06666666666666665,
            'dry: 5° to 25°C': 0,
            '2°C': 0,
            '4°C': 0,
            '7°C': 0,
            '25°C': 0,
          },
          '': {
            'frisch: 0° to 2°C': 0,
            'fresh: 1° to 4°C': 0,
            'fresh: 1° to 7°C': 0,
            'dry: 5° to 25°C': 0,
            '2°C': 0,
            '4°C': 0,
            '7°C': 0,
            '25°C': 0,
          },
        },
        item2: {
          USA: {
            'frisch: 0° to 2°C': 0.06666666666666665,
            'fresh: 1° to 4°C': 0.06666666666666665,
            'fresh: 1° to 7°C': 0.06666666666666665,
            'dry: 5° to 25°C': 0,
            '2°C': 0,
            '4°C': 0,
            '7°C': 0,
            '25°C': 0,
          },
          GERMAN: {
            'frisch: 0° to 2°C': 0.06666666666666665,
            'fresh: 1° to 4°C': 0.06666666666666665,
            'fresh: 1° to 7°C': 0.06666666666666665,
            'dry: 5° to 25°C': 0,
            '2°C': 0,
            '4°C': 0,
            '7°C': 0,
            '25°C': 0,
          },
          Thailand: {
            'frisch: 0° to 2°C': 0.06666666666666665,
            'fresh: 1° to 4°C': 0.06666666666666665,
            'fresh: 1° to 7°C': 0.06666666666666665,
            'dry: 5° to 25°C': 0,
            '2°C': 0,
            '4°C': 0,
            '7°C': 0,
            '25°C': 0,
          },
          '': {
            'frisch: 0° to 2°C': 0,
            'fresh: 1° to 4°C': 0,
            'fresh: 1° to 7°C': 0,
            'dry: 5° to 25°C': 0,
            '2°C': 0,
            '4°C': 0,
            '7°C': 0,
            '25°C': 0,
          },
        },
        item3: {
          USA: {
            'frisch: 0° to 2°C': 0.06666666666666665,
            'fresh: 1° to 4°C': 0.06666666666666665,
            'fresh: 1° to 7°C': 0.06666666666666665,
            'dry: 5° to 25°C': 0,
            '2°C': 0,
            '4°C': 0,
            '7°C': 0,
            '25°C': 0,
          },
          GERMAN: {
            'frisch: 0° to 2°C': 0.06666666666666665,
            'fresh: 1° to 4°C': 0.06666666666666665,
            'fresh: 1° to 7°C': 0.06666666666666665,
            'dry: 5° to 25°C': 0,
            '2°C': 0,
            '4°C': 0,
            '7°C': 0,
            '25°C': 0,
          },
          Thailand: {
            'frisch: 0° to 2°C': 0.06666666666666665,
            'fresh: 1° to 4°C': 0.06666666666666665,
            'fresh: 1° to 7°C': 0.06666666666666665,
            'dry: 5° to 25°C': 0,
            '2°C': 0,
            '4°C': 0,
            '7°C': 0,
            '25°C': 0,
          },
          '': {
            'frisch: 0° to 2°C': 0,
            'fresh: 1° to 4°C': 0,
            'fresh: 1° to 7°C': 0,
            'dry: 5° to 25°C': 0,
            '2°C': 0,
            '4°C': 0,
            '7°C': 0,
            '25°C': 0,
          },
        },
        item4: {
          USA: {
            'frisch: 0° to 2°C': 0.06666666666666665,
            'fresh: 1° to 4°C': 0.06666666666666665,
            'fresh: 1° to 7°C': 0.06666666666666665,
            'dry: 5° to 25°C': 0,
            '2°C': 0,
            '4°C': 0,
            '7°C': 0,
            '25°C': 0,
          },
          GERMAN: {
            'frisch: 0° to 2°C': 0.06666666666666665,
            'fresh: 1° to 4°C': 0.06666666666666665,
            'fresh: 1° to 7°C': 0.06666666666666665,
            'dry: 5° to 25°C': 0,
            '2°C': 0,
            '4°C': 0,
            '7°C': 0,
            '25°C': 0,
          },
          Thailand: {
            'frisch: 0° to 2°C': 0.06666666666666665,
            'fresh: 1° to 4°C': 0.06666666666666665,
            'fresh: 1° to 7°C': 0.06666666666666665,
            'dry: 5° to 25°C': 0,
            '2°C': 0,
            '4°C': 0,
            '7°C': 0,
            '25°C': 0,
          },
          '': {
            'frisch: 0° to 2°C': 0,
            'fresh: 1° to 4°C': 0,
            'fresh: 1° to 7°C': 0,
            'dry: 5° to 25°C': 0,
            '2°C': 0,
            '4°C': 0,
            '7°C': 0,
            '25°C': 0,
          },
        },
        item5: {
          USA: {
            'frisch: 0° to 2°C': 0.06666666666666665,
            'fresh: 1° to 4°C': 0.06666666666666665,
            'fresh: 1° to 7°C': 0.06666666666666665,
            'dry: 5° to 25°C': 0,
            '2°C': 0,
            '4°C': 0,
            '7°C': 0,
            '25°C': 0,
          },
          GERMAN: {
            'frisch: 0° to 2°C': 0.06666666666666665,
            'fresh: 1° to 4°C': 0.06666666666666665,
            'fresh: 1° to 7°C': 0.06666666666666665,
            'dry: 5° to 25°C': 0,
            '2°C': 0,
            '4°C': 0,
            '7°C': 0,
            '25°C': 0,
          },
          Thailand: {
            'frisch: 0° to 2°C': 0.06666666666666665,
            'fresh: 1° to 4°C': 0.06666666666666665,
            'fresh: 1° to 7°C': 0.06666666666666665,
            'dry: 5° to 25°C': 0,
            '2°C': 0,
            '4°C': 0,
            '7°C': 0,
            '25°C': 0,
          },
          '': {
            'frisch: 0° to 2°C': 0,
            'fresh: 1° to 4°C': 0,
            'fresh: 1° to 7°C': 0,
            'dry: 5° to 25°C': 0,
            '2°C': 0,
            '4°C': 0,
            '7°C': 0,
            '25°C': 0,
          },
        },
        item21: {
          USA: {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0,
          },
          GERMAN: {
            ml: 0.16666666666666663,
            g: 0.16666666666666663,
            kg: 0,
            piece: 0,
          },
          Thailand: {
            ml: 0.125,
            g: 0,
            kg: 0,
            piece: 0.125,
          },
          '': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0,
          },
        },
        item22: {
          USA: {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0,
          },
          GERMAN: {
            ml: 0.16666666666666663,
            g: 0.16666666666666663,
            kg: 0,
            piece: 0,
          },
          Thailand: {
            ml: 0.125,
            g: 0,
            kg: 0,
            piece: 0.125,
          },
          '': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0,
          },
        },
        item23: {
          USA: {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0,
          },
          GERMAN: {
            ml: 0.16666666666666663,
            g: 0.16666666666666663,
            kg: 0,
            piece: 0,
          },
          Thailand: {
            ml: 0.125,
            g: 0,
            kg: 0,
            piece: 0.125,
          },
          '': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0,
          },
        },
        item24: {
          USA: {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0,
          },
          GERMAN: {
            ml: 0.16666666666666663,
            g: 0.16666666666666663,
            kg: 0,
            piece: 0,
          },
          Thailand: {
            ml: 0.125,
            g: 0,
            kg: 0,
            piece: 0.125,
          },
          '': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0,
          },
        },
        item25: {
          USA: {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0,
          },
          GERMAN: {
            ml: 0.16666666666666663,
            g: 0.16666666666666663,
            kg: 0,
            piece: 0,
          },
          Thailand: {
            ml: 0.125,
            g: 0,
            kg: 0,
            piece: 0.125,
          },
          '': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0,
          },
        },
      },
    },
    {
      label: 'Deposit per unit',
      suggestions: [
        {
          key: 'deposit_per_unit',
          value: 1,
          isSameWord: true,
        },
        {
          key: 'price_in_us',
          value: 0.610611,
          isSameWord: false,
        },
      ],
      regexMatching: [],
      choice: {
        storage_temperature: {
          '0,15': {
            'frisch: 0° to 2°C': 0.06666666666666665,
            'fresh: 1° to 4°C': 0.06666666666666665,
            'fresh: 1° to 7°C': 0.06666666666666665,
            'dry: 5° to 25°C': 0.0714285714285714,
            '2°C': 0,
            '4°C': 0,
            '7°C': 0,
            '25°C': 0,
          },
          '0,03': {
            'frisch: 0° to 2°C': 0.06666666666666665,
            'fresh: 1° to 4°C': 0,
            'fresh: 1° to 7°C': 0,
            'dry: 5° to 25°C': 0,
            '2°C': 0,
            '4°C': 0,
            '7°C': 0,
            '25°C': 0,
          },
          '0,25': {
            'frisch: 0° to 2°C': 0.1333333333333333,
            'fresh: 1° to 4°C': 0,
            'fresh: 1° to 7°C': 0,
            'dry: 5° to 25°C': 0.1428571428571429,
            '2°C': 0,
            '4°C': 0,
            '7°C': 0,
            '25°C': 0.25,
          },
          '': {
            'frisch: 0° to 2°C': 0,
            'fresh: 1° to 4°C': 0,
            'fresh: 1° to 7°C': 0,
            'dry: 5° to 25°C': 0,
            '2°C': 0,
            '4°C': 0,
            '7°C': 0,
            '25°C': 0,
          },
        },
        deposit_per_unit: {
          '0,15': {
            '0,02': 0.33333333333333337,
            '0,03': 0.33333333333333337,
            '0,08': 0.33333333333333337,
            '0,15': 1,
            '0,25': 0.6666666666666667,
          },
          '0,03': {
            '0,02': 0.6666666666666667,
            '0,03': 1,
            '0,08': 0.6666666666666667,
            '0,15': 0.33333333333333337,
            '0,25': 0.33333333333333337,
          },
          '0,25': {
            '0,02': 0.5,
            '0,03': 0.33333333333333337,
            '0,08': 0.33333333333333337,
            '0,15': 0.6666666666666667,
            '0,25': 1,
          },
          '': {
            '0,02': 0,
            '0,03': 0,
            '0,08': 0,
            '0,15': 0,
            '0,25': 0,
          },
        },
        Product_size_metric: {
          '0,15': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0,
          },
          '0,03': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0,
          },
          '0,25': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0,
          },
          '': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0,
          },
        },
        item1: {
          '0,15': {
            'frisch: 0° to 2°C': 0.06666666666666665,
            'fresh: 1° to 4°C': 0.06666666666666665,
            'fresh: 1° to 7°C': 0.06666666666666665,
            'dry: 5° to 25°C': 0.0714285714285714,
            '2°C': 0,
            '4°C': 0,
            '7°C': 0,
            '25°C': 0,
          },
          '0,03': {
            'frisch: 0° to 2°C': 0.06666666666666665,
            'fresh: 1° to 4°C': 0,
            'fresh: 1° to 7°C': 0,
            'dry: 5° to 25°C': 0,
            '2°C': 0,
            '4°C': 0,
            '7°C': 0,
            '25°C': 0,
          },
          '0,25': {
            'frisch: 0° to 2°C': 0.1333333333333333,
            'fresh: 1° to 4°C': 0,
            'fresh: 1° to 7°C': 0,
            'dry: 5° to 25°C': 0.1428571428571429,
            '2°C': 0,
            '4°C': 0,
            '7°C': 0,
            '25°C': 0.25,
          },
          '': {
            'frisch: 0° to 2°C': 0,
            'fresh: 1° to 4°C': 0,
            'fresh: 1° to 7°C': 0,
            'dry: 5° to 25°C': 0,
            '2°C': 0,
            '4°C': 0,
            '7°C': 0,
            '25°C': 0,
          },
        },
        item2: {
          '0,15': {
            'frisch: 0° to 2°C': 0.06666666666666665,
            'fresh: 1° to 4°C': 0.06666666666666665,
            'fresh: 1° to 7°C': 0.06666666666666665,
            'dry: 5° to 25°C': 0.0714285714285714,
            '2°C': 0,
            '4°C': 0,
            '7°C': 0,
            '25°C': 0,
          },
          '0,03': {
            'frisch: 0° to 2°C': 0.06666666666666665,
            'fresh: 1° to 4°C': 0,
            'fresh: 1° to 7°C': 0,
            'dry: 5° to 25°C': 0,
            '2°C': 0,
            '4°C': 0,
            '7°C': 0,
            '25°C': 0,
          },
          '0,25': {
            'frisch: 0° to 2°C': 0.1333333333333333,
            'fresh: 1° to 4°C': 0,
            'fresh: 1° to 7°C': 0,
            'dry: 5° to 25°C': 0.1428571428571429,
            '2°C': 0,
            '4°C': 0,
            '7°C': 0,
            '25°C': 0.25,
          },
          '': {
            'frisch: 0° to 2°C': 0,
            'fresh: 1° to 4°C': 0,
            'fresh: 1° to 7°C': 0,
            'dry: 5° to 25°C': 0,
            '2°C': 0,
            '4°C': 0,
            '7°C': 0,
            '25°C': 0,
          },
        },
        item3: {
          '0,15': {
            'frisch: 0° to 2°C': 0.06666666666666665,
            'fresh: 1° to 4°C': 0.06666666666666665,
            'fresh: 1° to 7°C': 0.06666666666666665,
            'dry: 5° to 25°C': 0.0714285714285714,
            '2°C': 0,
            '4°C': 0,
            '7°C': 0,
            '25°C': 0,
          },
          '0,03': {
            'frisch: 0° to 2°C': 0.06666666666666665,
            'fresh: 1° to 4°C': 0,
            'fresh: 1° to 7°C': 0,
            'dry: 5° to 25°C': 0,
            '2°C': 0,
            '4°C': 0,
            '7°C': 0,
            '25°C': 0,
          },
          '0,25': {
            'frisch: 0° to 2°C': 0.1333333333333333,
            'fresh: 1° to 4°C': 0,
            'fresh: 1° to 7°C': 0,
            'dry: 5° to 25°C': 0.1428571428571429,
            '2°C': 0,
            '4°C': 0,
            '7°C': 0,
            '25°C': 0.25,
          },
          '': {
            'frisch: 0° to 2°C': 0,
            'fresh: 1° to 4°C': 0,
            'fresh: 1° to 7°C': 0,
            'dry: 5° to 25°C': 0,
            '2°C': 0,
            '4°C': 0,
            '7°C': 0,
            '25°C': 0,
          },
        },
        item4: {
          '0,15': {
            'frisch: 0° to 2°C': 0.06666666666666665,
            'fresh: 1° to 4°C': 0.06666666666666665,
            'fresh: 1° to 7°C': 0.06666666666666665,
            'dry: 5° to 25°C': 0.0714285714285714,
            '2°C': 0,
            '4°C': 0,
            '7°C': 0,
            '25°C': 0,
          },
          '0,03': {
            'frisch: 0° to 2°C': 0.06666666666666665,
            'fresh: 1° to 4°C': 0,
            'fresh: 1° to 7°C': 0,
            'dry: 5° to 25°C': 0,
            '2°C': 0,
            '4°C': 0,
            '7°C': 0,
            '25°C': 0,
          },
          '0,25': {
            'frisch: 0° to 2°C': 0.1333333333333333,
            'fresh: 1° to 4°C': 0,
            'fresh: 1° to 7°C': 0,
            'dry: 5° to 25°C': 0.1428571428571429,
            '2°C': 0,
            '4°C': 0,
            '7°C': 0,
            '25°C': 0.25,
          },
          '': {
            'frisch: 0° to 2°C': 0,
            'fresh: 1° to 4°C': 0,
            'fresh: 1° to 7°C': 0,
            'dry: 5° to 25°C': 0,
            '2°C': 0,
            '4°C': 0,
            '7°C': 0,
            '25°C': 0,
          },
        },
        item5: {
          '0,15': {
            'frisch: 0° to 2°C': 0.06666666666666665,
            'fresh: 1° to 4°C': 0.06666666666666665,
            'fresh: 1° to 7°C': 0.06666666666666665,
            'dry: 5° to 25°C': 0.0714285714285714,
            '2°C': 0,
            '4°C': 0,
            '7°C': 0,
            '25°C': 0,
          },
          '0,03': {
            'frisch: 0° to 2°C': 0.06666666666666665,
            'fresh: 1° to 4°C': 0,
            'fresh: 1° to 7°C': 0,
            'dry: 5° to 25°C': 0,
            '2°C': 0,
            '4°C': 0,
            '7°C': 0,
            '25°C': 0,
          },
          '0,25': {
            'frisch: 0° to 2°C': 0.1333333333333333,
            'fresh: 1° to 4°C': 0,
            'fresh: 1° to 7°C': 0,
            'dry: 5° to 25°C': 0.1428571428571429,
            '2°C': 0,
            '4°C': 0,
            '7°C': 0,
            '25°C': 0.25,
          },
          '': {
            'frisch: 0° to 2°C': 0,
            'fresh: 1° to 4°C': 0,
            'fresh: 1° to 7°C': 0,
            'dry: 5° to 25°C': 0,
            '2°C': 0,
            '4°C': 0,
            '7°C': 0,
            '25°C': 0,
          },
        },
        item21: {
          '0,15': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0,
          },
          '0,03': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0,
          },
          '0,25': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0,
          },
          '': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0,
          },
        },
        item22: {
          '0,15': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0,
          },
          '0,03': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0,
          },
          '0,25': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0,
          },
          '': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0,
          },
        },
        item23: {
          '0,15': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0,
          },
          '0,03': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0,
          },
          '0,25': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0,
          },
          '': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0,
          },
        },
        item24: {
          '0,15': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0,
          },
          '0,03': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0,
          },
          '0,25': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0,
          },
          '': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0,
          },
        },
        item25: {
          '0,15': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0,
          },
          '0,03': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0,
          },
          '0,25': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0,
          },
          '': {
            ml: 0,
            g: 0,
            kg: 0,
            piece: 0,
          },
        },
      },
    },
  ],
};
