import { useField, useForm } from 'react-final-form';
import React, { HTMLInputTypeAttribute, forwardRef, useCallback } from 'react';
import useErrorMessage from '../../../core/form/errorMessage';
import { ErrorMessage } from '../ErrorMessage';
import { cx } from 'core/emotion';

type NumberFieldProps = {
  placeholder?: string;
  name: string;
  type?: HTMLInputTypeAttribute;
  errorMessageClassName?: string;
  className?: string;
  withDecimal?: boolean;
  /** This prop is a workaround until we have a redesign */
  hint?: string;
};

const NumberField = forwardRef<HTMLInputElement, NumberFieldProps>(
  (
    {
      placeholder,
      type,
      name,
      errorMessageClassName,
      className,
      withDecimal,
      hint,
    },
    ref
  ) => {
    const { input, meta } = useField(name, {
      type,
    });

    const { change } = useForm();

    const handleChange = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        const isDelimiter = e.target.value.at(-1) === '.';

        if (isDelimiter && !withDecimal) {
          return;
        }

        let delimitersCount = 0;

        for (let i = 0; i < e.target.value.length; i++) {
          if (e.target.value[i] === '.') {
            delimitersCount++;
          }
        }

        if (delimitersCount > 1 && isDelimiter) {
          return;
        }

        const sanitizedValue = e.target.value.replace(/[^0-9.]/g, '');

        change(name, sanitizedValue);
      },
      [change, name, withDecimal]
    );

    const { isShowError, errorMessage } = useErrorMessage({
      meta,
      isFieldShowError: true,
    });

    return (
      <div>
        <input
          ref={ref}
          {...input}
          onChange={handleChange}
          className={cx(
            'border-1 rounded-medium placeholder-gray-410 focus:border-primary text-blue-dark-900 h-9 w-full px-3 text-sm',
            input.value ? 'border-primary' : 'border-gray-210',
            className
          )}
          placeholder={placeholder}
        />
        {!isShowError && hint && (
          <p className="text-gray-560 mt-1 text-xs">{hint}</p>
        )}
        {isShowError ? (
          <ErrorMessage className={errorMessageClassName}>
            {errorMessage}
          </ErrorMessage>
        ) : null}
      </div>
    );
  }
);

export default NumberField;
