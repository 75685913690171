import numbro from 'numbro';
import { NumberFormat } from '../dataModel/columnsAPI';
import { isNil } from 'lodash';

type ConvertToFormatOptions = {
  targetFormat: NumberFormat;
  /** Number of digits after decimal delimiter */
  decimalDigits?: number;
  /** Adornment */
  adornment?: 'currency' | 'percentage';
  symbol?: NumberFormat;
};

type DetectFormatOptions = {
  /** If targetFormat provided, resolve uncertainty with targetFormat */
  targetFormat?: NumberFormat;
  /** Number of digits after decimal delimiter */
  decimalDigits?: number;
  /** When resolveUncertainty is false, uncertainty will be resolved with "undefined" */
  resolveUncertainty?: boolean;
};

type ConvertToNumberOptions = {
  /** When format is defined */
  format?: NumberFormat;
  /** When format is not defined, but the target format is known */
  targetFormat?: NumberFormat;
  /** Number of digits after decimal delimiter */
  decimalDigits?: number;
};

export class NumberParser {
  private static currencySymbol: Record<NumberFormat, string> = {
    [NumberFormat.EU]: '€',
    [NumberFormat.US]: '$',
  };
  private static percentageSymbol = '%';

  /** Convert a number to EU (1.000,00) or US (1,000.00) display format */
  static convertToFormat(
    value: unknown,
    options?: ConvertToFormatOptions
  ): string {
    if (value === null || value === undefined || value === '') {
      return '';
    }

    if (typeof value === 'string' && value.trim() === '') {
      return '';
    }

    let strNumber = '';

    if (typeof value === 'string') {
      const converted: string | null = this.convertStringToNumber(value, {
        targetFormat: options?.targetFormat,
      });

      if (converted === null) {
        return value as string;
      }

      strNumber = converted;
    } else if (typeof value === 'number') {
      strNumber = value.toString();
    } else {
      throw new Error(
        `[convertToFormat] unsupported type of the "value" - ${typeof value}`
      );
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, decimal]: string[] = strNumber.split('.');

    const decimalsLength: number =
      options?.decimalDigits || decimal?.length || 0;
    const usOutput: string = numbro(strNumber).format({
      thousandSeparated: true,
      mantissa: decimalsLength,
    });

    if (options?.targetFormat === NumberFormat.EU) {
      const euOutput: string = usOutput
        .replace(/,/g, '$')
        .replace(/\./g, ',')
        .replace(/\$/g, '.');

      return this.appendAdornment(euOutput, options);
    }

    return this.appendAdornment(usOutput, options);
  }

  /** Detect the format of the string number. undefined is returned when it is unclear what format number is.
   * null is returned when the value is not a number. */
  static detectNumberFormat(
    input: string,
    options?: DetectFormatOptions
  ): NumberFormat | undefined | null {
    const delimiters: string[] = [];

    for (let i = 0; i < input.length; i++) {
      if (input[i] === ',' || input[i] === '.') {
        delimiters.push(input[i]);
      }
    }

    if (delimiters.length === 0) {
      return undefined;
    }

    const unsignedInput = input.replace(/([+\-EUR€SD$%\s])/g, '');

    if (delimiters.length === 1) {
      const [left, right]: string[] = unsignedInput.split(delimiters[0]);

      if (left.length > 3) {
        return delimiters[0] === ',' ? NumberFormat.EU : NumberFormat.US;
      } else {
        const leftWithNoZeros = left.replace(/0/g, '');

        if (leftWithNoZeros === '') {
          return delimiters[0] === ',' ? NumberFormat.EU : NumberFormat.US;
        }

        // Thousands delimiter
        if (right.length === 3) {
          if (options?.targetFormat) {
            return options?.targetFormat;
          }

          if (options?.resolveUncertainty === false) {
            return undefined;
          }

          return delimiters[0] === ',' ? NumberFormat.US : NumberFormat.EU;
        }

        // Decimal delimiter
        return delimiters[0] === ',' ? NumberFormat.EU : NumberFormat.US;
      }
    }

    const euRegex = /^([1-9]\d{0,2})(\.\d{3})*(,\d+)?$/;
    const usRegex = /^([1-9]\d{0,2})(,\d{3})*(\.\d+)?$/;

    if (euRegex.test(unsignedInput)) {
      return NumberFormat.EU;
    } else if (usRegex.test(unsignedInput)) {
      return NumberFormat.US;
    }

    return null;
  }

  static convertToUsWithDecimal(value: string, numberFormat: NumberFormat) {
    let universalDecimal: string | null = value;
    const delimiters = NumberParser.getDelimiters(value);
    const unsignedInput = value.replace(/([+\-EUR€SD$%\s])/g, '');

    if (delimiters.length === 1) {
      const [_left, right]: string[] = unsignedInput.split(delimiters[0]);
      // NOTE: biformat XXX.XXX
      if (right.length === 3) {
        if (numberFormat === NumberFormat.US) {
          universalDecimal = `${value}`?.replace(/,/g, '');
        } else {
          universalDecimal = `${value}`
            ?.replace(/\./g, '$')
            ?.replace(/,/, '.')
            .replace(/\$/g, '');
        }
        return universalDecimal;
      }
    }

    return this.replaceDelimiter(value);
  }

  /** Convert string number of different formats to a valid string-number */
  static convertStringToNumber = (
    input: unknown,
    options?: ConvertToNumberOptions
  ): string | null => {
    if (typeof input === 'number') {
      return `${input}`;
    }

    if (typeof input !== 'string') {
      return null;
    }

    const trimmedInput = input.trim().replace(/([EUR€SD$%\s])/g, '');
    const detectedFormat =
      options?.format ||
      this.detectNumberFormat(trimmedInput, {
        targetFormat: options?.targetFormat,
      });
    let result = '';

    if (detectedFormat === null) {
      return null;
    } else if (detectedFormat === NumberFormat.US) {
      result = trimmedInput.replace(/,/g, '');
    } else {
      result = trimmedInput
        .replace(/\./g, '$')
        .replace(/,/, '.')
        .replace(/\$/g, '');
    }

    if (isNaN(Number(result))) {
      return null;
    }

    return result;
  };

  /** Check if the number has decimal part */
  static hasDecimalPart(
    input: number | string,
    format?: NumberFormat
  ): boolean {
    if (typeof input === 'number') {
      return input % 1 !== 0;
    }

    const trimmedInput = input.trim();
    const detectedFormat = format || this.detectNumberFormat(trimmedInput);

    if (detectedFormat === null) {
      return false;
    } else if (detectedFormat === NumberFormat.US) {
      return input.includes('.');
    }

    return input.includes(',');
  }

  /** Append adornment to the string number */
  static appendAdornment(
    value: string,
    options?: ConvertToFormatOptions
  ): string {
    if (options?.adornment === 'currency') {
      if (options.symbol === NumberFormat.US) {
        return `${this.currencySymbol[NumberFormat.US]}${value}`;
      }
      return `${value} ${
        this.currencySymbol[options.symbol ?? NumberFormat.EU]
      }`;
    }

    if (options?.adornment === 'percentage') {
      return `${value} ${this.percentageSymbol}`;
    }

    return value;
  }

  static getDelimiters(value: string) {
    const delimiters: string[] = [];

    for (let i = 0; i < value.length; i++) {
      if (value[i] === ',' || value[i] === '.') {
        delimiters.push(value[i]);
      }
    }

    return delimiters;
  }

  private static replaceDelimiter(value: string) {
    let universalDecimal = value;
    const detectedFormat = NumberParser.detectNumberFormat(`${value}`);
    if (isNil(detectedFormat)) return null;
    if (detectedFormat === NumberFormat.EU) {
      universalDecimal = `${value}`
        ?.replace(/\./g, '$')
        ?.replace(/,/, '.')
        .replace(/\$/g, '');
    } else {
      universalDecimal = `${value}`?.replace(/,/g, '');
    }

    return universalDecimal;
  }
}
