import { map, concat, toArray } from 'rxjs';
import MatchingDTO from '../dto/MatchingDTO';
import { NuvoClientMachineLearningAble } from '../NuvoMachineLearning';
import { DataModel } from 'dataModel';
import { DataModelSheetMatch } from '../DataModelSheetMatching';

import { SheetColumn } from '@nuvo-importer/common/sdk';
import BaseMatchingRepository, { MLExecuteOptions } from './MatchingRepository';
import BackendMl from '../ml/backend/BackendMl';

class ClientMatchingRepository extends BaseMatchingRepository {
  private mlClient: NuvoClientMachineLearningAble;
  private mlBackend: BackendMl;

  constructor(mlClient: NuvoClientMachineLearningAble, mlBackend: BackendMl) {
    super();
    this.mlClient = mlClient;
    this.mlBackend = mlBackend;
  }

  protected matchMlColumns = (
    matchingMapperDTO: MatchingDTO,
    options: MLExecuteOptions
  ) => {
    const columns = matchingMapperDTO.getColumns().map((column) => {
      return { ...column, dropdownOptions: [] };
    });
    const headers = matchingMapperDTO.getInputHeaderRow();
    const licenseKey = matchingMapperDTO.getLicenseKey();

    return this.mlBackend.matchColumns(
      headers,
      columns,
      licenseKey,
      this.parseMlOptions(options)
    );
  };

  protected matchMlOptionsInitial = (
    dataModelSheetMatch: DataModelSheetMatch[],
    matchingMapperDTO: MatchingDTO,
    options: MLExecuteOptions
  ) => {
    const licenseKey = matchingMapperDTO.getLicenseKey();

    const allObservables = dataModelSheetMatch.map((match) => {
      const columns = MatchingDTO.mapDataModelToColumn(
        match.matchedDataModel!.dataModel!
      );
      const inputJson = MatchingDTO.getInputJsonBySheetColumn(
        match.sheetColumn,
        match.matchedDataModel!.dataModel!
      );

      return this.mlClient
        .execute(
          [columns],
          inputJson,
          licenseKey,
          this.parseMlOptions({
            ...options,
            ignoreReuseMappingColumn: true,
            isNetwork3G: true,
          })
        )
        .pipe(
          map((calculateSimilarityResults) => {
            return calculateSimilarityResults[0];
          })
        );
    });

    return concat(...allObservables).pipe(toArray());
  };

  protected matchMlOptions = ({
    dataModel,
    sheetColumn,
    licenseKey,
    options,
  }: {
    dataModel: DataModel;
    sheetColumn: SheetColumn;
    licenseKey: string;
    options: MLExecuteOptions;
  }) => {
    const column = MatchingDTO.mapDataModelToColumn(dataModel);
    const inputJson = MatchingDTO.getInputJsonBySheetColumn(
      sheetColumn,
      dataModel
    );

    return this.mlClient.execute(
      [column],
      inputJson,
      licenseKey,
      this.parseMlOptions({
        ...options,
        ignoreReuseMappingColumn: true,
        isNetwork3G: true,
      })
    );
  };

  prepareData = () => {
    return Promise.resolve();
  };

  initialize = () => {};

  load = () => {
    return this.mlClient.loadVector();
  };

  clearData = () => {};

  complete = () => {};
}

export default ClientMatchingRepository;
