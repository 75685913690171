import Button from 'baseUI/Button';
import BackButton from 'baseUI/BackButton';
import { useTranslation } from 'react-i18next';
import { useSettings } from 'settings';
import { useScroll } from 'core/scroll';
import { useTheme } from 'theme';
import { css, CSSInterpolation, CSSObject, cx } from 'core/emotion';
import { REVIEW_ENTRIES_PATH } from 'core/constants/route';
import useLocationPathname from 'core/location';
import { useMemo, useState } from 'react';
import Popover from 'baseUI/Popover';
import Message from 'baseUI/Popover/Message';
import { isMobile, isTablet } from 'react-device-detect';

type ActionFooterProps = {
  onPositiveClick?: () => void;
  onNegativeClick?: () => void;
  positiveButtonText?: string;
  disabledPositive?: boolean;
  disabledNegative?: boolean;
  className?: string;
  hideDisableStylePositive?: boolean;
  footerStyle?: string;
  positiveButtonClassName?: string;
  enablePopper?: boolean;
  popperMessage?: string;
  popoverInfoTheme?: CSSInterpolation;
};

const ActionFooter = ({
  onPositiveClick,
  onNegativeClick,
  positiveButtonText,
  disabledPositive = false,
  disabledNegative = false,
  className,
  hideDisableStylePositive = false,
  footerStyle,
  positiveButtonClassName,
  enablePopper,
  popperMessage,
  popoverInfoTheme,
}: ActionFooterProps) => {
  const { t } = useTranslation();
  const { scrollToTop } = useScroll();
  const theme = useTheme();
  const { modal } = useSettings();
  const { pathname } = useLocationPathname();
  const [isHoverPopoverShow, setIsHoverPopoverShow] = useState(false);

  const isShowPopover = useMemo(
    () => !!(enablePopper && isHoverPopoverShow),
    [isHoverPopoverShow, enablePopper]
  );

  return (
    <div
      className={cx(
        modal
          ? css`
              padding-top: 90px;
            `
          : pathname === REVIEW_ENTRIES_PATH
          ? css`
              padding-top: 65px;
            `
          : css`
              padding-top: 55px;
            `
      )}
    >
      <div className={cx(className, modal ? 'rounded-b-lg' : '')}>
        <div>
          <div
            className={cx(
              'flex items-center justify-end space-x-4 py-6',
              footerStyle ? footerStyle : '',
              modal ? 'rounded-b-lg' : '',
              css({ '&&': theme.getFooterTheme().root }),
              css({
                backgroundColor: theme.getGlobalTheme().getBackgroundColor(),
              })
            )}
          >
            <BackButton
              onClick={onNegativeClick}
              disabled={disabledNegative}
              className="xsmd:w-auto w-full"
            />
            <Popover
              isShow={isShowPopover}
              message={
                <Message
                  className={cx(
                    css({ '&&': popoverInfoTheme }),
                    css`
                      max-width: 230px;
                    `
                  )}
                >
                  {popperMessage}
                </Message>
              }
              isShowArrow
              arrowClassName={
                (popoverInfoTheme as CSSObject)?.['backgroundColor']
              }
              offset={[-3, 10]}
              direction="top"
              render={({ referenceElement }) => {
                return (
                  <span ref={referenceElement}>
                    <Button
                      disabled={disabledPositive}
                      onClick={() => {
                        scrollToTop();
                        onPositiveClick?.();
                        if (isMobile || isTablet) {
                          setIsHoverPopoverShow((isShow) => !isShow);
                        }
                      }}
                      className={cx(
                        `xsmd:w-auto w-full disabled:opacity-70`,
                        positiveButtonClassName
                      )}
                      textClassName="pointer-events-none"
                      hideDisableStyle={hideDisableStylePositive}
                      onMouseEnter={() => setIsHoverPopoverShow(true)}
                      onMouseOut={() => setIsHoverPopoverShow(false)}
                    >
                      {positiveButtonText || t('txt_continue')}
                    </Button>
                  </span>
                );
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActionFooter;
