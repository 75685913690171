import { css, cx } from 'core/emotion';
import Button from 'baseUI/Button';
import { useConfigure } from 'configure';
import { useTranslation } from 'react-i18next';
import { useSettings } from 'settings';
import { useLicenseKeyAuth } from 'license';

const UploadButton = ({
  className,
  isModalView,
  onLaunch,
}: {
  className?: string;
  isModalView?: boolean;
  onLaunch: () => void;
}) => {
  const { t } = useTranslation();
  const { multipleFileUpload, modal, embedUploadArea } = useSettings();
  const { renderUploadButton, children } = useConfigure();
  const { isLoading } = useLicenseKeyAuth();

  const getTextButton = () => {
    return multipleFileUpload
      ? t('txt_allow_multiple_select_files')
      : t('txt_select_files');
  };

  return (
    <div className="mt-6 flex items-center justify-center md:mt-0">
      {renderUploadButton ? (
        <div id="nuvo-custom-upload-button">
          {renderUploadButton({
            launch: isLoading
              ? () => {
                  console.log('verifying license key');
                }
              : onLaunch,
          })}
        </div>
      ) : (
        <Button
          onClick={onLaunch}
          loading={isLoading}
          disabled={isLoading}
          className={cx(
            className,
            css`
              min-width: 182px;
            `,
            'h-auto',
            modal && !embedUploadArea ? 'w-auto' : 'w-full'
          )}
        >
          <div>
            {modal === true &&
            (embedUploadArea || false) === false &&
            isModalView === true
              ? t('txt_import_data')
              : children
              ? children
              : getTextButton()}
          </div>
        </Button>
      )}
    </div>
  );
};

export default UploadButton;
