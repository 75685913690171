import { cx, css, CSSObject } from 'core/emotion';
import { useContextCreateNewColumnModal } from './CreateNewColumnModalContext';
import BaseModal from 'baseUI/Modal/BaseModal';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-final-form';
import FieldTextInput from '../../../baseUI/form/FieldTextInput';
import Label from '../../../baseUI/form/Label';
import useViewModel, { FormValues, createNewColumnModalId } from './viewModel';
import { Button } from '@nuvo-importer/common';
import FieldDropdown from '../../../baseUI/form/FieldDropdown';
import DataModelSheetMatcher from '../../../matching/DataModelSheetMatcher';
import DataModelSheetMatching from '../../../matching/DataModelSheetMatching';
import { DATATYPE } from '@nuvo-importer/common/sdk';
import { useMemo } from 'react';
import { useTheme } from 'theme';

type CreateNewColumnModalProps = {
  dataModelSheetMatcher: DataModelSheetMatcher;
  setDataModelSheetMatcher: (
    dataModelSheetMatcher: DataModelSheetMatcher
  ) => void;
  setDataModelSheetMatching: (
    dataModelSheetMatching: DataModelSheetMatching
  ) => void;
};

const CreateNewColumnModal = ({
  dataModelSheetMatcher,
  setDataModelSheetMatcher,
  setDataModelSheetMatching,
}: CreateNewColumnModalProps) => {
  const { isOpen, close } = useContextCreateNewColumnModal();
  const { t } = useTranslation();
  const theme = useTheme();
  const {
    onSubmit,
    dataModelOptions,
    dateFormatOptions,
    modalElement,
    validate,
    initialValues,
    hasSubmit,
    inputRef,
  } = useViewModel({
    isOpen,
    dataModelSheetMatcher,
    setDataModelSheetMatcher,
    setDataModelSheetMatching,
  });

  const configureStyled = useMemo(() => {
    return theme.getColumnMatchTheme().dialogCreateCustomColumn;
  }, [theme]);

  return (
    <BaseModal
      gravity="middle"
      className="w-full !p-0 sm:max-w-xl"
      isOpen={isOpen}
      onCloseModal={() => {
        close();
      }}
      wrapperClassName={cx(css`
        z-index: 10000 !important;
      `)}
      isShowCloseIcon={true}
      id={createNewColumnModalId}
      overlayStyled={configureStyled?.overlay}
      bodyStyled={configureStyled?.root}
      closeIconStyled={configureStyled?.closeIcon}
    >
      <Form<FormValues>
        onSubmit={onSubmit}
        validate={(values) => {
          if (hasSubmit) {
            return true;
          }
          return validate({
            ...values,
            columnName: values.columnName?.trim() ?? '',
          });
        }}
        initialValues={initialValues as FormValues}
        render={({ handleSubmit, values }) => {
          return (
            <form className="p-6 pt-0 text-left" onSubmit={handleSubmit}>
              <div>
                <p
                  className={cx(
                    'text-neutral-gray text-2xl font-semibold',
                    css({ '&&': configureStyled?.title })
                  )}
                >
                  {t('txt_create_new_column')}
                </p>
                <p
                  className={cx(
                    'text-gray-970 mt-2 text-sm leading-5',
                    css({ '&&': configureStyled?.description })
                  )}
                >
                  {t('txt_create_new_column_description')}
                </p>
              </div>
              <div className="mt-6">
                <Label
                  description={t(
                    'txt_create_new_column_column_name_field_description'
                  )}
                  className="mb-1"
                  textClassName={css({
                    '&&': configureStyled?.columnName?.label,
                  })}
                  iconClassName={css({
                    '&&': {
                      color: (configureStyled?.columnName?.icon as CSSObject)
                        ?.color,
                    },
                  })}
                >
                  {t('txt_create_new_column_column_name_field')}
                </Label>
                <FieldTextInput
                  ref={inputRef}
                  name="columnName"
                  placeholder={t(
                    'txt_create_new_column_column_name_field_placeholder'
                  )}
                  className={css({
                    '&&': configureStyled?.columnName?.input,
                  })}
                  errorMessageClassName={css({
                    '&&': configureStyled?.columnName?.errorMessage,
                  })}
                />
              </div>
              <div className="mt-4">
                <Label
                  description={t(
                    'txt_create_new_column_column_type_field_description'
                  )}
                  className="mb-1"
                  textClassName={css({
                    '&&': configureStyled?.columnType?.label,
                  })}
                  iconClassName={css({
                    '&&': {
                      color: (configureStyled?.columnType?.icon as CSSObject)
                        ?.color,
                    },
                  })}
                >
                  {t('txt_create_new_column_column_type_field')}
                </Label>
                <FieldDropdown
                  name="columnType"
                  placeholder={t('txt_select_column_type')}
                  options={dataModelOptions}
                  rootDropdownPortal={modalElement ?? undefined}
                  dropdownStyled={configureStyled?.columnType?.dropdown}
                />
              </div>
              {values.columnType === DATATYPE.DATE ? (
                <div className="mt-4">
                  <Label
                    description={t('txt_date_format_field_description')}
                    className="mb-1"
                    textClassName={css({
                      '&&': configureStyled?.dateFormat?.label,
                    })}
                    iconClassName={css({
                      '&&': {
                        color: (configureStyled?.dateFormat?.icon as CSSObject)
                          ?.color,
                      },
                    })}
                  >
                    {t('txt_date_format')}
                  </Label>
                  <FieldDropdown
                    name="dateFormat"
                    placeholder={t('txt_select_date_format')}
                    options={dateFormatOptions}
                    rootDropdownPortal={modalElement ?? undefined}
                    dropdownStyled={configureStyled?.dateFormat?.dropdown}
                  />
                </div>
              ) : null}
              <div className="flex justify-end pt-10">
                <Button
                  variant="secondary"
                  className="mr-4"
                  onClick={() => {
                    close();
                  }}
                >
                  {t('txt_cancel')}
                </Button>
                <Button type="submit">{t('txt_create')}</Button>
              </div>
            </form>
          );
        }}
      />
    </BaseModal>
  );
};

export default CreateNewColumnModal;
