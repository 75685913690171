import { CSSInterpolation, css, cx } from '../../../../../../core/emotion';
import { ReactComponent as CloseIcon } from '../../../../../../assets/icon/close-gray.svg';
import { forwardRef, KeyboardEvent, ClipboardEvent } from 'react';

type TextInputProps = {
  value?: string;
  onChange?: (value: string) => void;
  className?: string;
  placeholder?: string;
  onKeyDown?: (event: KeyboardEvent<HTMLInputElement>) => void;
  onKeyUp?: (event: KeyboardEvent<HTMLInputElement>) => void;
  onPaste?: (event: ClipboardEvent<HTMLInputElement>) => void;
  onKeyDownCapture?: (event: KeyboardEvent<HTMLInputElement>) => void;
  size?: 'small' | 'base';
  wrapperClassName?: string;
  showCloseButton?: boolean;
  configTheme?: {
    root?: CSSInterpolation;
    icon?: CSSInterpolation;
  };
};

const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  (
    {
      value,
      className,
      placeholder,
      onChange,
      onKeyDown,
      onPaste,
      size,
      wrapperClassName,
      showCloseButton = false,
      configTheme,
      onKeyDownCapture,
    }: TextInputProps,
    ref
  ) => {
    const isShowCloseButton = showCloseButton && (value?.trim() ?? '').length;

    return (
      <div className={cx('relative inline-block w-full', wrapperClassName)}>
        <input
          ref={ref}
          type="text"
          value={value}
          placeholder={placeholder}
          onChange={(event) => onChange?.(event.target.value)}
          onKeyDown={onKeyDown}
          onPaste={onPaste}
          onKeyDownCapture={onKeyDownCapture}
          className={cx(
            value ? 'border-blue-dark-900' : 'border-gray-120',
            'border-gray-120 rounded-medium placeholder-gray-560 focus:border-blue-dark-900 w-full pl-2 focus:ring-0',
            size === 'small' ? 'h-6 py-0.5 text-xs' : 'py-2 text-sm',
            isShowCloseButton ? 'pr-7' : 'pr-2',
            className,
            css({
              '&&': configTheme?.root,
            })
          )}
        />
        {isShowCloseButton ? (
          <CloseIcon
            className={cx(
              'text-gray-560 absolute right-2 top-1/2 -translate-y-1/2 transform cursor-pointer',
              css({
                '&&': configTheme?.icon,
              })
            )}
            onClick={() => {
              onChange?.('');
            }}
          />
        ) : null}
      </div>
    );
  }
);

export default TextInput;
