import { css, CSSInterpolation, cx } from '../../core/emotion';

export type Variant = 'primary' | 'secondary' | 'tertiary';

type VariantClassname = ({
  hideDisableStyle,
  configTheme,
}: {
  hideDisableStyle: boolean;
  configTheme?: {
    primary?: CSSInterpolation;
    secondary?: CSSInterpolation;
    tertiary?: CSSInterpolation;
  };
}) => string;

const primaryClassname: VariantClassname = ({
  hideDisableStyle,
  configTheme,
}) => {
  return cx(
    `${!hideDisableStyle ? 'disabled:opacity-75' : ''}`,
    'bg-blue-light-500 text-white border-transparent hover:bg-blue-light-700',
    css`
      :active {
        background-color: #0c3c9b;
      }
    `,
    css({ '&&': configTheme?.primary })
  );
};

const secondaryClassname: VariantClassname = ({ configTheme }) => {
  return cx(
    'bg-white text-color-primary border-blue-dark-50 hover:bg-blue-dark-50',
    css`
      :active {
        background-color: #d0dbed;
        border-color: #d0dbed;
      }
    `,
    css({ '&&': configTheme?.secondary })
  );
};

const tertiaryClassname: VariantClassname = ({
  hideDisableStyle,
  configTheme,
}) => {
  return cx(
    `${!hideDisableStyle ? 'disabled:opacity-75' : ''}`,
    'bg-blue-light-100 text-blue-light-600 hover:bg-blue-light-200',
    css`
      :active {
        background-color: #a3bff8;
      }
    `,
    css({ '&&': configTheme?.tertiary })
  );
};

export const variantClassname = ({
  variant,
  hideDisableStyle,
  configTheme,
}: {
  variant: Variant;
  hideDisableStyle: boolean;
  configTheme?: {
    primary?: CSSInterpolation;
    secondary?: CSSInterpolation;
    tertiary?: CSSInterpolation;
  };
}) => {
  switch (variant) {
    case 'primary':
      return primaryClassname({ hideDisableStyle, configTheme });
    case 'secondary':
      return secondaryClassname({ hideDisableStyle, configTheme });
    case 'tertiary':
      return tertiaryClassname({ hideDisableStyle, configTheme });
    default: {
      if (process.env.NODE_ENV === 'development') {
        throw new Error(`variant is not available: ${variant}`);
      }
      return '';
    }
  }
};
