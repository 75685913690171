import NuvoSessionHandler from 'main/DynamicImportHandler';
import { createContext, useContext } from 'react';
import ModalView from './ModalView';
import PageView from './PageView';
import useViewModel from './viewModel';

type ContextType = {
  closePage: () => void;
  cancel: (isCloseModal?: boolean) => void;
  setTimeUpload: (timeUpload: number) => void;
  timeUpload: number;
};

const MainViewProvider = createContext({} as ContextType);

const MainView = () => {
  const {
    isOpenModal,
    cancel,
    openModal,
    closeModal,
    modal,
    closePage,
    setIsOpenModal,
    setTimeUpload,
    timeUpload,
    isAuth,
    identifier,
  } = useViewModel();

  return (
    <MainViewProvider.Provider
      value={{
        closePage,
        setTimeUpload,
        timeUpload,
        cancel,
      }}
    >
      <div className="text-left">
        {modal ? (
          <ModalView
            key={identifier}
            setIsOpenModal={setIsOpenModal}
            isOpenModal={isOpenModal}
            cancel={cancel}
            openModal={openModal}
            closeModal={closeModal}
          />
        ) : (
          <div
            className="flex h-full w-full justify-center"
            id="nuvo-root-pageview"
          >
            <PageView key={identifier} />
          </div>
        )}
      </div>
      <NuvoSessionHandler
        key={identifier}
        modal={modal}
        openModal={openModal}
        isAuth={isAuth}
      />
    </MainViewProvider.Provider>
  );
};

export const usePage = () => {
  return useContext(MainViewProvider);
};

export default MainView;
