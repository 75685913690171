import { useMemo, useState } from 'react';
import { createContext, useContext } from 'react';
import { LicenseService, useDevelopMode } from 'license';
import { authLicense } from './authLicense';
import { useEffectOnce } from 'core/useEffectOnce';
import { useFeatureWhiteList } from '../../configure/ConfigureProvider';
import FeatureMapper from './FeatureMapper';
import { MatchingContextProviderProps } from '../types';
import { useSettings } from 'settings';

const AuthContext = createContext<{
  isAuth: boolean;
  isLoading: boolean;
  htLicenseKey: string;
  isExpire: boolean;
}>({
  isAuth: false,
  isLoading: false,
  htLicenseKey: 'non-commercial-and-evaluation',
  isExpire: false,
});

export const useLicenseKeyAuth = () => {
  const context = useContext(AuthContext);
  return context;
};

export const AuthContextProvider = ({
  licenseKey,
  children,
}: MatchingContextProviderProps) => {
  const commercialKey = 'non-commercial-and-evaluation';
  const [isAuth, setIsAuth] = useState(false);
  const [isExpire, setIsExpire] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [htLicenseKey, setHtLicenseKey] = useState<string>(commercialKey);
  const { language } = useSettings();
  const licenseService = useMemo(() => {
    return new LicenseService();
  }, []);
  const { setFeatureWhiteList } = useFeatureWhiteList();
  const { isDeveloperMode } = useDevelopMode();

  useEffectOnce(() => {
    if (licenseKey) {
      setIsLoading(true);
      const subscription = licenseService
        .verifyLicenseKey(licenseKey, language ?? 'default')
        .subscribe({
          next: (res) => {
            setHtLicenseKey(res?.ht_license_key ?? commercialKey);
            setIsAuth(authLicense(isDeveloperMode, res));
            const features = FeatureMapper.mapDAOtoFeatureWhitelist(res);
            setFeatureWhiteList(features);
            setIsLoading(false);
            setIsExpire(!res.is_verified ?? false);
          },
          error: () => {
            const features = FeatureMapper.getEmptyFeatureWhitelist();
            setFeatureWhiteList(features);
            setIsLoading(false);
          },
        });

      return () => {
        setIsLoading(false);
        subscription.unsubscribe();
      };
    } else {
      setIsAuth(false);
    }
    return () => {};
  });

  return (
    <AuthContext.Provider
      value={{
        isAuth,
        isLoading,
        htLicenseKey,
        isExpire,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
