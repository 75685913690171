import { isArray, isNil, uniq } from 'lodash';
import { ColumnAPI, NumberFormat } from '../../../dataModel/columnsAPI';
import { DataModel } from '../../../dataModel/model/DataModel';
import { FindDropdownOptionFn, OptionKey } from '../type';
import {
  FilterValueItem,
  FilterValueItems,
  FilterValuesSelected,
} from './FilterStrategy';
import Handsontable from 'handsontable';
import { DATATYPE } from '../../../dataType';
import { NumberParser } from '../../../utils/NumberParser';

const isBlankCell = (value: string) => {
  return value === '' || isNil(value);
};

const parseValue = (value: string) => {
  if (isBlankCell(value)) {
    return '(Blank cells)';
  } else {
    return value;
  }
};

class FilterStrategyValueItems {
  private valueItemsSnapshot: Record<number, FilterValueItems | null> = {};
  private columns: ColumnAPI[] = [];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private dataSet: Record<string, any>[] = [];
  private hotInstance?: Handsontable;

  constructor(columns: ColumnAPI[]) {
    this.columns = columns;
  }

  setHotInstance = (hotInstance: Handsontable) => {
    this.hotInstance = hotInstance;
  };

  getValueItems = (
    columnIndex: number,
    dataModel: DataModel,
    findDropdownOption: FindDropdownOptionFn,
    getAllData: boolean,
    isHideLastRow: boolean,
    readOnly: boolean
  ) => {
    const valueItemsSnapshot = this.valueItemsSnapshot[columnIndex];
    if (valueItemsSnapshot) {
      return valueItemsSnapshot;
    }
    const items: FilterValueItem['value'][] = [];
    const columnKey = this.columns[columnIndex].key;

    const dataSet = getAllData
      ? this.dataSet
      : this.getVisibleData(this.dataSet);

    const length =
      (getAllData || !isHideLastRow) && !readOnly
        ? dataSet.length - 1
        : dataSet.length;

    for (let i = 0; i < length; ++i) {
      const row = dataSet[i];
      const parsedValue = this.parseValueItem(row[columnKey]);
      if (isArray(parsedValue)) {
        for (let i = 0; i < parsedValue.length; ++i) {
          items.push(parsedValue[i]);
        }
      } else {
        items.push(parsedValue);
      }
    }

    const uniqueList = uniq(items);
    const valueItems = [];
    for (let i = 0; i < uniqueList.length; ++i) {
      const value = uniqueList[i];
      valueItems[i] = {
        label: this.getLabel(dataModel, findDropdownOption, columnIndex, value),
        value: value,
      };
    }
    const sortedUniqList = valueItems.sort((a, b) => {
      return `${a.label}`.localeCompare(`${b.label}`);
    });

    return sortedUniqList;
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private getVisibleData = (dataSet: Record<string, any>[]) => {
    if (!this.hotInstance) {
      return dataSet;
    }
    const columnFiltering = this.hotInstance.getPlugin('filters');
    if (!columnFiltering) {
      return dataSet;
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const visibleDataSet: Record<string, any>[] = [];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const filtersRowsMap = (columnFiltering as any).filtersRowsMap;
    dataSet.forEach((row, rowIndex) => {
      if (!filtersRowsMap.indexedValues[rowIndex]) {
        visibleDataSet.push(row);
      }
    });

    return visibleDataSet;
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setDataSet = (dataSet: Record<string, any>[]) => {
    this.dataSet = dataSet;
  };

  removeSelectedNotInValueItems = (
    selectedValues: FilterValuesSelected,
    valueItems: FilterValueItems,
    dataModel: DataModel
  ) => {
    const keys = Object.keys(selectedValues);
    const results: FilterValuesSelected = {};
    const isNumeric = dataModel.isNumeric();
    for (let i = 0; i < keys.length; ++i) {
      let key: string | number = keys[i];
      if (isNumeric && key !== '') {
        const result = Number(key);
        if (!isNaN(result)) {
          key = result;
        }
      }
      if (
        valueItems.some((item) => {
          return item.value === key;
        })
      ) {
        results[key] = selectedValues[key];
      }
    }

    return results;
  };

  isSelectedAllValueItems = (
    selectedValues: FilterValuesSelected | null,
    valueItems: FilterValueItems
  ) => {
    if (selectedValues === null) {
      return true;
    }
    let selectedCount = 0;
    const keys = Object.keys(selectedValues);
    for (let i = 0; i < keys.length; ++i) {
      const key = keys[i];
      if (selectedValues[key]) {
        selectedCount++;
      }
    }

    return selectedCount === valueItems.length;
  };

  getValueItemsSnapshot = () => {
    return this.valueItemsSnapshot;
  };

  setValueItemsSnapshot = (
    columnIndex: number,
    valueItems: FilterValueItems
  ) => {
    const physicalCol = this.hotInstance?.toPhysicalColumn(columnIndex) ?? 0;
    this.valueItemsSnapshot[physicalCol] = valueItems;
  };

  clearValueItemsSnapshot = (physicalCol: number) => {
    this.valueItemsSnapshot[physicalCol] = null;
  };

  clearAllValueItemsSnapshot = () => {
    const columnIndexes = Object.keys(this.valueItemsSnapshot ?? {});
    for (let i = 0; i < columnIndexes.length; ++i) {
      const columnIndex = Number(columnIndexes[i]);
      this.valueItemsSnapshot[columnIndex] = null;
    }
  };

  private getLabel = (
    dataModel: DataModel,
    findDropdownOption: FindDropdownOptionFn,
    currentColumnIndex: number,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any
  ) => {
    const isDropdown = dataModel.isDropdown();
    const isNumeric = dataModel.isNumeric();
    if (isDropdown) {
      const dropdownLabel = findDropdownOption(
        currentColumnIndex,
        value,
        OptionKey.LABEL
      );
      return parseValue(dropdownLabel);
    } else if (isNumeric) {
      const type = dataModel.getType();
      const targetFormat = dataModel.getNumberFormat();
      let numericLabel: string;
      if (type === DATATYPE.PERCENTAGE) {
        numericLabel = NumberParser.convertToFormat(value, {
          targetFormat,
          adornment: 'percentage',
        });
      } else if (dataModel.isCurrency()) {
        numericLabel = NumberParser.convertToFormat(value, {
          targetFormat,
          adornment: 'currency',
          symbol:
            dataModel.getType() === DATATYPE.CURRENCY_USD
              ? NumberFormat.US
              : NumberFormat.EU,
        });
      } else {
        numericLabel = NumberParser.convertToFormat(value, {
          targetFormat,
        });
      }
      return parseValue(numericLabel);
    } else {
      return parseValue(value);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private parseValueItem = (value: any) => {
    if (typeof value === 'string') {
      return value.trim();
    } else if (isArray(value)) {
      const items = [];
      for (let j = 0; j < value.length; ++j) {
        items.push(value[j] ?? '');
      }
      return items;
    } else {
      return value ?? '';
    }
  };
}

export default FilterStrategyValueItems;
