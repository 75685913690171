import { useField } from 'react-final-form';
import { HTMLInputTypeAttribute, forwardRef } from 'react';
import useErrorMessage from '../../../core/form/errorMessage';
import { ErrorMessage } from '../ErrorMessage';
import { cx } from 'core/emotion';

type TextFieldProps = {
  placeholder?: string;
  name: string;
  type?: HTMLInputTypeAttribute;
  errorMessageClassName?: string;
  className?: string;
};

const TextField = forwardRef<HTMLInputElement, TextFieldProps>(
  ({ placeholder, type, name, errorMessageClassName, className }, ref) => {
    const { input, meta } = useField(name, {
      type,
    });

    const { isShowError, errorMessage } = useErrorMessage({
      meta,
      isFieldShowError: true,
    });

    return (
      <div>
        <input
          ref={ref}
          {...input}
          className={cx(
            'border-1 rounded-medium placeholder-gray-410 focus:border-primary text-blue-dark-900 h-9 w-full px-3 text-sm',
            input.value ? 'border-primary' : 'border-gray-210',
            className
          )}
          placeholder={placeholder}
        />
        {isShowError ? (
          <ErrorMessage className={errorMessageClassName}>
            {errorMessage}
          </ErrorMessage>
        ) : null}
      </div>
    );
  }
);

export default TextField;
