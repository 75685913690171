import { SerializedStyles } from '@emotion/utils';
import {
  ThemeAPI,
  ReviewEntriesThemeAPI,
  FooterThemeAPI,
  HeaderThemeAPI,
  ProgressBarThemeAPI,
  ButtonThemeVariantAPI,
  PopoverInfoThemeAPI,
  PopoverThemeAPI,
} from './themeAPI';
import { GlobalTheme } from './GlobalTheme';
import {
  ArrayCSSInterpolation,
  ComponentSelector,
  CSSObject,
} from '../core/emotion';
import chroma from 'chroma-js';
import PrimaryButtonTheme from './ButtonTheme/PrimaryButtonTheme';
import SecondaryButtonTheme from './ButtonTheme/SecondaryButtonTheme';
import TertiaryButtonTheme from './ButtonTheme/TertiaryButtonTheme';

type CommonTheme = {
  root:
    | string
    | number
    | boolean
    | ComponentSelector
    | SerializedStyles
    | CSSObject
    | ArrayCSSInterpolation;
  content?:
    | string
    | number
    | boolean
    | ComponentSelector
    | SerializedStyles
    | CSSObject
    | ArrayCSSInterpolation;
  loadAnimationColor?: string;
};

class ThemeAPIMapper {
  private themeAPI: ThemeAPI;
  private reviewEntriesTheme!: ReviewEntriesThemeAPI;
  private footerTheme!: FooterThemeAPI;
  private headerTheme!: HeaderThemeAPI;
  private progressBarTheme!: ProgressBarThemeAPI;
  private buttonTheme!: ButtonThemeVariantAPI;
  private popoverInfoTheme: PopoverInfoThemeAPI;
  private popoverTheme!: PopoverThemeAPI;
  private globalTheme!: GlobalTheme;

  constructor(themeAPI: ThemeAPI) {
    this.themeAPI = themeAPI;
    this.initGlobalTheme();
    this.initReviewEntriesTheme();
    this.initFooterTheme();
    this.initHeaderTheme();
    this.initProgressBarTheme();
    this.initButtonTheme();
    this.initPopoverInfoTheme();
    this.initPopoverTheme();
  }

  getRootTheme = () => {
    return this.themeAPI.root ?? {};
  };

  getGlobalTheme = () => {
    return this.globalTheme;
  };

  getPopoverInfo = () => {
    return this.popoverInfoTheme;
  };

  getButtonTheme = () => {
    return this.buttonTheme;
  };

  getDialogTheme = () => {
    const rootDialog = this.themeAPI.dialog?.root ?? {};
    const overlayStyled = this.themeAPI.dialog?.overlay ?? {};
    const closeIconStyled = this.themeAPI.dialog?.closeIcon ?? {};
    const scrollbarStyled = this.themeAPI.dialog?.scrollbar ?? {};

    return {
      root: rootDialog,
      overlay: overlayStyled,
      closeIcon: closeIconStyled,
      scrollbar: scrollbarStyled,
    };
  };

  getDialogConfirmTheme = () => {
    const rootDialog = this.themeAPI.messagePopup?.root ?? {};
    const overlayStyled = this.themeAPI.messagePopup?.overlay ?? {};
    const titleStyled = this.themeAPI.messagePopup?.title ?? {};
    const descriptionStyled = this.themeAPI.messagePopup?.description ?? {};
    const iconStyled = this.themeAPI.messagePopup?.warnIcon ?? {};
    const closeIconStyled = this.themeAPI.messagePopup?.closeIcon ?? {};

    return {
      root: rootDialog,
      overlay: overlayStyled,
      title: titleStyled,
      description: descriptionStyled,
      icon: iconStyled,
      closeIcon: closeIconStyled,
    };
  };

  getLoaderTheme = (): CommonTheme => {
    const rootLoaderDialog = this.themeAPI.loader?.root ?? {};

    return {
      root: rootLoaderDialog,
      loadAnimationColor: this.themeAPI.loader?.loadAnimationColor ?? '',
      content: this.themeAPI.loader?.content ?? {},
    };
  };

  getHeaderMatchTheme = () => {
    const rootStyled = this.themeAPI.headerSelect?.root ?? {};
    const pageHeaderStyled = this.themeAPI.headerSelect?.pageHeader ?? {};
    const contentStyled = this.themeAPI.headerSelect?.content ?? {};
    const tableThStyled = this.themeAPI.headerSelect?.table?.th ?? {};
    const tableTdStyled = this.themeAPI.headerSelect?.table?.td ?? {};
    const tableSelectRowStyled =
      this.themeAPI.headerSelect?.table?.selectRowColor ?? '';
    const tableHoverRowStyled =
      this.themeAPI.headerSelect?.table?.hoverRowColor ?? '';
    const fullScreenStyled = this.themeAPI.headerSelect?.fullScreen ?? {};
    const scrollbarNavigatorColor =
      this.themeAPI.headerSelect?.table?.scrollbar?.navigatorColor ?? '';
    const scrollbarBackgroundColor =
      this.themeAPI.headerSelect?.table?.scrollbar?.backgroundColor ?? '';

    return {
      root: rootStyled,
      pageHeader: pageHeaderStyled,
      content: contentStyled,
      sheetName: this.themeAPI.headerSelect?.sheetName ?? {},
      th: tableThStyled,
      td: tableTdStyled,
      selectRowColor: tableSelectRowStyled,
      hoverRowColor: tableHoverRowStyled,
      fullScreen: fullScreenStyled,
      scrollbarNavigatorColor,
      scrollbarBackgroundColor,
      sheetNameSelected: this.themeAPI.headerSelect?.sheetNameSelected ?? {},
      progressBar: this.themeAPI.headerSelect?.progressBar ?? {},
    };
  };

  getJoinSheetTheme = () => {
    const rootStyled = this.themeAPI.joinSheet?.root ?? {};
    const pageHeader = this.themeAPI.joinSheet?.pageHeader ?? {};
    const contentStyled = this.themeAPI.joinSheet?.content ?? {};
    const tableThStyled = this.themeAPI.joinSheet?.table?.th ?? {};
    const tableTdStyled = this.themeAPI.joinSheet?.table?.td ?? {};
    const tableSelectColumnStyled =
      this.themeAPI.joinSheet?.table?.selectColumnColor ?? '';
    const tableHoverColumnStyled =
      this.themeAPI.joinSheet?.table?.hoverColumnColor ?? '';
    const scrollbarNavigatorColor =
      this.themeAPI.joinSheet?.table?.scrollbar?.navigatorColor ?? '';
    const scrollbarBackgroundColor =
      this.themeAPI.joinSheet?.table?.scrollbar?.backgroundColor ?? '';
    const tableStyled = this.themeAPI.joinSheet?.table?.root;

    return {
      root: rootStyled,
      pageHeader: pageHeader,
      content: contentStyled,
      sheet: this.themeAPI.joinSheet?.sheet ?? {},
      table: tableStyled,
      th: tableThStyled,
      td: tableTdStyled,
      selectColumnColor: tableSelectColumnStyled,
      hoverColumnColor: tableHoverColumnStyled,
      scrollbarNavigatorColor,
      scrollbarBackgroundColor,
      matchStatus: this.themeAPI.joinSheet?.matchStatus ?? {},
    };
  };

  getDropzoneTheme = () => {
    return {
      root: this.themeAPI.dropzone?.root ?? {},
      icon: this.themeAPI.dropzone?.icon ?? {
        box: {},
      },
      content: this.themeAPI.dropzone?.content ?? {},
      container: this.themeAPI.dropzone?.container ?? {},
    };
  };

  getProgressBarTheme = () => {
    return this.progressBarTheme;
  };

  getFooterTheme = () => {
    return this.footerTheme;
  };

  getHeaderTheme = () => {
    return this.headerTheme;
  };

  getColumnMatchTheme = () => {
    return {
      root: this.themeAPI.columnMatch?.root ?? {},
      pageHeader: this.themeAPI.columnMatch?.pageHeader ?? {},
      icon: this.themeAPI.columnMatch?.icon ?? {},
      borderColor: this.themeAPI.columnMatch?.borderColor ?? {},
      matchingTitle: this.themeAPI.columnMatch?.matchingTitle ?? {},
      matchingPercent: this.themeAPI.columnMatch?.matchingPercent ?? {},
      columnMatchHeader: this.themeAPI.columnMatch?.columnMatchHeader ?? {},
      columnMatchValue: this.themeAPI.columnMatch?.columnMatchValue ?? {},
      notMatchingValue: this.themeAPI.columnMatch?.notMatchingValue ?? {},
      buttonJoined: this.themeAPI.columnMatch?.buttonJoined ?? {},
      dialogJoinColumn: this.themeAPI.columnMatch?.dialogJoinColumn ?? {},
      dialogCreateCustomColumn:
        this.themeAPI.columnMatch?.dialogCreateCustomColumn ?? {},
      dialogCreateCustomOption:
        this.themeAPI.columnMatch?.dialogCreateCustomOption ?? {},
    };
  };

  getRequiredColumnsTheme = () => {
    const requiredColumns = this.themeAPI.columnMatch?.requiredColumns ?? {};

    return {
      title: requiredColumns.title,
      hasMatch: requiredColumns.hasMatch,
      hasMatchIcon: requiredColumns.hasMatchIcon,
      notMatch: requiredColumns.notMatch,
      notMatchIcon: requiredColumns.notMatchIcon,
      notMatchError: requiredColumns.notMatchError,
      notMatchErrorIcon: requiredColumns.notMatchErrorIcon,
      showMore: {
        root: requiredColumns.showMore?.root,
        text: requiredColumns.showMore?.text,
        icon: requiredColumns.showMore?.icon,
        badge: requiredColumns.showMore?.badge,
      },
    };
  };

  getReviewEntriesTheme = () => {
    return this.reviewEntriesTheme;
  };

  getSheetSelectTheme = () => {
    return {
      root: this.themeAPI.sheetSelect?.root ?? {},
      pageHeader: this.themeAPI.sheetSelect?.pageHeader ?? {},
      header: this.themeAPI.sheetSelect?.header ?? {},
      sheetName: this.themeAPI.sheetSelect?.sheetName ?? {},
      grid: this.themeAPI.sheetSelect?.grid ?? {},
      list: this.themeAPI.sheetSelect?.list ?? {},
      icon: this.themeAPI.sheetSelect?.icon ?? {},
      pagination: this.themeAPI.sheetSelect?.pagination,
      scrollbar: this.themeAPI.sheetSelect?.scrollbar,
      unSelectSheetName: this.themeAPI.sheetSelect?.unSelectSheetName,
      sheetList: this.themeAPI.sheetSelect?.sheetLists,
      addButton: this.themeAPI.sheetSelect?.addFileButton,
      sheetPreview: this.themeAPI.sheetSelect?.sheetPreview ?? {},
    };
  };

  getPopoverTheme = () => {
    return this.popoverTheme;
  };

  private initReviewEntriesTheme = () => {
    this.reviewEntriesTheme = this.themeAPI.reviewEntries ?? {};
  };

  private initFooterTheme = () => {
    this.footerTheme = {
      root: this.themeAPI.footer?.root ?? {},
    };
  };

  private initHeaderTheme = () => {
    this.headerTheme = {
      root: this.themeAPI.header?.root ?? {},
      title: this.themeAPI.header?.title ?? {},
      description: this.themeAPI.header?.description ?? {},
    };
  };

  private initProgressBarTheme = () => {
    this.progressBarTheme = {
      root: this.themeAPI.progressBar?.root ?? {},
      icon: this.themeAPI.progressBar?.icon ?? {},
      current: this.themeAPI.progressBar?.current ?? {},
      complete: this.themeAPI.progressBar?.complete ?? {},
      incomplete: this.themeAPI.progressBar?.incomplete ?? {},
    };
  };

  private initButtonTheme = () => {
    const primaryButton = new PrimaryButtonTheme(this.themeAPI).generate();
    const secondaryButton = new SecondaryButtonTheme(this.themeAPI).generate();
    const tertiaryButton = new TertiaryButtonTheme(this.themeAPI).generate();

    this.buttonTheme = {
      primary: primaryButton,
      secondary: tertiaryButton, // NOTE: this is intention. Our internal, we use secondary for outline button, but in theme props we called it tertiary
      tertiary: secondaryButton,
    };
  };

  private initPopoverInfoTheme = () => {
    this.popoverInfoTheme = this.themeAPI.popoverInfo ?? {};
  };

  private initPopoverTheme = () => {
    this.popoverTheme = {
      root: this.themeAPI.popover?.root ?? {},
      info: this.themeAPI.popover?.info ?? {},
      warning: this.themeAPI.popover?.warning ?? {},
      error: this.themeAPI.popover?.error ?? {},
    };
  };

  private initGlobalTheme = () => {
    const textColor = this.themeAPI.globals?.textColor;
    const primaryText = this.themeAPI.globals?.primaryTextColor;
    const secondaryText = this.themeAPI.globals?.secondaryTextColor;

    this.globalTheme = new GlobalTheme({
      fontFamily: this.themeAPI.globals?.fontFamily ?? 'Inter',
      primaryTextColor: primaryText
        ? primaryText
        : textColor
        ? textColor
        : '#162338',
      secondaryTextColor: secondaryText
        ? secondaryText
        : textColor
        ? textColor
        : '#8D8D8D',
      backgroundColor: this.themeAPI.globals?.backgroundColor,
      primaryColor: this.themeAPI.globals?.primaryColor,
      secondaryColor: this.themeAPI.globals?.secondaryColor,
      borderRadius: this.themeAPI.globals?.borderRadius,
    });
  };

  private getSecondaryButton = () => {
    if (this.themeAPI.buttons?.secondary) {
      return this.themeAPI.buttons?.secondary;
    }

    const secondaryGlobalColor = this.themeAPI.globals?.secondaryColor;

    if (secondaryGlobalColor) {
      const secondaryColorChroma = chroma(secondaryGlobalColor);
      const secondaryHoverColor = chroma
        .hsl(
          chroma(secondaryColorChroma).get('hsl.h') + 0.3325,
          chroma(secondaryColorChroma).get('hsl.s') - 0.0303,
          chroma(secondaryColorChroma).get('hsl.l') - 0.0647
        )
        .hex();

      return {
        backgroundColor: secondaryGlobalColor,
        borderColor: secondaryGlobalColor,
        ':hover': {
          backgroundColor: secondaryHoverColor,
          borderColor: secondaryHoverColor,
        },
      };
    }

    return;
  };
}

export default ThemeAPIMapper;
