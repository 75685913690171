import { css, cx } from 'core/emotion';
import { useTranslation } from 'react-i18next';
import { Option } from '../types';
import { useTheme } from '../../../../../../theme';

const SearchHeader = ({
  isSearching,
  searchOptions,
  configThemeHeader,
  options,
}: {
  isSearching: boolean;
  searchOptions: Option[];
  configThemeHeader?: string;
  options: Option[];
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <>
      {isSearching || options.length === 0 ? (
        <p
          className={cx(
            'px-4 py-2 text-xs',
            css({
              backgroundColor: theme.getGlobalTheme().getDark50Color(),
              color: theme.getGlobalTheme().getDark500Color(),
            }),
            configThemeHeader
          )}
        >
          {options.length === 0 ? t('txt_options') : t('txt_search_result')}
        </p>
      ) : null}
      {(isSearching && searchOptions.length <= 0) || options.length === 0 ? (
        <p className="block px-4 py-6 text-center text-sm capitalize italic text-gray-400">
          {t('txt_empty')}
        </p>
      ) : null}
    </>
  );
};

export default SearchHeader;
