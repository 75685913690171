import {
  CalculateSimilarityMapper,
  CalculateSimilarityResult,
  DataModel,
  DataModelSheetMatch,
  Sheet,
  SheetColumn,
  SheetColumnDataModelOptionSimilarityList,
} from '@nuvo-importer/common/sdk';
import { DATATYPE } from 'core/dataType';
import { truthyWords, falsyWords } from '../../core/constants/boolean';
import { Observable, map, switchMap } from 'rxjs';
import MatchingDTO from '../dto/MatchingDTO';
import DataModelSheetMatcher from '../DataModelSheetMatcher';

export type MLExecuteOptions = {
  ignoreReuseMappingColumn: boolean;
  enableAlternativeMatch: boolean;
  isNetwork3G: boolean;
};

abstract class BaseMatchingRepository {
  abstract prepareData: (
    allSheets: Sheet[],
    licenseKey: string
  ) => Promise<void>;

  abstract load: () => Observable<unknown>;

  protected abstract matchMlColumns: (
    matchingMapperDTO: MatchingDTO,
    options: MLExecuteOptions
  ) => Observable<CalculateSimilarityResult[]>;

  protected abstract matchMlOptionsInitial: (
    dataModelSheetMatch: DataModelSheetMatch[],
    matchingDTO: MatchingDTO,
    options: MLExecuteOptions,
    sheets: Sheet[]
  ) => Observable<CalculateSimilarityResult[]>;

  protected abstract matchMlOptions: (params: {
    dataModel: DataModel;
    sheetColumn: SheetColumn;
    sheets: Sheet[];
    licenseKey: string;
    options: MLExecuteOptions;
  }) => Observable<CalculateSimilarityResult[]>;

  abstract initialize: () => void;

  abstract clearData: (licenseKey: string) => void;

  abstract complete: (licenseKey: string) => void;

  private executeMatchOptionsInitial = (
    matchingMapperDTO: MatchingDTO,
    options: MLExecuteOptions,
    dataModelSheetMatch: DataModelSheetMatch[],
    baseCalculateSimilarityResult: CalculateSimilarityResult
  ) => {
    const sheets = matchingMapperDTO.getAllSheets();
    const dataModels = matchingMapperDTO.getAllDataModels();

    const allObservables = this.matchMlOptionsInitial(
      dataModelSheetMatch.filter((match) => {
        const dataModel = match.matchedDataModel?.dataModel;
        return !!dataModel && dataModel.isDropdown();
      }),
      matchingMapperDTO,
      options,
      sheets
    );

    return allObservables.pipe(
      map((baseCalculateSimilarityResultsItem) => {
        const baseResult = [...baseCalculateSimilarityResult.result].map(
          (item) => {
            return {
              ...item,
              choice: {},
            };
          }
        );

        let allCalculateSimilarityResults = {
          result: [...baseResult],
        };

        for (let i = 0; i < baseCalculateSimilarityResultsItem.length; ++i) {
          const calculateSimilarityResults =
            CalculateSimilarityMapper.cleanCalculateSimilarityResultOptions(
              baseCalculateSimilarityResultsItem[i]
            );
          allCalculateSimilarityResults =
            CalculateSimilarityMapper.mergeCalculateSimilarityResultOptions(
              allCalculateSimilarityResults,
              calculateSimilarityResults
            );
        }

        this.matchBoolean([allCalculateSimilarityResults], sheets, dataModels);
        const calculateSimilarityMapper = new CalculateSimilarityMapper({
          calculateSimilarityResult: allCalculateSimilarityResults,
          sheets,
          dataModels,
        });

        const sheetColumnDataModelOptionSimilarityList =
          calculateSimilarityMapper.getSheetColumnDataModelOptionSimilarityList();

        return {
          calculateSimilarityResult: allCalculateSimilarityResults,
          sheetColumnDataModelOptionSimilarityList,
        };
      })
    );
  };

  protected parseMlOptions = (options: MLExecuteOptions) => {
    return {
      reuseMappingData: !options.ignoreReuseMappingColumn,
      enableAlternativeMatch: options.enableAlternativeMatch,
      isNetwork3G: options.isNetwork3G,
    };
  };

  protected matchBoolean = (
    calculateSimilarityResults: CalculateSimilarityResult[],
    sheets: Sheet[],
    dataModels: DataModel[]
  ) => {
    calculateSimilarityResults.forEach(
      (calculateSimilarityResult, sheetIndex) => {
        calculateSimilarityResult.result.forEach((result) => {
          const sheet = sheets[sheetIndex];
          const column = sheet.getColumn(result.label);
          if (column) {
            const booleanDataModels = dataModels.filter(
              (dataModel) => dataModel.getType() === DATATYPE.BOOLEAN
            );
            booleanDataModels.forEach((booleanDataModel) => {
              const rows = column.getUniqueRows();

              for (let i = 0; i < rows.length; i++) {
                const row = rows[i];
                let matchedChoice: Record<string, number> | null = null;
                const parsedRow = `${row}`.toLowerCase().trim();

                if (truthyWords().includes(parsedRow)) {
                  matchedChoice = {
                    true: 1,
                  };
                } else if (falsyWords().includes(parsedRow)) {
                  matchedChoice = {
                    false: 1,
                  };
                }

                if (matchedChoice) {
                  const prevMatchingChoice =
                    result.choice[booleanDataModel.getKey()] ?? {};
                  result.choice[booleanDataModel.getKey()] = {
                    ...prevMatchingChoice,
                    [`${row}`]: matchedChoice,
                  };
                }
              }
            });
          }
        });
      }
    );
  };

  matchColumnsAndOptions = (
    matchingMapperDTO: MatchingDTO,
    options: MLExecuteOptions
  ) => {
    const sheets = matchingMapperDTO.getAllSheets();
    const dataModels = matchingMapperDTO.getAllDataModels();
    return this.matchMlColumns(matchingMapperDTO, options)
      .pipe(
        map((calculateSimilarityResults) => {
          const calculateSimilarityMapper = new CalculateSimilarityMapper({
            calculateSimilarityResult: calculateSimilarityResults[0],
            sheets,
            dataModels,
          });
          const sheetColumnDataModelSimilarityList =
            calculateSimilarityMapper.getSheetColumnDataModelSimilarityList();

          return {
            calculateSimilarityResult: calculateSimilarityResults[0],
            sheetColumnDataModelSimilarityList,
          };
        })
      )
      .pipe(
        switchMap(
          ({
            sheetColumnDataModelSimilarityList,
            calculateSimilarityResult,
          }) => {
            const dataModelSheetMatcher = new DataModelSheetMatcher({
              sheetColumnDataModelSimilarityList,
              sheetColumnDataModelOptionSimilarityList:
                new SheetColumnDataModelOptionSimilarityList({
                  sheetColumnDataModelOptionSimilarities: [],
                  mapSheetColumnDataModelOptions: [],
                }),
              dataModels,
              sheets: matchingMapperDTO.getAllSheets(),
              calculateSimilarityResult,
            });

            return this.executeMatchOptionsInitial(
              matchingMapperDTO,
              options,
              dataModelSheetMatcher.getMatching().getMatching(),
              calculateSimilarityResult
            ).pipe(
              map(
                ({
                  sheetColumnDataModelOptionSimilarityList,
                  calculateSimilarityResult:
                    updatedCalculateSimilarityResultOptions,
                }) => {
                  return {
                    sheetColumnDataModelSimilarityList,
                    sheetColumnDataModelOptionSimilarityList,
                    calculateSimilarityResult:
                      updatedCalculateSimilarityResultOptions,
                  };
                }
              )
            );
          }
        )
      );
  };

  matchOptions = ({
    dataModel,
    sheetColumn,
    sheets,
    dataModels,
    licenseKey,
    options,
  }: {
    dataModel: DataModel;
    sheetColumn: SheetColumn;
    sheets: Sheet[];
    dataModels: DataModel[];
    licenseKey: string;
    options: MLExecuteOptions;
  }) => {
    return this.matchMlOptions({
      dataModel,
      sheetColumn,
      sheets,
      licenseKey,
      options,
    }).pipe(
      map((baseCalculateSimilarityResults) => {
        const calculateSimilarityResults =
          CalculateSimilarityMapper.cleanCalculateSimilarityResultOptions(
            baseCalculateSimilarityResults[0]
          );
        this.matchBoolean([calculateSimilarityResults], sheets, dataModels);
        const calculateSimilarityMapper = new CalculateSimilarityMapper({
          calculateSimilarityResult: calculateSimilarityResults,
          sheets,
          dataModels,
        });

        const sheetColumnDataModelOptionSimilarityList =
          calculateSimilarityMapper.getSheetColumnDataModelOptionSimilarityList(
            sheetColumn
          );

        return {
          calculateSimilarityResult: calculateSimilarityResults,
          sheetColumnDataModelOptionSimilarityList,
        };
      })
    );
  };
}

export default BaseMatchingRepository;
