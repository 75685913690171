import DataModelSheetMatcher from './../../matching/DataModelSheetMatcher';
import DataModelSheetMatching from './../../matching/DataModelSheetMatching';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import DataModelSheetMatchingValues, {
  Matching,
} from '../DataModelSheetMatchingValues';
import {
  CategoryDataModel,
  ColumnAPI,
  DataModel,
  useDataModels,
} from 'dataModel';
import { useTranslation } from 'react-i18next';
import { useContextConfirmModalManager } from 'baseUI/Confirm/context';
import { HookedRecordResult, useHooks } from 'hooks';
import {
  useInvalidSubmitHandler,
  useValidSubmitHandler,
} from './SubmitHandler';
import { DataModelSheetFormRef, ValueParser } from 'dataModelSheet';
import { useReviewEntriesConfig } from '../reviewEntriesConfig';
import SpreadSheetNavigate from '../../uploadData/SelectHeaderPage/SpreadSheetNavigate';
import { useScroll } from 'core/scroll';
import { useModal } from '../../main/Modal';
import { forEach, isArray, isNil } from 'lodash';
import { useMainView, useSettings } from 'settings';
import isPromise from 'is-promise';
import { useConfigure } from 'configure';
import { HookedRecordRowResult, HookedRecordValue } from 'hooks';
import { useEffectOnce } from 'core/useEffectOnce';
import { DATATYPE, MAX_DATA_EXPORT } from 'core';
import { FieldValue, Values } from 'core/value';
import { DEFAULT_LEVEL, LEVEL } from 'core/level';
import useInAppNavigate from 'core/navigate';
import { useWidgetContext } from '../../main/WidgetProvider';
import { usePage } from './../../main/MainView/index';
import { SHEET_COLUMN_TYPE } from 'core/constants/sheet';
import matchingValuesBuildWorker from '../../worker/matchingValues.txt';
import hookBuildWorker from '../../worker/hooks.txt';
import { releaseProxy, Remote, wrap } from 'comlink';
import { findColIndexByKey, findDataModel } from '../../dataModel/utils';
import { createWorker } from 'core/worker/createWorker';
import { HookRowResult, Row } from '../../hooks/hooksAPI';
import { allDropdownOptions, breathing, findDropdownOption } from './utils';
import ValueBeforeHookParser from '../../dataModelSheet/valueResultParser/ValueBeforeHookParser';
import {
  CacheOption,
  Sheet,
  ReviewEntriesValidator as Validator,
  getTotalError,
} from '@nuvo-importer/common/sdk';
import { maxHeightOfReviewEntries } from '@nuvo-importer/common/sdk';
import { RecordInfo, RECORD_INFO_SOURCE } from '@nuvo-importer/common/sdk';
import {
  FreezeStrategyStore,
  detectSheetRegion,
  useTheme,
} from '@nuvo-importer/common';
import { isFirefox } from 'react-device-detect';
import { css, cx, CSSObject } from 'core/emotion';
import { useFeatureWhiteList } from '../../configure/ConfigureProvider';
import { REVIEW_ENTRIES_PATH } from 'core/constants/route';
import { CurrencyValue, Value } from '../../types';
import { NumberFormat, ValidateMessageUtil } from '@nuvo-importer/common/core';
import parserDateBuildWorker from '../../worker/dataParser.txt';
import { shouldParseDateFormat } from './utils';

type IDateFormatParseRemote =
  | Remote<{
      parseDateFormat: (values: Value[]) => Value[];
    }>
  | undefined;

type HandleOnEntryInit = (
  params: {
    initResults: [Record<string, HookedRecordResult> | undefined, number][];
    dataInfos: Record<string, RecordInfo[]>;
    initialValues: Values;
  },
  dataModels: DataModel[]
) => {
  dataInfos: Record<string, RecordInfo[]>;
  initialValues: Values;
};

type HandleOnEntryInitWidget = (
  params: {
    dataInfos: Record<string, RecordInfo[]>;
    initialValues: Values;
    resultRows: (HookRowResult | undefined)[];
  },
  dataModels: DataModel[]
) => {
  dataInfos: Record<string, RecordInfo[]>;
  initialValues: Values;
};

type HandleColumnHooks = (
  params: {
    initialValues: Values;
    key: string;
    result: HookedRecordRowResult[];
    colIndex: number;
  },
  dataModels: DataModel[]
) => {
  dataInfos: Record<string, RecordInfo[]>;
  initialValues: Values;
};

type IntegrateColumnHooksData = (params: {
  dataInfos: Record<string, RecordInfo[]>;
  columnDataInfos: Record<string, RecordInfo[]>;
}) => {
  dataInfos: Record<string, RecordInfo[]>;
};

const getJoinDataModel = (matching: Matching[]) => {
  return matching.find((match) => {
    return match.sheetColumn.getType() === SHEET_COLUMN_TYPE.JOIN;
  })?.matchedDataModel?.dataModel;
};

const getDataModels = ({
  dataModels,
  onlyMappedColumns,
  matchedDataModelKeys,
  joinDataModel,
  modifiedDataModels,
}: {
  dataModels: DataModel[];
  onlyMappedColumns?: boolean;
  matchedDataModelKeys: string[];
  joinDataModel?: DataModel;
  modifiedDataModels: DataModel[];
}) => {
  let dataModelColumns = dataModels;

  if (onlyMappedColumns) {
    const onlyMatchedColumns =
      dataModelColumns.filter(
        (col) => !!col && matchedDataModelKeys.includes(col.getKey())
      ) ?? [];
    dataModelColumns = onlyMatchedColumns;
  }

  if (joinDataModel) {
    dataModelColumns = [
      joinDataModel,
      ...dataModelColumns.filter(
        (dataModel) => dataModel.getKey() !== joinDataModel.getKey()
      ),
    ];
  }

  if (modifiedDataModels.length) {
    dataModelColumns = modifiedDataModels;
  }

  return dataModelColumns;
};

const createMemorizeFindDataModel = (dataModels: DataModel[]) => {
  const keyDataModels: Record<string, DataModel> = {};

  for (let i = 0; i < dataModels.length; i++) {
    const element = dataModels[i];
    keyDataModels[element.getKey()] = element;
  }

  return (key: string) => {
    return keyDataModels[key];
  };
};

const useViewModel = () => {
  const dataModel = useDataModels();
  const { t } = useTranslation();
  const { modal, enableExamples } = useMainView();
  const theme = useTheme();
  const { showConfirmModal } = useContextConfirmModalManager();

  const ref = useRef<DataModelSheetFormRef>(null);
  const { enableMassiveErrorAlert } = useReviewEntriesConfig();

  const initialValues = useRef<Values>([]);
  const dataInfos = useRef<Record<string, RecordInfo[]>>({});
  const hasCheckMassiveErrorAlert = useRef<boolean>(false);

  const baseHooksWorkerRef = useRef<Worker>();
  const baseMatchingValuesWorkerRef = useRef<Worker>();
  const baseColumnHooksWorkerRef = useRef<Worker>();
  const dateParserWorkerRef = useRef<Worker>();
  const dateParserRemoteRef = useRef<IDateFormatParseRemote>();

  const [initialWorkerCleanup, setInitialWorkerCleanup] = useState(true);

  const { onEntryInitAllRows } = useWidgetContext();

  const {
    setLoadingInitialValues: setContextLoadingInitialValues,
    dataHandlerMapper,
  } = useHooks();
  const [loadingInitialValues, setLoadingInitialValues] = useState(true);
  const navigate = useInAppNavigate();
  const { allowManualInput, disableTemplateDropdowns } = useSettings();
  const validator = useMemo(() => new Validator(), []);
  const errorCount = useRef(0);
  const { onEntryInit, columnHooks, settings } = useConfigure();

  const { state: locationState } = useLocation();
  const disableExportHasMoreData = useRef(false);
  const { cancel } = usePage();
  const [modifierColumn, setModifierColumn] = useState<ColumnAPI[]>([]);
  const [modifiedDataModels, setModifiedDataModels] = useState<DataModel[]>([]);
  const { featureWhiteList } = useFeatureWhiteList();

  useEffect(() => {
    setContextLoadingInitialValues(loadingInitialValues);
  }, [setContextLoadingInitialValues, loadingInitialValues]);

  const state = locationState as {
    dataModelSheetMatching: DataModelSheetMatching;
    spreadSheetNavigate: SpreadSheetNavigate;
    dataModelSheetMatcher: DataModelSheetMatcher;
    columns: ColumnAPI[];
    dataModels: DataModel[];
    hideStepper: boolean;
    hasBackStep: boolean;
    dynamicValues?: Values;
    dynamicDataInfos?: Record<string, RecordInfo[]>;
    dynamicUploadStart?: string;
    oldSelectedSingleSheetWithoutModified?: Sheet;
  };

  const headerRow = useMemo(() => {
    const headers: string[] = [];
    for (let i = 0; i < state.dataModelSheetMatching?.getSheets().length; ++i) {
      const sheet = state.dataModelSheetMatching?.getSheets()[i];
      for (let j = 0; j < sheet.getColumns().length; ++j) {
        const element = sheet.getColumns()?.[j];
        headers.push(element.getColumnKey());
      }
    }

    if (headers.length === 0) {
      for (let i = 0; i < state.columns.length; ++i) {
        const element = state.columns[i];
        headers.push(element.label);
      }
    }

    return headers;
  }, [state.columns, state.dataModelSheetMatching]);

  const validSubmitHandler = useValidSubmitHandler({
    dataModelSheetMatching: state?.dataModelSheetMatching
      ? state.dataModelSheetMatching
      : new DataModelSheetMatching({
          dataModels: dataModel.getDataModels(),
          dataModelSheetMatch: [],
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          sheetColumnDataModelOptionSimilarityList: {} as any,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          sheetColumnDataModelSimilarityList: {} as any,
          sheets: [],
        }),
    dataModelSheetMatcher: state?.dataModelSheetMatcher,
    headerRow,
  });

  const invalidSubmitHandler = useInvalidSubmitHandler({
    dataModelSheetMatching: state?.dataModelSheetMatching
      ? state.dataModelSheetMatching
      : new DataModelSheetMatching({
          dataModels: dataModel.getDataModels(),
          dataModelSheetMatch: [],
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          sheetColumnDataModelOptionSimilarityList: {} as any,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          sheetColumnDataModelSimilarityList: {} as any,
          sheets: [],
        }),
    dataModelSheetMatcher: state?.dataModelSheetMatcher,
    headerRow,
  });

  const { scrollToTop } = useScroll();

  const isDirectToReviewEntryManual = useMemo(() => {
    return (
      (allowManualInput || state?.hideStepper) &&
      state?.dynamicUploadStart !== REVIEW_ENTRIES_PATH &&
      !state?.dataModelSheetMatching
    );
  }, [
    allowManualInput,
    state?.hideStepper,
    state?.dataModelSheetMatching,
    state?.dynamicUploadStart,
  ]);

  const isDirectToReviewEntryFromDynamicImport = useMemo(() => {
    return (
      state?.dynamicUploadStart === REVIEW_ENTRIES_PATH &&
      !state?.dataModelSheetMatching
    );
  }, [state?.dataModelSheetMatching, state?.dynamicUploadStart]);

  const joinDataModel = useMemo(() => {
    if (isDirectToReviewEntryManual || isDirectToReviewEntryFromDynamicImport)
      return;
    const matching = state?.dataModelSheetMatching.getMatching();

    return getJoinDataModel(matching);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.dataModelSheetMatching]);

  const matchedDataModelKeys = useMemo(
    () => state?.dataModelSheetMatching?.getMatchedDataModelKeys?.() ?? [],
    [state?.dataModelSheetMatching]
  );

  const dataModels = useMemo(() => {
    return getDataModels({
      dataModels: state?.dataModels,
      onlyMappedColumns: settings.onlyMappedColumns,
      matchedDataModelKeys,
      modifiedDataModels,
      joinDataModel,
    });
  }, [
    joinDataModel,
    matchedDataModelKeys,
    modifiedDataModels,
    settings.onlyMappedColumns,
    state?.dataModels,
  ]);

  const columns = useMemo(() => {
    let columns: ColumnAPI[] = state?.columns.map((column) => {
      const isMultiSelect = !!findDataModel(
        dataModels,
        column.key
      )?.getIsMultiSelection();
      return {
        ...column,
        isMultiSelect,
        columnType: !isNil(column.columnType)
          ? column.columnType
          : DATATYPE.STRING,
      };
    });

    if (settings.onlyMappedColumns) {
      const onlyMatchedColumns =
        columns.filter(
          (col) => !!col && matchedDataModelKeys.includes(col.key)
        ) ?? [];
      columns = onlyMatchedColumns;
    }

    if (joinDataModel) {
      const joinColumn = columns.find(
        (column) => column.key === joinDataModel.getKey()
      )!;

      columns = [
        joinColumn,
        ...columns.filter((column) => column.key !== joinDataModel.getKey()),
      ];
    }

    if (modifierColumn.length) {
      columns = modifierColumn;
    }

    return columns;
  }, [
    dataModels,
    joinDataModel,
    matchedDataModelKeys,
    modifierColumn,
    settings.onlyMappedColumns,
    state?.columns,
  ]);

  const entryInitHookTask = useCallback(
    async (
      hooksWorker: Remote<{
        handleOnEntryInit: HandleOnEntryInit;
        handleOnEntryInitWidget: HandleOnEntryInitWidget;
      }>,
      updatedDataModels: DataModel[]
    ) => {
      const memorizeFindDataModel =
        createMemorizeFindDataModel(updatedDataModels);
      if (onEntryInitAllRows) {
        const parsedValues: Values = [];

        for (let i = 0; i < initialValues.current.length; ++i) {
          parsedValues[i] = {};
          const keys = Object.keys(initialValues.current[i]);
          for (let j = 0; j < keys.length; ++j) {
            const key = keys[j];
            const dataModel = memorizeFindDataModel(key);
            parsedValues[i][key] = dataModel
              ? (ValueBeforeHookParser.parse(
                  dataModel,
                  initialValues.current[i][key]
                ) as FieldValue)
              : initialValues.current[i][key];
          }
        }

        const resultRows = await onEntryInitAllRows(parsedValues);

        const workerResult = await hooksWorker.handleOnEntryInitWidget(
          {
            dataInfos: dataInfos.current,
            initialValues: initialValues.current,
            resultRows,
          },
          updatedDataModels
        );

        initialValues.current = workerResult.initialValues;
        dataInfos.current = workerResult.dataInfos;
      } else if (onEntryInit) {
        const promiseList: Promise<{
          resultItem: Record<string, HookedRecordResult> | undefined;
          i: number;
        }>[] = [];

        const initResults: [
          Record<string, HookedRecordResult> | undefined,
          number
        ][] = [];

        for (let i = 0; i < initialValues.current.length; i++) {
          const v: Row = {};

          const keys = Object.keys(initialValues.current[i]);
          for (let j = 0; j < keys.length; ++j) {
            const key = keys[j];
            const dataModel = memorizeFindDataModel(key);
            v[key] = dataModel
              ? (ValueBeforeHookParser.parse(
                  dataModel,
                  initialValues.current[i][key]
                ) as FieldValue)
              : initialValues.current[i][key];
          }

          const result = onEntryInit(v, i);
          if (isPromise(result)) {
            promiseList.push(
              result.then((resultItem) => {
                return {
                  resultItem,
                  i,
                };
              })
            );
          } else {
            initResults.push([result, i]);
          }
        }

        await Promise.all(promiseList).then((results) => {
          results.forEach(({ i, resultItem }) => {
            initResults.push([resultItem, i]);
          });
        });

        const result = await hooksWorker.handleOnEntryInit(
          {
            dataInfos: dataInfos.current,
            initialValues: initialValues.current,
            initResults,
          },
          updatedDataModels
        );

        initialValues.current = result.initialValues;
        dataInfos.current = result.dataInfos;
      }
    },
    [onEntryInit, onEntryInitAllRows]
  );

  const columnHookTask = useCallback(
    async (
      hooksWorker: Remote<{
        handleColumnHooks: HandleColumnHooks;
        integrateColumnHooksData: IntegrateColumnHooksData;
      }>,
      updatedDataModels: DataModel[]
    ) => {
      if (columnHooks) {
        if (typeof columnHooks === 'function') {
          (columnHooks as () => void)();
          return;
        }

        const memorizeFindDataModel =
          createMemorizeFindDataModel(updatedDataModels);

        for (const [key, callback] of Object.entries(columnHooks)) {
          const colIndex = findColIndexByKey(key, updatedDataModels);
          const dataModel = memorizeFindDataModel(key);
          const columnValues: HookedRecordValue[] = [];
          const columnDataInfos: Record<string, RecordInfo[]> = {};

          for (let i = 0; i < initialValues.current.length; ++i) {
            columnValues.push([
              dataModel
                ? (ValueBeforeHookParser.parse(
                    dataModel,
                    initialValues.current[i][key]
                  ) as FieldValue)
                : initialValues.current[i][key],
              i,
            ]);
            columnDataInfos[i] = (dataInfos.current[i] ?? []).filter(
              (entry) => entry.colIndex === colIndex
            );
          }

          const columnResult = await callback(columnValues);

          const result = await hooksWorker.handleColumnHooks(
            {
              initialValues: initialValues.current,
              key: key,
              result: columnResult,
              colIndex,
            },
            updatedDataModels
          );

          if (result.dataInfos) {
            const dataInfosResult = await hooksWorker.integrateColumnHooksData({
              dataInfos: dataInfos.current,
              columnDataInfos: result.dataInfos,
            });

            dataInfos.current = dataInfosResult.dataInfos;
          }

          initialValues.current = result.initialValues;
        }
      }
    },
    [columnHooks]
  );

  const addInfoForCustomOption = useCallback(
    (updatedDataModels: DataModel[]) => {
      for (let i = 0; i < initialValues.current.length; ++i) {
        const columnKeys = Object.keys(initialValues.current[i]);
        columnKeys.forEach((key) => {
          const dataModel = findDataModel(updatedDataModels, key);
          if (dataModel && dataModel.isCategoryType()) {
            const categoryDataModel = dataModel as CategoryDataModel;

            const customOptions = categoryDataModel
              .getOptions()
              .filter((option) => {
                return !!option.creator;
              });

            if (customOptions.length > 0) {
              const colIndex = findColIndexByKey(key, updatedDataModels);
              const value = initialValues.current[i][key];
              const isValueInCustomOption = (() => {
                if (dataModel.getIsMultiSelection()) {
                  if (isArray(value)) {
                    return value.every((valueItem) =>
                      customOptions.some((item) => item.value === valueItem)
                    );
                  } else {
                    return false;
                  }
                } else {
                  return customOptions.some((option) => option.value === value);
                }
              })();

              if (isValueInCustomOption) {
                const obj = {
                  rowIndex: i,
                  colIndex: colIndex,
                  popover: {
                    message: t('txt_option_custom_created_info'),
                    level: LEVEL.INFO,
                  },
                };
                if ((dataInfos.current[i]?.length ?? 0) === 0) {
                  dataInfos.current[i] = [obj];
                } else {
                  dataInfos.current[i].push(obj);
                }
              }
            }
          }
        });
      }
    },
    [t]
  );

  const checkEnableMassiveErrorAlert = useCallback(
    (totalErr: number) => {
      if (enableMassiveErrorAlert && !hasCheckMassiveErrorAlert.current) {
        hasCheckMassiveErrorAlert.current = true;
        const disableExportHasMoreData =
          initialValues.current.length >= MAX_DATA_EXPORT;

        if (totalErr >= enableMassiveErrorAlert) {
          showConfirmModal({
            isShowIcon: true,
            disabledPositiveButton: disableExportHasMoreData,
            title: t('txt_massive_error'),
            description: t('txt_import_data_error_exceed', {
              error: errorCount.current,
            }),
            textNegativeButton: t('txt_continue'),
            textPositiveButton: t('txt_export_as_excel'),
            isPopper: disableExportHasMoreData,
            textPopper: t('txt_not_export_file'),
            onClickNegativeButton: () => {},
            onClickPositiveButton: () => {
              ref.current?.exportValuesToXlsx(
                disableTemplateDropdowns ?? false
              );
            },
          });
        }
      }
    },
    [disableTemplateDropdowns, enableMassiveErrorAlert, showConfirmModal, t]
  );

  const memorizeAllDropdownOptions = useMemo(() => {
    const items = allDropdownOptions(dataModels);
    return items;
  }, [dataModels]);

  const memorizeFindDropdownOption = useCallback(
    (
      columnIndex: number,
      value: string,
      optionValueType: 'label' | 'value' | 'both'
    ) => {
      return findDropdownOption(
        columnIndex,
        value,
        memorizeAllDropdownOptions,
        optionValueType
      );
    },

    [memorizeAllDropdownOptions]
  );

  const parseDateFormatAsColumnDateType = async () => {
    const matching = state.dataModelSheetMatching?.getMatching() ?? [];

    for (let i = 0; i < matching.length; i++) {
      const col = matching[i];
      if (
        shouldParseDateFormat(
          col.matchedDataModel?.dataModel?.getType(),
          col.sheetColumn.hasParsedDate()
        )
      ) {
        const newRows: Value[] =
          (await dateParserRemoteRef?.current?.parseDateFormat(
            col.sheetColumn.getRows()
          )) || [];
        col.sheetColumn.setParsedDate(true);
        col.sheetColumn.setRows(newRows);
      }
    }
  };

  useEffectOnce(() => {
    try {
      localStorage.removeItem(CacheOption);
      // eslint-disable-next-line no-empty
    } catch (err) {}
    dateParserWorkerRef.current = createWorker(parserDateBuildWorker);
    const baseMatchingValuesWorker = createWorker(matchingValuesBuildWorker);
    const baseHooksWorker = createWorker(hookBuildWorker);
    const baseColumnHooksWorker = createWorker(hookBuildWorker);

    baseHooksWorkerRef.current = baseMatchingValuesWorker;
    baseMatchingValuesWorkerRef.current = baseHooksWorker;
    baseColumnHooksWorkerRef.current = baseColumnHooksWorker;

    const DataModelSheetMatchingValuesWorker = wrap<
      typeof DataModelSheetMatchingValues
    >(baseMatchingValuesWorker);

    const hooksWorker = wrap<{
      handleOnEntryInit: HandleOnEntryInit;
      handleOnEntryInitWidget: HandleOnEntryInitWidget;
    }>(baseHooksWorker);

    const columnHooksWorker = wrap<{
      handleColumnHooks: HandleColumnHooks;
      integrateColumnHooksData: IntegrateColumnHooksData;
    }>(baseColumnHooksWorker);

    dateParserRemoteRef.current = wrap<{
      parseDateFormat: (values: Value[]) => Value[];
    }>(dateParserWorkerRef.current);

    dataInfos.current = {};

    const runnable = async () => {
      await parseDateFormatAsColumnDateType();

      const matching = state?.dataModelSheetMatching?.getMatching() ?? [];
      let numberFormat = NumberFormat.EU;
      if (state.spreadSheetNavigate) {
        numberFormat = detectSheetRegion(matching);
        const spreadSheet = state.spreadSheetNavigate.getCurrentSpreadSheet();
        spreadSheet.setNumberFormat(numberFormat);
      }

      const dataModelSheetMatchingValues =
        await new DataModelSheetMatchingValuesWorker({
          matching,
          numberFormat,
        });

      if (isDirectToReviewEntryManual) {
        const initialRow: Record<string, string> = {};
        for (let i = 0; i < columns.length; ++i) {
          initialRow[columns[i].key] = '';
        }
        initialValues.current = [initialRow];
      } else if (isDirectToReviewEntryFromDynamicImport) {
        initialValues.current = state.dynamicValues ?? [];
        dataInfos.current = state.dynamicDataInfos ?? {};
      } else {
        const matchingValues = await dataModelSheetMatchingValues.getValues();
        initialValues.current = matchingValues;
      }

      let updatedDataModels = getDataModels({
        dataModels,
        joinDataModel,
        matchedDataModelKeys,
        modifiedDataModels: [],
        onlyMappedColumns: settings.onlyMappedColumns,
      });

      if (
        !isDirectToReviewEntryManual &&
        !isDirectToReviewEntryFromDynamicImport &&
        featureWhiteList.getDataHandler()
      ) {
        const {
          data: modifiedData,
          columns: modifiedColumns,
          dataModels: modifiedDataModels,
          dataInfos: modifiedDataInfos,
        } = await dataHandlerMapper.reviewStep.getDataModifier()({
          data: initialValues.current,
          columns,
          dataModels,
        });
        setModifierColumn(modifiedColumns);
        setModifiedDataModels(modifiedDataModels);
        updatedDataModels = getDataModels({
          dataModels,
          joinDataModel,
          matchedDataModelKeys,
          modifiedDataModels,
          onlyMappedColumns: settings.onlyMappedColumns,
        });
        for (let i = 0; i < modifiedData.length; i++) {
          const entry = modifiedData[i];
          valueParser(modifiedDataModels, entry, entry);
        }

        dataInfos.current = modifiedDataInfos;
        initialValues.current = modifiedData as Values;
      }

      if (settings.preloadData && isDirectToReviewEntryManual) {
        const parsedPreloadData = [];
        for (let index = 0; index < settings.preloadData.length; index++) {
          const entry = { ...settings.preloadData[index] };
          const parsedEntry: {
            [x: string]: FieldValue;
          } = {};
          valueParser(updatedDataModels, entry, parsedEntry);
          parsedPreloadData.push(parsedEntry);
        }

        const dataInfosRowIndexes = Object.keys(dataInfos.current);
        const newDataInfos: Record<string, RecordInfo[]> = {};
        for (let i = 0; i < dataInfosRowIndexes.length; ++i) {
          const rowIndex = Number(dataInfosRowIndexes[i]);
          const tmp = dataInfos.current[rowIndex];
          newDataInfos[rowIndex + parsedPreloadData.length] = tmp;
        }
        dataInfos.current = newDataInfos;
        initialValues.current = parsedPreloadData.concat(initialValues.current);
      }

      baseMatchingValuesWorker.terminate();

      if (!isManualInput) {
        await columnHookTask(columnHooksWorker, updatedDataModels);
        baseColumnHooksWorker.terminate();
        await breathing(50);

        await entryInitHookTask(hooksWorker, updatedDataModels);
        addInfoForCustomOption(updatedDataModels);
        await breathing(200);

        hooksWorker[releaseProxy]();
        baseHooksWorker.terminate();
      }

      setLoadingInitialValues(false);
    };

    const timeout = setTimeout(() => {
      runnable();
    }, 500);

    return () => {
      clearTimeout(timeout);
      dataInfos.current = {};
      baseHooksWorker.terminate();
      baseMatchingValuesWorker.terminate();
      baseColumnHooksWorker.terminate();
      dateParserRemoteRef?.current?.[releaseProxy]?.();
      dateParserWorkerRef?.current?.terminate();
      dataHandlerMapper.reviewStep.cleanUp();
    };
  });

  const valueParser = (
    dataModels: DataModel[],
    values: Record<string, FieldValue | CurrencyValue>,
    targetObject: Record<string, FieldValue | CurrencyValue>
  ) => {
    for (const [key, value] of Object.entries(values)) {
      const dataModel = findDataModel(dataModels, key);
      if (dataModel) {
        let numberFormat = NumberFormat.EU;
        const targetNumberFormat = dataModel.getNumberFormat();

        if (state.spreadSheetNavigate) {
          numberFormat = state.spreadSheetNavigate
            .getCurrentSpreadSheet()
            .getNumberFormat();
        } else if (targetNumberFormat) {
          numberFormat = targetNumberFormat;
        } else {
          numberFormat = NumberFormat.EU;
        }

        const parsedValue = ValueParser.parse(value, {
          dataModel,
          numberFormat,
        });
        if (parsedValue && dataModel.isDropdown()) {
          const columnIndex = dataModels.findIndex(
            (entry) => dataModel === entry
          );
          if (isArray(parsedValue)) {
            const values: string[] = [];
            for (let i = 0; i < parsedValue.length; i++) {
              const entryValue = memorizeFindDropdownOption(
                columnIndex,
                `${parsedValue[i]}`,
                'both'
              );
              values.push(entryValue);
            }
            targetObject[key] = values;
          } else {
            targetObject[key] = memorizeFindDropdownOption(
              columnIndex,
              `${parsedValue}`,
              'both'
            );
          }
        } else {
          targetObject[key] = parsedValue;
        }
      }
    }
  };

  const onBackClick = () => {
    if (isDirectToReviewEntryFromDynamicImport) {
      showConfirmModal({
        isShowIcon: true,
        title: t('txt_confirm_title'),
        description: t('txt_go_back_dynamic_import_title'),
        textNegativeButton: t('txt_confirm_leave_confirm_btn'),
        textPositiveButton: t('txt_confirm_leave_cancel_btn'),
        onClickNegativeButton: () => {
          scrollToTop();
          dataHandlerMapper.reviewStep.cleanUp();
          FreezeStrategyStore.cleanUp();
          cancel();
        },
      });
    } else {
      showConfirmModal({
        isShowIcon: true,
        title: t('txt_confirm_title'),
        description: t('txt_back_page_dialog'),
        textNegativeButton: t('txt_go_back'),
        textPositiveButton: t('txt_cancel'),
        onClickNegativeButton: () => {
          scrollToTop();
          dataHandlerMapper.reviewStep.cleanUp();
          FreezeStrategyStore.cleanUp();
          if (isDirectToReviewEntryManual) {
            cancel();
          } else {
            navigate(
              {
                pathname: '/match-column',
              },
              {
                state: {
                  dataModelSheetMatching: state.dataModelSheetMatching,
                  dataModelSheetMatcher: state.dataModelSheetMatcher,
                  spreadSheetNavigate: state.spreadSheetNavigate,
                  dynamicUploadStart: state?.dynamicUploadStart,
                  oldSelectedSingleSheetWithoutModified:
                    state?.oldSelectedSingleSheetWithoutModified,
                },
              }
            );
          }
        },
      });
    }
  };

  const { closeFullScreen } = useModal();

  useEffect(() => {
    return () => {
      closeFullScreen();
    };
  }, [closeFullScreen]);

  const handleChangeInfo = useCallback(
    (
      entryChange: Record<string, HookedRecordResult>,
      rowIndex: number,
      sourceCol: string
    ) => {
      clearChangeInfo(rowIndex, findColIndexByKey(sourceCol, dataModels));

      forEach(entryChange, (recordResult, key) => {
        const colIndex = findColIndexByKey(key, dataModels);
        recordResult.info?.forEach((info) => {
          const infoData = {
            rowIndex: rowIndex,
            colIndex: colIndex,
            popover: {
              message: info.message,
              level: info.level || DEFAULT_LEVEL,
              source: RECORD_INFO_SOURCE.CHANGE,
            },
          };
          if (dataInfos.current[rowIndex]) {
            dataInfos.current[rowIndex].push(infoData);
          } else {
            dataInfos.current[rowIndex] = [infoData];
          }
        });
      });
    },
    [dataModels]
  );

  const clearChangeInfo = (row: number, _col: number) => {
    for (let i = dataInfos.current[row]?.length - 1; i >= 0; --i) {
      dataInfos.current[row].splice(i, 1);
    }
  };

  const onValidateInitialFinish = useCallback(
    (dataSetLength: number) => {
      errorCount.current = getTotalError({
        dataSetLength,
        columnsLength: columns.length,
        dataInfos: dataInfos.current,
        errors: validator.getError(),
      });

      checkEnableMassiveErrorAlert(errorCount.current);
      disableExportHasMoreData.current = dataSetLength > MAX_DATA_EXPORT;

      // NOTE: Filter `dataInfos` from the `data` that are already part of the validation errors.
      if (isDirectToReviewEntryFromDynamicImport) {
        const errors = validator.getError();

        Object.keys(dataInfos.current).forEach((columnKey: string) => {
          const uniqueInfos: RecordInfo[] = [];
          dataInfos.current[columnKey].forEach((info: RecordInfo) => {
            const validationError = errors[info.rowIndex]?.[info.colIndex];

            if (validationError) {
              const validationMessage = ValidateMessageUtil.getValidateMessage(
                t,
                validationError,
                columns,
                columns
              );
              if (info.popover.message !== validationMessage) {
                uniqueInfos.push(info);
              }
            } else {
              uniqueInfos.push(info);
            }
          });
          dataInfos.current[columnKey] = uniqueInfos;
        });
      }
    },
    [
      checkEnableMassiveErrorAlert,
      columns,
      isDirectToReviewEntryFromDynamicImport,
      t,
      validator,
    ]
  );

  const updateTotalError = useCallback((diffErrorCount: number) => {
    errorCount.current = errorCount.current + diffErrorCount;
  }, []);

  useEffect(() => {
    return () => {
      if (!initialWorkerCleanup) {
        baseHooksWorkerRef.current?.terminate();
        baseMatchingValuesWorkerRef.current?.terminate();
        baseColumnHooksWorkerRef.current?.terminate();
      }
      setInitialWorkerCleanup(false);
    };
  }, [initialWorkerCleanup]);

  const isManualInput = useMemo(() => {
    return (
      !state?.dataModelSheetMatching && !isDirectToReviewEntryFromDynamicImport
    );
  }, [state, isDirectToReviewEntryFromDynamicImport]);

  const configTheme = useMemo(() => {
    return {
      reviewEntriesTheme: theme.getReviewEntriesTheme(),
      popover: theme.getPopoverTheme(),
      popoverInfo: theme.getPopoverInfo(),
    };
  }, [theme]);

  const getStyleTable = useCallback(
    ({
      mediaSize,
      isXsLargeScreen,
      dataLength,
      numOfEmptyRows,
    }: {
      mediaSize: boolean;
      isXsLargeScreen: boolean;
      dataLength: number;
      numOfEmptyRows: number;
    }) => {
      const baseHeightRow = mediaSize ? 22 : 33;
      const heightExpandExampleRow = 595;
      const heightExpandRow = 560;
      const rowLength = dataLength + numOfEmptyRows;
      const maxHeightPage = isXsLargeScreen
        ? 'calc(100vh - 270px)'
        : heightExpandRow;
      const minHeightPage = isXsLargeScreen ? 'calc(100vh - 270px)' : 'unset';
      const maxHeightModal = '100%';
      const minHeightModal = 'unset';

      return {
        height: Math.min(
          enableExamples
            ? rowLength * baseHeightRow + heightExpandExampleRow
            : rowLength * baseHeightRow + heightExpandRow,
          maxHeightOfReviewEntries
        ),
        maxHeight: !modal ? maxHeightPage : maxHeightModal,
        minHeight: !modal ? minHeightPage : minHeightModal,
      };
    },
    [enableExamples, modal]
  );

  const wrapperStyle = useMemo(() => {
    const textExample = t('txt_example');
    return cx(
      'data-review handsontable border-1 rounded-medium flex-grow border-gray-250 bg-gray-50 overflow-hidden',
      !isFirefox && 'not_firefox',
      enableExamples ? 'enable-example' : '',
      modal ? 'flex-shrink mb-4' : 'mb-0',
      css(
        enableExamples && {
          '&& .handsontable thead tr th:first-child::before':
            configTheme?.reviewEntriesTheme?.table?.example,
          borderTopColor: (
            configTheme?.reviewEntriesTheme?.table?.example as CSSObject
          )?.backgroundColor,
        }
      ),
      enableExamples &&
        css`
          .handsontable thead tr th:first-child::before {
            content: '${textExample}';
          }
        `
    );
  }, [t, enableExamples, modal, configTheme]);

  return {
    dataModels,
    initialValues,
    validSubmitHandler,
    invalidSubmitHandler,
    ref,
    loadingInitialValues,
    dataInfos,
    validator,
    errorCount,
    disableExportHasMoreData,
    columns,
    isManualInput,
    wrapperStyle,
    configTheme,
    onBackClick,
    handleChangeInfo,
    onValidateInitialFinish,
    updateTotalError,
    getStyleTable,
    showConfirmModal,
  };
};

export default useViewModel;
