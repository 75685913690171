import React from 'react';
import { useEffectOnce } from 'core/useEffectOnce';
import Tracking, { FRAMEWORK } from './Tracking';

type ImporterMeta = {
  framework?: {
    name?: FRAMEWORK;
    version?: string;
  };
  iframe?: boolean;
};

export const useTracking = (meta: ImporterMeta): void => {
  useEffectOnce(() => {
    if (meta?.iframe) {
      return;
    }

    Tracking.setFrameWork(meta?.framework?.name || FRAMEWORK.REACT);
    Tracking.setFrameworkVersion(
      meta?.framework?.name === FRAMEWORK.VANILLA
        ? ''
        : meta?.framework?.version || React.version
    );
    Tracking.setOrigin(window?.origin || '');
  });
};
