import { useNetworkConnection, LIST_NETWORK_SLOW } from './../hooks/useNetwork';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { MatchingDTO } from 'matching/dto';
import DataModelSheetMatcher from './../matching/DataModelSheetMatcher';
import DataModelSheetMatching from './../matching/DataModelSheetMatching';
import { useMatching } from './../matching/MatchingProvider';
import { useDataModels } from 'dataModel';
import SpreadSheetNavigate from '../uploadData/SelectHeaderPage/SpreadSheetNavigate';
import { CONFIG_ANIM_TIME } from 'core/constants/time';
import { useMainView, useSettings } from 'settings';
import { useConfigure } from 'configure';
import { useIgnoreReuseMappingColumns } from '../settings/reuseMappingColumn';
import { useFeatureWhiteList } from '../configure/ConfigureProvider';
import { delay, first, map, mergeMap, Observable, of, switchMap } from 'rxjs';
import { useSubmit } from './DataModelSheetMatcherForm/submit';
import { MATCH_COLUMN_PATH, REVIEW_ENTRIES_PATH } from 'core/constants/route';
import type { MLExecuteOptions } from '../matching/MatchingRepository/MatchingRepository';
import AutoMatching from '../matching/AutoMatching';
import AutoMatchingLocalStorage from '../matching/autoMatching/AutoMatchingLocalStorage';
import AutoMatchingAPI from '../matching/autoMatching/AutoMatchingAPI';
import { useEffectOnce } from 'core/useEffectOnce';
import { useContextConfirmModalManager } from 'baseUI/Confirm/context';
import { useTranslation } from 'react-i18next';
import { usePage } from 'main/MainView';
import type { ProgressProcessingImperativeHandle } from 'baseUI/Processing';
import { Sheet } from '@nuvo-importer/common/sdk';

const useViewModel = () => {
  const {
    ml,
    isLoadingModel,
    setExecuting: contextSetExecuting,
  } = useMatching();
  const [dataModelSheetMatching, setDataModelSheetMatching] =
    useState<DataModelSheetMatching>();
  const [dataModelSheetMatcher, setDataModelSheetMatcher] =
    useState<DataModelSheetMatcher>();
  const dataModel = useDataModels();

  const { state: locationState } = useLocation();
  const [executing, setExecuting] = useState(true);
  const { modal } = useMainView();
  const { licenseKey } = useConfigure();
  const ignoreReuseMappingColumn = useIgnoreReuseMappingColumns();
  const { featureWhiteList } = useFeatureWhiteList();
  const networkConnection = useNetworkConnection();
  const [timer, setTimer] = useState(1);
  const [hasDataModel, setHasDataModel] = useState(false);
  const { automaticMapping } = useSettings();
  const navigate = useNavigate();
  const { getColumnsAndDataModels } = useSubmit();
  const [isShowFieldRequired, setShowFieldRequired] = useState<boolean>(false);
  const { showConfirmModal } = useContextConfirmModalManager();
  const { t } = useTranslation();
  const { closePage } = usePage();
  const progressProcessingRef =
    useRef<ProgressProcessingImperativeHandle>(null);

  const state = locationState as {
    spreadSheetNavigate?: SpreadSheetNavigate;
    dataModelSheetMatching?: DataModelSheetMatching;
    dataModelSheetMatcher?: DataModelSheetMatcher;
    hasBackStep?: boolean;
    dynamicUploadStart?: string;
    oldSelectedSingleSheetWithoutModified?: Sheet;
  };

  useEffect(() => {
    contextSetExecuting(executing);
  }, [executing, contextSetExecuting]);

  const mlExecuteOptions = useMemo<MLExecuteOptions>(() => {
    return {
      ignoreReuseMappingColumn,
      enableAlternativeMatch:
        featureWhiteList.getEnableMultiLangFuzzyMatching(),
      isNetwork3G: LIST_NETWORK_SLOW.includes(networkConnection.effectiveType),
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [featureWhiteList, ignoreReuseMappingColumn]);

  const autoMatching = useMemo(() => {
    return new AutoMatching(
      new AutoMatchingLocalStorage(),
      new AutoMatchingAPI(),
      dataModel.getDataModels()
    );
  }, [dataModel]);

  useEffectOnce(() => {
    if (dataModel.getDataModels().length === 0) {
      console.error('The column of target data model is empty array');
    }

    const spreadSheetList = state.spreadSheetNavigate?.getSpreadSheetList();
    if (state.dataModelSheetMatching && state.dataModelSheetMatcher) {
      setDataModelSheetMatching(state.dataModelSheetMatching);
      setDataModelSheetMatcher(state.dataModelSheetMatcher);
      setTimeout(() => {
        setExecuting(false);
      }, CONFIG_ANIM_TIME.MEDIUM);
    } else if (spreadSheetList && !isLoadingModel) {
      const prepareDataObservable = new Observable((subscriber) => {
        ml.initialize();
        const prepareData = () => {
          ml.prepareData(spreadSheetList.getSelectedSheets(), licenseKey)
            .then(() => {
              subscriber.next();
              subscriber.complete();
            })
            .catch(() => {
              showConfirmModal({
                title: t('txt_matching_error_title'),
                description: t('txt_matching_error_description'),
                onClickNegativeButton: () => {
                  progressProcessingRef.current?.reset();
                  prepareData();
                },
                onClickPositiveButton: () => {
                  closePage();
                  subscriber.complete();
                },
                isShowIcon: true,
                isShowCloseIcon: false,
                disabledClickOutside: true,
                textNegativeButton: t('txt_retry_button'),
                textPositiveButton: t('txt_close'),
              });
            });
        };

        prepareData();
      });

      const dataModels = dataModel.getDataModels();

      const matchingMapperDTO = new MatchingDTO({
        spreadSheetList,
        dataModels,
        licenseKey,
      });

      const matchingByMl = () => {
        return of('')
          .pipe(delay(300))
          .pipe(
            switchMap(() => {
              return ml
                .matchColumnsAndOptions(matchingMapperDTO, mlExecuteOptions)
                .pipe(
                  map(
                    ({
                      sheetColumnDataModelSimilarityList,
                      sheetColumnDataModelOptionSimilarityList,
                      calculateSimilarityResult,
                    }) => {
                      const dataModelSheetMatcher = new DataModelSheetMatcher({
                        sheetColumnDataModelSimilarityList,
                        sheetColumnDataModelOptionSimilarityList,
                        dataModels,
                        sheets: spreadSheetList.getSelectedSheets(),
                        calculateSimilarityResult,
                      });

                      const dataModelSheetMatching =
                        dataModelSheetMatcher.getMatching();

                      const isFromSaving = false;

                      return {
                        dataModelSheetMatcher,
                        dataModelSheetMatching,
                        isFromSaving,
                      };
                    }
                  )
                );
            })
          );
      };

      let matchingObservable: Observable<{
        dataModelSheetMatcher: DataModelSheetMatcher;
        dataModelSheetMatching: DataModelSheetMatching;
        isFromSaving: boolean;
      }>;

      if (automaticMapping && state?.dynamicUploadStart !== MATCH_COLUMN_PATH) {
        matchingObservable = autoMatching
          .getAndSaveAutoMatching(
            spreadSheetList.getSelectedSheets(),
            licenseKey
          )
          .pipe(
            mergeMap((result) => {
              if (result) {
                return of({
                  isFromSaving: true,
                  dataModelSheetMatching: result.dataModelSheetMatching,
                  dataModelSheetMatcher: new DataModelSheetMatcher({
                    sheetColumnDataModelSimilarityList:
                      result.dataModelSheetMatching.getSheetColumnDataModelSimilarityList(),
                    sheetColumnDataModelOptionSimilarityList:
                      result.dataModelSheetMatching.getSheetColumnDataModelOptionSimilarityList(),
                    dataModels,
                    sheets: spreadSheetList.getSelectedSheets(),
                    calculateSimilarityResult: result.calculateSimilarityResult,
                  }),
                });
              } else {
                return matchingByMl();
              }
            })
          );
      } else {
        matchingObservable = matchingByMl();
      }

      const subscription = prepareDataObservable
        .pipe(
          switchMap(() => matchingObservable),
          first()
        )
        .subscribe({
          next: ({
            dataModelSheetMatcher,
            dataModelSheetMatching,
            isFromSaving,
          }) => {
            state.dataModelSheetMatcher = dataModelSheetMatcher;
            state.dataModelSheetMatching = dataModelSheetMatching;

            setDataModelSheetMatcher(dataModelSheetMatcher);
            setDataModelSheetMatching(dataModelSheetMatching);

            const {
              reviewEntriesColumns,
              reviewEntriesDataModels,
              notMatchedDataModel,
            } = getColumnsAndDataModels(
              dataModelSheetMatching,
              dataModelSheetMatching.getMatching()
            );

            const isHasNotMatchedRequiredDataModel =
              notMatchedDataModel.length > 0;

            if (isFromSaving && isHasNotMatchedRequiredDataModel) {
              setShowFieldRequired(true);
            }

            if (
              isFromSaving &&
              !isHasNotMatchedRequiredDataModel &&
              !state?.hasBackStep
            ) {
              navigate(
                {
                  pathname: REVIEW_ENTRIES_PATH,
                },
                {
                  state: {
                    dataModelSheetMatching: dataModelSheetMatching,
                    dataModelSheetMatcher: dataModelSheetMatcher,
                    spreadSheetNavigate: state.spreadSheetNavigate,
                    columns: reviewEntriesColumns,
                    dataModels: reviewEntriesDataModels,
                    isAutoMapping: true,
                    dynamicUploadStart: state?.dynamicUploadStart,
                    oldSelectedSingleSheetWithoutModified:
                      state?.oldSelectedSingleSheetWithoutModified,
                  },
                }
              );
            } else {
              setTimeout(() => {
                setExecuting(false);
                contextSetExecuting(false);
              }, CONFIG_ANIM_TIME.SHORT);
            }
          },
          error: () => {
            setTimeout(() => {
              setExecuting(false);
              contextSetExecuting(false);
            }, CONFIG_ANIM_TIME.SHORT);
            showConfirmModal({
              title: t('txt_matching_error_title'),
              description: t('txt_matching_error_description'),
              onClickNegativeButton: () => {
                closePage();
              },
              isShowCloseIcon: false,
              onClickPositiveButton: () => {},
              isShowIcon: true,
              isShowPositiveButton: false,
              disabledClickOutside: true,
              textNegativeButton: t('txt_close'),
            });
          },
        });

      return () => {
        subscription.unsubscribe();
      };
    }
    return () => {};
  });

  useEffect(() => {
    const spreadSheetList = state.spreadSheetNavigate?.getSpreadSheetList();
    if (state.dataModelSheetMatching && state.dataModelSheetMatcher) {
      setHasDataModel(true);
      setTimer(5);
    } else if (spreadSheetList && !isLoadingModel) {
      setTimer(100);
    }

    return () => {};
  }, [state, isLoadingModel]);

  return {
    dataModelSheetMatching,
    isLoadingModel,
    dataModelSheetMatcher,
    executing,
    modal,
    timer,
    hasDataModel,
    ml,
    isShowFieldRequired,
    setShowFieldRequired,
    setDataModelSheetMatcher,
    mlExecuteOptions,
    progressProcessingRef,
    setDataModelSheetMatching,
  };
};

export default useViewModel;
