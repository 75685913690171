import {
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import Sheet from './../../core/sheet/Sheet';
import { isNil } from 'lodash';
import { CSSInterpolation, cx } from '../../core/emotion';
import HotTable from '@handsontable/react';
import CellCoords from 'handsontable/3rdparty/walkontable/src/cell/coords';
import { GridSettings } from 'handsontable/settings';
import { useMediaQuery } from 'react-responsive';
import { SheetData } from '../../core/sheet/Sheet';
import { displayDateText } from '../../core/date';

type UseViewModelParams = {
  sheet: Sheet;
  headerRowIndexRef: MutableRefObject<number>;
  onHeaderRowIndexChange: (rowIndex: number) => void;
  configTheme?: {
    root: CSSInterpolation;
    th: CSSInterpolation;
    td: CSSInterpolation;
    selectRowColor: string;
    hoverRowColor: string;
  };
  htLicenseKey: string;
  readOnly: boolean;
};

const widthColumn = 251;

const useViewModel = ({
  sheet,
  headerRowIndexRef,
  onHeaderRowIndexChange,
  htLicenseKey,
  readOnly,
}: UseViewModelParams) => {
  const instance = useRef<HotTable>(null);

  const scrollContainerRef = useRef<HTMLDivElement>();
  const containerRef = useRef<HTMLDivElement>();

  const currentHoverRowCoord = useRef<{ row: number; col: number }>({
    row: -1,
    col: -1,
  });

  const data = useMemo(() => {
    const maxRow = 50;
    const rowLength =
      sheet.getData().length <= maxRow ? sheet.getData().length : maxRow;
    const data: SheetData = [];
    for (let i = 0; i < rowLength; i++) {
      const row = sheet.getData()[i].map((col) => displayDateText(col));
      data.push(row);
    }

    return data;
  }, [sheet]);

  const colAmount = useMemo(() => {
    return data.map((item) => item.length).sort((a, b) => b - a)[0];
  }, [data]);

  const dataSet = useMemo(() => {
    return data;
  }, [data]);

  const rowStyleHandler = useMemo(
    () => ({
      afterGetRowHeader: (row: number, TH: HTMLElement) => {
        const selectRow = headerRowIndexRef.current;
        const hoverRow = currentHoverRowCoord.current.row;

        if (row === selectRow && row === hoverRow) {
          TH.classList.add(
            'selecting-row-and-hovering',
            'selecting-row',
            'htMiddle'
          );
          return;
        }

        if (row === selectRow) {
          TH.classList.add('selecting-row', 'default-cell', 'htMiddle');
          return;
        }

        if (row === hoverRow) {
          TH.classList.add('hover-row', 'htMiddle');
          return;
        }

        TH.classList.add('default-cell', 'htMiddle');
        return;
      },
      cells: (row: number) => {
        const selectRow = headerRowIndexRef.current;
        const hoverRow = currentHoverRowCoord.current.row;
        const cellProperties: { className?: string } = {
          className: 'default-cell htMiddle',
        };
        if (row === selectRow && row === hoverRow) {
          cellProperties.className =
            'selecting-row-and-hovering selecting-row htMiddle';
          return cellProperties;
        }
        if (row === selectRow) {
          cellProperties.className = 'selecting-row htMiddle';
          return cellProperties;
        }
        if (row === hoverRow) {
          cellProperties.className = 'hover-row htMiddle';
          return cellProperties;
        }
        return cellProperties;
      },
    }),
    [headerRowIndexRef]
  );

  const applyRowStyle = () => {
    requestAnimationFrame(() => {
      instance.current?.hotInstance?.updateSettings(rowStyleHandler);
    });
  };

  const mediaSize = useMediaQuery({
    query: '(max-width: 1440px)',
  });

  const settingTable = useMemo(() => {
    const hotSetting: GridSettings = {
      data: dataSet,
      licenseKey: htLicenseKey,
      colHeaders: true,
      rowHeaders: true,
      autoColumnSize: true,
      autoRowSize: false,
      rowHeaderWidth: 60,
      columnHeaderHeight: mediaSize ? 23 : 33,
      renderAllRows: false,
      height: '100%',
      width: '100%',
      columns: Array(colAmount).fill({}),
      stretchH: 'all',
      colWidths: Array(colAmount).fill(widthColumn),
      className: 'htMiddle default-cell',
      rowHeights: mediaSize ? 23 : 33,
      viewportColumnRenderingOffset: 10,
      afterGetColHeader: (_columns, TH) => {
        TH.className = cx(
          'htMiddle htLeft bg-gray-50 text-sm font-normal text-gray-560 header'
        );
        TH.children[0].className =
          'px-4 htMiddle htLeft h-full flex items-center';
      },
      beforeOnCellMouseOver: (_event, coords) => {
        if (readOnly) {
          return;
        }
        currentHoverRowCoord.current = coords;
        applyRowStyle();
      },
      ...rowStyleHandler,
      outsideClickDeselects: false,
      readOnly: true,
      afterOnCellMouseDown: (_event, coords) => {
        onCellSelected(coords);
      },
      currentRowClassName: 'selected-row-class',
      beforeOnCellMouseDown: (event, coords) => {
        if (coords.row < 0) {
          event.stopImmediatePropagation();
        }
      },
      disableVisualSelection: true,
    };

    return hotSetting;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mediaSize, dataSet, colAmount]);

  useEffect(() => {
    const container = (containerRef.current = instance.current?.hotInstance
      ?.rootElement as HTMLDivElement);

    const scrollContainer = (scrollContainerRef.current =
      container.querySelector('.wtHolder') as HTMLDivElement);

    handleBorderLastRow();
    handleSpaceFirstColumn();

    function handleBorderLastRow() {
      const hasScrollVertical =
        scrollContainer.scrollHeight > scrollContainer.clientHeight;
      const hasScrollVerticalReachEnd =
        hasScrollVertical &&
        scrollContainer.offsetHeight + scrollContainer.scrollTop >=
          scrollContainer.scrollHeight;

      if (hasScrollVerticalReachEnd) {
        requestAnimationFrame(() => {
          container.classList.add('hide-border-last-row');
        });
      } else {
        requestAnimationFrame(() => {
          container.classList.remove('hide-border-last-row');
        });
      }
    }

    function handleSpaceFirstColumn() {
      const hasScrollHorizontal =
        scrollContainer.scrollWidth > scrollContainer.clientWidth;

      if (hasScrollHorizontal) {
        requestAnimationFrame(() => {
          container.classList.add('add-space-scrollbar-h');
          container.classList.add('hide-border-last-column');
        });
      } else {
        container.classList.remove('add-space-scrollbar-h');
        container.classList.remove('hide-border-last-column');
      }

      const allColumnsWidth = colAmount * widthColumn;
      if (allColumnsWidth > container.clientWidth) {
        container.classList.remove('wt-full-width');
      } else {
        container.classList.add('wt-full-width');
      }
    }

    const allColumnsWidth = colAmount * widthColumn;
    if (allColumnsWidth > container.clientWidth) {
      container.classList.remove('wt-full-width');
    } else {
      container.classList.add('wt-full-width');
    }

    function handleSpaceLastColumn() {
      const hasScrollVertical =
        scrollContainer.scrollHeight > scrollContainer.clientHeight;

      if (hasScrollVertical) {
        requestAnimationFrame(() => {
          container.classList.add('add-space-scrollbar-w');
        });
      } else {
        requestAnimationFrame(() => {
          container.classList.remove('add-space-scrollbar-w');
        });
      }
    }

    const hasScrollVertical =
      scrollContainer.scrollHeight > scrollContainer.clientHeight;

    if (hasScrollVertical) {
      requestAnimationFrame(() => {
        container.classList.add('add-space-scrollbar-w');
      });
    } else {
      requestAnimationFrame(() => {
        container.classList.remove('add-space-scrollbar-w');
      });
    }

    scrollContainer.addEventListener('scroll', handleBorderLastRow, {
      passive: true,
    });
    window.addEventListener('resize', handleBorderLastRow, {
      passive: true,
    });
    window.addEventListener('resize', handleSpaceFirstColumn, {
      passive: true,
    });
    window.addEventListener('resize', handleSpaceLastColumn, {
      passive: true,
    });

    return () => {
      window.removeEventListener('resize', handleBorderLastRow);
      window.removeEventListener('resize', handleSpaceFirstColumn);
      window.removeEventListener('resize', handleSpaceLastColumn);
      scrollContainer.removeEventListener('scroll', handleBorderLastRow);
    };
  }, [data, colAmount]);

  const onSelectedRowsChange = (selectedRows: Set<number>) => {
    const lastSelectedRow = Array.from(selectedRows).pop();

    if (!isNil(lastSelectedRow)) {
      onHeaderRowIndexChange(lastSelectedRow);
    }
  };

  const onCellSelected = useCallback((select: CellCoords) => {
    if (readOnly) {
      return;
    }

    headerRowIndexRef.current = select.row;
    onSelectedRowsChange(new Set([select.row]));

    instance.current?.hotInstance?.updateSettings({
      afterGetRowHeader: (row, TH) => {
        if (row === select.row) {
          TH.classList.add(
            'selecting-row-and-hovering',
            'selecting-row',
            'htMiddle'
          );
          return;
        }
        TH.classList.add('default-cell', 'htMiddle');
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    instance,
    settingTable,
  };
};

export default useViewModel;
