import { useSettings } from './../../settings/index';
import { useContextConfirmModalManager } from 'baseUI/Confirm/context';
import { useContextSuccessModalManager } from 'baseUI/Success/context';
import { DataModelSheet } from 'dataModelSheet';
import { useHooks } from 'hooks';
import { usePage } from 'main/MainView';
import DataModelSheetMatcher from './../../matching/DataModelSheetMatcher';
import DataModelSheetMatching from './../../matching/DataModelSheetMatching';
import { useTranslation } from 'react-i18next';
import { useReviewEntriesConfig } from '../reviewEntriesConfig';
import {
  useConfigure,
  useFeatureWhiteList,
} from '../../configure/ConfigureProvider';
import { useContextLoadingPopupManager } from 'baseUI/LoadingPopup/context';
import { PassSubmitResult } from '../../passSubmitResult';
import { useMatching } from '../../matching/MatchingProvider';
import { CompleteImportLogs } from '../../completeImportLog';
import { useCallback, useMemo } from 'react';
import AutoMatching from '../../matching/AutoMatching';
import AutoMatchingLocalStorage from '../../matching/autoMatching/AutoMatchingLocalStorage';
import AutoMatchingAPI from '../../matching/autoMatching/AutoMatchingAPI';
import { useDataModels } from '../../dataModel/dataModelConfig';

type SubmitParams = {
  dataModelSheetMatching: DataModelSheetMatching;
  dataModelSheetMatcher: DataModelSheetMatcher;
  headerRow: string[];
};

type SubmitHandler = (params: SubmitParams) => {
  handleSubmit: (dataModelSheet: DataModelSheet) => void;
};

const useBaseSubmitHandler = ({
  dataModelSheetMatcher,
  dataModelSheetMatching,
  headerRow,
}: SubmitParams) => {
  const { closePage } = usePage();
  const { createLog } = CompleteImportLogs({
    dataModelSheetMatcher,
    dataModelSheetMatching,
    headerRow,
  });
  const { featureWhiteList } = useFeatureWhiteList();
  const { t } = useTranslation();
  const { completeImportAction } = useReviewEntriesConfig();
  const { showSuccessModal } = useContextSuccessModalManager();
  const {
    disableSuccessModal,
    enableReuseMapping,
    processingEngine,
    automaticMapping,
  } = useSettings();
  const { ml } = useMatching();
  const { licenseKey } = useConfigure();
  const dataModel = useDataModels();

  const autoMatching = useMemo(() => {
    return new AutoMatching(
      new AutoMatchingLocalStorage(),
      new AutoMatchingAPI(),
      dataModel.getDataModels()
    );
  }, [dataModel]);

  const saveAutoMapping = useCallback(() => {
    try {
      if (enableReuseMapping && processingEngine === 'node') {
        autoMatching.saveAutoOptionMapping(licenseKey, dataModelSheetMatching);
      }

      if (automaticMapping) {
        autoMatching.saveAutoMatching(
          dataModelSheetMatcher,
          dataModelSheetMatching,
          licenseKey
        );
      }
    } catch (e) {
      // NOTE: ignore this error because dataHandler and manual input date features might make this error
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    saveData: (totalRow: number) => {
      saveAutoMapping();
      createLog(
        totalRow,
        featureWhiteList.getDisableColumnHeaderProcessing()
      ).catch(() => {});
    },
    handleSubmit: (passSubmitResult: PassSubmitResult) => {
      ml.complete(licenseKey);
      ml.clearData(licenseKey);
      closePage();
      if (!disableSuccessModal) {
        if (passSubmitResult) {
          showSuccessModal({
            title: passSubmitResult.getTitle(),
            description: passSubmitResult.getText(),
            logoURL: passSubmitResult.getImageUrl(),
            options: {
              amountOfSuccess: passSubmitResult.getSuccessfulRecords(),
              amountOfFailure: passSubmitResult.getFailedRecords(),
            },
            duration: passSubmitResult.getDuration(),
          });
        } else {
          showSuccessModal({
            title: t('txt_title_submit_success'),
            description: t('txt_description_submit_success'),
          });
        }
      }
    },
    completeImportAction,
  };
};

export const useValidSubmitHandler: SubmitHandler = ({
  dataModelSheetMatcher,
  dataModelSheetMatching,
  headerRow,
}) => {
  const { handleSubmit, saveData } = useBaseSubmitHandler({
    dataModelSheetMatcher,
    dataModelSheetMatching,
    headerRow,
  });
  const { resultHookObservable } = useHooks();
  const { showConfirmModal } = useContextConfirmModalManager();
  const { showLoadingPopup } = useContextLoadingPopupManager();
  const { t } = useTranslation();

  return {
    handleSubmit: (dataModelSheet: DataModelSheet) => {
      showConfirmModal({
        title: t('txt_title_confirm_submit_data_without_error'),
        textNegativeButton: t('txt_submit'),
        textPositiveButton: t('txt_back'),
        description: t('txt_confirm_submit_data_without_error'),
        onClickNegativeButton: () => {
          saveData(dataModelSheet.getValidValues().length);
          showLoadingPopup({ isShowDialog: true });
          resultHookObservable.next({
            dataModelSheet,
            notifyOnlyValidValues: true,
            onError: (err) => {
              showLoadingPopup({ isShowDialog: false });
              showConfirmModal({
                title: err.getTitle(),
                description: err.getMessage(),
                isShowIcon: true,
                isShowPositiveButton: false,
                textNegativeButton: t('txt_close'),
                onClickNegativeButton: () => {},
              });
            },
            onComplete: (passSubmitResult) => {
              showLoadingPopup({ isShowDialog: false });
              handleSubmit(passSubmitResult as PassSubmitResult);
            },
          });
        },
      });
    },
  };
};

export const useInvalidSubmitHandler: SubmitHandler = ({
  dataModelSheetMatcher,
  dataModelSheetMatching,
  headerRow,
}) => {
  const { handleSubmit, saveData, completeImportAction } = useBaseSubmitHandler(
    {
      dataModelSheetMatcher,
      dataModelSheetMatching,
      headerRow,
    }
  );
  const { resultHookObservable } = useHooks();
  const { t } = useTranslation();
  const { showConfirmModal } = useContextConfirmModalManager();
  const { showLoadingPopup } = useContextLoadingPopupManager();

  return {
    handleSubmit: (dataModelSheet: DataModelSheet) => {
      if (['submit', 'discard'].includes(completeImportAction)) {
        const exportEntriesWithError = completeImportAction === 'submit';
        const notifyOnlyValidValues = !exportEntriesWithError;
        const entriesLength = exportEntriesWithError
          ? dataModelSheet.getAllValuesAsResults().length
          : dataModelSheet.getValidValues().length;
        const modalDescription = exportEntriesWithError
          ? t('txt_allow_error_confirm_submit_data_with_error')
          : t('txt_confirm_submit_data_with_error');
        showConfirmModal({
          textNegativeButton: t('txt_submit'),
          textPositiveButton: t('txt_back'),
          title: t('txt_title_confirm_submit_data_without_error'),
          description: modalDescription,
          onClickNegativeButton: () => {
            saveData(entriesLength);
            showLoadingPopup({ isShowDialog: true });
            resultHookObservable.next({
              dataModelSheet,
              notifyOnlyValidValues: notifyOnlyValidValues,
              onError: (err) => {
                showLoadingPopup({ isShowDialog: false });
                showConfirmModal({
                  title: err.getTitle(),
                  description: err.getMessage(),
                  isShowIcon: true,
                  isShowPositiveButton: false,
                  textNegativeButton: t('txt_close'),
                  onClickNegativeButton: () => {},
                });
              },
              onComplete: (passSubmitResult) => {
                showLoadingPopup({ isShowDialog: false });
                handleSubmit(passSubmitResult as PassSubmitResult);
              },
            });
          },
        });
      }
    },
  };
};
