import { cx } from 'core/emotion';
import { DataModel } from 'dataModel';
import DataModelSheetMatching from './../../../../matching/DataModelSheetMatching';
import SheetColumn from './../../../../sheetImporter/SheetColumn';
import { useTheme } from 'theme';
import { useCollapseCard } from '../common/CollapseCard';
import { ColumnMatchThemeAPI } from '../PreviewData/types';
import MatchingText from './MatchingText';
import ValueRowsAsPercent from './ValueRowsAsPercent';
import useViewModel from './viewModel';
import WarnNotValuesAreMapped from './WarnNotValuesAreMapped';

const MatchingReport = ({
  dataModelSheetMatching,
  sheetColumn,
  matchedDataModel,
  prefixName,
  configTheme,
  loadingMatchingMoreOptions,
}: {
  dataModelSheetMatching: DataModelSheetMatching;
  sheetColumn: SheetColumn;
  matchedDataModel?: DataModel;
  prefixName: string;
  configTheme?: ColumnMatchThemeAPI;
  loadingMatchingMoreOptions: boolean;
}) => {
  const { isCollapse } = useCollapseCard();
  const { hasBorderSpacing, noPreviewIndicate, noPreviewValuesRows } =
    useViewModel({
      sheetColumn,
      matchedDataModel,
      prefixName,
    });
  const theme = useTheme();

  const wrapperClassName = isCollapse ? 'flex items-center h-full ' : '';

  return (
    <div
      className={cx(
        wrapperClassName,
        'pt-4.5 pl-4.5 pb-4.5 large:pt-0 large:pl-0 large:pb-0'
      )}
    >
      <MatchingText
        sheetColumn={sheetColumn}
        matchedDataModel={matchedDataModel}
        dataModelSheetMatching={dataModelSheetMatching}
        configTheme={configTheme}
      />
      {!isCollapse ? (
        <div className={cx(hasBorderSpacing ? 'mmd:mb-3 pb-1' : '')}>
          {!noPreviewValuesRows && (
            <ValueRowsAsPercent sheetColumn={sheetColumn} />
          )}
          {!noPreviewIndicate && !loadingMatchingMoreOptions ? (
            <WarnNotValuesAreMapped
              configTheme={theme.getColumnMatchTheme().notMatchingValue}
            />
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

export default MatchingReport;
