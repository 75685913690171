import {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  ReactNode,
} from 'react';
import {
  FileRejection,
  FileWithPath,
  useDropzone,
  ErrorCode,
} from 'react-dropzone';
import {
  accept,
  convertFileToJson,
  DataFile,
  getDescriptionTooManyFileError,
  getErrorMessage,
  supportTypes,
} from './fileUtils';
import { useContextCircleLoadingModalManager } from 'baseUI/Modal/CircleLoading/context';
import { useContextCustomViewModalManager } from 'baseUI/Modal/CustomView';
import { cx } from 'core/emotion';
import { PromiseCancelable } from '../core';
import { useTranslation } from 'react-i18next';
import { useLicenseKeyAuth, useWarnLicense } from 'license';
import { useScreenSize } from 'core/constants/screensSize';
import { useWarnSmallScreen } from 'hooks';
import { useSettings } from 'settings';
import { useFeatureWhiteList } from '../configure/ConfigureProvider';
import { useContextWebWorkerManager } from './worker/WebWorkerProvider';
import { usePage } from 'main/MainView';
import { ERROR_CODE_PARSE_FILE } from './../errors/errorCode';
import { useDataModels } from './../dataModel';
import { css } from '@emotion/css';
import { useTheme } from 'theme';

type DropzoneProps = {
  children: ReactNode;
  className?: string;
  onFileSelectedSuccess: (data: DataFile[]) => void;
  onFileSelectedError: (errorMessage: string, title?: string) => void;
  onProcessing: () => void;
};

const MainUploader = ({
  children,
  className,
  onFileSelectedSuccess,
  onFileSelectedError,
  onProcessing,
}: DropzoneProps) => {
  const { convertFile2JsonRef, setConvertFile2Json, setOpenModal } =
    useContextCustomViewModalManager();
  const { setUploadWebWorker } = useContextWebWorkerManager();
  const { isOpen, dismissLoading, isProcessing } =
    useContextCircleLoadingModalManager();
  const [data, setData] = useState<DataFile[] | null>(null);
  const [processing, setProcessing] = useState(false);
  const [importFiles, setImportFile] = useState<FileWithPath[] | null>(null);
  const convertFile2Json = useRef<PromiseCancelable<DataFile[]>>();
  const { t } = useTranslation();
  const { warnLicenseKeyIsInvalid } = useWarnLicense();
  const { isAuth } = useLicenseKeyAuth();
  const { isNotSmallScreen } = useScreenSize();
  const { warnSmallScreen } = useWarnSmallScreen();
  const { multipleFileUpload, advancedParsing } = useSettings();
  const { isLoading } = useLicenseKeyAuth();
  const { featureWhiteList } = useFeatureWhiteList();
  const { setTimeUpload } = usePage();
  const dataModel = useDataModels();

  const theme = useTheme();
  const dropzoneTheme = theme.getDropzoneTheme();

  useEffect(() => {
    if (!isProcessing && !data) {
      if (convertFile2JsonRef) {
        convertFile2JsonRef.current?.cancel();
        setConvertFile2Json(null);
      } else {
        convertFile2Json.current && convertFile2Json.current.cancel();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, data]);

  const setResultImportFile = (data: DataFile[] | null) => {
    setData(data);
    setProcessing(false);
    setImportFile(null);
    if (data) onFileSelectedSuccess(data);
  };

  useEffect(() => {
    if (importFiles) {
      convertFile2Json.current = convertFileToJson(
        importFiles,
        setUploadWebWorker,
        {
          advancedParsing: advancedParsing || false,
          hasDateType: dataModel.hasDateType(),
        },
        setTimeUpload
      );
      setConvertFile2Json(convertFile2Json.current);
      if (!processing) {
        setData(null);
        setProcessing(true);
        setOpenModal(false);
        onProcessing();
        convertFile2Json.current.promise
          .then((result) => {
            dismissLoading();
            setResultImportFile(result);
            setConvertFile2Json(null);
          })
          .catch((err) => {
            dismissLoading();
            setResultImportFile(null);
            if (!err.isCancelled) {
              if (
                err.code === ERROR_CODE_PARSE_FILE.ADVANCED_PARSING_NOT_ALLOWED
              ) {
                onFileSelectedError(
                  t('txt_nested_file_error'),
                  t('txt_import_error')
                );
              } else if (err.code === ERROR_CODE_PARSE_FILE.FORMAT_ELEMENT) {
                onFileSelectedError(
                  t('txt_file_format_error'),
                  t('txt_title_error_invalid_format')
                );
              } else {
                onFileSelectedError(t('txt_file_error'), t('txt_import_error'));
              }
            }
            setConvertFile2Json(null);
          });
      }
    }
    return () => {
      setImportFile(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [importFiles]);

  const onDropRejected = useCallback(
    (errors: FileRejection[]) => {
      if (!isAuth) {
        warnLicenseKeyIsInvalid();
        return;
      }

      const file = errors[0]?.file;
      if (!supportTypes.includes(file.type)) {
        onFileSelectedError(
          t('txt_file_format_error'),
          t('txt_title_upload_valid_file_error')
        );
        return;
      }

      const isFileTooLarge = errors[0].errors.find(
        (error) => error.code === ErrorCode.FileTooLarge
      );

      if (isFileTooLarge) {
        onFileSelectedError(
          t('txt_upload_exceed_max_size_error', {
            sizeInMb: featureWhiteList.getMaxFileSizeInMb(),
          }),
          t('txt_title_upload_exceed_max_size_error')
        );
        return;
      }

      const errorMessage = getErrorMessage(errors);

      onFileSelectedError(
        t(getDescriptionTooManyFileError(errorMessage)),
        t('txt_title_too_many_files_error')
      );
    },
    [isAuth, onFileSelectedError, t, warnLicenseKeyIsInvalid, featureWhiteList]
  );

  const onDropAccepted = useCallback(
    (acceptedFiles: FileWithPath[]) => {
      if (!isAuth) {
        warnLicenseKeyIsInvalid();
      } else {
        setImportFile(acceptedFiles);
      }
    },
    [isAuth, warnLicenseKeyIsInvalid]
  );

  const baseStyle = {
    transition: 'border .24s ease-in-out',
  };

  const acceptStyle = {
    borderColor: '#4B5563',
  };

  const rejectStyle = {
    borderColor: '#D0021B',
  };

  const onDrop = (acceptedFiles: File[], fileRejections: FileRejection[]) => {
    if (isNotSmallScreen) {
      if (fileRejections.length > 0) {
        onDropRejected(fileRejections);
      } else {
        onDropAccepted(acceptedFiles);
      }
    } else {
      warnSmallScreen();
    }
  };

  const { getRootProps, getInputProps, isDragAccept, isDragReject } =
    useDropzone({
      accept,
      noClick: !isAuth || !isNotSmallScreen,
      disabled: isLoading,
      multiple: multipleFileUpload,
      onDrop,
      maxSize: featureWhiteList.getIsFileSizeUnlimited()
        ? undefined
        : featureWhiteList.getMaxFileSizeInBytes(),
    });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isDragReject, isDragAccept]
  );

  return (
    <div
      className={cx(
        'w-full min-w-0',
        css({ '&&': dropzoneTheme.container }),
        className
      )}
      {...getRootProps({
        style,
        onClick: () => {
          if (!isAuth) {
            warnLicenseKeyIsInvalid();
          }
          if (!isNotSmallScreen) {
            warnSmallScreen();
          }
        },
      })}
    >
      <input
        {...getInputProps({
          multiple: multipleFileUpload,
        })}
      />
      {children}
    </div>
  );
};

export default MainUploader;
