export const connectionErrorIcon = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        padding: '10px 0px 40px 0px',
      }}
    >
      <svg
        width="90"
        height="90"
        viewBox="0 0 90 90"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M76.6875 84.75L65.9063 73.9688H23.25C17.75 73.9688 13.125 72.125 9.375 68.4375C5.625 64.75 3.75 60.1563 3.75 54.6563C3.75 49.6563 5.32813 45.4688 8.48438 42.0938C11.6406 38.7188 15.5938 36.6563 20.3438 35.9063C20.4688 35.0313 20.6719 34.0469 20.9531 32.9531C21.2344 31.8594 21.625 30.875 22.125 30L6.5625 14.4375L10.5 10.5L80.7187 80.7188L76.6875 84.75ZM23.25 68.3438H60.4688L26.7188 34.5938C26.0312 35.5313 25.5781 36.5938 25.3594 37.7813C25.1406 38.9688 25.0313 40.125 25.0313 41.25H23.25C19.375 41.25 16.0938 42.4844 13.4063 44.9531C10.7188 47.4219 9.375 50.5625 9.375 54.375C9.375 58.25 10.7188 61.5469 13.4063 64.2656C16.0938 66.9844 19.375 68.3438 23.25 68.3438V68.3438ZM80.0625 71.0625L75.6562 66.6563C77.2187 65.5938 78.4375 64.4063 79.3125 63.0938C80.1875 61.7813 80.625 60.2188 80.625 58.4063C80.625 55.7188 79.6563 53.4219 77.7188 51.5156C75.7813 49.6094 73.4687 48.6563 70.7812 48.6563H64.5V41.0625C64.5 35.5625 62.5938 30.9531 58.7813 27.2344C54.9688 23.5156 50.3125 21.6563 44.8125 21.6563C43.0625 21.6563 41.1719 21.9375 39.1406 22.5C37.1094 23.0625 35.25 23.9688 33.5625 25.2188L29.625 21.2813C31.875 19.4688 34.2969 18.1406 36.8906 17.2969C39.4844 16.4531 42.125 16.0313 44.8125 16.0313C51.75 16.0313 57.7031 18.5 62.6719 23.4375C67.6406 28.375 70.125 34.3125 70.125 41.25V43.2187C74.625 43.1562 78.4375 44.5625 81.5625 47.4375C84.6875 50.3125 86.25 53.9688 86.25 58.4063C86.25 60.5938 85.7344 62.8906 84.7031 65.2969C83.6719 67.7031 82.125 69.625 80.0625 71.0625V71.0625Z"
          fill="#DD5757"
        />
      </svg>
    </div>
  );
};

export const nuvoIcon = () => {
  return (
    <div>
      <svg
        width="59"
        height="12"
        viewBox="0 0 59 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0.404748 4.72H4.31525C4.53883 4.72 4.72 4.53882 4.72 4.31525V0.404676C4.72 0.181177 4.53883 0 4.31525 0H0.404748C0.181253 0 0 0.181177 0 0.404676V4.31525C0 4.53882 0.181253 4.72 0.404748 4.72"
          fill="#FFC116"
        />
        <mask
          id="mask0_5001_251959"
          maskUnits="userSpaceOnUse"
          x="5"
          y="0"
          width="6"
          height="5"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M5.76904 0H10.7513V4.98222H5.76904V0Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_5001_251959)">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M8.26015 4.98224V4.98224C9.63593 4.98224 10.7513 3.8669 10.7513 2.49112V2.49112C10.7513 1.11534 9.63593 0 8.26015 0V0C6.88438 0 5.76904 1.11534 5.76904 2.49112V2.49112C5.76904 3.8669 6.88438 4.98224 8.26015 4.98224"
            fill="#162338"
          />
        </g>
        <mask
          id="mask1_5001_251959"
          maskUnits="userSpaceOnUse"
          x="0"
          y="6"
          width="5"
          height="6"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M0 6.03125H4.72V11.0135H0V6.03125Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask1_5001_251959)">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M0.18619 6.03125H4.53384C4.63664 6.03125 4.72003 6.1213 4.72003 6.23244V8.4342C4.72003 9.85871 3.65132 11.0135 2.33298 11.0135V11.0135C1.0445 11.0135 0 9.88487 0 8.49262V6.23244C0 6.1213 0.083387 6.03125 0.18619 6.03125"
            fill="#5C8EF2"
          />
        </g>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M5.96556 6.03125H8.26418C9.63775 6.03125 10.7513 7.14477 10.7513 8.51841V8.51841C10.7513 9.8964 9.63419 11.0135 8.2562 11.0135H8.20705C6.86059 11.0135 5.76904 9.92192 5.76904 8.57546V6.22777C5.76904 6.1192 5.85707 6.03125 5.96556 6.03125"
          fill="#ED7070"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M32.7778 9.96391C30.4644 9.96391 28.5823 8.06231 28.5823 5.72488V1.80932C28.5823 1.24421 29.0357 0.786133 29.5949 0.786133C30.1542 0.786133 30.6076 1.24421 30.6076 1.80932V5.72488C30.6076 6.934 31.5811 7.91762 32.7778 7.91762C33.9746 7.91762 34.9481 6.934 34.9481 5.72488V1.80932C34.9481 1.24421 35.4015 0.786133 35.9607 0.786133C36.52 0.786133 36.9734 1.24421 36.9734 1.80932V5.72488C36.9734 8.06231 35.0913 9.96391 32.7778 9.96391"
          fill="#162338"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M24.4229 9.96391C23.8636 9.96391 23.4103 9.50583 23.4103 8.9408V5.02517C23.4103 3.81612 22.4367 2.83243 21.24 2.83243C20.0433 2.83243 19.0697 3.81612 19.0697 5.02517V8.9408C19.0697 9.50583 18.6164 9.96391 18.057 9.96391C17.4978 9.96391 17.0444 9.50583 17.0444 8.9408V5.02517C17.0444 2.68781 18.9265 0.786133 21.24 0.786133C23.5534 0.786133 25.4355 2.68781 25.4355 5.02517V8.9408C25.4355 9.50583 24.9822 9.96391 24.4229 9.96391"
          fill="#162338"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M43.936 9.96438C43.6471 9.96438 43.2449 9.85223 42.9694 9.31783L39.6876 2.40405C39.5758 2.16712 39.5656 1.91892 39.6577 1.68575C39.7479 1.45681 39.9215 1.27949 40.1737 1.15895C40.3136 1.08587 40.4586 1.04883 40.6049 1.04883C40.7039 1.04883 40.8038 1.0659 40.902 1.0995C41.1442 1.1826 41.3296 1.3427 41.453 1.57539L41.4561 1.58127L43.9175 6.8852L46.3538 1.58174L46.3572 1.57539C46.4813 1.34113 46.6732 1.18057 46.9276 1.09817C47.0286 1.06543 47.1322 1.04883 47.2354 1.04883C47.3873 1.04883 47.5403 1.08462 47.6906 1.15527C47.9396 1.2668 48.1091 1.44561 48.1943 1.68669C48.2787 1.92581 48.265 2.16689 48.1538 2.40334L44.8752 9.31047C44.6298 9.85027 44.2288 9.96438 43.936 9.96438"
          fill="#162338"
        />
        <mask
          id="mask2_5001_251959"
          maskUnits="userSpaceOnUse"
          x="49"
          y="0"
          width="10"
          height="10"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M49.822 0.787109H58.9998V9.96489H49.822V0.787109Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask2_5001_251959)">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M54.4188 2.70247C53.9232 2.70247 53.4808 2.8164 53.1041 3.04093C52.7267 3.26586 52.4248 3.58255 52.2071 3.98236C51.9876 4.38503 51.8763 4.8565 51.8763 5.38379C51.8763 5.91084 51.9874 6.3793 52.2064 6.77627C52.4241 7.17141 52.7262 7.48588 53.1041 7.71105C53.4808 7.93566 53.9232 8.04951 54.4188 8.04951C54.9141 8.04951 55.3565 7.93566 55.7335 7.71105C56.1106 7.4862 56.4096 7.17236 56.6221 6.7784C56.8368 6.38056 56.9456 5.91139 56.9456 5.38379C56.9456 4.85595 56.8365 4.38377 56.6215 3.98031C56.4089 3.5816 56.1102 3.26554 55.7334 3.04093C55.3565 2.8164 54.9141 2.70247 54.4188 2.70247M54.4188 9.96489C53.528 9.96489 52.7286 9.76696 52.0425 9.37672C51.3551 8.98608 50.8071 8.43901 50.4137 7.75076C50.0211 7.06466 49.822 6.26827 49.822 5.38376C49.822 4.48919 50.0211 3.68758 50.4137 3.00108C50.8071 2.31315 51.3551 1.76615 52.0426 1.3752C52.7291 0.98496 53.5285 0.787109 54.4188 0.787109C55.2984 0.787109 56.0925 0.98496 56.7792 1.3752C57.4664 1.76615 58.0145 2.31315 58.4081 3.001C58.8008 3.68805 58.9998 4.48967 58.9998 5.38376C58.9998 6.2674 58.8036 7.06331 58.4167 7.7495C58.0282 8.43837 57.4826 8.98585 56.7949 9.37672C56.1087 9.76704 55.3093 9.96489 54.4188 9.96489"
            fill="#162338"
          />
        </g>
      </svg>
    </div>
  );
};
