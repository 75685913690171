import Uploader from '../common/Uploader';
import DownloadTemplateBanner from '../common/DownloadTemplate/Banner';
import Stepper from 'baseUI/Stepper';
import DevelopMode from 'baseUI/Banner/index';
import { useSettings } from 'settings';
import { useDevelopMode, useLicenseKeyAuth } from 'license';
import { css, cx } from 'core/emotion';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import ProgressProcessing from 'baseUI/Processing';
import { usePage } from '../index';
import { useManualNavigate } from 'core/navigate';

const Page = () => {
  const { title, multipleFileUpload } = useSettings();
  const { isDeveloperMode } = useDevelopMode();
  const { t } = useTranslation();
  const [processing, setProcessing] = useState(false);
  const { timeUpload } = usePage();
  const { navigateToReviewEntries } = useManualNavigate();
  const { isLoading } = useLicenseKeyAuth();

  return (
    <div>
      <div
        className={cx('flex flex-col px-6', isDeveloperMode ? 'mb-4' : 'mb-8')}
      >
        {isDeveloperMode ? (
          isLoading ? null : (
            <div className="w-fit mb-4">
              <DevelopMode isLoading={isLoading} />
            </div>
          )
        ) : null}
        <Stepper
          className={cx(isDeveloperMode ? '!mb-4' : '!mb-0')}
          isLoading={isLoading}
        />
      </div>
      <div className="min-h-500">
        {!processing && (
          <div>
            <DownloadTemplateBanner title={title} titleClassName="!mt-0" />
            <Uploader
              containerClassName="px-6"
              onUploadSuccess={() => setProcessing(false)}
              onProcessing={() => setProcessing(true)}
              onUploadFailure={() => setProcessing(false)}
              onClickManualEntry={() => {
                navigateToReviewEntries();
              }}
            />
          </div>
        )}
        {processing && (
          <div className="flex justify-center">
            <ProgressProcessing
              timer={timeUpload}
              text={t(
                multipleFileUpload
                  ? 'txt_allow_multiple_upload_file'
                  : 'txt_upload_file'
              )}
              className={cx(
                'absolute left-1/2',
                css`
                  top: 19.6rem;
                  transform: translateX(-50%);
                `
              )}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Page;
