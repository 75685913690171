import { useCallback, useEffect, useMemo, useState, ReactNode } from 'react';
import {
  ErrorCode,
  FileRejection,
  FileWithPath,
  useDropzone,
} from 'react-dropzone';
import {
  accept,
  convertFileToJson,
  DataFile,
  getDescriptionTooManyFileError,
  getErrorMessage,
  supportTypes,
} from './fileUtils';
import { cx } from 'core/emotion';
import { useTranslation } from 'react-i18next';
import { useFeatureWhiteList } from '../configure/ConfigureProvider';
import { useContextWebWorkerManager } from './worker/WebWorkerProvider';
import { useDataModels } from './../dataModel';
import { useSettings } from 'settings';

type DropzoneProps = {
  children: ReactNode;
  className?: string;
  onFileSelectedSuccess: (data: DataFile[]) => void;
  onFileSelectedError: (errorMessage: string, title?: string) => void;
  onProcessing: (processing: boolean) => void;
};

const AddFilesUploader = ({
  children,
  className,
  onFileSelectedSuccess,
  onFileSelectedError,
  onProcessing,
}: DropzoneProps) => {
  const { setUploadWebWorker } = useContextWebWorkerManager();
  const [processing, setProcessing] = useState(false);
  const { t } = useTranslation();
  const { featureWhiteList } = useFeatureWhiteList();
  const dataModel = useDataModels();
  const { advancedParsing } = useSettings();

  useEffect(() => {
    onProcessing(processing);
  }, [processing, onProcessing]);

  const onDropRejected = useCallback(
    (errors: FileRejection[]) => {
      const file = errors[0]?.file;
      if (!supportTypes.includes(file.type)) {
        onFileSelectedError(
          t('txt_file_format_error'),
          t('txt_title_upload_valid_file_error')
        );
        return;
      }

      const isFileTooLarge = errors[0].errors.find(
        (error) => error.code === ErrorCode.FileTooLarge
      );

      if (isFileTooLarge) {
        onFileSelectedError(
          t('txt_upload_exceed_max_size_error', {
            sizeInMb: featureWhiteList.getMaxFileSizeInMb(),
          }),
          t('txt_title_upload_exceed_max_size_error')
        );
        return;
      }

      const errorMessage = getErrorMessage(errors);

      onFileSelectedError(
        t(getDescriptionTooManyFileError(errorMessage)),
        t('txt_title_too_many_files_error')
      );
    },
    [onFileSelectedError, t, featureWhiteList]
  );

  const onDropAccepted = useCallback(
    (acceptedFiles: FileWithPath[]) => {
      setProcessing(true);
      convertFileToJson(
        acceptedFiles,
        setUploadWebWorker,
        {
          advancedParsing: advancedParsing || false,
          hasDateType: dataModel.hasDateType(),
        },
        undefined
      )
        .promise.then((dataFiles) => {
          onFileSelectedSuccess(dataFiles);
        })
        .catch((err) => {
          onFileSelectedError(err);
        })
        .finally(() => {
          setProcessing(false);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onFileSelectedSuccess, setUploadWebWorker, onFileSelectedError, t]
  );

  const baseStyle = {
    transition: 'border .24s ease-in-out',
  };

  const acceptStyle = {
    borderColor: '#4B5563',
  };

  const rejectStyle = {
    borderColor: '#D0021B',
  };

  const onDrop = (acceptedFiles: File[], fileRejections: FileRejection[]) => {
    if (fileRejections.length > 0) {
      onDropRejected(fileRejections);
    } else {
      onDropAccepted(acceptedFiles);
    }
  };

  const { getRootProps, getInputProps, isDragAccept, isDragReject } =
    useDropzone({
      accept,
      onDrop,
      maxSize: featureWhiteList.getIsFileSizeUnlimited()
        ? undefined
        : featureWhiteList.getMaxFileSizeInBytes(),
    });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isDragReject, isDragAccept]
  );

  return (
    <div
      className={cx(className, 'w-full')}
      {...getRootProps({
        style,
      })}
    >
      <input
        {...getInputProps({
          multiple: true,
        })}
      />
      {children}
    </div>
  );
};

export default AddFilesUploader;
