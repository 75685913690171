import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import * as yup from 'yup';
import { DropdownOptionType } from '../../../dataModel/columnsAPI';
import { useTranslation } from 'react-i18next';
import {
  CategoryDataModel,
  SpreadSheetNavigate,
  useValidationSchema,
} from '@nuvo-importer/common/sdk';
import { useDuplicateOptions } from '../../DataModelSheetMatcherForm/DataModelSheetMatcher/common/duplicateOptions';
import { useContextCreateNewOptionModal } from '../CreateNewOptionModal/CreateNewOptionModalContext';
import DataModelSheetMatcher from '../../../matching/DataModelSheetMatcher';
import DataModelSheetMatching from '../../../matching/DataModelSheetMatching';
import { useLocation } from 'react-router-dom';

export const createNewOptionModalId = 'nuvo-create-new-option-modal';

export type FormValues = {
  optionName: string;
  optionType: DropdownOptionType;
};

type UseViewModelProps = {
  isOpen: boolean;
  dataModelSheetMatcher: DataModelSheetMatcher;
  setDataModelSheetMatcher: (
    dataModelSheetMatcher: DataModelSheetMatcher
  ) => void;
  setDataModelSheetMatching: (
    dataModelSheetMatching: DataModelSheetMatching
  ) => void;
};

const useViewModel = ({
  isOpen,
  dataModelSheetMatcher,
  setDataModelSheetMatcher,
  setDataModelSheetMatching,
}: UseViewModelProps) => {
  const [modalElement, setModalElement] = useState<HTMLDivElement | null>(null);
  const { t } = useTranslation();
  const { getCustomColumn } = useDuplicateOptions();
  const { dataModel, onSubmitCallback, close, sheetColumnOption } =
    useContextCreateNewOptionModal();
  const { state: locationState } = useLocation();
  const [hasSubmit, setHasSubmit] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        inputRef.current?.focus();
      }, 0);
      setHasSubmit(false);
    }
  }, [isOpen]);

  const state = locationState as {
    spreadSheetNavigate?: SpreadSheetNavigate;
    dataModelSheetMatching?: DataModelSheetMatching;
    dataModelSheetMatcher?: DataModelSheetMatcher;
  };

  const existingOptions = useMemo(() => {
    return dataModel?.getOptions() ?? [];
  }, [dataModel]);

  useEffect(() => {
    setTimeout(() => {
      if (isOpen) {
        setModalElement(
          (document.getElementById(createNewOptionModalId) as HTMLDivElement) ??
            null
        );
      } else {
        setModalElement(null);
      }
    }, 0);
  }, [isOpen]);

  const dropdownOptions = useMemo(() => {
    return [
      {
        label: t('txt_data_type_string'),
        value: 'string',
      },
      {
        label: t('txt_data_type_int'),
        value: 'int',
      },
      {
        label: t('txt_data_type_float'),
        value: 'float',
      },
    ];
  }, [t]);

  const onSubmit = (values: FormValues) => {
    if (!dataModel) {
      return;
    }
    const cleanedSearchValue = values.optionName?.trim() ?? '';
    const options = (dataModel as CategoryDataModel).getOptions();

    const option = getCustomColumn(cleanedSearchValue, options, false);
    const { key } = option;
    const rawOptions = dataModel.getOptions?.();
    const updatedValue = key.value;

    const updateOptions = [
      ...rawOptions,
      {
        label: cleanedSearchValue,
        value: updatedValue,
        type: values.optionType,
        alternativeMatches: [],
        creator: 'manual',
        baseKey: key.baseKey,
        baseKeyCounter: key.baseKeyCounter,
        baseLabel: cleanedSearchValue,
        baseLabelCounter: 0,
      },
    ];

    const oldDataModels = [
      ...dataModelSheetMatcher.getMatching().getDataModels(),
    ];
    const spreadSheetList = state.spreadSheetNavigate?.getSpreadSheetList();

    let updateCategoryDataModel: CategoryDataModel | null = null;

    for (let index = 0; index < oldDataModels.length; index++) {
      const entry = oldDataModels[index];
      if (entry.getKey() === dataModel.getKey()) {
        const cloneCategoryDataModel = dataModel.clone();
        cloneCategoryDataModel.setOptions(updateOptions);
        oldDataModels[index] = cloneCategoryDataModel;
        updateCategoryDataModel = cloneCategoryDataModel;
        break;
      }
    }

    const dataModels = [...oldDataModels];
    const sheetColumnDataModelSimilarityList =
      state.dataModelSheetMatching?.getSheetColumnDataModelSimilarityList();
    const sheetColumnDataModelOptionSimilarityList =
      state.dataModelSheetMatching?.getSheetColumnDataModelOptionSimilarityList();
    const calculateSimilarityResult =
      dataModelSheetMatcher?.getCalculateSimilarityResult();

    if (
      dataModels &&
      spreadSheetList &&
      sheetColumnDataModelSimilarityList &&
      sheetColumnDataModelOptionSimilarityList &&
      calculateSimilarityResult
    ) {
      const newDataModelSheetMatcher = new DataModelSheetMatcher({
        sheetColumnDataModelSimilarityList,
        sheetColumnDataModelOptionSimilarityList,
        dataModels,
        sheets: spreadSheetList.getSelectedSheets(),
        calculateSimilarityResult,
      });
      setDataModelSheetMatcher(newDataModelSheetMatcher);
      setDataModelSheetMatching(newDataModelSheetMatcher.getMatching());
    }

    onSubmitCallback.callback?.(updateCategoryDataModel, updatedValue);
    setHasSubmit(false);
    close();
  };

  const validateSchema = useMemo(() => {
    return yup.object({
      optionName: yup
        .string()
        .test(
          'duplicate_label',
          t('txt_field_invalid_dup_option_label'),
          (value?: string) => {
            return existingOptions.every(
              (item) => `${item.label}`.trim() !== value?.trim()
            );
          }
        )
        .when('optionType', {
          is: 'string',
          then: yup.string().required(t('txt_require_field')),
        })
        .when('optionType', {
          is: 'int',
          then: yup
            .string()
            .test(
              'invalid_int',
              t('txt_field_invalid_format_number_int'),
              (value?: string) => {
                return /^[0-9]*$/.test(value ?? '');
              }
            )
            .test(
              'invalid_int',
              t('txt_field_invalid_format_number'),
              (value?: string) => {
                return /^[0-9]{1,}([,.][0-9]{1,})?$/.test(value ?? '');
              }
            )
            .required(t('txt_require_field')),
        })
        .when('optionType', {
          is: 'float',
          then: yup
            .string()
            .test(
              'invalid_int',
              t('txt_field_invalid_format_number_float'),
              (value?: string) => {
                return /^[0-9]{1,}([,.][0-9]{1,})?$/.test(value ?? '');
              }
            )
            .required(t('txt_require_field')),
        }),
      optionType: yup.string().required(t('txt_require_field')),
    });
  }, [t, existingOptions]);

  const validate = useValidationSchema(validateSchema);

  const initialValues = useMemo(() => {
    return {
      optionType: 'string',
      optionName: `${sheetColumnOption ?? ''}`,
    };
  }, [sheetColumnOption]);

  const handleDropdownChange = useCallback(
    (values: FormValues, change) => (value: string | null) => {
      if (values.optionType === 'float' && value === 'int') {
        if (values.optionName.includes('.')) {
          change('optionName', '');
        }
      }

      if (values.optionType === 'string' && value === 'int') {
        const int = parseInt(values.optionName);
        if (int.toString() !== values.optionName) {
          change('optionName', '');
        }
      }

      if (values.optionType === 'string' && value === 'float') {
        const float = parseFloat(values.optionName);
        if (float.toString() !== values.optionName) {
          change('optionName', '');
        }
      }
    },
    []
  );

  return {
    onSubmit,
    modalElement,
    dropdownOptions,
    validate,
    initialValues,
    hasSubmit,
    inputRef,
    handleDropdownChange,
  };
};

export default useViewModel;
