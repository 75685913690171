/* eslint-disable react/jsx-pascal-case */
import CustomViewModalManager from 'baseUI/Modal/CustomView';
import CircleLoadingManager from 'baseUI/Modal/CircleLoading/context';
import SuccessModalManager from 'baseUI/Success/context';
import { ThemeCssVariableProvider } from 'theme';
import ConfirmModalManager from 'baseUI/Confirm/context';
import { ConfigureAPI, ConfigureProvider } from 'configure';
import MatchingContextProvider from './../matching/MatchingProvider';
import 'simplebar/dist/simplebar.min.css';
import './index.css';
import './dropdownScroll.css';
import '@nuvo-importer/common/css';
import './dropdownScrollBar.css';
import { HooksProvider } from 'hooks';
import MainView from './MainView';
import OverridesTranslator from '../locales/i18n';
import { AuthContextProvider, DeveloperModeProvider } from 'license';
import {
  MemoryRouter,
  UNSAFE_LocationContext,
  useInRouterContext,
} from 'react-router-dom';
import { NUVO_TAILWIND_CLASS } from 'core/constants/style';
import { registerAllModules } from 'handsontable/registry';
import JoinColumnsManager from 'baseUI/Modal/JoinColumns/context';
import { SheetImporterProvider } from '../sheetImporter/SheetImporterProvider';
import { InRouterContextProvider } from './InRouterProvider';
import WebWorkerManager from '../sheetImporter/worker/WebWorkerProvider';
import '../core/comlink/matching';
import '../core/comlink/reviewEntries';
import LoadingPopupManager from 'baseUI/LoadingPopup/context';
import { useTracking } from '../tracking/useTracking';

type NuvoImporterProps = ConfigureAPI;

/* eslint-disable @typescript-eslint/ban-ts-comment */
/* @ts-ignore-start */
window.devicePixelRatio = 1;
/* @ts-ignore-end */

registerAllModules();

export const NuvoImporter = (props: NuvoImporterProps) => {
  const isUseInRouter = useInRouterContext();

  // NOTE: metaData is a hidden property.
  // @ts-ignore
  useTracking(props.metaData);

  return (
    <div
      className={NUVO_TAILWIND_CLASS}
      key={props?.settings?.identifier ?? ''}
    >
      <CustomViewModalManager>
        <InRouterContextProvider isUseInRouter={isUseInRouter}>
          <ConfigureProvider configure={props}>
            <OverridesTranslator>
              <ConfirmModalManager>
                <LoadingPopupManager>
                  <SuccessModalManager>
                    <MatchingContextProvider>
                      <DeveloperModeProvider
                        developerMode={props.settings.developerMode ?? false}
                      >
                        <AuthContextProvider licenseKey={props.licenseKey}>
                          <CircleLoadingManager>
                            <JoinColumnsManager>
                              <WebWorkerManager>
                                <SheetImporterProvider>
                                  <HooksProvider>
                                    <ThemeCssVariableProvider />
                                    <UNSAFE_LocationContext.Provider
                                      value={null as never}
                                    >
                                      <MemoryRouter>
                                        <MainView />
                                      </MemoryRouter>
                                    </UNSAFE_LocationContext.Provider>
                                  </HooksProvider>
                                </SheetImporterProvider>
                              </WebWorkerManager>
                            </JoinColumnsManager>
                          </CircleLoadingManager>
                        </AuthContextProvider>
                      </DeveloperModeProvider>
                    </MatchingContextProvider>
                  </SuccessModalManager>
                </LoadingPopupManager>
              </ConfirmModalManager>
            </OverridesTranslator>
          </ConfigureProvider>
        </InRouterContextProvider>
      </CustomViewModalManager>
    </div>
  );
};
