import {
  MutableRefObject,
  RefObject,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { css, cx } from '../../../emotion';
import SortMenuItems from './SortingMenuItems';
import FreezeMenuItem from './FreezeMenuItem';
import HideMenuItem from './HideMenuItem';
import FilterMenu from './FilterMenu';
import useViewModel from './viewModel';
import ContextMenuController from './controller/ContextMenuController';
import AllColumnSetting from '../columns/AllColumnSetting';
import { ConfigTheme, FindDropdownOptionFn } from '../type';
import { DataModel } from '../../../dataModel/model/DataModel';
import SimpleBar from 'simplebar-react';
import HotTable from '@handsontable/react';

type ContextMenuProps = {
  contextMenuController: ContextMenuController;
  parentTableElement: MutableRefObject<HTMLElement | undefined>;
  allColumnSetting: AllColumnSetting;
  findDropdownOption: FindDropdownOptionFn;
  dataModels: DataModel[];
  hotInstance: RefObject<HotTable>;
  htCloneLeftWtHolderElement: MutableRefObject<HTMLElement | undefined>;
  configTheme?: ConfigTheme;
  readOnly?: boolean;
  modal?: boolean;
};

const ContextMenu = ({
  contextMenuController,
  parentTableElement,
  allColumnSetting,
  findDropdownOption,
  dataModels,
  hotInstance,
  htCloneLeftWtHolderElement,
  configTheme,
  readOnly,
  modal,
}: ContextMenuProps) => {
  const {
    isOpen,
    setPopperElement,
    popperStyles,
    popperAttributes,
    columnSetting,
    valueItems,
    currentColumnIndex,
    physicalCurrentColumnIndex,
  } = useViewModel({
    contextMenuController,
    parentTableElement,
    allColumnSetting,
    dataModels,
    findDropdownOption,
    hotInstance,
    htCloneLeftWtHolderElement,
    readOnly,
  });
  const menuStyle = useMemo(() => {
    return css`
      box-shadow: 0px 3px 5px 0px #1b507140;
      width: 256px;
      z-index: 180;
      &[data-popper-reference-hidden='true'] {
        visibility: hidden;
        pointer-events: none;
        .simplebar-vertical {
          display: none;
        }
      }
    `;
  }, []);
  const [maxHeight, setMaxHeight] = useState<number | undefined>(undefined);

  useEffect(() => {
    if (isOpen && modal) {
      const adjustMaxHeight = () => {
        if (hotInstance.current?.hotInstance) {
          const tableHeight =
            hotInstance.current?.hotInstance.rootElement.offsetHeight;
          setMaxHeight(tableHeight + 75);
        }
      };
      adjustMaxHeight();
      window.addEventListener('resize', adjustMaxHeight);

      return () => {
        window.removeEventListener('resize', adjustMaxHeight);
      };
    } else {
      return () => {};
    }
  }, [hotInstance, isOpen, modal]);

  if (
    !isOpen ||
    !columnSetting.state ||
    currentColumnIndex === null ||
    physicalCurrentColumnIndex === null
  ) {
    return null;
  }

  const currentDataModel = dataModels[physicalCurrentColumnIndex];

  return (
    <div
      ref={setPopperElement}
      style={popperStyles['popper']}
      {...popperAttributes.popper}
      className={cx(
        'rounded-medium border-blue-dark-50 z-170 context-menu-scroll border bg-white',
        menuStyle,
        css({
          '&&': configTheme?.reviewEntriesTheme?.smartTable?.contextMenu?.root,
        })
      )}
    >
      <SimpleBar
        style={{
          width: '100%',
          maxHeight,
        }}
        forceVisible={false}
        autoHide
      >
        <SortMenuItems
          allColumnSetting={allColumnSetting}
          sort={columnSetting.state.sort}
          contextMenuController={contextMenuController}
          currentColumnIndex={currentColumnIndex}
          configTheme={configTheme}
        />
        <div className="bg-gray-120 h-px w-full" />
        <FreezeMenuItem
          allColumnSetting={allColumnSetting}
          freeze={columnSetting.state.freeze}
          contextMenuController={contextMenuController}
          currentColumnIndex={currentColumnIndex}
          configTheme={configTheme}
        />
        <HideMenuItem
          allColumnSetting={allColumnSetting}
          hide={columnSetting.state.hide}
          contextMenuController={contextMenuController}
          currentColumnIndex={currentColumnIndex}
          configTheme={configTheme}
        />
        <div className="bg-gray-120 h-px w-full" />
        <FilterMenu
          valueItems={valueItems}
          allColumnSetting={allColumnSetting}
          contextMenuController={contextMenuController}
          columnSetting={columnSetting.state}
          currentColumnIndex={currentColumnIndex}
          currentDataModel={currentDataModel}
          configTheme={configTheme}
        />
      </SimpleBar>
    </div>
  );
};

export default ContextMenu;
