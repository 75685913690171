import { css, CSSObject, cx } from '@emotion/css';
import { ReactNode, useMemo } from 'react';
import { COLOR_SCROLL_BAR, COLOR_TABLE } from '@nuvo-importer/common/core';
import { useTheme } from 'theme';

type WrapperStyledProps = {
  children: ReactNode;
};

const WrapperStyled = ({ children }: WrapperStyledProps) => {
  const theme = useTheme();
  const configTheme = theme.getSheetSelectTheme().sheetPreview.table;

  const header = useMemo(() => {
    return css`
      .sheet-preview .handsontable .header {
        ${configTheme?.th}
      }

      .sheet-preview .handsontable .htCore tr:first-child td {
        border-top-color: ${(configTheme?.td as CSSObject)?.borderColor ||
        COLOR_TABLE.BORDER_COLOR} !important;
        padding: 0 16px !important;
         {
        }
      }

      .sheet-preview .handsontable .htCore tr:first-child th {
        ${configTheme?.th}
        border-top-color: ${(configTheme?.th as CSSObject)?.borderColor ||
        COLOR_TABLE.BORDER_COLOR} !important;
      }

      .sheet-preview .handsontable .htCore th {
        box-sizing: border-box;
        height: 33px !important;
        padding: 8px 0 !important;
        ${configTheme?.th}
      }
    `;
  }, [configTheme]);

  const body = useMemo(() => {
    return css`
      .sheet-preview .ht_master .wtHider .htCore tbody .default-cell {
        ${configTheme?.td}
        padding: 0 16px !important;
      }

      .sheet-preview .ht_clone_left .wtHider .htCore tbody .default-cell {
        ${configTheme?.td}
        padding: 0 16px !important;
      }
    `;
  }, [configTheme]);

  const scrollbar = useMemo(() => {
    return css`
      .sheet-preview
        .ht_master.handsontable
        .wtHolder::-webkit-scrollbar-thumb {
        background-color: ${configTheme?.scrollbarNavigatorColor};
      }

      .sheet-preview
        .ht_master.handsontable
        .wtHolder::-webkit-scrollbar-track {
        background-color: ${configTheme?.scrollbarBackgroundColor};
      }

      scrollbar-color: ${configTheme?.scrollbarNavigatorColor
          ? configTheme?.scrollbarNavigatorColor
          : COLOR_SCROLL_BAR.NAVIGATOR}
        ${configTheme?.scrollbarBackgroundColor
          ? configTheme?.scrollbarBackgroundColor
          : COLOR_SCROLL_BAR.BACKGROUND};
    `;
  }, [configTheme]);

  return (
    <div
      className={cx(
        'h-full',
        css`
          .sheet-preview {
            ${configTheme?.root}
          }
        `,
        header,
        body,
        scrollbar
      )}
    >
      {children}
    </div>
  );
};

export default WrapperStyled;
