import DataModelSheetMatching, {
  DataModelSheetMatch,
  MatchedOption,
} from './DataModelSheetMatching';
import Sheet, { Value } from './../sheetImporter/Sheet';
import SheetColumn from './../sheetImporter/SheetColumn';
import { DataModel } from 'dataModel';
import SheetColumnDataModelSimilarityList from './SheetColumnDataModelSimilarityList';
import SheetColumnDataModelSimilarity from './SheetColumnDataModelSimilarity';
import SheetColumnDataModelOptionSimilarityList from './SheetColumnDataModelOptionSimilarityList';
import { CalculateSimilarityResult } from './CalculateSimilarityMapper';
import { isSheetColumnOptionEqual } from '../sheetImporter/utils';
import SheetColumnDataModelOptionSimilarity from './SheetColumnDataModelOptionSimilarity';
import { maxBy, uniq } from 'lodash';
import { separateMultipleValues } from '@nuvo-importer/common/core';

class DataModelSheetMatcher {
  private dataModels: DataModel[];
  private dataModelSheetMatch: DataModelSheetMatch[] = [];
  private sheets: Sheet[];
  private sheetColumnDataModelSimilarityList: SheetColumnDataModelSimilarityList;
  private sheetColumnDataModelOptionSimilarityList: SheetColumnDataModelOptionSimilarityList;
  private calculateSimilarityResult: CalculateSimilarityResult;

  constructor({
    dataModels,
    sheets,
    sheetColumnDataModelSimilarityList,
    sheetColumnDataModelOptionSimilarityList,
    calculateSimilarityResult,
  }: {
    dataModels: DataModel[];
    sheets: Sheet[];
    sheetColumnDataModelSimilarityList: SheetColumnDataModelSimilarityList;
    sheetColumnDataModelOptionSimilarityList: SheetColumnDataModelOptionSimilarityList;
    calculateSimilarityResult: CalculateSimilarityResult;
  }) {
    this.dataModels = dataModels;
    this.sheets = sheets;
    this.sheetColumnDataModelSimilarityList =
      sheetColumnDataModelSimilarityList;
    this.sheetColumnDataModelOptionSimilarityList =
      sheetColumnDataModelOptionSimilarityList;
    this.calculateSimilarityResult = calculateSimilarityResult;
  }

  /* Matching the options of the data model with the options of the sheet column. */
  matchingSheetColumnOptionWithDataModelOption = () => {
    this.dataModelSheetMatch.forEach((dataModelSheetMatch) => {
      const matchedDataModel = dataModelSheetMatch.matchedDataModel;
      if (matchedDataModel && matchedDataModel.dataModel?.isDropdown()) {
        const matchedOptions = this.getMatchedOptions(
          matchedDataModel.dataModel,
          dataModelSheetMatch.sheetColumn
        );
        matchedDataModel.matchedOptions = matchedOptions;
        matchedDataModel.originMatchedOptionsByML = matchedOptions;
      }
    });
  };

  /* A function that returns the matched options of the sheet column and the data model. */
  getMatchOptions = (sheetColumn: SheetColumn, dataModel: DataModel) => {
    if (dataModel.isDropdown()) {
      return this.getMatchedOptions(dataModel, sheetColumn);
    } else {
      return null;
    }
  };

  /* A function that returns the matched options of the sheet column and the data model. */
  getMatching = () => {
    this.dataModelSheetMatch = [];
    this.matchingSheetColumnWithDataModel();
    this.matchingSheetColumnOptionWithDataModelOption();

    return new DataModelSheetMatching({
      dataModelSheetMatch: this.dataModelSheetMatch,
      dataModels: this.dataModels,
      sheets: this.sheets,
      sheetColumnDataModelSimilarityList:
        this.sheetColumnDataModelSimilarityList,
      sheetColumnDataModelOptionSimilarityList:
        this.sheetColumnDataModelOptionSimilarityList,
    });
  };

  getSheetColumnDataModelOptionSimilarityList = () => {
    return this.sheetColumnDataModelOptionSimilarityList;
  };

  getSheetColumnDataModelSimilarityList = () => {
    return this.sheetColumnDataModelSimilarityList;
  };

  getSheets = () => {
    return this.sheets;
  };

  addSheetColumnDataModelOptionSimilarityList = (
    sheetColumnDataModelOptionSimilarityList: SheetColumnDataModelOptionSimilarityList
  ) => {
    this.sheetColumnDataModelOptionSimilarityList =
      this.sheetColumnDataModelOptionSimilarityList.merge(
        sheetColumnDataModelOptionSimilarityList
      );
  };

  getDataModels = () => {
    return this.dataModels;
  };

  getCalculateSimilarityResult = () => {
    return this.calculateSimilarityResult;
  };

  /* Matching the options of the data model with the options of the sheet column. */
  private getMatchedOptions = (
    dataModel: DataModel,
    sheetColumn: SheetColumn
  ) => {
    const similaritySheetColumnOptions =
      this.sheetColumnDataModelOptionSimilarityList.getSimilaritySheetColumnOptions(
        sheetColumn,
        dataModel
      );

    const predicate = (
      similaritySheetColumnOption: SheetColumnDataModelOptionSimilarity,
      uniqueOption: Value,
      isMultiSelect: boolean
    ) => {
      const sheetColumnOption =
        similaritySheetColumnOption.getSheetColumnOption().option;
      const isSameSheetColumn =
        similaritySheetColumnOption.getSheetColumnOption().sheetColumn ===
        sheetColumn;
      const isSameDataModel =
        similaritySheetColumnOption.getDataModelOption().dataModel ===
        dataModel;

      if (isMultiSelect) {
        return isSameSheetColumn && isSameDataModel;
      }
      return (
        isSameSheetColumn &&
        isSameDataModel &&
        isSheetColumnOptionEqual(uniqueOption, sheetColumnOption)
      );
    };

    const matchedOptions: MatchedOption[] = [];
    const uniqueOptions = sheetColumn.getUniqueRows();
    const isMultiSelection = dataModel.getIsMultiSelection();

    uniqueOptions.forEach((uniqueOption) => {
      if (isMultiSelection) {
        const individualMatchedByML: Record<string, string> = {};
        const matchedOptionItems = similaritySheetColumnOptions.filter(
          (similaritySheetColumnOption) => {
            return predicate(similaritySheetColumnOption, uniqueOption, true);
          }
        );

        if (matchedOptionItems.length > 0) {
          const mostMatchedOption = matchedOptionItems[0];
          const dataModelOptions: string[] = [];

          const initialOption: Value =
            mostMatchedOption.getSheetColumnOption().option;

          if (initialOption) {
            const optionValuesByTdm: string[] =
              separateMultipleValues(uniqueOption);

            for (let i = 0; i < optionValuesByTdm.length; i++) {
              const option = optionValuesByTdm[i]?.trim();
              const targetMatchedList = [];

              for (let j = 0; j < matchedOptionItems.length; j++) {
                const item = matchedOptionItems[j];
                if (
                  `${item.getSheetColumnOption()?.option}`?.trim() === option
                ) {
                  targetMatchedList.push(item);
                }
              }

              if (targetMatchedList.length === 1) {
                const itemTdmMatched = targetMatchedList?.[0]
                  ?.getDataModelOption()
                  ?.option?.toString();
                dataModelOptions.push(itemTdmMatched);
                individualMatchedByML[itemTdmMatched] = option;
              } else {
                // NOTE: The option has multiple matching
                const maxSimilarity = maxBy(targetMatchedList, (item) =>
                  item.getSimilarity()?.getSimilarity()
                );
                const maxSimilarityList = targetMatchedList.filter(
                  (item) =>
                    item.getSimilarity()?.getSimilarity() ===
                    maxSimilarity?.getSimilarity()?.getSimilarity()
                );

                if (maxSimilarityList?.length === 1) {
                  const itemTdmMatched = maxSimilarityList?.[0]
                    ?.getDataModelOption()
                    ?.option?.toString();
                  dataModelOptions.push(itemTdmMatched);
                  individualMatchedByML[itemTdmMatched] = option;
                }
              }
            }
          }

          matchedOptions.push({
            sheetOption: uniqueOption,
            dataModelOptions: uniq(dataModelOptions),
            individualMatchedByML,
          });
        } else {
          matchedOptions.push({
            sheetOption: uniqueOption,
          });
        }
      } else {
        const matchedOptionItems = similaritySheetColumnOptions.filter(
          (similaritySheetColumnOption) => {
            return predicate(similaritySheetColumnOption, uniqueOption, false);
          }
        );

        const maxSimilarity = maxBy(matchedOptionItems, (item) =>
          item.getSimilarity()?.getSimilarity()
        );
        const maxSimilarityList = matchedOptionItems.filter(
          (item) =>
            item.getSimilarity()?.getSimilarity() ===
            maxSimilarity?.getSimilarity()?.getSimilarity()
        );

        if (maxSimilarityList.length === 1) {
          matchedOptions.push({
            sheetOption: matchedOptionItems[0].getSheetColumnOption().option,
            dataModelOption: matchedOptionItems[0].getDataModelOption().option,
          });
        } else {
          matchedOptions.push({
            sheetOption: uniqueOption,
          });
        }
      }
    });

    return matchedOptions;
  };

  /* Adding a new data model to the dataModelSheetMatch array. */
  private addSheetMatchDataModel = (
    matching: SheetColumnDataModelSimilarity,
    sheetColumn: SheetColumn
  ) => {
    this.dataModelSheetMatch.push({
      sheetColumn,
      matchedDataModel: {
        dataModel: matching.getDataModel(),
      },
    });
  };

  private matchingSheetColumnWithDataModel = () => {
    this.sheetColumnDataModelSimilarityList
      .getSortedSheetColumnDataModelSimilarity()
      .forEach((sheetColumnDataModelSimilarity) => {
        if (sheetColumnDataModelSimilarity.getMarkApplied()) {
          return;
        }

        const sheetColumn = sheetColumnDataModelSimilarity.getSheetColumn();

        const maxSimilarity = sheetColumnDataModelSimilarity?.getSimilarity();
        const maxSimilarities = this.sheetColumnDataModelSimilarityList
          .getSortedSheetColumnDataModelSimilarity()
          .filter((item) => {
            return (
              item.getSimilarity().getSimilarity() ===
                maxSimilarity?.getSimilarity() &&
              item.getSheetColumn() === sheetColumn
            );
          });

        if (maxSimilarities.length > 1) {
          return;
        }

        if (sheetColumn) {
          const mostSimilarityBySheetColumn =
            this.sheetColumnDataModelSimilarityList.getMostSimilarityBySheetColumn(
              sheetColumn
            );

          if (
            mostSimilarityBySheetColumn?.getSimilarity().getSimilarity() !==
            sheetColumnDataModelSimilarity.getSimilarity().getSimilarity()
          ) {
            return;
          }

          this.addSheetMatchDataModel(
            sheetColumnDataModelSimilarity,
            sheetColumn
          );
          this.sheetColumnDataModelSimilarityList.markDataModel(
            sheetColumnDataModelSimilarity.getDataModel()
          );
          this.sheetColumnDataModelSimilarityList.markSheetColumn(sheetColumn);
        }
      });

    this.sheetColumnDataModelSimilarityList.clearMark();
  };
}

export default DataModelSheetMatcher;
