import { SHEET_COLUMN_TYPE } from '@nuvo-importer/common/worker/matching-value';
import { JoinSheetColumn } from '@nuvo-importer/common/worker/matching-value';
import { Sheet } from '@nuvo-importer/common/worker/matching-value';
import { SheetColumn } from '@nuvo-importer/common/worker/matching-value';
import {
  SheetColumnJSON,
  SheetJSON,
} from '@nuvo-importer/common/worker/matching-value';

export class SheetSerializer {
  static serialize = (sheet: Sheet) => {
    return sheet.serialize();
  };

  static deSerialize = (sheetJSON: SheetJSON) => {
    return new Sheet({
      data: sheetJSON.data,
      name: sheetJSON.name,
      isNotSetColumn: true,
      id: sheetJSON.id,
    });
  };
}

export class SheetColumnSerializer {
  static serialize = (sheetColumn: SheetColumn) => {
    return sheetColumn.serialize();
  };

  static deSerialize = (
    sheetColumnJSON: SheetColumnJSON,
    options: { notSetUnique?: boolean }
  ) => {
    const mapJSONToConstructor = (sheetColumnJSON: SheetColumnJSON) => {
      return {
        columnKey: sheetColumnJSON.columnKey,
        rows: sheetColumnJSON.rows,
        sheet: SheetSerializer.deSerialize(sheetColumnJSON.sheet),
        id: sheetColumnJSON.id,
        notSetUnique: options.notSetUnique,
      };
    };

    if (sheetColumnJSON.type === SHEET_COLUMN_TYPE.JOIN) {
      return new JoinSheetColumn({
        rows: sheetColumnJSON.rows,
        sheet: SheetSerializer.deSerialize(sheetColumnJSON.sheet),
        id: sheetColumnJSON.id,
        sheetColumns: sheetColumnJSON.sheetColumns!.map((sheetColumnJSON) => {
          const sheetColumn = new SheetColumn(
            mapJSONToConstructor(sheetColumnJSON)
          );

          sheetColumn.remove();
          sheetColumn.joinCombined();

          return sheetColumn;
        }),
      });
    } else {
      return new SheetColumn(mapJSONToConstructor(sheetColumnJSON));
    }
  };
}
