import { RefObject } from 'react';
import HotTable from '@handsontable/react';

const useScroll = ({ hotInstance }: { hotInstance: RefObject<HotTable> }) => {
  const autoScroll = (row: number, col: number, switchFocus?: boolean) => {
    hotInstance.current?.hotInstance?.selectCell(
      row,
      col,
      undefined,
      undefined,
      false,
      switchFocus
    );
    hotInstance.current?.hotInstance?.scrollViewportTo({
      row,
      col,
      horizontalSnap: 'start',
      verticalSnap: 'top',
    });
  };

  return { autoScroll };
};

export default useScroll;
