import { isNil } from 'lodash';
import { DataModel } from '../../../dataModel/model/DataModel';
import { ColumnAPI, NumberFormat } from '../../../dataModel/columnsAPI';
import Handsontable from 'handsontable';
import { Filters } from 'handsontable/plugins/filters';
import { NumberParser } from '../../../utils/NumberParser';
import FilteringValueItems from './FilterValueItems';
import FilterStrategyValueItems from './FilterValueItems';
import { BehaviorSubject } from 'rxjs';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type FilterValueItem = { label: string; value: any };

export type FilterValueItems = FilterValueItem[];

export type FilterState = {
  byCondition: {
    items: [FilterByConditionState | null, FilterByConditionState | null];
    logicalOperator: FilterLogicalOperator;
  };
  byValue: FilterByValueState;
};

export enum FilterCondition {
  // NOTE: general handsontable filter
  NONE = 'none',
  IS_EMPTY = 'empty',
  IS_NOT_EMPTY = 'not_empty',
  IS_EQUAL_TO = 'eq',
  IS_NOT_EQUAL_TO = 'neq',
  BEGINS_WITH = 'begins_with',
  ENDS_WITH = 'ends_with',
  CONTAINS = 'contains',
  DOES_NOT_CONTAIN = 'not_contains',
  BETWEEN = 'between',
  NOT_BETWEEN = 'not_between',
  // NOTE: numeric handsontable filter
  GREATER_THAN = 'gt',
  GREATER_THAN_OR_EQUAL = 'gte',
  LESS_THAN = 'lt',
  LESS_THAN_OR_EQUAL = 'lte',

  // NOTE: date handsontable filter
  DATE_BEFORE = 'date_before',
  DATE_AFTER = 'date_after',
  DATE_TOMORROW = 'date_tomorrow',
  DATE_TODAY = 'date_today',
  DATE_YESTERDAY = 'date_yesterday',

  // NOTE: custom filter
  IS_ERROR = 'IS_ERROR',
  IS_WARNING = 'IS_WARNING',
  IS_INFO = 'IS_INFO',

  BY_VALUE = 'by_value',
}

export const filterConditionsHasValueField = [
  FilterCondition.IS_EQUAL_TO,
  FilterCondition.IS_NOT_EQUAL_TO,
  FilterCondition.BEGINS_WITH,
  FilterCondition.ENDS_WITH,
  FilterCondition.CONTAINS,
  FilterCondition.DOES_NOT_CONTAIN,
  FilterCondition.GREATER_THAN,
  FilterCondition.GREATER_THAN_OR_EQUAL,
  FilterCondition.LESS_THAN,
  FilterCondition.LESS_THAN_OR_EQUAL,
  FilterCondition.DATE_BEFORE,
  FilterCondition.DATE_AFTER,
  FilterCondition.BETWEEN,
  FilterCondition.NOT_BETWEEN,
];

export const filterConditionsHasSecondValueFields = [
  FilterCondition.BETWEEN,
  FilterCondition.NOT_BETWEEN,
];

export const customFilterConditions = [
  FilterCondition.IS_ERROR,
  FilterCondition.IS_WARNING,
  FilterCondition.IS_INFO,
  FilterCondition.BY_VALUE,
  FilterCondition.IS_EMPTY,
  FilterCondition.IS_NOT_EMPTY,
  FilterCondition.DATE_BEFORE,
  FilterCondition.DATE_AFTER,
  FilterCondition.BETWEEN,
  FilterCondition.DATE_TODAY,
  FilterCondition.DATE_TOMORROW,
  FilterCondition.DATE_YESTERDAY,
];

export type FilterByConditionState = {
  condition: FilterCondition;
  value: string;
  secondValue: string;
};

export enum FilterLogicalOperator {
  AND = 'conjunction',
  OR = 'disjunction',
}

export type FilterValuesSelected = Record<string, boolean>;

type FilterByValueState = {
  selected: FilterValuesSelected | null;
};

class FilterStrategy {
  private hotInstance?: Handsontable;
  private filterValueItems: FilterStrategyValueItems;
  private _filterObservable: BehaviorSubject<FilterStrategy>;

  constructor(columns: ColumnAPI[]) {
    this.filterValueItems = new FilteringValueItems(columns);
    this._filterObservable = new BehaviorSubject<FilterStrategy>(this);
  }

  filterObservable = () => {
    return this._filterObservable;
  };

  getFilterValueItems = () => {
    return this.filterValueItems;
  };

  setHotInstance = (hotInstance: Handsontable) => {
    this.hotInstance = hotInstance;
  };

  setFilterCondition = (
    columnIndex: number,
    filter: FilterState | null,
    dataModel: DataModel
  ) => {
    if (!this.hotInstance) {
      return;
    }
    const columnFiltering = this.hotInstance.getPlugin('filters');
    if (!columnFiltering) {
      return;
    }
    columnFiltering.removeConditions(columnIndex);
    this.setFilterByCondition(columnIndex, filter, columnFiltering, dataModel);
    this.setFilterByValue(columnIndex, filter, columnFiltering, dataModel);
  };

  clearAllFilterConditions = (dataModels: DataModel[]) => {
    if (!this.hotInstance) {
      return;
    }
    for (let i = 0; i < dataModels.length; i++) {
      const dataModel = dataModels[i];
      this.setFilterCondition(i, null, dataModel);
    }
    const columnFiltering = this.hotInstance.getPlugin('filters');
    if (!columnFiltering) {
      return;
    }
    columnFiltering.filter();
    this._filterObservable.next(this);
  };

  filter = (
    columnIndex: number,
    filter: FilterState | null,
    isShowLastRow = false,
    dataModel: DataModel
  ) => {
    if (!this.hotInstance) {
      return;
    }
    this.setFilterCondition(columnIndex, filter, dataModel);
    const columnFiltering = this.hotInstance.getPlugin('filters');
    if (!columnFiltering) {
      return;
    }
    columnFiltering.filter();
    if (!isShowLastRow) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const filtersRowsMap = (columnFiltering as any).filtersRowsMap;
      filtersRowsMap.setValueAtIndex(
        filtersRowsMap.indexedValues.length - 1,
        true
      );
    }

    this._filterObservable.next(this);
  };

  recalculate = () => {
    if (!this.hotInstance) {
      return;
    }
    const columnFiltering = this.hotInstance.getPlugin('filters');
    if (!columnFiltering) {
      return;
    }
    columnFiltering.filter();
  };

  getFilteredVisibleRowIndex = (isVisual = true) => {
    return this.getFilteredRowIndex(true, isVisual);
  };

  getFilteredHideRowIndex = (isVisual = true) => {
    return this.getFilteredRowIndex(false, isVisual);
  };

  getFilteredHideRowIndexMaps = () => {
    const hot = this.hotInstance;
    if (!hot) {
      return {};
    }
    const columnFiltering = hot.getPlugin('filters');

    if (!columnFiltering) {
      return {};
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const filtersRowsMap = (columnFiltering as any).filtersRowsMap;

    const hideRowsMaps: Record<number, boolean> = {};
    if (filtersRowsMap) {
      filtersRowsMap.indexedValues.forEach(
        (isHide: boolean, rowIndex: number) => {
          if (isHide) {
            hideRowsMaps[hot.toVisualRow(rowIndex)] = true;
          }
        }
      );
    }

    return hideRowsMaps;
  };

  private parseConditionValue = (
    value: string,
    filterCondition: FilterCondition,
    dataModel: DataModel
  ) => {
    if (isNil(value) || value === '') {
      return value;
    }

    if (
      dataModel.isNumeric() &&
      (filterConditionsHasValueField.includes(filterCondition) ||
        filterConditionsHasSecondValueFields.includes(filterCondition))
    ) {
      const universalDecimal = NumberParser.convertToUsWithDecimal(
        `${value}`,
        dataModel.getNumberFormat()
      );
      if (universalDecimal === null) {
        return value;
      }
      const convertedUS = NumberParser.convertStringToNumber(universalDecimal, {
        format: NumberFormat.US,
      });
      if (convertedUS !== null) {
        return convertedUS;
      } else {
        return value;
      }
    } else {
      return value;
    }
  };

  private setFilterByCondition = (
    columnIndex: number,
    filter: FilterState | null,
    columnFiltering: Filters,
    dataModel: DataModel
  ) => {
    if (!filter) {
      return;
    }

    const firstCondition = filter.byCondition.items[0];
    const secondCondition = filter.byCondition.items[1];

    if (firstCondition) {
      columnFiltering.addCondition(
        columnIndex,
        firstCondition.condition,
        [
          this.parseConditionValue(
            firstCondition.value,
            firstCondition.condition,
            dataModel
          ),
          this.parseConditionValue(
            firstCondition.secondValue,
            firstCondition.condition,
            dataModel
          ),
        ],
        filter.byCondition.logicalOperator
      );
    }

    if (secondCondition) {
      columnFiltering.addCondition(
        columnIndex,
        secondCondition.condition,
        [
          this.parseConditionValue(
            secondCondition.value,
            secondCondition.condition,
            dataModel
          ),
          this.parseConditionValue(
            secondCondition.secondValue,
            secondCondition.condition,
            dataModel
          ),
        ],
        filter.byCondition.logicalOperator
      );
    }
  };

  private setFilterByValue = (
    columnIndex: number,
    filter: FilterState | null,
    columnFiltering: Filters,
    dataModel: DataModel
  ) => {
    const selectedValues = this.getValueSelected(filter);
    if (!filter || !selectedValues) {
      return;
    }

    columnFiltering.addCondition(columnIndex, 'by_value', [
      selectedValues,
      dataModel,
    ]);
  };

  private getValueSelected = (filter: FilterState | null) => {
    const target = filter?.byValue.selected;
    if (!filter || !target) {
      return null;
    }

    const selectedValues = Object.keys(target).filter((key) => target[key]);

    return selectedValues;
  };

  private getFilteredRowIndex = (isVisible: boolean, isVisual: boolean) => {
    const hot = this.hotInstance;
    if (!hot) {
      return [];
    }
    const columnFiltering = hot.getPlugin('filters');

    if (!columnFiltering) {
      return [];
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const filtersRowsMap = (columnFiltering as any).filtersRowsMap;

    if (isVisible) {
      const visibleRows: number[] = [];

      if (filtersRowsMap) {
        filtersRowsMap.indexedValues.forEach(
          (isHide: boolean, rowIndex: number) => {
            if (!isHide) {
              if (isVisual) {
                visibleRows.push(hot.toVisualRow(rowIndex));
              } else {
                visibleRows.push(rowIndex);
              }
            }
          }
        );
      }

      return visibleRows;
    } else {
      const hideRows: number[] = [];
      if (filtersRowsMap) {
        filtersRowsMap.indexedValues.forEach(
          (isHide: boolean, rowIndex: number) => {
            if (isHide) {
              if (isVisual) {
                hideRows.push(hot.toVisualRow(rowIndex));
              } else {
                hideRows.push(rowIndex);
              }
            }
          }
        );
      }

      return hideRows;
    }
  };
}

export default FilterStrategy;
