import { SettingsAPI } from '../settings/settingsAPI';

export type TrackSettings = Required<
  Omit<
    SettingsAPI,
    | 'developerMode'
    | 'style'
    | 'preloadData'
    | 'columns'
    | 'i18nOverrides'
    | 'dataHandler'
  > & {
    i18nOverrides: boolean;
    preloadData: boolean;
    style: boolean;
    onEntryInit: boolean;
    onEntryChange: boolean;
    columnHooks: boolean;
    dataHandlerUsage: {
      headerStep: boolean;
      reviewStep: boolean;
    };
  }
>;

export enum FRAMEWORK {
  REACT = 'react',
  VANILLA = 'vanilla',
  ANGULAR = 'angular',
  VUE = 'vue',
}

class Tracking {
  private static framework: FRAMEWORK = FRAMEWORK.REACT;
  private static origin?: string;
  private static versionNumber: string =
    process.env.NX_SDK_VERSION_NUMBER || '';
  private static frameworkVersion: string;

  static setFrameWork(framework: FRAMEWORK) {
    this.framework = framework;
  }

  static getFrameWork() {
    return this.framework;
  }

  static setOrigin(origin: string) {
    this.origin = origin;
  }

  static getOrigin() {
    return this.origin ?? '';
  }

  static setVersionNumber(versionNumber: string) {
    this.versionNumber = versionNumber;
  }

  static getVersionNumber() {
    return this.versionNumber;
  }

  static setFrameworkVersion(frameworkVersion: string) {
    this.frameworkVersion = frameworkVersion;
  }

  static getFrameworkVersion() {
    return this.frameworkVersion;
  }
}

export default Tracking;
