import HotTable from '@handsontable/react';
import { ItemMenuPopper } from '../CellComponents/Editor/Dropdown/itemMenu/itemMenuPopper';
import {
  MutableRefObject,
  RefObject,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import { Option } from '../../dataModel/model/CategoryDataModel';
import Handsontable from 'handsontable';
import { DataModel } from '../../dataModel/model/DataModel';
import { FieldValue } from '../../value';
import { filterMultipleValues } from '../../reviewEntries/utils/dropdown';
import FreezeStrategyStore from './columns/FreezeStrategyStore';
import AllColumnSetting from './columns/AllColumnSetting';

export const useDropdown = ({
  hotInstance,
  dataModels,
  colWidths,
  parentTableElement,
  dropdownElement,
  currentSelectingCoord,
  searchValueRef,
  enableExamples,
  htCloneLeftWtHolderElement,
  allColumnSetting,
}: {
  hotInstance: RefObject<HotTable>;
  dataModels: DataModel[];
  colWidths: number[];
  parentTableElement: MutableRefObject<HTMLElement | undefined>;
  dropdownElement: MutableRefObject<HTMLElement | undefined>;
  currentSelectingCoord: MutableRefObject<{ row: number; col: number }>;
  searchValueRef: MutableRefObject<string>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dataSet: Record<string, any>[];
  enableExamples?: boolean;
  htCloneLeftWtHolderElement: MutableRefObject<HTMLElement | undefined>;
  allColumnSetting: AllColumnSetting;
}) => {
  const itemMenuPopper = useMemo(() => new ItemMenuPopper(), []);

  const currentSelectorRef = useRef(-1);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const timeoutRef = useRef<any>();
  const currentEditingValueRef = useRef('');
  const currentEditingModelRef = useRef<DataModel>();
  const dropdownOptionsRef = useRef<Option[]>([]);
  const editCol = useRef<number>(-1);
  const editRow = useRef<number>(-1);

  const showDropdownMenu = useCallback(
    (row: number, col: number, instance: Handsontable) => {
      const value = instance?.getValue();
      const physicalColIndex = instance.toPhysicalColumn(col);
      const columnWidth = colWidths[physicalColIndex];
      const rootElement = instance.getCell(row, col, true);
      currentEditingModelRef.current = dataModels[physicalColIndex];
      currentEditingValueRef.current = value;

      if (
        !rootElement ||
        !parentTableElement.current ||
        !dropdownElement.current ||
        !htCloneLeftWtHolderElement.current
      ) {
        return;
      }

      dropdownElement.current.style.width = `${columnWidth - 3}px`;
      searchValueRef.current = '';
      const isFreeze = allColumnSetting.getFreezeColumns().includes(col);

      itemMenuPopper.open(
        rootElement,
        dropdownElement.current,
        parentTableElement.current,
        enableExamples ?? false,
        !isFreeze ? htCloneLeftWtHolderElement.current : undefined
      );
    },
    [
      colWidths,
      dataModels,
      parentTableElement,
      dropdownElement,
      searchValueRef,
      itemMenuPopper,
      enableExamples,
      htCloneLeftWtHolderElement,
      allColumnSetting,
    ]
  );

  const handleOnClose = useCallback(() => {
    itemMenuPopper.close();
    searchValueRef.current = '';
  }, [itemMenuPopper, searchValueRef]);

  const setDropdownOptionValue = useCallback(
    ({ row, col, value }: { row: number; col: number; value: FieldValue }) => {
      const instance = hotInstance.current?.hotInstance;
      instance?.setDataAtCell(row, col, value);
    },
    [hotInstance]
  );

  const handleBeforeKeyDown = useCallback(
    (event: KeyboardEvent) => {
      clearTimeout(timeoutRef.current);

      const [row, col] =
        hotInstance.current?.hotInstance?.getSelected()?.[0] ?? [-1, -1];

      if (row < 0 || col < 0 || row === undefined || col === undefined) return;
      editCol.current = col;
      editRow.current = row;

      const physicalColIndex =
        hotInstance.current?.hotInstance?.toPhysicalColumn(col) ?? 0;

      if (dataModels[physicalColIndex].isDropdown()) {
        const instance = hotInstance.current?.hotInstance;
        const options = instance?.getCellMeta(row, col)?.[
          'dropdownOptions'
        ] as unknown as Option[];

        if (itemMenuPopper.isEditing) {
          if (event.key === 'ArrowUp') {
            if (currentSelectorRef.current > 0) {
              currentSelectorRef.current--;
            }
            event?.stopImmediatePropagation();
            event?.preventDefault();
            return;
          } else if (event.key === 'ArrowDown') {
            if (currentSelectorRef.current < options.length - 1) {
              currentSelectorRef.current++;
            }
            event?.stopImmediatePropagation();
            event?.preventDefault();
            return;
          } else {
            if (event?.key === 'Enter') {
              if (currentSelectorRef.current === -1) {
                return handleOnClose();
              }
              setDropdownOptionValue({
                row,
                col,
                value: filterMultipleValues({
                  currentValue:
                    typeof currentEditingValueRef.current === 'string'
                      ? [currentEditingValueRef.current]
                      : currentEditingValueRef.current || [],
                  isMultiSelect:
                    currentEditingModelRef.current?.getIsMultiSelection() ??
                    false,
                  options,
                  updateValue: options[currentSelectorRef.current].value,
                }),
              });
              handleOnClose();
            } else if (
              event?.key === 'Escape' ||
              event?.key === 'ArrowLeft' ||
              event?.key === 'ArrowRight'
            ) {
              handleOnClose();
            }
            currentSelectorRef.current = 0;
          }
        } else {
          const selector = options?.findIndex(
            (entry) =>
              entry.value === hotInstance.current?.hotInstance?.getValue()
          );
          currentSelectorRef.current = selector;

          if (
            event?.key === 'ArrowUp' ||
            event?.key === 'ArrowDown' ||
            event?.key === 'ArrowLeft' ||
            event?.key === 'ArrowRight' ||
            event.key === 'Tab' ||
            event.key === 'Shift'
          ) {
            return;
          }
          if (event?.key === 'Enter') {
            event?.stopImmediatePropagation();
            event?.preventDefault();
          }
        }

        if (event?.key === 'Enter') {
          timeoutRef.current = setTimeout(() => {
            const [row, col] =
              hotInstance.current?.hotInstance?.getSelected()?.[0] ?? [-1, -1];

            if (instance?.getCellMeta(row, col)?.['readOnly']) {
              return handleOnClose();
            } else if (row >= 0 || col >= 0) {
              showDropdownMenu(row, col, instance as Handsontable);
            }
          }, 0);
        }
      } else {
        handleOnClose();
      }
    },
    [
      dataModels,
      hotInstance,
      itemMenuPopper,
      showDropdownMenu,
      handleOnClose,
      setDropdownOptionValue,
    ]
  );

  const openDropdown = useCallback(
    (
      visualRow: number,
      visualCol: number,
      physicalRow: number,
      physicalCol: number
    ) => {
      clearTimeout(timeoutRef.current);

      if (
        visualRow < 0 ||
        physicalCol < 0 ||
        visualRow === undefined ||
        physicalCol === undefined ||
        physicalRow === undefined
      )
        return;
      let visualColWithFreeze = visualCol;
      const instance = hotInstance.current?.hotInstance;
      const freezeColIndex = FreezeStrategyStore.getFreezeColumns().findIndex(
        (id) => id === dataModels[physicalCol].getBaseKey()
      );

      if (freezeColIndex !== -1) {
        visualColWithFreeze = freezeColIndex;
      }

      const columType = dataModels[physicalCol].getType();

      if (dataModels[physicalCol].isCategoryType()) {
        dropdownElement.current?.classList.add('menu-single-select');
        dropdownElement.current?.classList.remove('menu-boolean-select');
      }
      if (columType === 'boolean') {
        dropdownElement.current?.classList.add('menu-boolean-select');
        dropdownElement.current?.classList.remove('menu-single-select');
      }

      if (dataModels[physicalCol].isDropdown()) {
        if (
          instance?.getCellMeta(visualRow, visualColWithFreeze)?.['readOnly']
        ) {
          return handleOnClose();
        }
        const options = instance?.getCellMeta(visualRow, visualColWithFreeze)?.[
          'dropdownOptions'
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ] as any;

        editCol.current =
          hotInstance.current?.hotInstance?.toVisualColumn(physicalCol) ??
          physicalCol;
        editRow.current = visualRow;

        const selector = options?.findIndex(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (entry: any) =>
            entry.value === hotInstance.current?.hotInstance?.getValue()
        );
        currentSelectorRef.current = selector;

        setTimeout(() => {
          // NOTE: control position of dropdown menu
          showDropdownMenu(visualRow, visualCol, instance as Handsontable);
        }, 0);
      } else {
        handleOnClose();
      }
    },
    [dataModels, showDropdownMenu, hotInstance, dropdownElement, handleOnClose]
  );

  const onSelectOption = useCallback(
    (value: string | number | string[], multiSelect?: boolean) => {
      setDropdownOptionValue({
        row: editRow.current,
        col: editCol.current,
        value: value.toString().length ? value : null,
      });

      hotInstance.current?.hotInstance?.render();

      if (multiSelect) {
        const value = hotInstance.current?.hotInstance?.getValue();
        currentEditingValueRef.current = value;
      } else {
        handleOnClose();
      }
    },
    [hotInstance, editCol, editRow, handleOnClose, setDropdownOptionValue]
  );

  const handleDropdownMenuItem = useCallback(() => {
    if (itemMenuPopper.isEditing) {
      const rootElement = hotInstance.current?.hotInstance?.getCell(
        currentSelectingCoord.current.row,
        currentSelectingCoord.current.col,
        true
      );

      if (
        rootElement &&
        dropdownElement.current &&
        parentTableElement.current
      ) {
        itemMenuPopper.show(rootElement, dropdownElement.current);
      } else {
        itemMenuPopper.hide();
      }
    }
  }, [
    dropdownElement,
    parentTableElement,
    currentSelectingCoord,
    itemMenuPopper,
    hotInstance,
  ]);

  return {
    currentEditingModelRef,
    currentEditingValueRef,
    onSelectOption,
    dropdownOptionsRef,
    openDropdown,
    handleBeforeKeyDown,
    currentSelectorRef,
    itemMenuPopper: itemMenuPopper,
    handleDropdownMenuItem,
  };
};
