import { DATATYPE } from '../dataType';
import {
  COUNTRY_CODE_ALPHA_2_OPTIONS,
  COUNTRY_CODE_ALPHA_3_OPTIONS,
  CURRENCY_CODE_OPTIONS,
} from '../dropdownOptions';
import { isArray } from 'lodash';
import {
  ColumnAPI,
  NumberFormat,
  ColumnType,
  ColumnTypeValue,
  ColumnValueAsKeyAPI,
  ValidationTypeAPI,
  ValidatorAPI,
  ValidatorValuesAPI,
} from './columnsAPI';
import CategoryDataModel, { Option } from './model/CategoryDataModel';
import {
  CategoryDataModelType,
  DataModel,
  DataModelType,
} from './model/DataModel';
import Validator, { VALIDATION } from './validator/Validator';

class ColumnsAPIMapper {
  private columns: ColumnAPI[];
  private dataModels: DataModel[] = [];

  constructor(columns: ColumnAPI[]) {
    this.columns = columns;
    this.setDataModels();
  }

  /**
   * Mapping the validation type from the API to the validation type in the Validator class.
   * @param {ValidationTypeAPI} validation - ValidationTypeAPI - this is an validation type that are applied to
   * the field.
   * @returns A validator type key for the field.
   */
  private mapValidation = (validation: ValidationTypeAPI) => {
    switch (validation) {
      case 'regex':
        return VALIDATION.REGEX;
      case 'required':
        return VALIDATION.REQUIRED;
      case 'required_with':
        return VALIDATION.REQUIRED_WITH;
      case 'required_with_all':
        return VALIDATION.REQUIRED_WITH_ALL;
      case 'required_with_all_values':
        return VALIDATION.REQUIRED_WITH_ALL_VALUES;
      case 'required_with_values':
        return VALIDATION.REQUIRED_WITH_VALUES;
      case 'required_without':
        return VALIDATION.REQUIRED_WITHOUT;
      case 'required_without_all':
        return VALIDATION.REQUIRED_WITHOUT_ALL;
      case 'required_without_all_values':
        return VALIDATION.REQUIRED_WITHOUT_ALL_VALUES;
      case 'required_without_values':
        return VALIDATION.REQUIRED_WITHOUT_VALUES;
      case 'unique':
        return VALIDATION.UNIQUE;
      default: {
        throw new Error(
          `validation "${validation}" isn't in available options`
        );
      }
    }
  };

  /**
   * Mapping the columnType to the DataModelType.
   * @param {ColumnType} columnType - ColumnType - this is an column type of field.
   * @param {boolean} isMultiple - boolean - this is a boolean value to check is multi selection default is `false`.
   * @returns A colum type mapping.
   */
  static mapDataModelType = ({
    columnType,
  }: {
    columnType?: ColumnType | null;
  }): DataModelType => {
    switch (columnType) {
      case 'boolean':
        return DATATYPE.BOOLEAN;
      case 'int':
        return DATATYPE.INTEGER;
      case 'float':
        return DATATYPE.FLOAT;
      case 'category':
        return DATATYPE.SINGLE_SELECT;
      case 'date':
        return DATATYPE.DATE;
      case 'date_dmy':
        return DATATYPE.DATE_DMY;
      case 'date_mdy':
        return DATATYPE.DATE_MDY;
      case 'date_iso':
        return DATATYPE.DATE_ISO;
      case 'datetime':
        return DATATYPE.DATETIME;
      case 'time_hms':
        return DATATYPE.TIME_HMS;
      case 'time_hms_24':
        return DATATYPE.TIME_HMS_24;
      case 'time_hm':
        return DATATYPE.TIME_HM;
      case 'time_hm_24':
        return DATATYPE.TIME_HM_24;
      case 'email':
        return DATATYPE.EMAIL;
      case 'url_www':
        return DATATYPE.URL_WWW;
      case 'url_https':
        return DATATYPE.URL_HTTPS;
      case 'url':
        return DATATYPE.URL;
      case 'phone':
        return DATATYPE.PHONE;
      case 'zip_code_de':
        return DATATYPE.ZIP_CODE_DE;
      case 'percentage':
        return DATATYPE.PERCENTAGE;
      case 'country_code_alpha_2':
        return DATATYPE.COUNTRY_CODE_ALPHA_2;
      case 'country_code_alpha_3':
        return DATATYPE.COUNTRY_CODE_ALPHA_3;
      case 'currency_code':
        return DATATYPE.CURRENCY_CODE;
      case 'currency_eur':
        return DATATYPE.CURRENCY_EUR;
      case 'currency_usd':
        return DATATYPE.CURRENCY_USD;
      case 'bic':
        return DATATYPE.BIC;
      case 'vat_eu':
        return DATATYPE.VAT_EU;
      case 'gtin':
        return DATATYPE.GTIN;
      case 'iban':
        return DATATYPE.IBAN;
      default:
        return DATATYPE.STRING;
    }
  };

  static mapColumnType = ({
    dataType,
  }: {
    dataType?: DATATYPE | null;
  }): ColumnType => {
    switch (dataType) {
      case DATATYPE.BOOLEAN:
        return 'boolean';
      case DATATYPE.INTEGER:
        return 'int';
      case DATATYPE.FLOAT:
        return 'float';
      case DATATYPE.SINGLE_SELECT:
        return 'category';
      case DATATYPE.DATE:
        return 'date';
      case DATATYPE.DATE_DMY:
        return 'date_dmy';
      case DATATYPE.DATE_MDY:
        return 'date_mdy';
      case DATATYPE.DATE_ISO:
        return 'date_iso';
      case DATATYPE.DATETIME:
        return 'datetime';
      case DATATYPE.TIME_HMS:
        return 'time_hms';
      case DATATYPE.TIME_HMS_24:
        return 'time_hms_24';
      case DATATYPE.TIME_HM:
        return 'time_hm';
      case DATATYPE.TIME_HM_24:
        return 'time_hm_24';
      case DATATYPE.EMAIL:
        return 'email';
      case DATATYPE.URL_WWW:
        return 'url_www';
      case DATATYPE.URL_HTTPS:
        return 'url_https';
      case DATATYPE.URL:
        return 'url';
      case DATATYPE.PHONE:
        return 'phone';
      case DATATYPE.ZIP_CODE_DE:
        return 'zip_code_de';
      case DATATYPE.PERCENTAGE:
        return 'percentage';
      case DATATYPE.COUNTRY_CODE_ALPHA_2:
        return 'country_code_alpha_2';
      case DATATYPE.COUNTRY_CODE_ALPHA_3:
        return 'country_code_alpha_3';
      case DATATYPE.CURRENCY_CODE:
        return 'currency_code';
      case DATATYPE.CURRENCY_EUR:
        return 'currency_eur';
      case DATATYPE.CURRENCY_USD:
        return 'currency_usd';
      case DATATYPE.BIC:
        return 'bic';
      case DATATYPE.VAT_EU:
        return 'vat_eu';
      case DATATYPE.GTIN:
        return 'gtin';
      case DATATYPE.IBAN:
        return 'iban';
      default:
        return 'string';
    }
  };

  /**
   * A function that returns a new Validator object.
   * @param {ValidatorValuesAPI} validator - ValidatorValuesAPI[] - this is an validator object that are applied to
   * the field.
   * @returns A object validator each field.
   */
  private getValidator = (validator: ValidatorValuesAPI) => {
    return new Validator({
      validation: this.mapValidation(validator.validate),
      regex: validator.regex ?? undefined,
      fields:
        validator.columnValues?.map((columnValue) => {
          return {
            key: columnValue.key,
            value: columnValue.value ?? [],
            type: this.findDataModelTypeByKey(columnValue.key),
            label: this.findDataModelLabelByKey(columnValue.key),
          };
        }) ?? [],
      errorMessage: validator.errorMessage,
    });
  };

  private findDataModel = (key: string) =>
    this.columns.find((column) => column.key === key);

  private findDataModelTypeByKey = (key: string) => {
    const dataModel = this.findDataModel(key);
    if (dataModel) {
      return ColumnsAPIMapper.mapDataModelType({
        columnType: dataModel.columnType,
      });
    } else {
      throw new Error(`validation key "${key}" isn't found.`);
    }
  };

  private findDataModelLabelByKey = (key: string) => {
    const dataModel = this.findDataModel(key);
    if (dataModel) {
      return dataModel.label;
    } else {
      throw new Error(`validation key "${key}" isn't found.`);
    }
  };

  /**
   * It's a function that returns an array of objects that contains the key and value of the column.
   * @param {ValidatorAPI} validator - ValidatorAPI - this is an validator object that are applied to
   * the field.
   * @returns A an value to validation object has relate with these columns REQUIRED_WITH_VALUES, REQUIRED_WITH_ALL_VALUES, REQUIRED_WITHOUT_VALUES, REQUIRED_WITHOUT_ALL_VALUES
   * and transform a value to validate with REQUIRED_WITH, REQUIRED_WITHOUT, REQUIRED_WITHOUT_ALL, and REQUIRED_WITH_ALL
   */
  private validatorParser = (validator: ValidatorAPI) => {
    const validate = validator.validate.toUpperCase();
    const columnValues = validator.columnValues ?? {};
    const columns = validator.columns ?? [];
    if (
      validate === VALIDATION.REQUIRED_WITH_VALUES ||
      validate === VALIDATION.REQUIRED_WITH_ALL_VALUES ||
      validate === VALIDATION.REQUIRED_WITHOUT_VALUES ||
      validate === VALIDATION.REQUIRED_WITHOUT_ALL_VALUES
    ) {
      return Object.keys(columnValues).map((columnKey) => ({
        key: columnKey,
        value: isArray(columnValues[columnKey])
          ? columnValues[columnKey]
          : [columnValues[columnKey]],
      }));
    } else if (
      validate === VALIDATION.REQUIRED_WITH ||
      validate === VALIDATION.REQUIRED_WITHOUT ||
      validate === VALIDATION.REQUIRED_WITHOUT_ALL ||
      validate === VALIDATION.REQUIRED_WITH_ALL
    ) {
      return columns.map((columnKey) => ({
        key: columnKey,
      }));
    } else {
      return [];
    }
  };

  static createDropdownOptions = (column: ColumnAPI): Option[] => {
    if (column.columnType === ColumnTypeValue.CATEGORY) {
      return (
        column.dropdownOptions?.map((dropdownOption) => {
          return {
            label: `${dropdownOption.label}`,
            value: `${dropdownOption.value}`,
            type: dropdownOption.type,
            alternativeMatches: dropdownOption.alternativeMatches ?? [],
            validations: dropdownOption.validations ?? [],
          };
        }) ?? []
      );
    } else if (column.columnType === ColumnTypeValue.CURRENCY_CODE) {
      return CURRENCY_CODE_OPTIONS;
    } else if (column.columnType === ColumnTypeValue.COUNTRY_CODE_ALPHA_2) {
      return COUNTRY_CODE_ALPHA_2_OPTIONS;
    } else if (column.columnType === ColumnTypeValue.COUNTRY_CODE_ALPHA_3) {
      return COUNTRY_CODE_ALPHA_3_OPTIONS;
    } else {
      return [];
    }
  };

  /* It's a function that generate an array of DataModel objects by Column objects. */
  setDataModels = () => {
    this.dataModels = this.columns.map(
      (column: ColumnAPI): CategoryDataModel | DataModel => {
        const dataModelType = ColumnsAPIMapper.mapDataModelType({
          columnType: column.columnType,
        });

        if (
          column.numberFormat === undefined &&
          dataModelType === DATATYPE.CURRENCY_USD
        ) {
          column.numberFormat = NumberFormat.US;
        }

        const dataModelParam = {
          description: column.description ?? '',
          example: column.example ?? '',
          key: column.key,
          label: column.label,
          type: dataModelType,
          isRequired: false,
          outputFormat: column.outputFormat ?? '',
          validators: column.validations?.map((validator) => {
            return this.getValidator({
              ...validator,
              columnValues: this.validatorParser(
                validator
              ) as ColumnValueAsKeyAPI[],
            });
          }),
          alternativeMatches: column.alternativeMatches,
          columnSize: 1,
          numberFormat:
            (column.numberFormat as NumberFormat) || NumberFormat.EU,
        };

        if (DataModel.isTypeCategory(dataModelType)) {
          return new CategoryDataModel({
            ...dataModelParam,
            type: dataModelType as CategoryDataModelType,
            options: ColumnsAPIMapper.createDropdownOptions(column),
            isMultiSelection: column.isMultiSelect ?? false,
            allowCustomOptions: column.allowCustomOptions || false,
          });
        } else {
          return new DataModel(dataModelParam);
        }
      }
    );
  };

  /* It's a function that returns an array of DataModel objects. */
  getDataModels = () => {
    return this.dataModels;
  };

  hasDateType = () => {
    let hasDateType = false;
    for (let i = 0; i < this.dataModels.length; ++i) {
      if (
        [
          DATATYPE.DATE,
          DATATYPE.DATETIME,
          DATATYPE.DATE_DMY,
          DATATYPE.DATE_ISO,
          DATATYPE.DATE_MDY,
        ].includes(this.dataModels?.[i].getType())
      ) {
        hasDateType = true;
        break;
      }
    }
    return hasDateType;
  };
}

export default ColumnsAPIMapper;
