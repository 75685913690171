import { Remote, wrap } from 'comlink';
import {
  ColumnConfig,
  ExecuteFn,
  ExecuteOptions,
  InputData,
  License,
  LoadVectorFn,
  MatchingAble,
  GetVectorsFn,
} from '../../../types/ml';
import mlWorker from '../../../../worker/ml.txt';
import { createWorker } from 'core/worker/createWorker';

type WrapWorker = {
  loadVector: LoadVectorFn;
  execute: ExecuteFn;
  getVectors: GetVectorsFn;
};

class MatchingWorker implements MatchingAble {
  private worker?: Remote<WrapWorker>;

  constructor() {
    this.setup();
  }

  setup = () => {
    const worker = createWorker(mlWorker);
    this.worker = wrap<WrapWorker>(worker);
  };

  loadVector = () => {
    if (!this.worker) {
      throw new Error('worker not setup');
    }
    return this.worker.loadVector();
  };

  getVectors = () => {
    if (!this.worker) {
      throw new Error('worker not setup');
    }
    return this.worker.getVectors();
  };

  execute = (
    columns: ColumnConfig[],
    inputJson: InputData,
    license: License,
    options: ExecuteOptions
  ) => {
    if (!this.worker) {
      throw new Error('worker not setup');
    }

    return this.worker.execute(columns, inputJson, license, options);
  };
}

export default MatchingWorker;
