import { cssManualButtonWhitelist } from './styled';

export const copyNodeStyle = (
  sourceNode: HTMLElement,
  targetNode: HTMLElement
) => {
  const computedStyle = window.getComputedStyle(sourceNode);

  Array.from(computedStyle).forEach((key) => {
    if (!cssManualButtonWhitelist.includes(key)) {
      targetNode.style.setProperty(
        key,
        computedStyle.getPropertyValue(key),
        computedStyle.getPropertyPriority(key)
      );
    }
  });
};
