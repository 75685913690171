import { ReactNode } from 'react';
import PopoverInfo from './PopoverInfo';
import { cx } from 'core/emotion';

type LabelProps = {
  children: ReactNode;
  description?: string;
  className?: string;
  textClassName?: string;
  iconClassName?: string;
};

const Label = ({
  children,
  description,
  className,
  textClassName,
  iconClassName,
}: LabelProps) => {
  return (
    <div className={cx('flex items-center', className)}>
      <span className={cx('mr-1 text-sm text-gray-700', textClassName)}>
        {children}
      </span>
      {description ? (
        <PopoverInfo description={description} configTheme={iconClassName} />
      ) : null}
    </div>
  );
};

export default Label;
