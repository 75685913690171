/* istanbul ignore file */
import TextInfo from 'baseUI/TextInfo';

const DevelopMode = ({ isLoading }: { isLoading?: boolean }) => {
  return (
    <div
      className={`${
        isLoading ? 'invisible' : 'visible'
      } bg-blue-light-50 rounded-full px-2 py-1`}
    >
      <TextInfo
        text="The development mode is activated. This mode is not allowed for commercial use, but only for pure testing purposes."
        textSize="small"
        textColor="text-blue-dark-900"
      />
    </div>
  );
};

export default DevelopMode;
