import { SubmitResultType } from 'core/submitResult';
import {
  Row,
  HookRowResult,
  Values,
  HookedRecordValue,
  HookedRecordRowResult,
  ResultValues,
  IEntryChangeOptions,
  ImportLogs,
} from 'nuvo-react';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import {
  DataHandlerMetaData,
  HeaderStepHandlerData,
  ModifierStack,
  ReviewStepHandlerData,
  SheetData,
} from '../../../../libs/importer/src/types';

type HandleEntryInit = (data: Values) => Promise<(HookRowResult | undefined)[]>;

type HandleEntryInitRow = (
  record: Row,
  index: number
) => HookRowResult | undefined;

type HandleResults = (
  values: ResultValues,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errors: any,
  logs: ImportLogs
) => Promise<{
  title: string;
  message: string;
  type: SubmitResultType;
  failedRecords?: number;
  successfulRecords?: number;
  text?: string;
  imageUrl?: string;
  duration?: number;
}>;

type HandleCancel = () => void;

type HandleEntryChange = (
  record: Row,
  index: number,
  options: IEntryChangeOptions
) => HookRowResult | undefined;

type HandleColumnHooks = (
  fieldName: string,
  data: HookedRecordValue[]
) => Promise<HookedRecordRowResult[]>;

type HandleRenderUploadButton = () => Promise<string>;

type HandleText = () => Promise<string>;

type GetColumnHooks = () => Promise<string[]>;

export type ColumnHookCallback = (
  values: HookedRecordValue[]
) => Promise<HookedRecordRowResult[]> | HookedRecordRowResult[];

export type ParentAPI = {
  handleEntryInit: HandleEntryInit;
  handleEntryInitRow: HandleEntryInitRow;
  handleResults: HandleResults;
  handleCancel: HandleCancel;
  handleEntryChange: HandleEntryChange;
  handleColumnHooks: HandleColumnHooks;
  handleRenderUploadButton: HandleRenderUploadButton;
  handleText: HandleText;
  getColumnHooks: GetColumnHooks;
  dataHandlerHeaderStep: (data: HeaderStepHandlerData[]) => Promise<{
    operators: ModifierStack<SheetData>[];
    data: SheetData;
  }>;
  dataHandlerReviewStep: (
    data: ReviewStepHandlerData,
    metaData?: DataHandlerMetaData[]
  ) => Promise<{
    operators: ModifierStack<ResultValues>[];
    data: ResultValues;
  }>;
};

export enum MODIFIER_TYPE {
  ADD_ROW = 'addRow',
  ADD_COLUMN = 'addColumn',
  REMOVE_ROW = 'removeRow',
  REMOVE_COLUMN = 'removeColumn',
}
