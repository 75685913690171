import { useTranslation } from 'react-i18next';
import ButtonSwitch from '../../../../../components/ButtonSwitch';
import { css, cx } from '../../../../emotion';
import { thousandFormat } from '../../../utils';

export enum VIEW_ACTION {
  SHOW_ALL = 'SHOW_ALL',
  SHOW_ERROR = 'SHOW_ONLY_ERROR',
}

type ToggleViewButtonProps = {
  errorCountNumber: number;
  isShowOnlyError: boolean;
  onToggleViewChange: (action: VIEW_ACTION) => void;
};

const ToggleViewButton = ({
  errorCountNumber,
  isShowOnlyError,
  onToggleViewChange,
}: ToggleViewButtonProps) => {
  const { t } = useTranslation();
  return (
    <ButtonSwitch<VIEW_ACTION>
      actions={[
        {
          label: t('txt_all_rows'),
          key: VIEW_ACTION.SHOW_ALL,
          activeClassName: 'font-semibold',
        },
        {
          label:
            errorCountNumber === 1
              ? t('txt_one_error')
              : t('txt_error_amount', {
                  amountOfErrors: `${thousandFormat(errorCountNumber)}`,
                }),
          key: VIEW_ACTION.SHOW_ERROR,
          className: cx(
            '!text-salmon-500 !w-auto px-4',
            css`
              min-width: 132px;
            `
          ),
        },
      ]}
      currentAction={
        isShowOnlyError ? VIEW_ACTION.SHOW_ERROR : VIEW_ACTION.SHOW_ALL
      }
      onActionChange={onToggleViewChange}
    />
  );
};

export default ToggleViewButton;
