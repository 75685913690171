/* istanbul ignore file */

import Sheet from './Sheet';
import { NumberFormat } from '../dataModel/columnsAPI';

export type SpreadSheetType =
  | 'csv'
  | 'xlsx'
  | 'json'
  | 'xml'
  | 'dynamic-import';

class SpreadSheet {
  private sheets: Sheet[];
  private filename: string;
  private type: SpreadSheetType;
  private fileSize: number;
  private numberFormat: NumberFormat = NumberFormat.EU;

  constructor({
    sheets,
    filename,
    type,
    fileSize,
  }: {
    sheets: Sheet[];
    filename: string;
    type: SpreadSheetType;
    fileSize?: number;
  }) {
    this.sheets = sheets;
    this.filename = filename;
    this.type = type;
    this.fileSize = fileSize ?? 0;

    this.sheets.forEach((sheet) => {
      sheet.setSpreadSheet(this);
    });
  }

  addSheet = (sheet: Sheet) => {
    this.sheets.push(sheet);
  };

  isSelected = () => {
    for (let index = 0; index < this.sheets.length; index++) {
      const sheet = this.sheets[index];
      if (sheet.isSelected()) {
        return true;
      }
    }

    return false;
  };

  getSheets = () => {
    return this.sheets;
  };

  getSelectedSheets = () => {
    return this.sheets.filter((sheet) => sheet.isSelected());
  };

  getFilename = () => {
    return this.filename;
  };

  selectAllSheet = (selected: boolean) => {
    return this.sheets.forEach((sheet) => {
      sheet.setSelected(selected);
    });
  };

  getType = () => {
    return this.type;
  };

  getFileSize = () => {
    const fileSizeInMb = this.fileSize / 1000000;
    return Number(parseFloat(`${fileSizeInMb}`).toFixed(4));
  };

  setNumberFormat(numberFormat: NumberFormat): void {
    this.numberFormat = numberFormat;
  }

  getNumberFormat(): NumberFormat {
    return this.numberFormat;
  }
}

export default SpreadSheet;
