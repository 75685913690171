import { useMemo } from 'react';
import { css, cx } from '../../core/emotion';

type ButtonSwitchAction<T> = {
  label: string;
  key: T;
  className?: string;
  activeClassName?: string;
};

type ButtonSwitchProps<T> = {
  actions: ButtonSwitchAction<T>[];
  currentAction: T;
  onActionChange: (action: T) => void;
};

const ButtonSwitch = <T,>({
  actions,
  currentAction,
  onActionChange,
}: ButtonSwitchProps<T>) => {
  const buttonStyle = useMemo(() => {
    return css`
      padding-top: 10px;
      padding-bottom: 10px;
    `;
  }, []);

  return (
    <div
      className={cx(
        'bg-gray-120 rounded-medium',
        css`
          box-shadow: 0 0 0 1px #c5c5c5 inset;
        `
      )}
    >
      {actions.map((action) => {
        const activeClassName = cx('bg-white border', action.activeClassName);

        return (
          <button
            type="button"
            key={`${action.key}`}
            onClick={() => {
              onActionChange(action.key);
            }}
            style={{
              width: 132,
            }}
            className={cx(
              'rounded-medium border-gray-260 text-neutral-gray text-sm',
              action.className,
              currentAction === action.key ? activeClassName : '',
              buttonStyle
            )}
          >
            {action.label}
          </button>
        );
      })}
    </div>
  );
};

export default ButtonSwitch;
