import { FieldValue } from '../value';
import { VALIDATION_KEY } from '../../core/reviewEntries/constant';

export type ColumnAPI = {
  label: string;
  key: string;
  description?: string;
  example?: string | null;
  validations?: ValidatorAPI[] | null;
  columnType?: ColumnType | null;
  dropdownOptions?: DropdownOptionAPI[] | null;
  alternativeMatches?: string[];
  isMultiSelect?: boolean;
  outputFormat?: string | null;
  allowCustomOptions?: boolean;
  numberFormat?: 'eu' | 'us';
};

type ValueOf<T> = T[keyof T];

export type InternalValidationTypeAPI = ValueOf<typeof VALIDATION_KEY>;

export type ValidationTypeAPI =
  | 'required'
  | 'unique'
  | 'regex'
  | 'required_with'
  | 'required_without'
  | 'required_with_all'
  | 'required_without_all'
  | 'required_with_values'
  | 'required_without_values'
  | 'required_with_all_values'
  | 'required_without_all_values';

export enum ColumnTypeValue {
  CATEGORY = 'category',
  BOOLEAN = 'boolean',
  INT = 'int',
  FLOAT = 'float',
  STRING = 'string',
  DATE = 'date',
  /**
   * @deprecated This property should not be used
   */
  DATE_DMY = 'date_dmy',
  /**
   * @deprecated This property should not be used
   */
  DATE_MDY = 'date_mdy',
  /**
   * @deprecated This property should not be used
   */
  DATE_ISO = 'date_iso',
  /**
   * @deprecated This property should not be used
   */
  DATETIME = 'datetime',
  /**
   * @deprecated This property should not be used
   */
  TIME_HMS = 'time_hms',
  /**
   * @deprecated This property should not be used
   */
  TIME_HMS_24 = 'time_hms_24',
  /**
   * @deprecated This property should not be used
   */
  TIME_HM = 'time_hm',
  /**
   * @deprecated This property should not be used
   */
  TIME_HM_24 = 'time_hm_24',
  EMAIL = 'email',
  URL_WWW = 'url_www',
  URL_HTTPS = 'url_https',
  URL = 'url',
  PHONE = 'phone',
  ZIP_CODE_DE = 'zip_code_de',
  PERCENTAGE = 'percentage',
  COUNTRY_CODE_ALPHA_2 = 'country_code_alpha_2',
  COUNTRY_CODE_ALPHA_3 = 'country_code_alpha_3',
  CURRENCY_CODE = 'currency_code',
  CURRENCY_EUR = 'currency_eur',
  CURRENCY_USD = 'currency_usd',
  BIC = 'bic',
  VAT_EU = 'vat_eu',
  GTIN = 'gtin',
  IBAN = 'iban',
}

export type ColumnType = `${ColumnTypeValue}`;

export type DropdownOptionType = 'string' | 'float' | 'int';

type DropdownOptionAPI = DropdownOptionAPIBase &
  (DropdownOptionAPIString | DropdownOptionAPINumber);

type DropdownOptionAPIBase = {
  label: string;
  alternativeMatches?: string[];
  validations?: DropdownOptionValidation[];
};

type DropdownOptionAPIString = {
  type: 'string';
  value: string;
};

type DropdownOptionAPINumber = {
  type: 'float' | 'int';
  value: number;
};

export type DropdownOptionValidation = {
  validate: DropdownOptionValidate;
  errorMessage?: string;
};

export type ValidateOperators =
  | 'AND'
  | 'OR'
  | 'GTE'
  | 'GT'
  | 'LT'
  | 'LTE'
  | 'EQ'
  | 'NEQ';

export type DropdownOptionValidate = Record<
  string,
  { [key: string]: DropdownOptionValidate | FieldValue } | FieldValue
>;

export type ValidatorAPI = {
  validate: ValidationTypeAPI;
  regex?: string | null;
  columnValues?: ColumnValueAPI | null;
  columns?: columnsAPI;
  errorMessage?: string;
};

export type columnsAPI = string[];

export type ColumnValueAPI = Record<string, FieldValue[] | FieldValue>;

export type ValidatorValuesAPI = {
  validate: ValidationTypeAPI;
  regex?: string | null;
  columnValues?: ColumnValueAsKeyAPI[] | null;
  errorMessage?: string;
};

export type ColumnValueAsKeyAPI = {
  key: string;
  value?: FieldValue[];
};

export enum NumberFormat {
  US = 'us',
  EU = 'eu',
}
