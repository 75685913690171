import { RefObject, useEffect } from 'react';
import { HotTable } from '@handsontable/react';

export const useHotTableScroll = (
  hotInstanceRef: RefObject<HotTable>,
  colAmount: number,
  widthColumn: number,
  isOpen: boolean
): void => {
  useEffect(() => {
    if (!isOpen) {
      return;
    }

    const handleScrollbar = () => {
      const container = hotInstanceRef.current?.hotInstance
        ?.rootElement as HTMLDivElement;

      const scrollContainer = container?.querySelector(
        '.wtHolder'
      ) as HTMLDivElement;

      if (!container || !scrollContainer) {
        return;
      }

      handleBorderLastRow();
      handleSpaceFirstColumn();

      function handleBorderLastRow() {
        const hasScrollVertical =
          scrollContainer.scrollHeight > scrollContainer.clientHeight;
        const hasScrollVerticalReachEnd =
          hasScrollVertical &&
          scrollContainer.offsetHeight + scrollContainer.scrollTop >=
            scrollContainer.scrollHeight;

        if (hasScrollVerticalReachEnd) {
          requestAnimationFrame(() => {
            container.classList.add('hide-border-last-row');
          });
        } else {
          requestAnimationFrame(() => {
            container.classList.remove('hide-border-last-row');
          });
        }
      }

      function handleSpaceFirstColumn() {
        const hasScrollHorizontal =
          scrollContainer.scrollWidth > scrollContainer.clientWidth;

        if (hasScrollHorizontal) {
          requestAnimationFrame(() => {
            container.classList.add('add-space-scrollbar-h');
            container.classList.add('hide-border-last-column');
          });
        } else {
          container.classList.remove('add-space-scrollbar-h');
          container.classList.remove('hide-border-last-column');
        }

        const allColumnsWidth = colAmount * widthColumn;
        if (allColumnsWidth > container.clientWidth) {
          container.classList.remove('wt-full-width');
        } else {
          container.classList.add('wt-full-width');
        }
      }

      const allColumnsWidth = colAmount * widthColumn;
      if (allColumnsWidth > container.clientWidth) {
        container.classList.remove('wt-full-width');
      } else {
        container.classList.add('wt-full-width');
      }

      function handleSpaceLastColumn() {
        const hasScrollVertical =
          scrollContainer.scrollHeight > scrollContainer.clientHeight;

        if (hasScrollVertical) {
          requestAnimationFrame(() => {
            container.classList.add('add-space-scrollbar-w');
          });
        } else {
          requestAnimationFrame(() => {
            container.classList.remove('add-space-scrollbar-w');
          });
        }
      }

      const hasScrollVertical =
        scrollContainer.scrollHeight > scrollContainer.clientHeight;

      if (hasScrollVertical) {
        requestAnimationFrame(() => {
          container.classList.add('add-space-scrollbar-w');
        });
      } else {
        requestAnimationFrame(() => {
          container.classList.remove('add-space-scrollbar-w');
        });
      }

      scrollContainer.addEventListener('scroll', handleBorderLastRow, {
        passive: true,
      });
      window.addEventListener('resize', handleBorderLastRow, {
        passive: true,
      });
      window.addEventListener('resize', handleSpaceFirstColumn, {
        passive: true,
      });
      window.addEventListener('resize', handleSpaceLastColumn, {
        passive: true,
      });

      return () => {
        window.removeEventListener('resize', handleBorderLastRow);
        window.removeEventListener('resize', handleSpaceFirstColumn);
        window.removeEventListener('resize', handleSpaceLastColumn);
        scrollContainer.removeEventListener('scroll', handleBorderLastRow);
      };
    };

    setTimeout(() => {
      handleScrollbar();
    }, 0);
  }, [colAmount, hotInstanceRef, widthColumn, isOpen]);
};
