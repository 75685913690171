import {
  isArray,
  isBoolean,
  isEmpty,
  isNil,
  isNumber,
  isObject,
  isString,
  remove,
  uniq,
} from 'lodash';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type InputValue = any;

class DataModelValidator {
  static validate = (input: InputValue) => {
    const errorMessages: string[] = this.identifyDuplicateKeys(input);

    errorMessages.push(...this.identifyInvalidColumProperties(input));

    input.forEach((item: InputValue, index: number) => {
      const adjustedIndex = index + 1;

      errorMessages.push(this.validateKey(item, adjustedIndex));
      errorMessages.push(this.validateLabel(item, adjustedIndex));
      errorMessages.push(this.validateDescription(item, adjustedIndex));
      errorMessages.push(this.validateExample(item, adjustedIndex));
      errorMessages.push(this.validateIsMultiSelect(item, adjustedIndex));
      errorMessages.push(this.validateOutputFormat(item, adjustedIndex));
      errorMessages.push(this.validateAlternativeMatches(item, adjustedIndex));
      errorMessages.push(this.validateColumnType(item, adjustedIndex));
      errorMessages.push(...this.validateDropdownOptions(item, adjustedIndex));
      errorMessages.push(...this.validateValidations(item, adjustedIndex));
      errorMessages.push(this.validateAllowCustomOptions(item, adjustedIndex));
      errorMessages.push(this.validateNumberFormat(item, adjustedIndex));
    });

    uniq(errorMessages);
    remove(errorMessages, (item) => item === '');
    return {
      isValid: !errorMessages.length,
      errors: errorMessages,
    };
  };

  private static identifyDuplicateKeys = (input: InputValue): string[] => {
    const existingKeys: string[] = [];
    const duplicateKeyErrors: string[] = [];

    input.forEach((item: InputValue, index: number) => {
      const adjustedIndex = index + 1;

      if (existingKeys.includes(item?.key)) {
        duplicateKeyErrors.push(
          `Column ${adjustedIndex}: key should be unique across all columns`
        );
      } else {
        existingKeys.push(item.key);
      }
    });

    return duplicateKeyErrors;
  };

  private static identifyInvalidColumProperties = (
    input: InputValue
  ): string[] => {
    const invalidKeyErrors: string[] = [];

    input.forEach((item: InputValue, index: number) => {
      const adjustedIndex = index + 1;

      Object.keys(item).forEach((key) => {
        if (
          ![
            'key',
            'label',
            'columnType',
            'validations',
            'isMultiSelect',
            'outputFormat',
            'dropdownOptions',
            'alternativeMatches',
            'example',
            'description',
            'allowCustomOptions',
            'numberFormat',
          ].includes(key)
        ) {
          invalidKeyErrors.push(
            `Column ${adjustedIndex}: ${key} is not a valid property`
          );
        }
      });
    });

    return invalidKeyErrors;
  };

  private static validateKey = (input: InputValue, index: number): string => {
    if (isNil(input.key)) {
      return `Column ${index}: key is missing`;
    } else if (isEmpty(input.key) || !isString(input.key)) {
      return `Column ${index}: key is invalid`;
    }

    return '';
  };

  private static validateLabel = (input: InputValue, index: number): string => {
    if (isNil(input.label)) {
      return `Column ${index}: label is missing`;
    } else if (!isString(input.label)) {
      return `Column ${index}: label is invalid`;
    }

    return '';
  };

  private static validateDescription = (
    input: InputValue,
    index: number
  ): string => {
    if (!this.isStringOrNil(input.description)) {
      return `Column ${index}: description is invalid`;
    }

    return '';
  };

  private static validateExample = (
    input: InputValue,
    index: number
  ): string => {
    if (!this.isStringOrNil(input.example)) {
      return `Column ${index}: example is invalid`;
    }
    return '';
  };

  private static validateIsMultiSelect = (
    input: InputValue,
    index: number
  ): string => {
    if (!isBoolean(input.isMultiSelect) && !isNil(input.isMultiSelect)) {
      return `Column ${index}: isMultiSelect should be a boolean`;
    }

    if (
      isBoolean(input.isMultiSelect) &&
      ![
        'category',
        'country_code_alpha_2',
        'country_code_alpha_3',
        'currency_code',
      ].includes(input.columnType)
    ) {
      return `Column ${index}: isMultiSelect is not allowed for this column type`;
    }
    return '';
  };

  private static validateAllowCustomOptions = (
    input: InputValue,
    index: number
  ): string => {
    if (
      !isBoolean(input.allowCustomOptions) &&
      !isNil(input.allowCustomOptions)
    ) {
      return `Column ${index}: allowCustomOptions should be a boolean`;
    }

    if (
      isBoolean(input.allowCustomOptions) &&
      !['category'].includes(input.columnType)
    ) {
      return `Column ${index}: allowCustomOptions is not allowed for this column type`;
    }
    return '';
  };

  private static validateNumberFormat = (
    input: InputValue,
    index: number
  ): string => {
    if (
      isString(input.numberFormat) &&
      !['int', 'float', 'percentage', 'currency_eur', 'currency_usd'].includes(
        input.columnType
      )
    ) {
      return `Column ${index}: numberFormat is not allowed for this column type`;
    }
    if (
      ['int', 'float', 'percentage', 'currency_eur', 'currency_usd'].includes(
        input.columnType
      ) &&
      this.isStringOrNil(input.numberFormat)
    ) {
      if (
        !['eu', 'us'].includes(input.numberFormat) &&
        isString(input.numberFormat)
      ) {
        return `Column ${index}: numberFormat is invalid`;
      }
    }

    return '';
  };

  private static validateOutputFormat = (
    input: InputValue,
    index: number
  ): string => {
    if (isString(input.outputFormat) && input.columnType !== 'date') {
      return `Column ${index}: outputFormat is only allowed for date columns`;
    }
    if (!this.isStringOrNil(input.outputFormat)) {
      return `Column ${index}: outputFormat is invalid`;
    }

    return '';
  };

  private static validateAlternativeMatches = (
    input: InputValue,
    index: number
  ): string => {
    if (
      !isNil(input.alternativeMatches) &&
      (!isArray(input.alternativeMatches) ||
        input.alternativeMatches.some((item: InputValue) => !isString(item)))
    ) {
      return `Column ${index}: alternativeMatches should be an array of strings`;
    }

    return '';
  };

  private static validateColumnType = (
    input: InputValue,
    index: number
  ): string => {
    if (isNil(input.columnType)) {
      return '';
    }
    switch (input.columnType) {
      case 'string':
      case 'boolean':
      case 'int':
      case 'float':
      case 'category':
      case 'date':
      case 'date_dmy':
      case 'date_mdy':
      case 'date_iso':
      case 'datetime':
      case 'time_hms':
      case 'time_hms_24':
      case 'time_hm':
      case 'time_hm_24':
      case 'email':
      case 'url_www':
      case 'url_https':
      case 'url':
      case 'phone':
      case 'zip_code_de':
      case 'percentage':
      case 'country_code_alpha_2':
      case 'country_code_alpha_3':
      case 'currency_code':
      case 'currency_eur':
      case 'currency_usd':
      case 'bic':
      case 'vat_eu':
      case 'gtin':
      case 'iban':
        return '';
      default: {
        return `Column ${index}: columnType is invalid`;
      }
    }
  };

  private static validateDropdownOptions = (
    input: InputValue,
    index: number
  ): string[] => {
    if (input.columnType === 'category') {
      if (!input.dropdownOptions || input.dropdownOptions?.length === 0) {
        return [
          `Column ${index}: dropdownOptions are required for category columns`,
        ];
      }

      const errorMessages: string[] = [];
      input.dropdownOptions?.forEach((item: InputValue, position: number) => {
        const adjustedPosition = position + 1;
        errorMessages.push(
          ...this.validateDropdownOption(item, adjustedPosition, index)
        );

        //identify invalid object keys
        Object.keys(item).forEach((key) => {
          if (
            ![
              'key',
              'label',
              'type',
              'validations',
              'value',
              'alternativeMatches',
            ].includes(key)
          ) {
            errorMessages.push(
              `Column ${index}: Option ${adjustedPosition} contains an invalid property called '${key}'`
            );
          }
        });
      });

      return errorMessages;
    } else if (!isNil(input.dropdownOptions)) {
      return [
        `Column ${index}: dropdownOptions are only allowed for category columns`,
      ];
    }
    return [];
  };

  private static validateDropdownOption = (
    input: InputValue,
    position: number,
    index: number
  ): string[] => {
    const errorMessages: string[] = [];

    if (isNil(input.value)) {
      errorMessages.push(
        `Column ${index}: The value of Option ${position} is missing`
      );
    } else if (!isString(input.value) && !isNumber(input.value)) {
      errorMessages.push(
        `Column ${index}: The value of Option ${position} is invalid`
      );
    }

    if (isNil(input.label)) {
      errorMessages.push(
        `Column ${index}: The label of Option ${position} is missing`
      );
    } else if (!isString(input.label)) {
      errorMessages.push(
        `Column ${index}: The label of Option ${position} is invalid`
      );
    }

    if (
      !isNil(input.type) &&
      !['string', 'float', 'int'].includes(input.type)
    ) {
      errorMessages.push(
        `Column ${index}: The type of Option ${position} is invalid`
      );
    }

    if (
      !isNil(input.alternativeMatches) &&
      (!isArray(input.alternativeMatches) ||
        input.alternativeMatches.some((item: InputValue) => !isString(item)))
    ) {
      errorMessages.push(
        `Column ${index}: The alternativeMatches of Option ${position} are invalid`
      );
    }

    if (
      !isNil(input.validations) &&
      (!isArray(input.validations) ||
        input.validations.some((item: InputValue) => !isObject(item)))
    ) {
      errorMessages.push(
        `Column ${index}: The validations of Option ${position} are invalid`
      );
    }

    return errorMessages;
  };

  private static validateValidations = (
    input: InputValue,
    index: number
  ): string[] => {
    if (isNil(input.validations)) {
      return [];
    }

    const errorMessages: string[] = [];

    input.validations.forEach((item: InputValue, position: number) => {
      errorMessages.push(
        this.validateValidationsItem(
          item,
          position + 1,
          index,
          input.columnType
        )
      );
    });

    return errorMessages;
  };

  private static validateValidationsItem = (
    input: InputValue,
    position: number,
    index: number,
    columnType: string
  ): string => {
    if (!isObject(input)) {
      return `Column ${index}: Validation ${position} is not an object`;
    }

    if (
      ![
        'required',
        'unique',
        'regex',
        'required_with',
        'required_without',
        'required_with_all',
        'required_without_all',
        'required_with_values',
        'required_without_values',
        'required_with_all_values',
        'required_without_all_values',
      ].includes((input as InputValue).validate)
    ) {
      return `Column ${index}: Validation ${position} has an invalid validate value`;
    }

    if (
      (input as InputValue).validate === 'regex' &&
      columnType !== 'string' &&
      !!columnType
    ) {
      return `Column ${index}: Validation ${position} regex validation is only allowed for string columns`;
    } else if (!this.isStringOrNil((input as InputValue).regex)) {
      return `Column ${index}: Validation ${position} has an invalid regular expression`;
    }

    if (
      isString((input as InputValue).regex) &&
      (input as InputValue).validate !== 'regex'
    ) {
      return `Column ${index}: Validation ${position} regex is only allowed for regular expression validation`;
    }

    if (
      !isString((input as InputValue).regex) &&
      (input as InputValue).validate === 'regex'
    ) {
      return `Column ${index}: Validation ${position} is missing a regular expression`;
    }

    if (
      !isNil((input as InputValue).columnValues) &&
      !isObject((input as InputValue).columnValues)
    ) {
      return `Column ${index}: Validation ${position} has invalid columnValues`;
    }

    if (
      !isNil((input as InputValue).columns) &&
      (!isArray((input as InputValue).columns) ||
        (input as InputValue).columns.some(
          (item: InputValue) => !isString(item)
        ))
    ) {
      return `Column ${index}: Validation ${position} has invalid columns`;
    }

    if (!this.isStringOrNil((input as InputValue).errorMessage)) {
      return `Column ${index}: Validation ${position} has an invalid errorMessage`;
    }

    return '';
  };

  private static isStringOrNil = (item: InputValue) => {
    if (isString(item) || isNil(item)) {
      return true;
    } else {
      return false;
    }
  };
}

export default DataModelValidator;
