import { BehaviorSubject } from 'rxjs';
import ContextMenuPopper from '../popper/ContextMenuPopper';

class ContextMenuController {
  private currentOpenMenu: number | null = null;
  static menuButtonClassName = 'context-menu-button';
  static selectedClassName = 'selected';
  private _contextMenuObservable: BehaviorSubject<ContextMenuController>;
  private contextMenuPopper: ContextMenuPopper;
  private tableContainer?: HTMLDivElement;

  constructor() {
    this._contextMenuObservable = new BehaviorSubject<ContextMenuController>(
      this
    );
    this.contextMenuPopper = new ContextMenuPopper();
  }

  setTableContainer = (container: HTMLDivElement) => {
    this.tableContainer = container;
  };

  getContextMenuPopper = () => {
    return this.contextMenuPopper;
  };

  openMenu = (colIndex: number) => {
    if (colIndex === this.currentOpenMenu) {
      this.closeMenu();
      return;
    }
    this.currentOpenMenu = colIndex;
    this._contextMenuObservable.next(this);
  };

  closeMenu = () => {
    this.currentOpenMenu = null;
    this._contextMenuObservable.next(this);
    this.clearAllSelectedClass();
  };

  contextMenuObservable = () => {
    return this._contextMenuObservable;
  };

  registerEventHeaderMenuButton = (
    th: HTMLTableHeaderCellElement,
    colIndex: number
  ) => {
    const menuButton = th.querySelector(
      `.${ContextMenuController.menuButtonClassName}`
    ) as HTMLDivElement | null;

    if (!menuButton) return;

    const addSelectedClass = () => {
      menuButton.classList.add(ContextMenuController.selectedClassName);
    };

    const removeSelectedClass = () => {
      menuButton.classList.remove(ContextMenuController.selectedClassName);
    };

    th.addEventListener('click', () => {
      this.closeMenu();
    });

    menuButton.addEventListener(
      'click',
      (event) => {
        event.stopPropagation();
        this.openMenu(colIndex);
        this.contextMenuPopper.setReferenceElement(menuButton);
        this.clearAllSelectedClass();
        addSelectedClass();
      },
      false
    );

    if (this.currentOpenMenu === colIndex) {
      this.contextMenuPopper.setReferenceElement(menuButton);
      addSelectedClass();
    } else {
      removeSelectedClass();
    }
  };

  getCurrentOpenMenu = () => {
    return this.currentOpenMenu;
  };

  private clearAllSelectedClass = () => {
    if (!this.tableContainer) {
      return;
    }
    const menuButtons = this.tableContainer.querySelectorAll(
      `.${ContextMenuController.menuButtonClassName}`
    );

    menuButtons.forEach((item) => {
      item.classList.remove(ContextMenuController.selectedClassName);
    });
  };
}

export default ContextMenuController;
