import {
  HEADER_SELECTION_PATH,
  MAIN_PAGE_PATH,
  MATCH_COLUMN_PATH,
  SHEET_SELECTION_PATH,
} from 'core/constants/route';
import { useHooks } from 'hooks';
import { useMatching } from './../../matching/MatchingProvider';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useSettings } from 'settings';
import SpreadSheetNavigate from '../../uploadData/SelectHeaderPage/SpreadSheetNavigate';
import useLocationPathname from 'core/location';

type StepperListType = {
  path: string;
  label: string;
  rightIcon: boolean;
};

const useViewModel = () => {
  const { pathname } = useLocationPathname();
  const { state: locationState } = useLocation();
  const setting = useSettings();
  const { t } = useTranslation();
  const { executing, isLoadingModel } = useMatching();
  const { loadingInitialValues } = useHooks();
  const state = locationState as {
    spreadSheetNavigate: SpreadSheetNavigate;
    isAutoMapping?: boolean;
    dynamicUploadStart?: string;
  };

  const steps = useMemo(() => {
    const baseItems = [
      {
        path: MAIN_PAGE_PATH,
        label: t('txt_progress_upload'),
        rightIcon: true,
      },
      {
        path: '/select-sheet',
        label: t('txt_progress_sheet_selection'),
        rightIcon: true,
      },
      {
        path: '/select-header',
        label: t('txt_progress_header_selection'),
        rightIcon: true,
      },
      {
        path: '/match-column',
        label: t('txt_progress_match_column'),
        rightIcon: true,
      },
      {
        path: '/review-entries',
        label: t('txt_progress_review_entries'),
        rightIcon: false,
      },
    ];
    if (state?.dynamicUploadStart === SHEET_SELECTION_PATH) {
      return [baseItems[1], baseItems[2], baseItems[3], baseItems[4]];
    } else if (state?.dynamicUploadStart === HEADER_SELECTION_PATH) {
      return [baseItems[2], baseItems[3], baseItems[4]];
    } else if (state?.dynamicUploadStart === MATCH_COLUMN_PATH) {
      return [baseItems[3], baseItems[4]];
    } else {
      return baseItems;
    }
  }, [t, state?.dynamicUploadStart]);

  const { automaticHeaderDetection, multipleFileUpload } = setting;

  const insertJoinColumnsStep = useCallback(
    (stepperList: StepperListType[], position: number) => {
      return stepperList.reduce((previousValue, currentValue, currentIndex) => {
        currentIndex - position
          ? previousValue.push(currentValue)
          : previousValue.push(
              {
                path: '/join-column',
                label: t('txt_progress_join_column'),
                rightIcon: true,
              },
              currentValue
            );
        return previousValue;
      }, [] as StepperListType[]);
    },
    [t]
  );

  const navigationSteps = useMemo(() => {
    const selectedSheetLength =
      state?.spreadSheetNavigate?.getSpreadSheetList()?.getSelectedSheets()
        ?.length ?? 0;

    let newSteps = steps;

    if (automaticHeaderDetection) {
      newSteps = newSteps.filter((item) => item.path !== '/select-header');
    }

    if (
      (multipleFileUpload || !multipleFileUpload) &&
      selectedSheetLength > 1
    ) {
      if (!newSteps.find((entry) => entry.path === '/join-column')) {
        newSteps = insertJoinColumnsStep(newSteps, newSteps.length - 2);
      }
    } else {
      newSteps = newSteps.filter((item) => item.path !== '/join-column');
    }

    return newSteps;
  }, [
    steps,
    automaticHeaderDetection,
    multipleFileUpload,
    state,
    insertJoinColumnsStep,
  ]);

  const currentStep = useMemo(() => {
    let position = navigationSteps.findIndex((item) => item.path === pathname);

    const isLoadingInMatchColumn =
      pathname === '/match-column' && (executing || isLoadingModel);

    const isLoadingInReviewEntires =
      pathname === '/review-entries' && loadingInitialValues;

    if (state?.isAutoMapping && isLoadingInReviewEntires) {
      position -= 2;
    } else if (isLoadingInReviewEntires || isLoadingInMatchColumn) {
      position--;
    }

    return position >= 0 ? position : 0;
  }, [
    pathname,
    navigationSteps,
    executing,
    isLoadingModel,
    loadingInitialValues,
    state,
  ]);

  const getStatus = useCallback(
    (index: number) => {
      if (index < currentStep) {
        return 'previous';
      } else if (currentStep === index) {
        return 'current';
      } else {
        return 'next';
      }
    },
    [currentStep]
  );

  return {
    navigation: navigationSteps,
    getStatus,
  };
};

export default useViewModel;
