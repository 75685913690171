import { getBooleanOptionsToRenderUI } from '../constants/boolean';
import { Value } from '../exportData/valueParser/ExportValueParser';
import { DataModel } from '../dataModel/model/DataModel';
import CategoryDataModel from '../dataModel/model/CategoryDataModel';
import { Worksheet } from 'nuvo-exceljs';
import { ARABIC_DECIMAL_SEPARATOR } from '../constants/character';
import { HookedRecordInfoLevel } from '../hooks/hooksAPI';
import { CELL_COLOR } from '../level';
import { DATATYPE } from '../dataType';

type HeaderInfoType = {
  header: string;
  key: string;
  width: number;
  description: string;
  mark: string;
}[];

type DataRow = Record<
  string,
  {
    value: Value;
    errors: { message: string; level: HookedRecordInfoLevel }[];
  }
>;

const DEFAULT_WIDTH_COLUMN = 42;

export const setHeaderStyle = (
  ws: Worksheet,
  headers?: HeaderInfoType,
  isIncludedDesHeader?: boolean
) => {
  for (let i = 0; i < 1; i++) {
    ws.getRow(i + 1).font = {
      bold: i === 0,
    };

    ws.views = [{ state: 'frozen', ySplit: i + 1 }];

    /* istanbul ignore next */

    for (let j = 0; j < ws.getRow(i + 1).cellCount; j++) {
      const cell = ws.getRow(i + 1).getCell(j + 1);
      if (headers && (headers?.length ?? 0) > 0 && isIncludedDesHeader) {
        for (let k = 0; k < headers?.length; k++) {
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FF162338' },
          };
          cell.font = {
            color: { argb: 'FFFFFF' },
          };
          if (
            headers[k]?.header === cell.value &&
            (headers[k].description?.trim()?.length > 0 ||
              headers[k].mark?.trim()?.length > 0)
          ) {
            cell.note = {
              texts: [
                {
                  font: {
                    bold: true,
                    size: 12,
                    color: { argb: '000000' },
                  },
                  text: `DESCRIPTION:\n`,
                },
                {
                  font: {
                    bold: true,
                    size: 12,
                    color: { argb: 'FFFF0000' },
                  },
                  text: `${headers[k].mark}\n`,
                },
                {
                  font: {
                    size: 12,
                  },
                  text: headers[k].description,
                },
              ],
            };
            break;
          }
        }
      }
    }
  }

  let rowIndex = 1;
  for (rowIndex; rowIndex <= ws.rowCount; rowIndex++) {
    ws.getRow(rowIndex).alignment = { vertical: 'top', wrapText: true };
  }
};

export const setColumnWidthAutoFit = (ws: Worksheet) => {
  ws.columns.forEach((column) => {
    column.width = DEFAULT_WIDTH_COLUMN;
  });
};

export const setErrorStyleAndComment = (
  ws: Worksheet,
  contents: DataRow[],
  dataModels: DataModel[],
  columnKeysToIgnoreContent: string[]
) => {
  const maxCell = 80000;
  let count = 0;

  for (let i = 0; i < contents.length; i++) {
    const element = contents[i];
    for (let j = 0; j < dataModels.length; j++) {
      const dataModel = dataModels[j];
      const errors = columnKeysToIgnoreContent.includes(dataModel.getKey())
        ? []
        : element[dataModel.getKey()].errors;
      count++;
      if (errors.length > 0) {
        const cell = ws.getRow(i + 2).getCell(j + 1);

        let hasError = false;
        let hasWarning = false;
        let hasInfo = false;

        for (let i = 0; i < errors.length; i++) {
          if (errors[i].level === 'error') {
            hasError = true;
            break;
          }
        }

        /* istanbul ignore next */
        if (!hasError) {
          for (let i = 0; i < errors.length; i++) {
            if (errors[i].level === 'warning') {
              hasWarning = true;
              break;
            }
          }
        }

        if (!hasError && !hasWarning) {
          for (let i = 0; i < errors.length; i++) {
            if (errors[i].level === 'info') {
              hasInfo = true;
              break;
            }
          }
        }

        const color = (() => {
          if (hasError) {
            return CELL_COLOR.error;
          } else if (hasWarning) {
            return CELL_COLOR.warning;
          } else if (hasInfo) {
            return CELL_COLOR.info;
          } else {
            return CELL_COLOR.info;
          }
        })();

        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: color },
        };

        if (count <= maxCell) {
          cell.note = {
            texts: errors.map((error) => {
              return {
                font: {
                  size: 14,
                },
                text: `${error.message} \n`,
              };
            }),
          };
        }
      }
    }
  }
};

export const setDataValidation = (
  ws: Worksheet,
  dataModels: DataModel[],
  startRow = 1,
  totalRow = 1,
  columnKeysToIgnoreContent: string[]
) => {
  const fieldValidation: {
    model: DataModel;
    index: number;
    options: string;
  }[] = [];
  dataModels.forEach((dataModel, index) => {
    const cellIndex = index + 1;
    if (dataModel.getType() === DATATYPE.SINGLE_SELECT) {
      const categoryModal = dataModel as CategoryDataModel;
      const itemLabelLength = categoryModal
        .getOptions()
        .map((item) => item.label)
        .join(',').length;

      const optionKey = itemLabelLength <= 250 ? 'label' : 'value';

      fieldValidation.push({
        model: dataModel,
        index: cellIndex,
        options: categoryModal
          .getOptions()
          .map((item) =>
            `${item[optionKey]}`.replace(/,/g, ARABIC_DECIMAL_SEPARATOR)
          )
          .join(','),
      });
    }
    if (dataModel.getType() === DATATYPE.BOOLEAN) {
      fieldValidation.push({
        model: dataModel,
        index: cellIndex,
        options: getBooleanOptionsToRenderUI().join(','),
      });
    }
  });

  for (let i = startRow; i <= totalRow; ++i) {
    fieldValidation.forEach((field) => {
      if (!columnKeysToIgnoreContent.includes(field?.model?.getKey())) {
        ws.getCell(i, field?.index).dataValidation = {
          type: 'list',
          allowBlank: true,
          formulae: [`"${field?.options}"`],
        };
      }
    });
  }
};

export const getHeaderKeysDropdownType = (
  dataModels: DataModel[]
): string[] => {
  const headerKeys: string[] = [];
  for (let i = 0; i < dataModels.length; ++i) {
    const element = dataModels[i];
    if (
      element.getType() === DATATYPE.MULTIPLE_SELECT ||
      element.getType() === DATATYPE.SINGLE_SELECT ||
      element.getType() === DATATYPE.COUNTRY_CODE_ALPHA_2 ||
      element.getType() === DATATYPE.COUNTRY_CODE_ALPHA_3 ||
      element.getType() === DATATYPE.BOOLEAN ||
      element.getIsMultiSelection()
    ) {
      headerKeys.push(element.getKey());
    }
  }
  return headerKeys;
};
