import { copyNodeStyle } from 'core/style';
import { useEffect } from 'react';
import { useConfigure } from './../../../configure';

type UseViewModelProps = {
  openModal: () => void;
};

const useViewModel = ({ openModal }: UseViewModelProps) => {
  const { renderUploadButton } = useConfigure();
  const onUploadSuccess = () => {
    openModal();
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (renderUploadButton) {
        const editDataButton = document.getElementById('nuvo-edit-data-button');
        const wrapperRenderButton = document.getElementById(
          'nuvo-custom-upload-button'
        );
        const renderButton =
          wrapperRenderButton?.getElementsByTagName('button');

        if (renderButton?.[0] && editDataButton) {
          copyNodeStyle(renderButton?.[0], editDataButton);
        }
      }
    }, 0);

    return () => {
      clearTimeout(timeout);
    };
  }, [renderUploadButton]);

  return {
    onUploadSuccess,
    renderUploadButton,
  };
};

export default useViewModel;
