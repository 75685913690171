import { css, cx } from 'core/emotion';
import { forwardRef } from 'react';
import { ReactComponent as PlusIcon } from '../../../../../../assets/icon/plus.svg';
import { useTheme } from 'theme';

type CreateNewColumnType = {
  configTheme?: {
    root?: string;
    icon?: string;
  };
  onCreateColumn: () => void;
  label?: string;
};

const CreateNewColumnInput = forwardRef<HTMLInputElement, CreateNewColumnType>(
  ({ configTheme, onCreateColumn, label }) => {
    const theme = useTheme();

    const onClick = () => {
      onCreateColumn();
    };

    return (
      <div
        className={cx(
          'text-blue-dark-900 leading-5.5 border-t-1 rounded-b-medium flex w-full cursor-pointer items-center p-4 text-sm',
          css({
            height: '50px',
            borderColor: theme.getGlobalTheme().getDark50Color(),
          }),
          cx(
            'cursor-pointer',
            css({
              ':hover': {
                backgroundColor: theme.getGlobalTheme().getDark50Color(),
              },
            })
          ),
          configTheme?.root
        )}
        onClick={onClick}
      >
        <PlusIcon
          className={cx(
            'mr-2',
            css`
              min-width: 16px;
            `,
            configTheme?.icon
          )}
        />
        <div className="leading-5.5 w-fit text-blue-light-500 text-sm font-semibold">
          <span>{label}</span>
        </div>
      </div>
    );
  }
);

export default CreateNewColumnInput;
