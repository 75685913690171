import {
  createPopper,
  detectOverflow,
  Instance,
  ModifierArguments,
} from '@popperjs/core';

export class ItemMenuPopper {
  dropdownInstance!: Instance | undefined;
  rootElement!: Element | null;
  dropdownElement!: HTMLElement;
  parentElement!: Element;
  enableExamples = false;
  isEditing = false;
  private defaultPaddingLeft = 87;
  private paddingLeft = this.defaultPaddingLeft;

  initPopper(
    rootElement: Element,
    dropdownElement: HTMLElement,
    parentElement: Element,
    enableExamples: boolean
  ) {
    this.rootElement = rootElement;
    this.dropdownElement = dropdownElement;
    this.parentElement = parentElement;
    this.enableExamples = enableExamples;
    this.dropdownInstance = createPopper(
      this.rootElement,
      this.dropdownElement,
      {
        placement: 'bottom',
        strategy: 'absolute',
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [-1, 0],
            },
          },
          {
            name: 'preventOverflow',
            enabled: false,
          },
          {
            name: 'flip',
            enabled: true,
            options: {
              fallbackPlacements: ['top'],
              boundary: parentElement,
            },
          },
          {
            name: 'overflow-offset',
            enabled: true,
            phase: 'main',
            requiresIfExists: ['offset', 'flip'],
            fn: this.overflowHide,
          },
        ],
      }
    );

    setTimeout(() => {
      this.dropdownInstance?.forceUpdate();
    }, 100);
  }

  /* eslint-disable @typescript-eslint/no-explicit-any */
  overflowHide = ({ state }: ModifierArguments<any>) => {
    const overflowWithRef = detectOverflow(state, {
      boundary: this.parentElement,
      elementContext: 'reference',
    });
    const overflow = detectOverflow(state, {
      boundary: this.parentElement,
    });

    const top = this.enableExamples ? -34 : 0;
    const left = this.paddingLeft * -1 + 32;

    if (
      overflowWithRef.top > top ||
      overflowWithRef.bottom > 0 ||
      overflow.left > left ||
      overflow.right > 2
    ) {
      state.attributes['popper']['overflow-hide'] = true;
    } else {
      state.attributes['popper']['overflow-hide'] = false;
    }
  };

  open(
    rootElement: Element,
    dropdownElement: HTMLElement,
    parentElement: Element,
    enableExamples: boolean,
    htCloneLeftWtHolderElement?: Element
  ) {
    let updatePaddingLeft;
    if (htCloneLeftWtHolderElement) {
      updatePaddingLeft = htCloneLeftWtHolderElement.clientWidth;
    } else {
      updatePaddingLeft = this.defaultPaddingLeft;
    }
    if (this.paddingLeft !== updatePaddingLeft) {
      this.dropdownInstance?.destroy();
      this.dropdownInstance = undefined;
    }
    this.paddingLeft = updatePaddingLeft;

    this.removeEventListener();

    if (rootElement === this.rootElement) {
      this.rootElement = rootElement;

      if (dropdownElement.hasAttribute('overflow-hide')) {
        return dropdownElement.removeAttribute('overflow-hide');
      }

      this.close();

      return;
    }

    if (dropdownElement.style) {
      dropdownElement.scrollTo(0, 0);
    }
    this.isEditing = true;
    this.addEventListener();
    if (this.dropdownInstance) {
      this.dropdownElement = dropdownElement;
      this.rootElement = rootElement;
      this.dropdownInstance.state.elements.reference = rootElement;
      setTimeout(() => {
        this.show(
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          <Element>this.rootElement,
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          <HTMLDivElement>this.dropdownElement
        );
        this.isEditing = true;
      }, 150);
    } else {
      this.initPopper(
        rootElement,
        dropdownElement,
        parentElement,
        enableExamples
      );
    }
  }

  show(rootElement: Element, dropdownElement: HTMLElement) {
    this.rootElement = rootElement;
    this.dropdownElement = dropdownElement;

    this.dropdownElement.style.borderWidth = '1px';

    requestAnimationFrame(() => {
      this.dropdownElement.style.opacity = '1';
      this.dropdownElement.style.pointerEvents = 'auto';

      if (this.dropdownInstance) {
        this.dropdownInstance.state.elements.reference = rootElement;
      }
      this.dropdownInstance?.forceUpdate();
    });
  }

  private addEventListener() {
    window.addEventListener('click', this.onClickOutside);
  }

  private removeEventListener() {
    window.removeEventListener('click', this.onClickOutside);
  }

  private onClickOutside = (event: MouseEvent) => {
    const target = event.target as HTMLDivElement;
    const targetClassList = target.classList;
    if (
      !targetClassList.contains('custom-dropdown-renderer') &&
      !targetClassList.contains('htAutocomplete') &&
      !targetClassList.contains('wtBorder') &&
      !targetClassList.contains('wtSpreader') &&
      !targetClassList.contains('custom-dropdown-boolean-renderer') &&
      !targetClassList.contains('dropdown-item') &&
      !targetClassList.contains('current') &&
      !targetClassList.contains('search-input') &&
      !targetClassList.contains('dropdown-sub-item') &&
      !targetClassList.contains('tick-path') &&
      !targetClassList.contains('dropdown-header') &&
      !targetClassList.contains('dropdown-editor-scroller')
    ) {
      this.close();
    }
  };

  clearInstance() {
    this.dropdownInstance?.destroy();
    this.dropdownInstance = undefined;
    this.removeEventListener();
    this.isEditing = false;
    this.rootElement = null;
  }

  close = () => {
    if (this.dropdownElement) {
      this.dropdownElement.style.opacity = '0';
      this.dropdownElement.style.pointerEvents = 'none';
      this.dropdownElement.style.borderWidth = '0';
      this.isEditing = false;
      this.rootElement = null;
    }
    this.removeEventListener();
  };

  hide = () => {
    if (this.dropdownElement) {
      this.dropdownElement.style.opacity = '0';
      this.dropdownElement.style.pointerEvents = 'none';
      this.dropdownElement.style.borderWidth = '0';
    }
  };
}
