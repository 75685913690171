import {
  ColumnHooks,
  HookedRecordValue,
  NuvoImporter,
  PassSubmitResult,
  RejectSubmitResult,
  SettingsAPI,
  Values,
  WidgetProvider,
  UploadOptions,
  nuvoSession,
  FunctionsUsage,
} from 'nuvo-react';
import { connectToParent } from 'penpal';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { MODIFIER_TYPE, ParentAPI } from 'types/parentAPI';
import Tracking, { FRAMEWORK } from 'tracking';
import { connectionErrorIcon, nuvoIcon } from 'utils/icon';
import { SubmitResultType } from 'core/submitResult';

export function App() {
  const [loading, setLoading] = useState(true);
  const [parentIframe, setParentIframe] = useState<ParentAPI>();
  const [settings, setSettings] = useState<SettingsAPI>();
  const [licenseKey, setLicenseKey] = useState('');
  const [text, setText] = useState('Select file');
  const [renderButtonHtml, setRenderButtonHtml] = useState('');
  const [columnHooks, setColumnHooks] = useState<ColumnHooks>({});
  const [connectionError, setConnectionError] = useState<string>('');
  const [functionsUsage, setFunctionsUsage] = useState<
    undefined | FunctionsUsage
  >();

  useEffect(() => {
    const connection = connectToParent<ParentAPI>({
      debug: process.env.NODE_ENV !== 'production',
      parentOrigin: '*',
      timeout: 1000 * 10,
      methods: {
        init: (
          licenseKey: string,
          settings: SettingsAPI,
          framework: FRAMEWORK,
          origin: string,
          functionsUsage: FunctionsUsage,
          versionNumber: string,
          frameworkVersion: string
        ) => {
          setLicenseKey(licenseKey);
          setSettings(settings);
          setFunctionsUsage(functionsUsage);
          Tracking.setFrameWork(framework);
          Tracking.setOrigin(origin);
          Tracking.setVersionNumber(versionNumber);
          Tracking.setFrameworkVersion(frameworkVersion);
        },
        startSession: (identifier?: string) => {
          nuvoSession.start(identifier);
        },
        uploadSession: (options: UploadOptions, identifier?: string) => {
          nuvoSession.upload(options, identifier);
        },
      },
    });

    connection.promise
      .then((parent) => {
        setParentIframe(parent as ParentAPI);
        setLoading(false);
      })
      .catch((error) => {
        setConnectionError(error.message);
        console.log('[nuvo-error] There was an error', error);
      });
  }, []);

  useEffect(() => {
    parentIframe?.getColumnHooks().then((parentColumnHooks) => {
      setColumnHooks(
        parentColumnHooks?.reduce((columnHooks, column) => {
          return {
            ...columnHooks,
            [column]: (values: HookedRecordValue[]) => {
              return parentIframe.handleColumnHooks(column, values);
            },
          };
        }, {}) ?? {}
      );
    });
  }, [settings, parentIframe]);

  useEffect(() => {
    if (parentIframe) {
      if (
        parentIframe?.handleRenderUploadButton &&
        typeof parentIframe?.handleRenderUploadButton === 'function'
      ) {
        parentIframe?.handleRenderUploadButton()?.then((result) => {
          setRenderButtonHtml(result);
        });
      }

      parentIframe?.handleText()?.then((text) => {
        if (text) {
          setText(text);
        }
      });
    }
  }, [parentIframe]);

  const onEntryInitAllRows = useCallback(
    (data: Values) => {
      return parentIframe!.handleEntryInit(data);
    },
    [parentIframe]
  );

  const connectionErrorPlaceholder = useMemo(() => {
    return (
      <div className="container-error">
        <div>
          <p
            style={{
              display: 'flex',
              justifyContent: 'center',
              fontSize: '24px',
              fontWeight: '500',
            }}
          >
            Error
          </p>
          {connectionErrorIcon()}
          <p style={{ fontSize: '18px' }}>
            Unable to connect to the parent frame
          </p>
          <div className="container-error-powered">
            <div
              style={{
                cursor: 'pointer',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              onClick={() => {
                window.open('https://getnuvo.com', '_blank');
              }}
            >
              <div>{nuvoIcon()}</div>
              <p style={{ fontSize: '12px', margin: '0px 6px' }}>Powered.</p>
              <p className="container-error-tag-hover">learn more</p>
            </div>
          </div>
        </div>
      </div>
    );
  }, []);

  if (connectionError) {
    return connectionErrorPlaceholder;
  }

  if (loading || !parentIframe || !settings || !licenseKey) {
    return null;
  }

  return (
    <WidgetProvider
      onEntryInitAllRows={onEntryInitAllRows}
      functionsUsage={functionsUsage}
    >
      <NuvoImporter
        licenseKey={licenseKey}
        settings={settings}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        metaData={{
          iframe: true,
        }}
        onResults={(result, errors, complete, logs) => {
          return parentIframe
            .handleResults(result, errors, logs)
            .then((submitResult) => {
              if (submitResult !== null) {
                if (submitResult === undefined) {
                  complete();
                } else {
                  const submitResultInstance =
                    submitResult.type === SubmitResultType.Pass
                      ? new PassSubmitResult({
                          failedRecords: submitResult?.failedRecords as number,
                          successfulRecords:
                            submitResult.successfulRecords as number,
                          title: submitResult?.title ?? '',
                          text: submitResult?.text ?? '',
                          imageUrl: submitResult?.imageUrl ?? '',
                          duration: submitResult?.duration,
                        })
                      : new RejectSubmitResult(
                          submitResult?.title ?? '',
                          submitResult?.message ?? ''
                        );

                  complete(submitResultInstance);
                }
              } else {
                setTimeout(() => {
                  complete();
                }, 5000);
              }
            });
        }}
        onCancel={parentIframe.handleCancel}
        onEntryChange={parentIframe.handleEntryChange}
        onEntryInit={parentIframe.handleEntryInitRow}
        columnHooks={columnHooks}
        dataHandler={{
          headerStep: (modifier, data) => {
            return parentIframe?.dataHandlerHeaderStep?.(data)?.then((res) => {
              for (let i = 0; i < res?.operators?.length; i++) {
                const ele = res.operators[i];
                if (ele === undefined || ele === null) continue;
                if (ele.modifierType === MODIFIER_TYPE.ADD_ROW) {
                  if (ele.rowData === undefined) continue;
                  modifier.addRow({
                    data: ele.rowData,
                    index: ele.rowIndex,
                  });
                } else if (ele.modifierType === MODIFIER_TYPE.ADD_COLUMN) {
                  if (ele.columnLabel === undefined) continue;
                  modifier.addColumn({ label: ele.columnLabel });
                } else if (ele.modifierType === MODIFIER_TYPE.REMOVE_ROW) {
                  if (ele.rowIndex === undefined) continue;
                  modifier.removeRow(ele.rowIndex);
                } else if (ele.modifierType === MODIFIER_TYPE.REMOVE_COLUMN) {
                  if (ele.columnIndex === undefined) continue;
                  modifier.removeColumn(ele.columnIndex);
                }
              }
              return res?.data;
            });
          },
          reviewStep: (modifier, data, metaData) => {
            return parentIframe
              ?.dataHandlerReviewStep?.(data, metaData)
              ?.then((res) => {
                for (let i = 0; i < res?.operators?.length; i++) {
                  const ele = res.operators[i];
                  if (ele === undefined || ele === null) continue;
                  if (ele.modifierType === MODIFIER_TYPE.ADD_ROW) {
                    if (ele.rowData === undefined) continue;
                    modifier.addRow({
                      data: ele.rowData,
                      index: ele.rowIndex,
                    });
                  } else if (ele.modifierType === MODIFIER_TYPE.ADD_COLUMN) {
                    if (
                      ele.columnLabel === undefined ||
                      ele.columnType === undefined ||
                      ele.columnKey === undefined
                    ) {
                      continue;
                    }
                    modifier.addColumn({
                      label: ele.columnLabel,
                      columnType: ele.columnType,
                      key: ele.columnKey,
                      dropdownOptions: ele.columnDropdownOptions,
                      validations: ele.columnValidations,
                      outputFormat: ele.outputFormat,
                    });
                  } else if (ele.modifierType === MODIFIER_TYPE.REMOVE_ROW) {
                    if (ele.rowIndex === undefined) continue;
                    modifier.removeRow(ele.rowIndex);
                  } else if (ele.modifierType === MODIFIER_TYPE.REMOVE_COLUMN) {
                    if (ele.columnKey === undefined) continue;
                    modifier.removeColumn(ele.columnKey);
                  }
                }
                return res?.data;
              });
          },
        }}
        renderUploadButton={
          renderButtonHtml
            ? ({ launch }) => (
                <div
                  dangerouslySetInnerHTML={{ __html: renderButtonHtml }}
                  onClick={launch}
                />
              )
            : undefined
        }
      >
        {text}
      </NuvoImporter>
    </WidgetProvider>
  );
}

export default App;
