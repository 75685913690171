import FileImportBanner from './../../../../sheetImporter/FileImportBanner';
import useViewModel from './viewModel';

type UploaderProps = {
  className?: string;
  containerClassName?: string;
  onUploadSuccess: () => void;
  onProcessing: () => void;
  onUploadFailure: () => void;
  onClickManualEntry: () => void;
};

const Uploader = ({
  className,
  onUploadSuccess,
  onProcessing,
  onUploadFailure,
  containerClassName,
  onClickManualEntry,
}: UploaderProps) => {
  const { onUploadFileSuccess } = useViewModel({
    onUploadSuccess,
    onUploadFailure,
  });
  return (
    <FileImportBanner
      className={className}
      containerClassName={containerClassName}
      onFileSelectedSuccess={onUploadFileSuccess}
      onProcessing={onProcessing}
      onFileSelectedError={onUploadFailure}
      onClickManualEntry={onClickManualEntry}
    />
  );
};

export default Uploader;
