import { MutableRefObject, useMemo } from 'react';
import Sheet from './../../sheetImporter/Sheet';
import { CSSInterpolation } from 'core/emotion';
import { useScreenSize } from 'core/constants/screensSize';
import { useLicenseKeyAuth } from 'license';
import { useMediaQuery } from 'react-responsive';
import { SheetData } from '../../sheetImporter/Sheet';
import { displayDateText } from '../../core/date';

type UseViewModelParams = {
  sheet: Sheet;
  headerRowIndexRef: MutableRefObject<number>;
  onHeaderRowIndexChange: (rowIndex: number) => void;
  configTheme?: {
    root: CSSInterpolation;
    th: CSSInterpolation;
    td: CSSInterpolation;
    selectRowColor: string;
    hoverRowColor: string;
  };
};

const useViewModel = ({ sheet }: UseViewModelParams) => {
  const { htLicenseKey } = useLicenseKeyAuth();
  const { isXlargeScreen, isSxlargeScreen } = useScreenSize();

  const data = useMemo(() => {
    const maxRow = 50;
    const rowLength =
      sheet.getData().length <= maxRow ? sheet.getData().length : maxRow;
    const data: SheetData = [];
    for (let i = 0; i < rowLength; i++) {
      const row = sheet.getData()[i].map((col) => displayDateText(col));
      data.push(row);
    }

    return data;
  }, [sheet]);

  const mediaSize = useMediaQuery({
    query: '(max-width: 1440px)',
  });

  const values = useMemo(() => {
    const values = data.map((dataItem, index) => {
      return { ...dataItem, index };
    });

    return values;
  }, [data]);

  return {
    values,
    isSxlargeScreen,
    isXlargeScreen,
    mediaSize,
    htLicenseKey,
  };
};

export default useViewModel;
