import { Route, Routes } from 'react-router-dom';
import {
  SheetSelectionPage,
  SelectHeaderPage,
  UploadFilePage,
} from '../uploadData';
import MatchColumn from '../matchColumn';
import ReviewEntriesPage from '../reviewEntries/ReviewEntriesPage';
import Stepper from 'baseUI/Stepper';
import DevelopMode from 'baseUI/Banner/index';
import { useMainView } from 'settings';
import { MAIN_PAGE_PATH } from 'core/constants/route';
import { useDevelopMode } from 'license';
import { css, cx } from 'core/emotion';
import { useTheme } from 'theme';
import { JoinColumnPage } from 'sheetImporter';
import useLocationPathname from 'core/location';
import { COLOR_SCROLL_BAR } from 'core/constants/colors';
import { useLocation } from 'react-router-dom';
import { useMemo } from 'react';

const RouteList = () => {
  return (
    <Routes>
      <Route path="/upload-file" element={<UploadFilePage />} />
      <Route path="/select-sheet" element={<SheetSelectionPage />} />
      <Route path="/select-header" element={<SelectHeaderPage />} />
      <Route path="/join-column" element={<JoinColumnPage />} />
      <Route path="/match-column" element={<MatchColumn />} />
      <Route path="/review-entries" element={<ReviewEntriesPage />} />
    </Routes>
  );
};

const Router = () => {
  const { pathname } = useLocationPathname();
  const { isDeveloperMode } = useDevelopMode();
  const mainView = useMainView();
  const theme = useTheme();
  const { state: locationState } = useLocation();

  const renderStepper = useMemo(() => {
    const isShowDeveloperMode = isDeveloperMode && !mainView.modal;
    const noPadding = mainView.modal;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if ((locationState as any)?.hideStepper ?? false) {
      return isDeveloperMode ? null : <div className="my-8" />;
    }
    return (
      <div
        className={cx(
          'flex flex-col justify-between',
          noPadding ? '' : 'px-6',
          isShowDeveloperMode ? '-mb-1' : 'mb-4'
        )}
      >
        {isShowDeveloperMode && (
          <div className={cx('w-fit mb-4')}>
            <DevelopMode />
          </div>
        )}
        <Stepper className={cx(isShowDeveloperMode ? '!mb-4' : '!mb-0')} />
      </div>
    );
  }, [locationState, isDeveloperMode, mainView.modal]);

  if (mainView.modal) {
    return (
      <div id="page-wrapper" className="flex h-full w-full flex-col">
        <div className="px-3 lg:px-8">
          {pathname !== MAIN_PAGE_PATH ? renderStepper : null}
        </div>
        <div
          className={cx(
            'flex-shrink flex-grow overflow-y-auto px-3 lg:px-8',
            css({
              '&&': css`
                ::-webkit-scrollbar {
                  -webkit-appearance: none;
                  width: 4px;
                }
                ::-webkit-scrollbar-track {
                  border-radius: 60px;
                  background-color: ${theme.getDialogTheme().scrollbar
                    .backgroundColor ?? COLOR_SCROLL_BAR.BACKGROUND};
                }
                ::-webkit-scrollbar-thumb {
                  border-radius: 60px;
                  background-color: ${theme.getDialogTheme().scrollbar
                    .navigatorColor ?? COLOR_SCROLL_BAR.NAVIGATOR};
                }
                scrollbar-width: thin;
                scrollbar-gutter: stable;
                scrollbar-color: ${theme.getDialogTheme().scrollbar
                    .navigatorColor ?? COLOR_SCROLL_BAR.NAVIGATOR}
                  ${theme.getDialogTheme().scrollbar.backgroundColor ??
                  COLOR_SCROLL_BAR.BACKGROUND};
              `,
            })
          )}
        >
          <RouteList />
        </div>
      </div>
    );
  } else {
    return (
      <div id="page-wrapper" className="w-full">
        {pathname !== MAIN_PAGE_PATH ? renderStepper : null}
        <RouteList />
      </div>
    );
  }
};

export default Router;
