/* istanbul ignore file */

import { cx } from 'core/emotion';
import { ReactComponent as InfoIconText } from '../../assets/icon/info-icon-text.svg';

const TextInfo = ({
  text,
  isShowIcon = true,
  textColor,
  textSize = 'normal',
}: {
  text: string;
  isShowIcon?: boolean;
  textColor?: string;
  textSize?: 'small' | 'normal' | 'large';
}) => {
  return (
    <div
      className={cx(
        'flex justify-center text-left text-base font-normal',
        textSize === 'small' ? '!text-xs' : '',
        textSize === 'large' ? '!text-lg' : '',
        textColor ? textColor : 'text-color-secondary'
      )}
    >
      {isShowIcon && (
        <InfoIconText
          className={cx('mr-2', textColor ? textColor : 'text-color-secondary')}
        />
      )}
      <div className="flex items-center">{text}</div>
    </div>
  );
};

export default TextInfo;
