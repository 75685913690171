import { useTranslation } from 'react-i18next';
import ReviewEntriesActionFooter from '../navigation/ActionFooter';
import { css, cx } from 'core/emotion';
import DataModelSheetForm from '../../dataModelSheet/DataModelSheetForm';
import useViewModel from './viewModel';
import Loading from './Loading';
import { useMainView, useSettings } from 'settings';
import { useLicenseKeyAuth } from 'license';
import {
  useConfigure,
  useFeatureWhiteList,
} from '../../configure/ConfigureProvider';
import { useModal } from '../../main/Modal';
import { useEventLog } from '../../log/eventLog';
import { useAutomaticMapping } from '../../dataModelSheet/hook/useAutomaticMapping';
import Header from '../../navigation/Header';

const footerStyle = css`
  filter: drop-shadow(0px -13px 50px rgba(17, 48, 68, 0.08));
`;

const ReviewEntriesPage = () => {
  const { t } = useTranslation();
  const {
    dataModels,
    initialValues,
    invalidSubmitHandler,
    loadingInitialValues,
    validSubmitHandler,
    ref,
    dataInfos,
    validator,
    errorCount,
    disableExportHasMoreData,
    columns,
    isManualInput,
    wrapperStyle,
    configTheme,
    handleChangeInfo,
    onValidateInitialFinish,
    updateTotalError,
    onBackClick,
    getStyleTable,
    showConfirmModal,
  } = useViewModel();

  const { htLicenseKey } = useLicenseKeyAuth();
  const {
    identifier,
    columns: baseColumns,
    disableTemplateDropdowns,
  } = useSettings();
  const { featureWhiteList } = useFeatureWhiteList();
  const { onEntryChange, onEntryInit } = useConfigure();
  const { toggleFullScreen, isFullScreen } = useModal();
  const { sendLog } = useEventLog();
  const { isBypass, setBypass } = useAutomaticMapping();
  const { modal, enableExamples } = useMainView();
  const wrapperClassName = modal ? 'absolute' : 'fixed';
  const widthWrapperClassName = modal ? 'w-full' : 'px-0';
  const { smartTable } = useSettings();

  if (loadingInitialValues) {
    return <Loading timer={300} initialProgress={0} />;
  }

  return (
    <div className={cx('w-full', modal ? 'h-full' : '')}>
      <DataModelSheetForm
        ref={ref}
        dataModels={dataModels}
        columns={columns}
        onSubmit={validSubmitHandler.handleSubmit}
        onSubmitWhenInvalid={invalidSubmitHandler.handleSubmit}
        dataInfos={dataInfos.current}
        dataSet={initialValues.current}
        handleChangeInfo={handleChangeInfo}
        validator={validator}
        errorCount={errorCount}
        disableExportHasMoreData={disableExportHasMoreData}
        onValidateInitialFinish={onValidateInitialFinish}
        updateTotalError={updateTotalError}
        configTheme={configTheme}
        disableTemplateDropdowns={disableTemplateDropdowns ?? false}
        showConfirmModal={showConfirmModal}
        renderFooter={({ handleSubmit, validating, errorCount }) => {
          return (
            <ReviewEntriesActionFooter
              className={cx(
                'z-101 bottom-0 left-0 right-0 flex flex-col bg-white',
                wrapperClassName,
                widthWrapperClassName,
                footerStyle
              )}
              positiveButtonText={t('txt_complete_imports')}
              disabledPositive={validating}
              onPositiveClick={() => {
                handleSubmit(identifier);
              }}
              onNegativeClick={onBackClick}
              errorCount={errorCount}
              popoverInfoTheme={configTheme.popoverInfo}
            />
          );
        }}
        isManualInput={isManualInput}
        htLicenseKey={htLicenseKey}
        identifier={identifier}
        baseColumns={baseColumns}
        enableExamples={enableExamples}
        modal={modal}
        onEntryChange={onEntryChange}
        onEntryInit={onEntryInit}
        toggleFullScreen={toggleFullScreen}
        isFullScreen={isFullScreen}
        sendLog={sendLog}
        isBypass={isBypass}
        setBypass={setBypass}
        wrapperStyle={wrapperStyle}
        getStyleTable={getStyleTable}
        smartTable={smartTable || false}
        renderHeader={() => {
          return t('txt_review_entries')?.length > 0 ||
            t('txt_review_entries_description')?.length > 0 ? (
            <Header
              title={t('txt_review_entries')}
              description={t('txt_review_entries_description')}
              wrapperClassName={!modal ? '!px-0' : ''}
              headerTheme={configTheme.reviewEntriesTheme.pageHeader}
            />
          ) : null;
        }}
        rowsLimit={featureWhiteList.getRowsLimit()}
      />
    </div>
  );
};

export default ReviewEntriesPage;
