import { ColumnConfig, InputData, ColumnType } from '../types/ml';
import { DATATYPE } from 'core/dataType';
import { CategoryDataModel, DataModel, Option } from 'dataModel';
import Sheet, { Value } from './../../sheetImporter/Sheet';
import SpreadSheetList from './../../sheetImporter/SpreadSheetList';
import { flatten, isNil } from 'lodash';
import { SheetColumn } from '@nuvo-importer/common/sdk';
import {
  checkIsMultipleValues,
  separateMultipleValues,
} from '@nuvo-importer/common/core';

class MatchingDTO {
  private spreadSheetList: SpreadSheetList;
  private dataModels: DataModel[];
  private allSheets: Sheet[];
  private licenseKey: string;

  constructor({
    spreadSheetList,
    dataModels,
    licenseKey,
  }: {
    spreadSheetList: SpreadSheetList;
    dataModels: DataModel[];
    licenseKey: string;
  }) {
    this.spreadSheetList = spreadSheetList;
    this.dataModels = dataModels;
    this.allSheets = this.spreadSheetList.getSelectedSheets();
    this.licenseKey = licenseKey;
  }

  private static getDropdownOptions = (dataModel: DataModel) => {
    if (dataModel.isCategoryType()) {
      const options = (dataModel as CategoryDataModel).getOptions();

      const alternativeOptions: Option[] = [];
      const optionsMapping: Record<string, boolean> = {};

      for (let i = 0; i < options.length; ++i) {
        const option = options[i];
        optionsMapping[`${option.label}:::${option.value}`] = true;
        for (let j = 0; j < option.alternativeMatches.length; ++j) {
          const alternativeOption = option.alternativeMatches[j];
          alternativeOptions.push({
            label: alternativeOption,
            value: alternativeOption,
            type: option.type,
            alternativeMatches: [],
            validations: [],
          });
        }
      }

      const optionsValue = [
        ...options,
        ...alternativeOptions.filter((item) => {
          return !optionsMapping[`${item.label}:::${item.value}`];
        }),
      ];

      return optionsValue;
    } else {
      return null;
    }
  };

  private static getType = (dataModel: DataModel) => {
    const type = dataModel.getType();
    switch (type) {
      case DATATYPE.BOOLEAN:
        return ColumnType.BOOLEAN;
      case DATATYPE.SINGLE_SELECT:
      case DATATYPE.COUNTRY_CODE_ALPHA_2:
      case DATATYPE.COUNTRY_CODE_ALPHA_3:
      case DATATYPE.CURRENCY_CODE:
        return ColumnType.CATEGORY;
      case DATATYPE.INTEGER:
      case DATATYPE.FLOAT:
        return ColumnType.NUMBER;
      default:
        return ColumnType.STRING;
    }
  };

  static mapDataModelToColumn = (dataModel: DataModel) => {
    return {
      key: dataModel.getKey(),
      label: dataModel.getLabel(),
      description: dataModel.getDescription(),
      isMultiple: dataModel.getIsMultiSelection(),
      dropdownOptions: this.getDropdownOptions(dataModel),
      validators: [],
      columnType: this.getType(dataModel).toUpperCase(),
      alternativeMatches: dataModel.getAlternativeMatches(),
      isStaticOptions: [
        DATATYPE.COUNTRY_CODE_ALPHA_2,
        DATATYPE.COUNTRY_CODE_ALPHA_3,
        DATATYPE.CURRENCY_CODE,
        DATATYPE.SINGLE_SELECT,
      ].includes(dataModel.getType()),
      isDynamicItems: dataModel.getType() === DATATYPE.SINGLE_SELECT,
    };
  };

  getColumns = (): ColumnConfig[] => {
    const dataModels = this.dataModels;
    const dataModel = dataModels.map((dataModel) => {
      return MatchingDTO.mapDataModelToColumn(dataModel);
    });

    const dataModelAsAlternative = flatten(
      dataModels
        .map((dataModel) => {
          return dataModel.getAlternativeMatches().map((alternative) => {
            return {
              key: alternative,
              label: alternative,
              description: dataModel.getDescription(),
              isMultiple: dataModel.getIsMultiSelection(),
              dropdownOptions: MatchingDTO.getDropdownOptions(dataModel),
              validators: [],
              columnType: MatchingDTO.getType(dataModel).toUpperCase(),
              alternativeMatches: dataModel.getAlternativeMatches(),
              isStaticOptions: [
                DATATYPE.COUNTRY_CODE_ALPHA_2,
                DATATYPE.COUNTRY_CODE_ALPHA_3,
                DATATYPE.CURRENCY_CODE,
                DATATYPE.SINGLE_SELECT,
              ].includes(dataModel.getType()),
            };
          });
        })
        .filter((data) => data.length > 0)
    );

    const columns = [];

    if (dataModelAsAlternative) {
      columns.push(...dataModel, ...dataModelAsAlternative);
    } else {
      columns.push(...dataModel);
    }

    return columns.filter((column) => !!column);
  };

  static parseValue = (value: Value) => {
    return !isNil(value) ? `${value}` : '';
  };

  static getInputJsonBySheetColumn = (
    sheetColumn: SheetColumn,
    dataModel: DataModel
  ) => {
    const values: InputData = [];
    const rows = sheetColumn.getUniqueRows();
    const maxRow = rows.length;
    const multipleValuesRows: Record<string, string[]> = {};
    const key = sheetColumn.getColumnKey();

    let counter = 0;
    for (let i = 0; i < maxRow; i++) {
      const rowValue = MatchingDTO.parseValue(rows[i]);
      const isMultipleValues =
        checkIsMultipleValues(rowValue) && dataModel.getIsMultiSelection();

      if (isMultipleValues) {
        const multipleValues = separateMultipleValues(rowValue);
        multipleValues.forEach((value) => {
          if (multipleValuesRows[key]) {
            multipleValuesRows[key].push(value);
          } else {
            multipleValuesRows[key] = [value];
          }
        });
      } else {
        if (values[counter]) {
          values[counter][key] = rowValue;
        } else {
          values[counter] = {
            [key]: rowValue,
          };
        }
        counter++;
      }
    }

    const keys = Object.keys(multipleValuesRows);
    const lastRow = values.length;

    for (let i = 0; i < keys.length; i++) {
      const columnKey = keys[i];
      const multipleValues = multipleValuesRows[columnKey];
      multipleValues.forEach((value, index) => {
        const currentRowIndex = lastRow + index;
        if (values[currentRowIndex]) {
          values[currentRowIndex][columnKey] = value;
        } else {
          values[currentRowIndex] = { [columnKey]: value };
        }
      });
    }
    return [values];
  };

  getInputJsonForHeader = () => {
    const values: InputData = [{}];

    this.allSheets.forEach((sheet) => {
      const sheetColumns = sheet.getColumns();
      sheetColumns.forEach((sheetColumn) => {
        values[0][sheetColumn.getColumnKey()] = '';
      });
    });

    return [values];
  };

  getInputHeaderRow = () => {
    const headers: Value[] = [];

    this.allSheets.forEach((sheet) => {
      const sheetColumns = sheet.getColumns();
      sheetColumns.forEach((sheetColumn) => {
        headers.push(sheetColumn.getColumnKey());
      });
    });

    return headers;
  };

  getAllSheets = () => {
    return this.allSheets;
  };

  getAllDataModels = () => {
    return this.dataModels;
  };

  getLicenseKey = () => {
    return this.licenseKey;
  };
}

export default MatchingDTO;
