import { useLayoutEffect, useState } from 'react';
import ContextMenuItem, { Arrow } from '../components/ContextMenuItem';
import { ContextMenuIcon } from '../components/Icon';
import useViewModel from './viewModel';
import RadioChoice from '../components/RadioChoice';
import TextInput from '../components/TextInput';
import Button from '../../../../../components/Button';
import FilterByCondition from './FilterByCondition';
import FilterByValueList from './FilterByValueList';
import AllColumnSetting, {
  ColumnSetting,
} from '../../columns/AllColumnSetting';
import {
  FilterLogicalOperator,
  FilterValueItems,
} from '../../columns/FilterStrategy';
import ContextMenuController from '../controller/ContextMenuController';
import { useTranslation } from 'react-i18next';
import { DataModel } from '../../../../dataModel/model/DataModel';
import { ConfigTheme } from '../../type';
import { css, cx } from '../../../../emotion';

type FilterMenuProps = {
  valueItems: FilterValueItems;
  allColumnSetting: AllColumnSetting;
  contextMenuController: ContextMenuController;
  currentColumnIndex: number;
  columnSetting: ColumnSetting;
  currentDataModel: DataModel;
  configTheme?: ConfigTheme;
};

const FilterMenu = ({
  columnSetting,
  valueItems,
  allColumnSetting,
  contextMenuController,
  currentColumnIndex,
  currentDataModel,
  configTheme,
}: FilterMenuProps) => {
  const [isOpen, setIsOpen] = useState(
    allColumnSetting.hasFilterByIndex(currentColumnIndex)
  );
  const {
    filterByConditions,
    onFirstFilterByConditionChange,
    onSecondFilterByConditionChange,
    logicalOperator,
    logicalOptions,
    setLogicalOperator,
    valuesSelected,
    onValuesSelected,
    onApplyClick,
    onClearFilterClick,
    searchValue,
    setSearchValue,
    showValueItems,
    loading,
    clearFilterLoading,
    checkedCount,
  } = useViewModel({
    columnSetting,
    allColumnSetting,
    contextMenuController,
    valueItems,
    currentColumnIndex,
    currentDataModel,
  });
  const { t } = useTranslation();
  const secondFilterByCondition = filterByConditions[1];

  useLayoutEffect(() => {
    setIsOpen(allColumnSetting.hasFilterByIndex(currentColumnIndex));
  }, [allColumnSetting, currentColumnIndex]);

  return (
    <>
      <ContextMenuItem
        icon={ContextMenuIcon.FILTER}
        text={t('txt_filter')}
        arrowIcon={isOpen ? Arrow.UP : Arrow.DOWN}
        onClick={() => {
          setIsOpen(!isOpen);
        }}
        configTheme={configTheme}
      />
      <div
        className={cx(
          'text-left',
          css({
            '&&': configTheme?.reviewEntriesTheme?.smartTable?.contextMenu
              ?.filter?.root,
          })
        )}
      >
        {isOpen ? (
          <>
            <div className="border-b border-dashed px-4 pb-2 pt-2">
              <FilterByCondition
                condition={filterByConditions[0].condition}
                value={filterByConditions[0].value}
                secondValue={filterByConditions[0].secondValue}
                currentDataModel={currentDataModel}
                onConditionChange={(condition) => {
                  onFirstFilterByConditionChange({
                    condition,
                    value: filterByConditions[0].value,
                    secondValue: filterByConditions[0].secondValue,
                  });
                }}
                onValueChange={(value) => {
                  onFirstFilterByConditionChange({
                    condition: filterByConditions[0].condition,
                    value,
                    secondValue: filterByConditions[0].secondValue,
                  });
                }}
                onSecondValueChange={(value) => {
                  onFirstFilterByConditionChange({
                    condition: filterByConditions[0].condition,
                    value: filterByConditions[0].value,
                    secondValue: value,
                  });
                }}
                configTheme={configTheme}
              />
              {secondFilterByCondition ? (
                <div className="mt-2">
                  <RadioChoice<FilterLogicalOperator>
                    value={logicalOperator}
                    choices={logicalOptions}
                    onChange={setLogicalOperator}
                    size="small"
                    configTheme={configTheme}
                  />
                </div>
              ) : null}
              {secondFilterByCondition ? (
                <FilterByCondition
                  currentDataModel={currentDataModel}
                  className="mt-2"
                  condition={secondFilterByCondition.condition}
                  value={secondFilterByCondition.value}
                  secondValue={secondFilterByCondition.secondValue}
                  onConditionChange={(condition) => {
                    onSecondFilterByConditionChange({
                      condition,
                      value: secondFilterByCondition.value,
                      secondValue: secondFilterByCondition.secondValue,
                    });
                  }}
                  onValueChange={(value) => {
                    onSecondFilterByConditionChange({
                      condition: secondFilterByCondition.condition,
                      value,
                      secondValue: secondFilterByCondition.secondValue,
                    });
                  }}
                  onSecondValueChange={(value) => {
                    onSecondFilterByConditionChange({
                      condition: secondFilterByCondition.condition,
                      value: secondFilterByCondition.value,
                      secondValue: value,
                    });
                  }}
                  configTheme={configTheme}
                />
              ) : null}
            </div>
            <div className="px-4 pt-2 pb-3">
              <div>
                <p
                  className={cx(
                    'text-xss leading-none text-gray-700',
                    css({
                      '&&': configTheme?.reviewEntriesTheme?.smartTable
                        ?.contextMenu?.filter?.label,
                    })
                  )}
                >
                  {t('txt_filter_by_value')}
                </p>
                <TextInput
                  wrapperClassName="mt-1"
                  placeholder={t('txt_search')}
                  value={searchValue}
                  onChange={setSearchValue}
                  size="small"
                  showCloseButton
                  configTheme={
                    configTheme?.reviewEntriesTheme?.smartTable?.contextMenu
                      ?.filter?.input
                  }
                />
              </div>
              <FilterByValueList
                valuesSelected={valuesSelected}
                onValuesSelected={onValuesSelected}
                valueItems={showValueItems}
                currentColumnIndex={currentColumnIndex}
                checkedCount={checkedCount}
                configTheme={configTheme}
              />
              <div className="mt-2 flex space-x-2">
                <Button
                  variant="secondary"
                  loading={clearFilterLoading}
                  disabled={clearFilterLoading}
                  className={cx(
                    'py-10px w-1/2 !px-0',
                    css({
                      '&&': configTheme?.reviewEntriesTheme?.smartTable
                        ?.contextMenu?.filter?.secondButton,
                    })
                  )}
                  loadingStyle="!ml-0 !mr-0"
                  size="small"
                  onClick={onClearFilterClick}
                >
                  {clearFilterLoading
                    ? ''
                    : t('txt_filter_clear', { count: 1 })}
                </Button>
                <Button
                  loading={loading}
                  disabled={loading}
                  loadingStyle="!ml-0 !mr-0"
                  className={cx(
                    'py-10px w-1/2 !px-0',
                    css({
                      '&&': configTheme?.reviewEntriesTheme?.smartTable
                        ?.contextMenu?.filter?.primaryButton,
                    })
                  )}
                  size="small"
                  onClick={onApplyClick}
                >
                  {loading ? '' : t('txt_filter_apply')}
                </Button>
              </div>
            </div>
          </>
        ) : null}
      </div>
    </>
  );
};

export default FilterMenu;
