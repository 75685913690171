import { Sheet, SheetData, SpreadSheet } from '@nuvo-importer/common/sdk';
import { isArray, isNumber } from 'lodash';
import {
  MODIFIER_TYPE,
  MappingStepModifierAddColumn,
  ModifierRemoveRow,
  DataHandlerHeaderStep,
  HeaderStepHandlerData,
  HeaderStepModifierAddRow,
  ModifierStack,
  HeaderStepModifier,
} from './DataHandlerAPI';
import { HandlerFileData } from '../types';
import { getIsDataInSheetDataFormat } from '../sheetImporter/utils';
import { parse } from '@getnuvo/json-parser';
import { ERROR_CODE_IMPORT_FILE } from '../errors/errorCode';

export class HeaderStepHandler {
  private modifierSheets: Sheet[] = [];
  private modifierData: SheetData = [];
  private spreadSheets: SpreadSheet[] = [];
  private headerStepHandler?: DataHandlerHeaderStep;
  private removeRowIndexes: number[] = [];
  private modifierStack: ModifierStack<SheetData>[] = [];

  constructor(headerStepHandler?: DataHandlerHeaderStep) {
    this.headerStepHandler = headerStepHandler;
  }

  private executeModifier = (modifier: ModifierStack<SheetData>) => {
    switch (modifier.modifierType) {
      case MODIFIER_TYPE.ADD_ROW:
        return this.executorAddRow({
          data: modifier.rowData ?? [],
          index: modifier.rowIndex,
        });
      case MODIFIER_TYPE.ADD_COLUMN:
        return this.executorAddColumn({
          label: modifier.columnLabel ?? '',
        });
      case MODIFIER_TYPE.REMOVE_ROW:
        return this.executorRemoveRow(modifier.rowIndex ?? 0);
      case MODIFIER_TYPE.REMOVE_COLUMN:
        if (isNumber(modifier.columnIndex)) {
          return this.executorRemoveColumn(modifier.columnIndex);
        }
    }
  };

  private mappedHandler = async ({
    spreadSheets,
  }: {
    spreadSheets: SpreadSheet[];
  }): Promise<SpreadSheet[]> => {
    this.spreadSheets = spreadSheets;
    if (this?.headerStepHandler) {
      // NOTE: Generate Data parameter for handler function
      const dataParameter: HeaderStepHandlerData[] = [];
      this.spreadSheets.forEach((file) => {
        file.getSelectedSheets().forEach((sheet) => {
          dataParameter.push({
            data: sheet.getData(),
            fileName: file.getFilename(),
            fileSize: file.getFileSize(),
            fileType: file.getType(),
            sheetName: sheet.getName(),
          });
        });
      });

      const userModifiedData = await this.headerStepHandler(
        this.getModifier(),
        dataParameter
      );

      // NOTE: data is not returned
      if (Boolean(userModifiedData) === false) {
        this.modifierData = this.spreadSheets[0]
          .getSelectedSheets()[0]
          .getData();
        this.modifierSheets = this.spreadSheets[0].getSelectedSheets();

        // Apply modifier operations
        this.modifierStack.forEach((entry) => {
          this.executeModifier(entry);
        });
        this.removeRow();
        this.modifierSheets[0].mergedModifiedData(this.modifierData);
        return [
          new SpreadSheet({
            filename: this.spreadSheets[0].getFilename(),
            fileSize: this.spreadSheets[0].getFileSize(),
            sheets: this.modifierSheets,
            type: this.spreadSheets[0].getType(),
          }),
        ];
      } else if (userModifiedData) {
        // NOTE: Single sheet is returned as SheetData
        if (getIsDataInSheetDataFormat(userModifiedData)) {
          this.modifierData = userModifiedData as SheetData;

          this.modifierSheets.push(
            new Sheet({
              data: this.modifierData,
              name: this.spreadSheets[0].getSelectedSheets()[0].getName(),
            })
          );

          this.modifierStack.forEach((entry) => {
            this.executeModifier(entry);
          });
          this.removeRow();
          this.modifierSheets[0].setSelected(true);
          this.modifierSheets[0].mergedModifiedData(this.modifierData);

          return [
            new SpreadSheet({
              filename: this.spreadSheets[0].getFilename(),
              fileSize: this.spreadSheets[0].getFileSize(),
              sheets: this.modifierSheets,
              type: this.spreadSheets[0].getType(),
            }),
          ];
        } else if (
          !isArray(userModifiedData[0]) &&
          this.isFileDataObject(userModifiedData as HandlerFileData[])
        ) {
          // NOTE: Multiple files are returned
          const spreadSheets: SpreadSheet[] = [];

          for (const file of userModifiedData as HandlerFileData[]) {
            if (!getIsDataInSheetDataFormat(file.data)) {
              try {
                file.data = (await parse(JSON.stringify(file.data), {
                  hasDateType: false,
                  advancedParsing: false,
                })) as SheetData;
              } catch (error) {
                throw new Error(`${ERROR_CODE_IMPORT_FILE.INVALID_FORMAT}`);
              }
            }

            const spreadSheet = new SpreadSheet({
              filename: file.fileName,
              sheets: [
                new Sheet({
                  data: file.data as SheetData,
                  name: file.sheetName,
                }),
              ],
              type: file.fileType
                ? file.fileType
                : this.spreadSheets[0].getType(),
            });

            spreadSheet.selectAllSheet(true);
            spreadSheets.push(spreadSheet);
          }

          return spreadSheets;
        } else {
          // NOTE: Single sheet is returned as JSON data
          try {
            this.modifierData = (await parse(JSON.stringify(userModifiedData), {
              hasDateType: false,
              advancedParsing: false,
            })) as SheetData;
          } catch (error) {
            throw new Error(`${ERROR_CODE_IMPORT_FILE.INVALID_FORMAT}`);
          }

          this.modifierSheets.push(
            new Sheet({
              data: this.modifierData,
              name: this.spreadSheets[0].getSelectedSheets()[0].getName(),
            })
          );

          this.modifierStack.forEach((entry) => {
            this.executeModifier(entry);
          });
          this.removeRow();
          this.modifierSheets[0].setSelected(true);
          this.modifierSheets[0].mergedModifiedData(this.modifierData);

          return [
            new SpreadSheet({
              filename: this.spreadSheets[0].getFilename(),
              fileSize: this.spreadSheets[0].getFileSize(),
              sheets: this.modifierSheets,
              type: this.spreadSheets[0].getType(),
            }),
          ];
        }
      }
    }
    return [];
  };

  private dataModifierAddRow: HeaderStepModifierAddRow = ({ data, index }) => {
    this.modifierStack.push({
      modifierType: MODIFIER_TYPE.ADD_ROW,
      rowIndex: index,
      rowData: data,
    });
  };

  private dataModifierAddColumn: MappingStepModifierAddColumn = ({ label }) => {
    this.modifierStack.push({
      modifierType: MODIFIER_TYPE.ADD_COLUMN,
      columnLabel: label,
    });
  };

  private dataModifierRemoveRow: ModifierRemoveRow = (index) => {
    this.modifierStack.push({
      modifierType: MODIFIER_TYPE.REMOVE_ROW,
      rowIndex: index,
    });
  };

  private dataModifierRemoveColumn = (index: number) => {
    this.modifierStack.push({
      modifierType: MODIFIER_TYPE.REMOVE_COLUMN,
      columnIndex: index,
    });
  };

  private executorAddRow: HeaderStepModifierAddRow = ({ data, index }) => {
    if (isNumber(index)) {
      for (let i = 0; i < data.length; i++) {
        const row = this.modifierData[0];
        const newRowData = [];
        for (let j = 0; j < row?.length; j++) {
          const column = data[i][j];
          newRowData.push(column);
        }
        this.modifierData.splice(index + i, 0, newRowData);
      }
    } else {
      if (!this.modifierData) {
        this.modifierData = [];
      }
      for (let i = 0; i < data.length; i++) {
        const row = this.modifierData[0];
        const newRowData = [];
        for (let j = 0; j < row?.length; j++) {
          const column = data[i][j];
          newRowData.push(column);
        }
        this.modifierData?.push(newRowData);
      }
    }
  };

  private executorRemoveRow: ModifierRemoveRow = (index) => {
    this.removeRowIndexes.push(index);
  };

  private executorAddColumn: MappingStepModifierAddColumn = ({ label }) => {
    const isDuplicateColumnKey = this.modifierSheets[0]
      .getColumns()
      .find((entry) => entry.getColumnKey() === label);
    if (!isDuplicateColumnKey) {
      this.modifierData[this.modifierSheets[0].getHeaderRowIndex()].push(label);
      this.modifierSheets[0].addColumn(label);
    }
  };

  private executorRemoveColumn = async (index: number) => {
    this.modifierSheets[0].removeColumnByIndex(index);
    for (let i = 0; i < this.modifierData.length; i++) {
      const rowData = this.modifierData[i];
      const newRowData = [];
      for (let j = 0; j < rowData.length; j++) {
        const column = rowData[j];
        if (j !== index) {
          newRowData.push(column);
        }
      }
      this.modifierData[i] = newRowData;
    }
  };

  private removeRow() {
    const newData = [];
    for (let i = 0; i < this.modifierData.length; i++) {
      if (!this.removeRowIndexes.includes(i)) {
        newData.push(this.modifierData[i]);
      }
    }
    this.modifierData = newData;
  }

  private getModifier(): HeaderStepModifier {
    return {
      addRow: this.dataModifierAddRow,
      removeRow: this.dataModifierRemoveRow,
      addColumn: this.dataModifierAddColumn,
      removeColumn: this.dataModifierRemoveColumn,
    };
  }

  getDataModifier = () => {
    return this.mappedHandler;
  };

  cleanUp = () => {
    this.modifierData = [];
    this.modifierStack = [];
  };

  isFileDataObject = (objects: HandlerFileData[]): boolean => {
    return objects.every(
      (obj) => 'fileName' in obj && 'data' in obj && 'sheetName' in obj
    );
  };
}
